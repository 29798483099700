import { ApiResponse, axiosClient, toApiResponse } from '@/lib/axios';
import { snakeToCamelParser } from '@/Utils';
import { IBequest } from './types';

export type UpsertWillsBequestInput = {
  id?: number | undefined;
  willsRelationshipId: number;
  email: string;
  phone?: string | undefined;
  allocation: string;
  dob?: string;
  firstName?: string;
  lastName?: string;
  organizationName?: string;
  organizationId?: string;
};

export const upsertWillsBequest = async (bequest: UpsertWillsBequestInput): Promise<ApiResponse<IBequest>> => {
  const response = await toApiResponse(axiosClient.post('/wills-bequest', bequest));

  return {
    ...response,
    data: response.data && snakeToCamelParser(response.data),
  };
};
