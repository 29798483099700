import React, { useContext } from 'react';
import {
  StyledPlansWrapper,
  StyledSinglePlanWrapper,
  StyledSinglePlanTopWrapper,
  StyledSinglePlanTagWrapper,
  StyledSinglePlanCoverageInfoWrapper,
  StyledTitle,
  StyledPrice,
  StyledPriceContainer,
  StyledInfo,
  StyledButton,
  StyledSinglePlanBottomWrapper,
} from './WillsPlans.styles';
import { PLANS } from './WillsPlansConstants';
import { PlansBenefits } from '../PlansBenefits';
import { WillsSelectPlanContext } from '../../WillsSelectPlan.context';

const WillsPlans = () => {
  const { handlePlanSelect } = useContext(WillsSelectPlanContext);

  return (
    <StyledPlansWrapper>
      {PLANS.map((plan, index) => {
        const { type, price, info, featuresTitle, features } = plan;

        return (
          <StyledSinglePlanWrapper key={index} type={type}>
            <StyledSinglePlanTopWrapper type={type}>
              <StyledSinglePlanTagWrapper type={type}>MOST POPULAR</StyledSinglePlanTagWrapper>

              <StyledSinglePlanCoverageInfoWrapper>
                <StyledTitle>{type} Coverage</StyledTitle>
                <StyledPriceContainer>
                  <StyledPrice>{price}</StyledPrice> per will
                </StyledPriceContainer>
                <StyledInfo type={type}>{info}</StyledInfo>
                <StyledButton typeOfPlan={type} onClick={() => handlePlanSelect(type)}>
                  Select Plan
                </StyledButton>
              </StyledSinglePlanCoverageInfoWrapper>
            </StyledSinglePlanTopWrapper>
            <StyledSinglePlanBottomWrapper>
              <PlansBenefits type={type} featuresTitle={featuresTitle} features={features} />
            </StyledSinglePlanBottomWrapper>
          </StyledSinglePlanWrapper>
        );
      })}
    </StyledPlansWrapper>
  );
};

export default WillsPlans;
