import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledPrimaryRelativePageContainer = styled.div`
  height: 100%;
  max-width: 90%;

  @media screen and (min-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    max-width: 75%;
  }
`;

export const StyledPrimaryRelativeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.5rem;
  min-height: 30rem;
`;

export const StyledNavigationButtonsWrapper = styled.div`
  margin-top: auto;
`;

export const StyledPrimaryReltiveTextMessage = styled.p`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
  margin-top: 1rem;
`;
