import React, { useContext, useEffect, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { observer } from 'mobx-react-lite';

import { AddressStore, ContactStore, ProfileStore } from '@/Stores';
import { IProfile, IUserAddress, IUserContact } from '@/Types';
import { LocaleContext } from '@/providers';

import { CitizenshipInput, Introduction, LocationInput, Question } from './Components';
import { ArrowLeft, BackButton, OnboardingContainer } from './Onboarding.styles';
import { AddressForm } from './Components/AddressForm';
import onboardingLocales from './onboardingLocales.json';

interface OnboardingProps {
  renderOnComplete: () => JSX.Element;
}
const Onboarding: React.FC<OnboardingProps> = ({ renderOnComplete }) => {
  const { locale } = useContext(LocaleContext);
  const { profile, setProfile } = useContext(ProfileStore);
  const { contact, setContact } = useContext(ContactStore);
  const { address, setAddress } = useContext(AddressStore);

  const [profileData, setProfileData] = useState<IProfile>(
    profile || {
      firstName: '',
      middleName: '',
      lastName: '',
      dob: '',
      citizenship: '',
      preferredLanguage: 'ENGLISH',
      marritalStatus: 'SINGLE',
    },
  );

  const [contactData, setContactData] = useState<IUserContact>(
    contact || {
      cellPhone: '',
      homePhone: '',
      primaryPhone: '',
    },
  );

  const [addressData, setAddressData] = useState<IUserAddress>(
    address || {
      streetName: '',
      streetNumber: '',
      unitSuiteApt: '',
      city: '',
      provinceState: '',
      postalZip: '',
      country: '',
    },
  );

  const [stepNumber, setStepNumber] = useState(0);

  useEffect(() => {
    amplitude.track('Invest KYC Started');
  }, []);

  const incrementStep = () => {
    setStepNumber(stepNumber + 1);
  };

  const isNotStartOfQuestions = () => {
    const numberOfQuestions = 1;
    return stepNumber > numberOfQuestions;
  };

  const isLastQuestion = () => {
    return stepNumber === 10;
  };

  const saveOnboardingData = () => {
    setProfile(profileData);
    setContact(contactData);
    setAddress(addressData);
  };

  const goToNextQuestion = () => {
    if (isLastQuestion()) {
      saveOnboardingData();
    }
    incrementStep();
  };

  const goToPreviousQuestion = () => {
    if (isNotStartOfQuestions()) {
      setStepNumber((currentStep) => {
        const isAddressFormStep = currentStep === 9;
        if (isAddressFormStep) return stepNumber - 2;
        return stepNumber - 1;
      });
    }
  };

  const recordProfile = (name: string, value: string) => {
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const recordContact = (name: string, value: string) => {
    setContactData({
      ...contactData,
      [name]: value,
      primaryPhone: value,
    });
  };

  const onboardingLocale = onboardingLocales[locale];

  const elementArray = [
    <Introduction onClickNext={incrementStep} />,
    <Question
      question={onboardingLocale['question.first-name']}
      placeholder={onboardingLocale['placeholder.first-name']}
      name="firstName"
      goToNextQuestion={goToNextQuestion}
      recordAnswer={recordProfile}
      model={profileData}
      required
    />,
    <Question
      question={onboardingLocale['question.middle-name']}
      placeholder={onboardingLocale['placeholder.middle-name']}
      name="middleName"
      goToNextQuestion={goToNextQuestion}
      recordAnswer={recordProfile}
      model={profileData}
    />,
    <Question
      question={onboardingLocale['question.last-name']}
      placeholder={onboardingLocale['placeholder.last-name']}
      name="lastName"
      goToNextQuestion={goToNextQuestion}
      recordAnswer={recordProfile}
      model={profileData}
      required
    />,
    <Question
      question={onboardingLocale['question.dob']}
      placeholder="yyyy-mm-dd"
      name="dob"
      goToNextQuestion={goToNextQuestion}
      recordAnswer={recordProfile}
      model={profileData}
      formType="dob"
      required
    />,
    <CitizenshipInput
      userProfileData={profileData}
      setProfileData={setProfileData}
      goToNextQuestion={goToNextQuestion}
    />,
    <Question
      question={onboardingLocale['question.marrital-status']}
      placeholder="Married/Unmarried"
      name="marritalStatus"
      goToNextQuestion={goToNextQuestion}
      recordAnswer={recordProfile}
      model={profileData}
      formType="marritalStatus"
      required
    />,
    <Question
      question="What is your preferred language?"
      placeholder="English/French"
      name="preferredLanguage"
      goToNextQuestion={goToNextQuestion}
      recordAnswer={recordProfile}
      model={profileData}
      formType="preferredLanguage"
      required
    />,
    <Question
      question={onboardingLocale['question.cell-phone']}
      name="cellPhone"
      goToNextQuestion={goToNextQuestion}
      recordAnswer={recordContact}
      model={contactData}
      formType="phone"
      required
    />,
    <LocationInput setAddressData={setAddressData} goToNextQuestion={goToNextQuestion} />,
    <AddressForm recordAddress={setAddressData} address={addressData} goToNextQuestion={goToNextQuestion} />,
  ];

  if (stepNumber >= elementArray.length) {
    return renderOnComplete();
  }

  return (
    <OnboardingContainer>
      {isNotStartOfQuestions() && (
        <BackButton type="button" onClick={goToPreviousQuestion}>
          <ArrowLeft />
        </BackButton>
      )}
      {elementArray[stepNumber]}
    </OnboardingContainer>
  );
};

export default observer(Onboarding);
