import React, { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { getCode } from 'country-list';

import { LocaleContext, SnackBarContext } from '@/providers';
import { ProfileStore } from '@/Stores';
import { IProfile } from '@/Types';
import { updateCountryInfo } from '@/lib/invest/pages/Onboarding/Components/CitizenshipInput/CitizenshipConstants';

import { IEditProfile, IProfileInformationFormProps } from './ProfileInformationForm.types';
import profileInfoFormLocales from './profileInformationFormLocales.json';
import { validateDob } from './ProfileInformationForm.helpers';
import {
  BackToProfileContainer,
  ContentHeading,
  EditIcon,
  EditIconContainer,
  Form,
  FormContent,
  GoBackButton,
} from '../../Profile.style';
import { ProfileFormFields } from '../ProfileFormFields';
import { SubmitButton } from '../SubmitButton';
import { EDataTestId } from '@/test-utils/EDataTestId';

const ProfileInformationForm = ({ isEditMode, setIsEditMode }: IProfileInformationFormProps) => {
  const {
    register,
    handleSubmit,
    setError,
    setValue,
    formState: { errors },
  } = useForm();

  const { locale } = useContext(LocaleContext);
  const { setSnackMessage } = useContext(SnackBarContext);
  const { profile, editProfile } = useContext(ProfileStore);

  const [profileInfo, setProfileInfo] = useState<IEditProfile>({ ...profile });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const profileInfoFormLocale = profileInfoFormLocales[locale];

  const onSubmit: SubmitHandler<IEditProfile> = async (data) => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    const enterPressedCitizenshipValue = document.querySelector('.pac-item')?.textContent?.toString();
    let countryShortCode = '';
    if (enterPressedCitizenshipValue) {
      updateCountryInfo();
      const info = getCode(enterPressedCitizenshipValue!);
      if (info) {
        countryShortCode = info!;
      }
    }

    if (data.citizenship === undefined) {
      data.citizenship = (profile as IProfile).citizenship;
    }

    const newUserInfoData: IProfile = {
      firstName: data.firstName as string,
      middleName: data.middleName as string,
      lastName: data.lastName as string,
      marritalStatus: data.marritalStatus as string,
      citizenship: countryShortCode || (data.citizenship as string),
      dob: data.dob?.slice(0, 10) as string,
      preferredLanguage: data.preferredLanguage as string,
    };

    try {
      validateDob(newUserInfoData.dob, locale);
    } catch (validationError: any) {
      setError('dob', { type: 'custom', message: validationError.message });
      setIsSubmitting(false);
      return;
    }

    try {
      await editProfile(newUserInfoData);
      setIsEditMode(false);
      setSnackMessage(profileInfoFormLocale['message.form.success']);
    } catch (error: any) {
      setSnackMessage(error.message);
    }
  };
  return (
    <FormContent data-testid={EDataTestId.USER_PERSONAL_INFO_FORM}>
      <ContentHeading>{profileInfoFormLocale['card.heading']}</ContentHeading>
      <ContentHeading>
        <BackToProfileContainer show={isEditMode}>
          <GoBackButton onClick={() => setIsEditMode(false)} title="exit" aria-label="exit" />
        </BackToProfileContainer>
        <EditIconContainer show={isEditMode}>
          <EditIcon onClick={() => setIsEditMode(true)} />
        </EditIconContainer>
      </ContentHeading>

      <Form onSubmit={handleSubmit(onSubmit)}>
        <ProfileFormFields
          register={register}
          errors={errors}
          profileInfo={profileInfo}
          setProfileInfo={setProfileInfo}
          setValue={setValue}
        />
        <SubmitButton onClick={handleSubmit(onSubmit)} disabled={isSubmitting} />
      </Form>
    </FormContent>
  );
};

export default ProfileInformationForm;
