import { axiosClient, toApiResponse } from '@/lib/axios';
import { IDocumentChecklist } from './types';

export interface IUpdateDocumentChecklistInput {
  isReviewDocumentsCompleted?: true;
  isSupportingDocumentsCompleted?: true;
  isSignDocumentsCompleted?: true;
  isStoreDocumentsCompleted?: true;
}

export const updateDocumentChecklist = (input: IUpdateDocumentChecklistInput) =>
  toApiResponse<IDocumentChecklist>(axiosClient.put('/wills/document-checklist', input));
