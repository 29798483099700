import styled from 'styled-components';

export const LocationInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const LocationInputFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const RequiredInfoIndicator = styled.span`
  color: #ca6e56;
  margin-left: 0.2rem;
`;
