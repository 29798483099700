import { axiosClient } from '@/lib/axios';
import { IProfile, IWillsProfile } from '@/Types';

export const getUserProfile = async () => {
  const response = await axiosClient.get('/profile');
  return response.data;
};

export const getWillsProfile = async () => {
  const response = await axiosClient.get('/wills/profile');
  return response.data;
};

export const putUserProfile = async (profile: IProfile) => {
  const response = await axiosClient.put('/profile', profile);
  return response.data;
};

export const putWillsUserProfile = async (willsProfile: IWillsProfile) => {
  const response = await axiosClient.put('/wills/profile', willsProfile);
  return response.data;
};

export const postUserProfile = async (profile: IProfile) => {
  const response = await axiosClient.post('/profile', profile);
  return response.data;
};

export const postWillsProfile = async (willsProfile: IWillsProfile) => {
  const response = await axiosClient.post('/wills/profile', willsProfile);
  return response.data;
};
