import { CANADIAN_AREA_CODES } from './PhoneFormConstants';

export const getCountryCode = (value: string) => {
  if (value !== '') {
    return value.split('(')[0];
  }
  return '+1';
};

export const getPhoneNumberWithoutCountryCode = (value: string) => {
  if (value !== '') {
    return `(${value.split('(')[1]}`;
  }
  return '';
};

export const isNotEmptyString = (value: string) => {
  return value !== '';
};

export const validatePhoneNumber = (countryCode: string, phoneNumber: string) => {
  const isCanadianCountryCode = countryCode === '+1';
  if (!isCanadianCountryCode) throw new Error('Not a Canadian number');

  const areaCode = phoneNumber.split('(')[1].split(')')[0];
  const isCanadianAreaCode = CANADIAN_AREA_CODES.some((code) => code === areaCode);
  if (!isCanadianAreaCode) throw new Error('Not a valid Canadian area code');
};
