import React, { useRef, useEffect, ChangeEvent, KeyboardEvent, useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as amplitude from '@amplitude/analytics-browser';

import {
  StyledFormLabel,
  StyledInputField,
  StyledForm,
  StyledFormFieldContainer,
  StyledInfoIconWrapper,
  StyledInfoIconContainer,
  StyledInfoIcon,
  WillsProfileContext,
  EAboutYouSteps,
  InfoContentWrapper,
} from '@wills/components';
import { ILegalNameInputs } from './LegalNameQuestion.types';
import { HiddenSubmitButton } from '@/Shared/Shared.styles';
import { AboutYouContainer, Content, FormHeader, FormFooter, IWillsProps } from '../../shared';

import { ProfileStore } from '@/Stores';
import { Modal } from '@/Shared/Components';
import LegalNameQuestionInfo from './LegalNameQuestionInfo';

const LegalNameQuestion = ({ goBack, isEdit, goToSummaryPage }: IWillsProps) => {
  const { profileData, recordProfile, isOnboarding, updateProfile, createProfile, goToStep } =
    useContext(WillsProfileContext);
  const { setProfile } = useContext(ProfileStore);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ILegalNameInputs>({
    defaultValues: {
      firstName: profileData.firstName,
      middleName: profileData.middleName,
      lastName: profileData.lastName,
    },
  });
  const [showInfoBlurb, setShowInfoBlurb] = useState(false);

  const firstNameRef = useRef<HTMLInputElement | null>(null);
  const { name } = register('firstName', { required: true });

  const onTabPressInLastInput = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Tab') {
      event.preventDefault();
      firstNameRef.current?.focus();
    }
  };

  const onNameSubmit: SubmitHandler<ILegalNameInputs> = (_: ILegalNameInputs, event?: React.BaseSyntheticEvent) => {
    const formEvent = event as React.KeyboardEvent<HTMLFormElement>;
    const data = {
      firstName: profileData.firstName,
      middleName: profileData.middleName,
      lastName: profileData.lastName,
    };

    if (!isOnboarding) {
      updateProfile(data);
    } else {
      createProfile(data);
    }
    setProfile({ ...profileData });
    if (formEvent.target && formEvent.target?.id === 'summary-btn') {
      goToSummaryPage!();
    } else {
      goToStep(EAboutYouSteps.DOB_QUESTION);
    }
  };

  const onFirstNameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('firstName', event.target.value);
    recordProfile(event.target.name, event.target.value);
  };

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    recordProfile(event.target.name, event.target.value);
  };

  useEffect(() => {
    firstNameRef?.current?.focus();
  }, []);

  useEffect(() => {
    amplitude.track('Wills About You Flow Started');
  }, []);

  return (
    <AboutYouContainer data-testid="legal-name-question">
      <InfoContentWrapper InfoContent={LegalNameQuestionInfo}>
        <Content>
          <FormHeader subHeader="ABOUT YOU" header="Tell us your full legal name" />
          <StyledForm onSubmit={(event) => handleSubmit(onNameSubmit)(event)}>
            <StyledFormFieldContainer>
              <StyledFormLabel>First Name</StyledFormLabel>
              <StyledInputField
                ref={firstNameRef}
                name={name}
                value={profileData.firstName}
                placeholder="please provide first name"
                onChange={onFirstNameChangeHandler}
                error={!!errors.firstName}
              />
            </StyledFormFieldContainer>
            <StyledFormFieldContainer>
              <StyledFormLabel>Middle Name (optional)</StyledFormLabel>
              <StyledInputField {...register('middleName', { onChange })} placeholder="please provide middle name" />
            </StyledFormFieldContainer>
            <StyledFormFieldContainer>
              <StyledFormLabel>Last Name</StyledFormLabel>
              <StyledInputField
                {...register('lastName', { required: true, onChange })}
                onKeyDown={(event) => onTabPressInLastInput(event)}
                placeholder="please provide last name"
                error={!!errors.lastName}
              />
            </StyledFormFieldContainer>
            <HiddenSubmitButton type="submit" />
          </StyledForm>

          <StyledInfoIconWrapper noMargin>
            <StyledInfoIconContainer onClick={() => setShowInfoBlurb(true)}>
              <StyledInfoIcon />
            </StyledInfoIconContainer>
          </StyledInfoIconWrapper>

          {showInfoBlurb && (
            <Modal transparent onCloseCallback={() => setShowInfoBlurb(false)}>
              <LegalNameQuestionInfo />
            </Modal>
          )}

          <FormFooter
            goBack={goBack}
            handleSubmit={handleSubmit}
            onFormSubmit={handleSubmit(onNameSubmit)}
            isEdit={isEdit}
          />
        </Content>
      </InfoContentWrapper>
    </AboutYouContainer>
  );
};

export default LegalNameQuestion;
