import React, { useContext } from 'react';

import { PROVINCE_DATA } from '@/Shared/Constants/provinceData';
import { LocaleContext } from '@/providers';

import {
  AddressIcon,
  ErrorMessage,
  InfoAttributes,
  InfoContent,
  InfoHeader,
  InputField,
  SelectField,
  SingleInfoWrapper,
} from '../../Profile.style';
import addressFormFieldsLocales from './AddressFormFields.locales.json';
import { limitAddressValue } from '../../ProfileHelpers';
import { IAddressFormFieldsProps } from './AddressFormFields.types';

const AddressFormFields = ({ register, errors, addressInfo, setAddressInfo }: IAddressFormFieldsProps) => {
  const { locale } = useContext(LocaleContext);
  const inputLocales = addressFormFieldsLocales[locale];

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    limitAddressValue(event.target.name, event);
    setAddressInfo({ ...addressInfo, [event.target.name]: event.target.value });
  };

  return (
    <>
      <SingleInfoWrapper>
        <AddressIcon />
        <InfoAttributes>
          <InfoHeader>{inputLocales['label.street-name']}</InfoHeader>
          <InfoContent>
            <InputField
              type="text"
              {...register('streetName', {
                required: inputLocales['message.error.street-name.required'],
                onChange,
              })}
              value={addressInfo.streetName}
            />
            {errors.streetName && <ErrorMessage>{errors.streetName.message?.toString()}</ErrorMessage>}
          </InfoContent>
        </InfoAttributes>
      </SingleInfoWrapper>

      <SingleInfoWrapper>
        <AddressIcon />
        <InfoAttributes>
          <InfoHeader>{inputLocales['label.street-number']}</InfoHeader>
          <InfoContent>
            <InputField
              type="text"
              {...register('streetNumber', {
                required: inputLocales['message.error.street-number.required'],
                onChange,
              })}
              value={addressInfo.streetNumber}
            />
            {errors.streetNumber && <ErrorMessage>{errors.streetNumber.message?.toString()}</ErrorMessage>}
          </InfoContent>
        </InfoAttributes>
      </SingleInfoWrapper>

      <SingleInfoWrapper>
        <AddressIcon />
        <InfoAttributes>
          <InfoHeader>{inputLocales['label.apartment']}</InfoHeader>
          <InfoContent>
            <InputField type="text" {...register('unitSuiteApt', { onChange })} value={addressInfo.unitSuiteApt} />
          </InfoContent>
        </InfoAttributes>
      </SingleInfoWrapper>

      <SingleInfoWrapper>
        <AddressIcon />
        <InfoAttributes>
          <InfoHeader>{inputLocales['label.city']}</InfoHeader>
          <InfoContent>
            <InputField
              type="text"
              {...register('city', {
                required: inputLocales['message.error.city.required'],
                onChange,
              })}
              value={addressInfo.city}
            />
            {errors.city && <ErrorMessage>{errors.city.message?.toString()}</ErrorMessage>}
          </InfoContent>
        </InfoAttributes>
      </SingleInfoWrapper>

      <SingleInfoWrapper>
        <AddressIcon />
        <InfoAttributes>
          <InfoHeader>{inputLocales['label.province']}</InfoHeader>
          <InfoContent>
            <SelectField {...register('provinceState', { onChange })} value={addressInfo.provinceState}>
              {PROVINCE_DATA.map((province) => (
                <option key={province.id} value={province.value}>
                  {province.name[locale]}
                </option>
              ))}
            </SelectField>
            {errors.provinceState && <ErrorMessage>{errors.provinceState.message?.toString()}</ErrorMessage>}
          </InfoContent>
        </InfoAttributes>
      </SingleInfoWrapper>

      <SingleInfoWrapper>
        <AddressIcon />
        <InfoAttributes>
          <InfoHeader>{inputLocales['label.zip']}</InfoHeader>
          <InfoContent>
            <InputField
              type="text"
              {...register('postalZip', {
                required: inputLocales['message.error.zip.required'],
                onChange,
              })}
              value={addressInfo.postalZip}
            />
            {errors.postalZip && <ErrorMessage>{errors.postalZip.message?.toString()}</ErrorMessage>}
          </InfoContent>
        </InfoAttributes>
      </SingleInfoWrapper>

      <SingleInfoWrapper>
        <AddressIcon />
        <InfoAttributes>
          <InfoHeader>{inputLocales['label.country']}</InfoHeader>
          <InfoContent>
            <SelectField {...register('country', { onChange })}>
              <option value="CA">Canada</option>
            </SelectField>
            {errors.country && <ErrorMessage>{errors.country.message?.toString()}</ErrorMessage>}
          </InfoContent>
        </InfoAttributes>
      </SingleInfoWrapper>
    </>
  );
};

export default AddressFormFields;
