import React from 'react';
import { Navigate, Route, Outlet } from 'react-router-dom';
import { createWillsRoute } from '@/lib/wills/routes/createWillsRoute/createWillsRoute';
import { ReturnAfterCheckout } from '@/lib/payment/components/ReturnAfterCheckout';
import { createInvestRoute } from '@/lib/invest';
import { ProductSelector, Profile, UserKyc } from '@/lib/user';
import { PrivateOnload } from './PrivateOnload';
import { UserProvider } from '@/lib/user/UserContext';
import { ErrorPage } from '@/components';
import { ErrorBoundaryWithUrlReset } from '@/components/ErrorBoundaryWithUrlReset/ErrorBoundaryWithUrlReset';

export const privateRoutes = (
  <Route
    id="private"
    key="private"
    element={
      <ErrorBoundaryWithUrlReset renderFallback={(props) => <ErrorPage {...props} />}>
        <UserProvider>
          <PrivateOnload>
            <Outlet />
          </PrivateOnload>
        </UserProvider>
      </ErrorBoundaryWithUrlReset>
    }
  >
    <Route key="private-index" index element={<Navigate to="product-selector" replace />} />
    <Route key="profile" path="profile" element={<Profile />} />
    <Route key="product-selector" path="product-selector" element={<ProductSelector />} />
    <Route key="return-after-checkout" path="return" element={<ReturnAfterCheckout />} />
    {createInvestRoute()}
    {createWillsRoute()}
    <Route key="private-fallback-route" path="*" element={<Navigate to="../product-selector" replace />} />
    <Route
      id="onboarding"
      key="user"
      path="onboarding"
      element={<UserKyc renderOnComplete={() => <Navigate to="/product-selector" replace />} />}
    />
  </Route>
);
