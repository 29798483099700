import styled from 'styled-components';

export const StyledCheckoutFormButtonContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 1rem;
  background-color: transparent;
  border: none;
`;
