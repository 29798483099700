import React from "react";

import { StyledBadge } from "./Badge.styles";

type TProps = {
    bgColor: string;
    textColor: string;
    children: React.ReactNode;
}

const Badge = ({ bgColor, textColor, children }: TProps) => {
  return (
    <StyledBadge bgColor={bgColor} textColor={textColor}>{children}</StyledBadge>
  );
};
export default Badge;