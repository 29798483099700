import React, { useContext } from 'react';

import { LocaleContext } from '@/providers';

import { ArrowIcon, IconContainer, PromptContainer, PromptText } from './Prompt.styles';
import promptLocales from './promptLocales.json';

interface IProps {
  onClick: any;
  disabled?: boolean;
}

const Prompt: React.FC<IProps> = ({ onClick, disabled }) => {
  const { locale } = useContext(LocaleContext);

  const promptLocale = promptLocales[locale];

  return (
    <PromptContainer onClick={onClick}>
      <PromptText>{promptLocale.text}</PromptText>
      <IconContainer disabled={disabled}>
        <ArrowIcon />
      </IconContainer>
    </PromptContainer>
  );
};

export default Prompt;
