import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledPowerOfAttorneyPageContainer = styled.div`
  background-color: ${Palette.AQUA_HAZE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledPowerOfAttorneyGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 0.75fr) minmax(0, 0.25fr);
  gap: 2rem;
  min-height: 30rem;

  @media (max-width: ${Breakpoints.TABLET_MAX_WIDTH}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledPowerOfAttorneyContentContainer = styled.div`
  width: min(70%, 1366px);
  margin: 5rem auto;

  @media (max-width: ${Breakpoints.TABLET_MAX_WIDTH}) {
    width: 85%;
  }
`;

export const StyledPowerOfAttorneyInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledNavigationButtonsWrapper = styled.div`
  margin-top: auto;
`;

export const StyledRelativeTipsWrapper = styled.div`
  @media (max-width: ${Breakpoints.TABLET_MAX_WIDTH}) {
    display: none;
  }
`;

export const StyledPowerOfAttorneyTextMessage = styled.p<{ center?: boolean }>`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
  margin-top: 1rem;
  ${({ center }) => center && 'text-align: center;'}
`;

export const StyledPowerOfAttorneyChooserContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

export const StyledPowerOfAttorneyChooserContent = styled.div`
  width: 45%;
  min-width: 18rem;
  gap: 2rem;
  display: flex;
`;

export const StyledBackupTrusteesContainer = styled.div`
  margin-top: 2rem;
`;

export const StyledBackupPowerOfAttorneyContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
`;

export const StyledBackupPowerOfAttorneyContent = styled.div`
  min-width: 23rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${Breakpoints.TABLET_MAX_WIDTH}) {
    width: 85%;
    min-width: 21rem;
  }
`;
