import React from 'react';

import {
  StyledMfaModalContainer,
  StyledModalButton,
  StyledModalButtonGroup,
  StyledModalParagraph,
} from './RemoveMfaModal.styles';
import { IRemoveMfaModalProps } from './RemoveMfaModal.types';

const RemoveMfaModal = ({ onConfirmMfaBtnClick, onCancel, disabled }: IRemoveMfaModalProps) => {
  return (
    <StyledMfaModalContainer>
      <StyledModalParagraph>Are you sure you want to remove Multi-factor authentication?</StyledModalParagraph>
      <StyledModalButtonGroup>
        <StyledModalButton secondary type="button" onClick={onCancel}>
          Cancel
        </StyledModalButton>
        <StyledModalButton type="button" disabled={disabled} onClick={onConfirmMfaBtnClick}>
          Confirm
        </StyledModalButton>
      </StyledModalButtonGroup>
    </StyledMfaModalContainer>
  );
};

export default RemoveMfaModal;
