import React from 'react';
import { Overlay } from '@/Shared/Components/Overlay';
import { Content } from './OpenUrl.style';

export const OpenUrl: React.FC<{
  url: string;
  type: 'view' | 'download';
  onClose: () => void;
}> = ({ url, type, onClose }) => {
  const ref = React.useRef<HTMLAnchorElement | null>(null);

  React.useEffect(() => {
    ref.current?.click();
  }, [url]);

  return (
    <Overlay onBackgroundClick={onClose} type="fixed">
      <Content>
        {type === 'download' ? (
          <p>
            Downloading file... If your file does not download,{' '}
            <a href={url} ref={ref} download rel="noreferrer" onClick={onClose}>
              click here
            </a>
            .
          </p>
        ) : (
          <p>
            Opening file... If your file does not open,{' '}
            <a href={url} ref={ref} rel="noreferrer" onClick={onClose}>
              click here
            </a>
            .
          </p>
        )}
      </Content>
    </Overlay>
  );
};
