import { useEffect, useContext, useReducer, useCallback } from 'react';
import { SnackBarContext } from '@/providers';
import { getWillsRelationships } from '../apis/relationship/getWillsRelationships';
import { IRelativeResponse } from '../pages/WillsFamilyFlow/WillsFamilyFlow.types';
import { AppointeeFilterKey, AppointeeRole, IAppointeeRole, RelationShipList } from '../types';
import { snakeToCamelParser } from '@/Utils';

interface IAppointeeState {
  executors: RelationShipList;
  guardians: RelationShipList;
  poaProperty: RelationShipList;
  poaPersonalCare: RelationShipList;
}

interface IAppointeeAction {
  type: string;
  payload: IAppointeeState;
}

const INITIAL_STATE: IAppointeeState = {
  executors: null,
  guardians: null,
  poaProperty: null,
  poaPersonalCare: null,
};

/**
 * maps relatives to appointees
 * @param relatives {IRelativeResponse[]}
 * @param key {AppointeeFilterKey} - key to filter relatives. Eg. "stateExecutor", "poaProperty" etc.
 * @param role {string} - any value for displaying. Eg. Primary Guardian
 *
 * @returns IAppointeeRole[]
 */
function getAppointeesFromResponse(
  relatives: IRelativeResponse[],
  key: AppointeeFilterKey,
  role: AppointeeRole,
): IAppointeeRole[] {
  const filteredRelatives = relatives.filter((relative) => relative[key]);

  const appointees = filteredRelatives.map((relative) => {
    const appoiteeRole = {
      name: `${relative.firstName} ${relative.lastName}`,
      role,
      email: relative.contact?.email ?? '',
    };
    return appoiteeRole;
  });

  return appointees;
}

function reducer(state: IAppointeeState, action: IAppointeeAction): IAppointeeState {
  const { type, payload } = action;

  switch (type) {
    case 'SET_STATE':
      return payload;
    default:
      return state;
  }
}

export function useAppointees() {
  const { setSnackMessage } = useContext(SnackBarContext);
  const [appointees, dispatch] = useReducer(reducer, INITIAL_STATE);

  const loadRelationships = useCallback(async () => {
    const { data, error } = await getWillsRelationships('all');

    if (error || !data) {
      setSnackMessage(error.message ?? 'Something went wrong');
      return;
    }

    const transformedRelatives = data.map((relative) => {
      const transformedRelative: IRelativeResponse = snakeToCamelParser(relative);
      transformedRelative.contact = snakeToCamelParser(relative.contact);
      return transformedRelative;
    });

    const primaryExecutors = getAppointeesFromResponse(transformedRelatives, 'stateExecutor', 'Estate Executor');
    const secondaryExecutors = getAppointeesFromResponse(
      transformedRelatives,
      'backupStateExecutor',
      'Backup Estate Executor',
    );
    const primaryGuardians = getAppointeesFromResponse(
      transformedRelatives,
      'primaryChildGuardian',
      'Primary Child Guardian',
    );
    const secondaryGuardians = getAppointeesFromResponse(
      transformedRelatives,
      'secondaryChildGuardian',
      'Secondary Child Guardian',
    );
    const primaryPoaProperty = getAppointeesFromResponse(
      transformedRelatives,
      'poaProperty',
      'Power of Attorney for Property',
    );
    const secondaryPoaProperty = getAppointeesFromResponse(
      transformedRelatives,
      'backupPoaProperty',
      'Backup Power of Attorney for Property',
    );
    const primaryPoaPersonalCare = getAppointeesFromResponse(
      transformedRelatives,
      'poaPersonalCare',
      'Power of Attorney for Personal Care',
    );
    const secondaryPoaPersonalCare = getAppointeesFromResponse(
      transformedRelatives,
      'backupPoaPersonalCare',
      'Backup Power of Attorney for Personal Care',
    );

    const payload: IAppointeeState = {
      executors: primaryExecutors.concat(secondaryExecutors),
      guardians: primaryGuardians.concat(secondaryGuardians),
      poaProperty: primaryPoaProperty.concat(secondaryPoaProperty),
      poaPersonalCare: primaryPoaPersonalCare.concat(secondaryPoaPersonalCare),
    };

    dispatch({ type: 'SET_STATE', payload });
  }, []);

  useEffect(() => {
    loadRelationships();
  }, [loadRelationships]);

  return { appointees };
}
