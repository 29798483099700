import { IAppointeeRole } from '@/lib/wills';

export function findAppointeeInEmailPayload(emailsToNotify: IAppointeeRole[], appointee: IAppointeeRole) {
  return emailsToNotify.filter(
    (emailPayload) => emailPayload.email === appointee.email && emailPayload.role === appointee.role,
  );
}

export function removeAppointeeFromEmailPayload(emailsToNotify: IAppointeeRole[], appointee: IAppointeeRole) {
  return emailsToNotify.filter(
    (emailPayload) => emailPayload.email !== appointee.email || emailPayload.role !== appointee.role,
  );
}
