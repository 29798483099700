import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const AuthContainerWrapper = styled.div`
  width: 100%;
  background-color: ${Palette.AQUA_HAZE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  box-sizing: border-box;
  padding: 1.8rem 0;
  min-height: 100vh;
`;

export const AuthContainer = styled.div`
  width: min(35rem, 90%);
  min-height: 30rem;
  background-color: ${Palette.NEW_WHITE};
  margin-top: 5rem;
  border-radius: 0.5rem;
  // box-shadow: 0 2px 10px 1px ${Palette.GREY};
`;

export const ExternalLink = styled.a`
  color: ${Palette.NEW_BLUE};
`;
