/* eslint-disable no-nested-ternary */
import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';

import { Header } from '@/Shared/Components';
import { LocaleContext } from '@/providers';
import { ProfileStore } from '@/Stores';
import { homeSvg, investSvg, willsSvg, cardSvg, bankingSvg } from '@/Assets/images';

import { Product } from './Components';
import {
  HeaderWrapper,
  ProductListContainer,
  ProductSelectorWrapper,
  ProductLink,
  ProductLinkExternal,
  StyledBlockLetter,
  StyledLetter,
} from './ProductSelector.styles';
import { renderGreetingsText } from './ProductSelectorHelper';
import productSelectorLocales from './productSelectorLocales.json';
import { EDataTestId } from '@/test-utils/EDataTestId';
import { useReligion } from '@/lib/contextAware';

const ProductSelector: React.FC = () => {
  const { locale } = useContext(LocaleContext);
  const { profile } = useContext(ProfileStore);
  const { isReligious } = useReligion();
  const productSelectorLocale = productSelectorLocales[locale];

  return (
    <ProductSelectorWrapper data-testid={EDataTestId.PRODUCT_SELECTOR}>
      <ProductListContainer>
        <HeaderWrapper>
          <Header italic bold>
            {isReligious ? (
              <>
                Assal
                <StyledBlockLetter>
                  <StyledLetter>a</StyledLetter>
                </StyledBlockLetter>
                mu &apos;Alaykum
              </>
            ) : (
              productSelectorLocale['header.greeting']
            )}
            {profile ? `, ${profile.firstName}.\n${renderGreetingsText(locale)}` : '.'}
          </Header>
        </HeaderWrapper>
        <ProductLinkExternal
          href="https://getmanzil.typeform.com/to/qEh0ChP1#source=xxxxx&clicked=xxxxx"
          target="_blank"
        >
          <Product
            image={homeSvg}
            title={productSelectorLocale['product.homes.header']}
            description={productSelectorLocale['product.homes.description']}
            footer={productSelectorLocale['product.homes.footer']}
          />
        </ProductLinkExternal>
        <ProductLink to="/invest">
          <Product
            image={investSvg}
            title={productSelectorLocale['product.invest.header']}
            description={productSelectorLocale['product.invest.description']}
          />
        </ProductLink>
        <ProductLink to="/wills">
          <Product
            image={willsSvg}
            title={productSelectorLocale['product.wills.header']}
            description={productSelectorLocale['product.wills.description']}
            footer={productSelectorLocale['product.wills.footer']}
          />
        </ProductLink>
        <ProductLinkExternal href="https://getmanzil.typeform.com/banking" target="_blank" rel="noreferrer">
          <Product
            image={bankingSvg}
            title={productSelectorLocale['product.chequings.header']}
            description={productSelectorLocale['product.chequings.description']}
          />
        </ProductLinkExternal>
      </ProductListContainer>
    </ProductSelectorWrapper>
  );
};

export default observer(ProductSelector);
