import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const StyledPlansWrapper = styled.div`
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  margin-top: 2rem;

  @media screen and (min-width: 768px) {
    width: 60%;
    flex-direction: row;
    margin-top: 4rem;
  }
`;

export const StyledSinglePlanWrapper = styled.div<{ type?: string }>`
  box-shadow: rgba(16, 24, 40, 0.08);
  @media screen and (min-width: 768px) {
    order: ${({ type }) => (type === 'Premium' ? '2' : '1')};
    width: 50%;
    align-self: ${({ type }) => type === 'Essentials' && 'flex-end'};
  }
`;

export const StyledSinglePlanTopWrapper = styled.div<{ type?: string }>`
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  background-color: ${({ type }) => (type === 'Premium' ? `${Palette.SLATE_GREEN}` : '#FFFFFF')};
  color: ${({ type }) => (type === 'Premium' ? `${Palette.NEW_WHITE}` : `${Palette.NEW_BLUE}`)};
`;

export const StyledSinglePlanTagWrapper = styled.div<{ type?: string }>`
  background-color: ${Palette.GOLD_CRAYOLA};
  font-weight: 500;
  font-family: 'SofiaPro-Regular';
  height: 3.75rem;
  text-align: center;
  font-size: 1.125rem;
  color: ${Palette.NEW_BLUE};
  display: grid;
  place-items: center;
  border-top-right-radius: 1rem;
  border-top-left-radius: 1rem;
  display: ${({ type }) => (type === 'Premium' ? 'grid' : 'none')};
`;

export const StyledSinglePlanBottomWrapper = styled.div`
  background-color: #ffffff;
  padding: 1.5rem;
  border-bottom-left-radius: 1rem;
  border-bottom-right-radius: 1rem;
`;

export const StyledSinglePlanCoverageInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding: 1.5rem;
`;

export const StyledTitle = styled.h4`
  font-family: 'SofiaPro-Regular';
  font-weight: 600;
  font-size: 1.25rem;
`;

export const StyledPriceContainer = styled.div<{ type?: string }>`
  font-family: 'SofiaPro-Regular';
  font-size: 1rem;
`;

export const StyledPrice = styled.span`
  font-weight: 600;
  font-size: 3.75rem;
  display: inline-block;
`;

export const StyledInfo = styled.p<{ type?: string }>`
  font-family: 'SofiaPro-Regular';
  font-weight: 400;
  font-size: 1rem;
  color: ${({ type }) => (type === 'Premium' ? `${Palette.NEW_GREY}` : `${Palette.PLANET_BLUE}`)};
`;

export const StyledButton = styled.button<{ typeOfPlan?: string }>`
  cursor: pointer;
  display: block;
  width: 100%;
  background-color: ${Palette.GOLD_CRAYOLA};
  display: grid;
  place-items: center;
  font-weight: 600;
  color: ${Palette.NEW_BLUE};
  padding: 0.8rem 1.25rem;
  border: none;
  border-radius: 0.5rem;

  @media screen and (min-width: 1600px) {
    ${({ typeOfPlan }) =>
      typeOfPlan === 'Essentials' &&
      `
            position: relative;
            top: 1.3rem;
        `}
  }
`;
