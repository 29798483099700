import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const SubHeaderText = styled.h3<{ alignCenter?: boolean; mb?: number }>`
  font-family: 'SofiaPro-Regular';
  margin-bottom: 2rem;
  color: ${Palette.NEW_BLUE};
  ${({ alignCenter }) => alignCenter && 'text-align: center;'}
  ${({ mb }) => `margin-bottom: ${mb ?? 2}rem;`}
`;
