import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Header, Spinner } from '@/Shared/Components';
import { LocaleContext } from '@/providers';

import { StyledMessage, StyledVerificationContainer, StyledLink } from './Verification.styles';
import { confirmUser } from '../../api/confirmUser';
import verificationLocales from './Verification.locales.json';

const Verification: React.FC = () => {
  const { locale } = useContext(LocaleContext);
  const { userSub } = useParams();

  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const verificationLocale = verificationLocales[locale];

  const verifyUser = async (userSubString: string) => {
    setLoading(true);

    try {
      const { data, error: apiError } = await confirmUser(userSubString);
      if (apiError) {
        throw new Error(apiError.message);
      }
      if (data.Code === 'ConfirmationFailure') {
        if (data.Message.includes('Current status is CONFIRMED')) return;
        throw new Error(data.Message);
      }
    } catch (err: any) {
      setError(verificationLocale['text.error-message']);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (userSub === null) {
      setError(verificationLocale['text.error-message']);
    } else {
      verifyUser(userSub!);
    }
  }, []);

  const errorComponent = <Header alignCenter>{error}</Header>;

  const verifiedComponent = (
    <>
      <Header alignCenter>{verificationLocale['text.success-heading']}</Header>
      <StyledMessage mb={1}>
        <StyledLink to="/login">{verificationLocale['link.login']}</StyledLink>
        &nbsp;
        {verificationLocale['text.success-subheading']}
      </StyledMessage>
      <StyledMessage size={18}>
        <strong>{verificationLocale['text.ci-direct-message.strong']}</strong>
        &nbsp;
        {verificationLocale['text.ci-direct-message.before-link']}
        &nbsp;
        <StyledLink to="/forgot-password">{verificationLocale['link.forgot-password']}</StyledLink>
        &nbsp;
        {verificationLocale['text.ci-direct-message.after-link']}
      </StyledMessage>
    </>
  );

  const component = error ? errorComponent : verifiedComponent;

  return <StyledVerificationContainer>{loading ? <Spinner /> : component}</StyledVerificationContainer>;
};

export default Verification;
