import React, { useContext, useState } from 'react';

import { WillsFamilyFlowContext } from '../../WillsFamilyFlow.context';
import { RelativeDropdown } from '../RelativeDropdown';
import {
  StyledDropdownButton,
  StyledDropdownButtonContainer,
  StyledPlusIcon,
  StyledRelativeSelectorContainer,
  StyledRelativeSelectorName,
} from './RelativeSelector.styles';
import { IRelativeSelectorProps } from './RelativeSelector.types';

const RelativeSelector = ({ relativeTypes, disabled }: IRelativeSelectorProps) => {
  const { selectedRelativeDefinition } = useContext(WillsFamilyFlowContext);
  const [dropdownVisible, setDropdownVisible] = useState(false);

  function onTriggerButtonClick() {
    if (!dropdownVisible) {
      setDropdownVisible(true);
    }
  }

  return (
    <StyledRelativeSelectorContainer>
      <StyledRelativeSelectorName>Relationship</StyledRelativeSelectorName>
      <StyledDropdownButtonContainer>
        <StyledDropdownButton type="button" onClick={onTriggerButtonClick} disabled={disabled}>
          {selectedRelativeDefinition?.description || 'Add a person'}
          <StyledPlusIcon />
        </StyledDropdownButton>
        {dropdownVisible && (
          <RelativeDropdown
            right="0"
            top="3rem"
            setDropdownVisible={setDropdownVisible}
            relativeTypes={relativeTypes}
          />
        )}
      </StyledDropdownButtonContainer>
    </StyledRelativeSelectorContainer>
  );
};

export default RelativeSelector;
