import React, { useEffect, useRef, KeyboardEvent, useContext, useState } from 'react';

import { getCode } from 'country-list';
import { Header } from '@/Shared/Components';
import { IProfile } from '@/Types';
import { useGooglePlace } from '@/Shared/Hooks';
import { LocaleContext } from '@/providers';

import { HiddenSubmitButton, LongInput } from '../../../../../../Shared/Shared.styles';
import { updateCountryInfo } from './CitizenshipConstants';
import { Prompt } from '../Prompt';
import {
  CitizenshipInputContainer,
  CitizenshipInputFormContainer,
  RequiredInfoIndicator,
  StyledMessage,
} from './CitizenshipInput.styles';
import citizenshipInputLocales from './citizenshipInputLocales.json';

interface IProps {
  userProfileData: IProfile;
  setProfileData: React.Dispatch<React.SetStateAction<IProfile>>;
  goToNextQuestion: () => void;
}

const CitizenshipInput: React.FC<IProps> = ({ userProfileData, setProfileData, goToNextQuestion }) => {
  const { locale } = useContext(LocaleContext);
  const [clearWithEscape, setClearWithEscape] = useState(false);
  const [clearWithClick, setClearWithClick] = useState(false);
  const inputRef = useRef<HTMLInputElement | null>(null);
  const options = {
    types: ['country'],
  };

  const { place } = useGooglePlace(inputRef, options);
  const isEnterKeyPressed = (event: KeyboardEvent) => {
    return event.key === 'Enter';
  };

  const firstDropdownElement = () => {
    const dropdownContainers = document.querySelectorAll('.pac-container');
    const firstDropDownContainer = dropdownContainers[dropdownContainers.length - 1];
    const firstItem = firstDropDownContainer.querySelector('.pac-item')?.textContent;
    return firstItem;
  };

  const onSubmit = (event: any) => {
    event.preventDefault();
    if (clearWithEscape || clearWithClick) {
      return;
    }
    let countryShortCode = '';
    if (firstDropdownElement()) {
      const firstItem = firstDropdownElement();
      updateCountryInfo();
      const info = getCode(firstItem!);
      if (info) {
        countryShortCode = info!;
      }
    } else {
      if (!place) return;
      countryShortCode = place?.[0].short_name;
    }
    setProfileData((profileData) => ({
      ...profileData,
      citizenship: countryShortCode,
    }));
    if (userProfileData.citizenship !== '' || place !== undefined) {
      goToNextQuestion();
    }
  };

  const focusInputOnLoad = () => inputRef.current?.focus();

  useEffect(() => {
    setProfileData((profileData) => ({
      ...profileData,
      citizenship: '',
    }));
    focusInputOnLoad();

    const clearInput = () => {
      inputRef.current!.value = '';
    };

    const handleKeyDown = (event: any) => {
      if (event.key === 'Escape') {
        clearInput();
        setClearWithEscape(true);
      }
    };

    const handleClick = (event: MouseEvent) => {
      const target = event.target as HTMLElement;

      if (!target.classList.contains('pac-target-input')) {
        clearInput();
        setClearWithClick(true);
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('click', handleClick);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
      document.removeEventListener('click', handleClick);
    };
  }, []);

  const citizenshipInputLocale = citizenshipInputLocales[locale];

  return (
    <CitizenshipInputContainer>
      <Header alignCenter>
        {citizenshipInputLocale['label.header']}
        <RequiredInfoIndicator>*</RequiredInfoIndicator>
        <StyledMessage>{citizenshipInputLocale['label.subheader']}</StyledMessage>
      </Header>
      <CitizenshipInputFormContainer>
        <form onSubmit={onSubmit}>
          <LongInput
            ref={inputRef}
            onChange={() => {
              setClearWithEscape(false);
              setClearWithClick(false);
              setProfileData({ ...userProfileData, citizenship: '' });
            }}
            placeholder={citizenshipInputLocale['input.placeholder']}
            onKeyUp={(event) => {
              if (isEnterKeyPressed(event) && firstDropdownElement() && !clearWithEscape) {
                inputRef.current!.value = firstDropdownElement()!;
              }
            }}
          />
          <HiddenSubmitButton type="button" />
        </form>
        <Prompt disabled={!userProfileData.citizenship && !place} onClick={onSubmit} />
      </CitizenshipInputFormContainer>
    </CitizenshipInputContainer>
  );
};

export default CitizenshipInput;
