import React, { useContext, useState } from 'react';
import { useParams } from 'react-router-dom';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

import { BigButton, Header, SubHeader } from '@/Shared/Components';

import {
  StyledForgotPasswordContainer,
  StyledForgotPasswordForm,
  StyledTransparentDivider,
} from './ForgotPassword.styles';
import { Input } from '../../components/Input';
import { IResetPasswordData, confirmResetPasswordRequest, forgotPasswordRequest } from './ForgotPasswordApi';
import { FormMessage } from '../../components/FormMessage';
import { AuthLink } from '../../components/AuthLink';
import { LocaleContext } from '@/providers';
import forgotPasswordLocales from './forgotPasswordLocales.json';
import { AuthWrapper } from '../../components/AuthWrapper/AuthWrapper';

type ConfirmPasswordFormData = {
  email: string;
  newPassword: string;
};

const ForgotPassword: React.FC = () => {
  const { locale } = useContext(LocaleContext);

  const forgotPasswordLocale = forgotPasswordLocales[locale];

  const schema = yup.object({
    email: yup.string().email().min(1),
    newPassword: yup
      .string()
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$_%^&-*-/+=.,]).{8,}$/,
        forgotPasswordLocale['message.error.password.schema'],
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
  } = useForm<ConfirmPasswordFormData>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { userSub } = useParams();

  const [responseMessage, setResponseMessage] = useState<string | null>(null);
  const [resetComplete, setResetComplete] = useState(false);

  const { ref: emailRef, onChange: onEmailChange, onBlur: onEmailBlur } = register('email');

  const { ref: passwordRef, onChange: onPasswordChange, onBlur: onPasswordBlur } = register('newPassword');

  const submitEmail = async (email: string) => {
    try {
      const response = await forgotPasswordRequest(email);
      if (response.Code === 'OldUserExists') window.location.href = 'https://app.manzil.io/forgot-password';
      if (/aws/.test(response.Message)) throw new Error(forgotPasswordLocale['message.error.generic']);
      setResponseMessage(response.Message);
    } catch (err: any) {
      setResponseMessage(err.message);
    }
  };

  const submitNewPasswordWithUserSub = async (data: IResetPasswordData) => {
    try {
      const response = await confirmResetPasswordRequest(data);
      setResponseMessage(response.Message);
      setResetComplete(true);
    } catch (err: any) {
      setResponseMessage(err.message);
    }
  };

  const onSubmit: SubmitHandler<ConfirmPasswordFormData> = async (data) => {
    if (!userSub) {
      await submitEmail(data.email);
    } else {
      const body: IResetPasswordData = {
        userSub,
        newPassword: data.newPassword,
      };

      await submitNewPasswordWithUserSub(body);
    }
  };

  const subHeaderText = userSub
    ? forgotPasswordLocale['subheader.with-sub']
    : forgotPasswordLocale['subheader.without-sub'];

  const buttonText = userSub
    ? forgotPasswordLocale['button.text.with-sub']
    : forgotPasswordLocale['button.text.without-sub'];

  const buttonDisabled = !isValid || isSubmitting;

  return (
    <AuthWrapper>
      <StyledForgotPasswordContainer>
        {!resetComplete ? (
          <>
            <Header alignCenter>{forgotPasswordLocale.header}</Header>
            <SubHeader alignCenter>{subHeaderText}</SubHeader>
            {responseMessage && <FormMessage>{responseMessage}</FormMessage>}
            <StyledForgotPasswordForm onSubmit={handleSubmit(onSubmit)}>
              {!userSub ? (
                <Input
                  type="email"
                  name="email"
                  placeholder={forgotPasswordLocale['placeholder.email']}
                  inputRef={emailRef}
                  onChange={onEmailChange}
                  onBlur={onEmailBlur}
                />
              ) : (
                <Input
                  type="password"
                  name="newPassword"
                  placeholder={forgotPasswordLocale['placeholder.password']}
                  inputRef={passwordRef}
                  onChange={onPasswordChange}
                  onBlur={onPasswordBlur}
                />
              )}
              {errors.newPassword && <FormMessage>{errors.newPassword!.message!}</FormMessage>}
              <StyledTransparentDivider />
              <BigButton disabled={buttonDisabled} btnText={buttonText} />
            </StyledForgotPasswordForm>
          </>
        ) : (
          <>
            <Header>{forgotPasswordLocale['header.complete']}</Header>
            <StyledTransparentDivider />
            <SubHeader>
              {forgotPasswordLocale['subheader.complete.first-part']}
              &nbsp;
              <AuthLink to="/login">{forgotPasswordLocale['link.login']}</AuthLink>
              &nbsp;
              {forgotPasswordLocale['subheader.complete.second-part']}
            </SubHeader>
          </>
        )}
      </StyledForgotPasswordContainer>
    </AuthWrapper>
  );
};

export default ForgotPassword;
