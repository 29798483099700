import styled from 'styled-components';
import { FaArrowLeft } from 'react-icons/fa';

import { Palette } from '@/Shared/Constants';

export const StyledContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
  background-color: ${Palette.AQUA_HAZE};
  padding: 5rem;
  position: relative;

  @media (max-width: 1024px) {
    padding: 5rem 2rem;
  }

  @media (max-width: 600px) {
    padding: 5rem 1rem;
  }
`;

export const StyledContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledBackButton = styled.button`
  position: absolute;
  left: 25%;
  border: none;
  background: none;
  cursor: pointer;

  @media (max-width: 1024px) {
    left: 8%;
  }

  @media (max-width: 600px) {
    left: 5%;
    top: 10%;
  }
`;

export const StyledArrowLeft = styled(FaArrowLeft)`
  font-size: 1.5rem;
  color: ${Palette.INDEPENDENCE_BLUE};
`;
