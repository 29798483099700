import React, { useContext, useState } from 'react';
import { axiosClient, toApiResponse } from '@/lib/axios';
import { SnackBarContext } from '@/providers';
import { CardElement } from '@/lib/payment/components/CardElement';
import { usePaymentInfo } from '@/lib/payment/hooks/usePaymentInfo';
import {
  ProfileContainer,
  ProfileForm,
  FormContent,
  ContentHeading,
  BackToProfileContainer,
  GoBackButton,
} from '../../Profile.style';
import { IPaymentDispayProps } from '../PaymentDisplay/PaymentDisplay.types';
import { StyledPaymentFormContainer } from './PaymentForm.styles';
import { StyledDarkButton } from '@/lib/wills/components/styles';

const PaymentForm = ({ isEditMode, setIsEditMode }: IPaymentDispayProps) => {
  const { paymentInfo } = usePaymentInfo();
  const { setSnackMessage } = useContext(SnackBarContext);
  const [submitting, setSubmitting] = useState(false);

  function onSuccessCallback() {
    setSubmitting(false);
    setSnackMessage('Payment method updated');
    setIsEditMode(false);
  }

  async function onRemoveBtnClick() {
    if (submitting) return;

    setSubmitting(true);

    const { error } = await toApiResponse(axiosClient.delete('/delete-payment-info'));

    if (error) {
      setSnackMessage(error.message);
    }

    onSuccessCallback();
  }

  return (
    <ProfileContainer>
      <ProfileForm>
        <FormContent>
          <ContentHeading>Payment Information</ContentHeading>
          <ContentHeading>
            <BackToProfileContainer show={isEditMode}>
              <GoBackButton onClick={() => setIsEditMode(false)} title="exit" aria-label="exit" />
            </BackToProfileContainer>
          </ContentHeading>

          <StyledPaymentFormContainer>
            {paymentInfo && paymentInfo.paymentMethodId ? (
              <StyledDarkButton onClick={onRemoveBtnClick}>{submitting ? 'Removing...' : 'Remove'}</StyledDarkButton>
            ) : (
              <CardElement onSuccess={onSuccessCallback} />
            )}
          </StyledPaymentFormContainer>
        </FormContent>
      </ProfileForm>
    </ProfileContainer>
  );
};

export default PaymentForm;
