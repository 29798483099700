import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { AuthProvider, LocaleProvider, SnackBarProvider, DeeplinkProvider } from '@/providers';
import { ScrollToTop } from '@/Shared/Components';

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { ErrorBoundary, ErrorPage } from './components';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <LocaleProvider>
      <BrowserRouter>
        <SnackBarProvider>
          <AuthProvider>
            <ErrorBoundary renderFallback={(props) => <ErrorPage {...props} />}>
              <DeeplinkProvider>
                <ScrollToTop />
                <App />
              </DeeplinkProvider>
            </ErrorBoundary>
          </AuthProvider>
        </SnackBarProvider>
      </BrowserRouter>
    </LocaleProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
