import React, { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import {
  StyledInfoContainer,
  StyledIntroductionContentContainer,
  StyledIntroductionPageContainer,
  StyledNavigationButtonsWrapper,
  StyledOrderedList,
  StyledWillsGrid,
  StyledWillsImage,
  StyledWillsImageContainer,
  StyledIntroText,
} from './Introduction.styles';
import { StyledWillsIntroText, StyledWillsText, StyledWillsTitle, NavigationButtons } from '../../components';
import { pieChart } from './assets';
import { RESPONSIBILITIES } from './Introduction.constants';

export const Introduction: React.FC<{ onBack: () => void; onNext: () => void }> = ({ onBack, onNext }) => {
  useEffect(() => {
    amplitude.track('Wills Estate Flow Started');
  }, []);

  return (
    <StyledIntroductionPageContainer>
      <StyledIntroductionContentContainer>
        <StyledWillsIntroText>Up next</StyledWillsIntroText>
        <StyledWillsTitle>Allocating your estate</StyledWillsTitle>
        <StyledWillsGrid>
          <StyledInfoContainer>
            <StyledWillsText>
              In the next steps, you will be asked to name individuals who will act as your Executor and Estate Trustee,
              Power of Attorney (if applicable), and Guardians for your children (if applicable). Your Executor is the
              person you select to handle the administration of your Will and the distribution of your assets to your
              family.
            </StyledWillsText>
            <StyledIntroText>
              Some of the primary responsibilities your Executor is tasked with include:
            </StyledIntroText>
            <StyledOrderedList>
              {RESPONSIBILITIES.map((singleResponsibility) => {
                return <li key={singleResponsibility.id}>{singleResponsibility.text}</li>;
              })}
            </StyledOrderedList>
            <StyledIntroText>
              Accordingly, it is recommended that you name someone who you trust and who has the necessary skills to
              handle such matters. Your Personal Representative also has the option to hire a probate attorney if they
              need legal assistance after your death.
            </StyledIntroText>
          </StyledInfoContainer>
          <StyledWillsImageContainer>
            <StyledWillsImage src={pieChart} alt="family-tree" />
          </StyledWillsImageContainer>
        </StyledWillsGrid>
        <StyledNavigationButtonsWrapper>
          <NavigationButtons confirmBtnText="Continue" onContinue={onNext} onPrevious={onBack} />
        </StyledNavigationButtonsWrapper>
      </StyledIntroductionContentContainer>
    </StyledIntroductionPageContainer>
  );
};
