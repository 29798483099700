import { axiosClient } from '@/lib/axios';

export const forgotPasswordRequest = async (email: string) => {
  const response = await axiosClient.post('/forgot-password', { email });

  return response.data;
};

export interface IResetPasswordData {
  userSub: string;
  newPassword: string;
}

export const confirmResetPasswordRequest = async (data: IResetPasswordData) => {
  const response = await axiosClient.post('/confirm-reset-password', data);

  return response.data;
};
