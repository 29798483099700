import { ApiResponse, axiosClient } from '@/lib/axios';

export interface SetPoaPersonalCareInput {
  mainPoaPersonalCareId: number;
  backupPoaPersonalCareId: number | null;
}

export function setPoaPersonalCare(input: SetPoaPersonalCareInput): Promise<ApiResponse<never>> {
  return axiosClient.put('/wills-set-poa-personal-care', input);
}
