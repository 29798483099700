import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SavePaymentMethod } from '@/lib/payment/components/SavePaymentMethod';

const WillsSavePaymentMethod = () => {
  const navigate = useNavigate();

  return <SavePaymentMethod onSuccess={() => navigate('/wills/payment')} />;
};

export default WillsSavePaymentMethod;
