import React, { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import * as amplitude from '@amplitude/analytics-browser';

import { BigButton, Header, SubHeader } from '@/Shared/Components';
import { LocaleContext, DeeplinkContext } from '@/providers';

import { StyledButtonContainer, StyledErrorMessage, StyledSignupContainer, StyledSignupForm } from './Signup.styles';
import { signup } from '../../api/signup';
import { AuthWrapper, Paragraph, Input, AuthLink, FormMessage } from '../../components';
import signupLocales from './Signup.locales.json';
import { toApiResponseError } from '@/lib/axios/toApiResponseError';
import { SignupFooter } from './SignupFooter';

export type SignupFormValues = {
  email: string;
  password: string;
  otp?: string;
};

const Signup: React.FC = () => {
  const { locale } = useContext(LocaleContext);
  const { redirectUrl, tag } = useContext(DeeplinkContext);
  const signupLocale = signupLocales[locale];

  const schema = yup.object({
    email: yup.string().email().required(),
    password: yup
      .string()
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$_%^&-*-/+=.,]).{8,}$/,
        signupLocale['message.error.password.schema'],
      ),
  });

  const {
    register,
    handleSubmit,
    formState: { isValid, isSubmitting, errors },
  } = useForm<SignupFormValues>({
    mode: 'onChange',
    resolver: yupResolver(schema),
  });

  const { ref: emailRef, onChange: onEmailChange, onBlur: onEmailBlur } = register('email', { required: true });

  const {
    ref: passwordRef,
    onChange: onPasswordChange,
    onBlur: onPasswordBlur,
  } = register('password', { required: true });

  const [formMessage, setFormMessage] = useState<string | null>(null);

  const onSubmit: SubmitHandler<SignupFormValues> = async (formValues) => {
    try {
      const { data, error } = await signup({
        ...formValues,
        urlParams: {
          redirectUrl,
          tag,
        },
      });

      if (error) {
        setFormMessage(error.message);
        return;
      }

      if (data.UserSub) {
        setFormMessage(signupLocale['message.verification-link-sent']);
      } else {
        setFormMessage(data.Message);
      }
      amplitude.track('Sign up');
    } catch (err: unknown) {
      const error = toApiResponseError(err);
      setFormMessage(error.message);
    }
  };

  const buttonDisabled = isSubmitting || !isValid;

  return (
    <AuthWrapper footer={<SignupFooter />}>
      <StyledSignupContainer>
        <Header alignCenter>{signupLocale.header}</Header>
        <SubHeader alignCenter>{signupLocale.subheader}</SubHeader>
        {formMessage && <FormMessage>{formMessage}</FormMessage>}
        <StyledSignupForm onSubmit={handleSubmit(onSubmit)}>
          <Input
            name="email"
            placeholder={signupLocale['placeholder.email']}
            type="email"
            inputRef={emailRef}
            onChange={onEmailChange}
            onBlur={onEmailBlur}
          />
          <Input
            name="password"
            placeholder={signupLocale['placeholder.password']}
            type="password"
            inputRef={passwordRef}
            onChange={onPasswordChange}
            onBlur={onPasswordBlur}
          />
          {errors.password && <StyledErrorMessage>{errors.password.message}</StyledErrorMessage>}
          <StyledButtonContainer>
            <BigButton disabled={buttonDisabled} btnText={signupLocale['button.signup']} />
          </StyledButtonContainer>
          <Paragraph>
            {signupLocale['question.account-exists']}
            &nbsp;
            <AuthLink to="/login" bold>
              {signupLocale['link.login']}
            </AuthLink>
          </Paragraph>
        </StyledSignupForm>
      </StyledSignupContainer>
    </AuthWrapper>
  );
};

export default Signup;
