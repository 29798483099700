import React, { useState, useContext, FormEvent } from 'react';
import { SetupIntent } from '@stripe/stripe-js';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';
import { SnackBarContext } from '@/providers/SnackBarProvider';
import { StyledCheckoutFormButtonContainer } from './CardForm.styles';
import { StyledPrimaryButton } from '@/lib/wills/components/styles';
import { updateCustomerPaymentMethod } from './CardForm.api';
import { confirmSetupIntentWithStripe } from './CardForm.helpers';

export interface ICardForm {
  intent: SetupIntent;
  onSubmit?: () => Promise<void>;
}

const CardForm = ({ intent, onSubmit }: ICardForm) => {
  const stripe = useStripe();
  const elements = useElements();
  const { setSnackMessage } = useContext(SnackBarContext);
  const [submitting, setSubmitting] = useState(false);

  const handleSubmit = async (event: FormEvent) => {
    event.preventDefault();

    if (submitting) return;

    if (!stripe || !elements) return;

    setSubmitting(true);

    try {
      const paymentMethod = await getOrAttachPaymentMethodWithSetupIntent();

      await updateCustomerPaymentMethod(paymentMethod as string);
    } catch (error) {
      const errorMessage = error instanceof Error ? error.message : 'Something went wrong';

      setSnackMessage(errorMessage);
      setSubmitting(false);
      return;
    }

    if (onSubmit) {
      await onSubmit();
    }

    setSubmitting(false);
  };

  async function getOrAttachPaymentMethodWithSetupIntent() {
    const isPaymentMethodSaved = intent.payment_method;

    if (isPaymentMethodSaved) {
      return intent.payment_method;
    }

    const setupIntentWithPaymentMethod = await confirmSetupIntentWithStripe(stripe!, elements!);

    return setupIntentWithPaymentMethod.payment_method;
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <StyledCheckoutFormButtonContainer>
        <StyledPrimaryButton type="submit" disabled={submitting || !stripe || !elements}>
          {submitting ? 'Processing...' : 'Submit'}
        </StyledPrimaryButton>
      </StyledCheckoutFormButtonContainer>
    </form>
  );
};

export default CardForm;
