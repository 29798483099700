import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom';
import { Palette } from '@/Shared/Constants';
import { button, disabled, sectionGap, sectionTitle } from '@/components';

const primaryColors = css`
  background-color: ${Palette.GOLD_CRAYOLA};
  color: ${Palette.GUN_POWDER};
  border-color: ${Palette.GOLD_CRAYOLA};
`;

const secondaryColors = css`
  background-color: ${Palette.NEW_WHITE};
  color: ${Palette.GUN_POWDER};
  border-color: ${Palette.LIGHT_GREY};
`;

export const PrimaryButton = styled.button`
  ${button}
  ${primaryColors}
`;

export const SecondaryButton = styled.button`
  ${button}
  ${secondaryColors}
`;

export const PrimaryLink = styled(Link)`
  ${button}
  ${primaryColors}
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: center;
  text-decoration: none;
`;

export const SecondaryLink = styled(PrimaryLink)`
  ${secondaryColors}
`;

export const PrimaryLinkDisabled = styled.span<any>`
  ${button}
  ${primaryColors}
  ${disabled}
`;

export const SecondaryLinkDisabled = styled(PrimaryLinkDisabled)`
  ${secondaryColors}
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 20px;
`;

export const Section = styled.section`
  display: flex;
  flex-flow: column nowrap;
  min-width: 340px;
  ${sectionGap}
`;

export const SectionTitle = styled.h2`
  ${sectionTitle}
  color: ${Palette.NEW_BLUE};
`;

export const ActionCardsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 30px;
`;
