import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledHeader } from '../Introduction/Introduction.styles';
import { StyledButton, StyledDescription, StyledSmallHeader } from '../../components/styles';
import { StyledDetailsWrapper } from './CompleteWills.styles';
import { AboutYouContainerWithContent } from '../AboutYou/shared';
import { Page } from '@/components';

const CompleteWills: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Page>
      <AboutYouContainerWithContent>
        <StyledHeader>What are my next steps?</StyledHeader>
        <StyledDetailsWrapper>
          <StyledSmallHeader>Download and print your documents</StyledSmallHeader>
          <StyledDescription>
            Your documents are now ready to download. You must print, sign and store your documents to make them legal.
            Click the link below to go to your documents page.
          </StyledDescription>
        </StyledDetailsWrapper>
        <StyledButton onClick={() => navigate('../onboarding')} padding>
          View Documents
        </StyledButton>
      </AboutYouContainerWithContent>
    </Page>
  );
};

export default CompleteWills;
