import { css } from 'styled-components';
import { buttonLabel } from './typography.styles';

const clickable = css`
  cursor: pointer;
`;

const subtleDarken = css`
  filter: brightness(98%);
`;

// Needs to override other effects
export const disabled = css`
  opacity: 0.5;
  cursor: not-allowed;
  filter: none; // override subtleDarken
`;

const buttonOutline = css`
  border-radius: 0.3rem;
  border-width: 1px;
  border-style: solid;
  padding: 0.5rem 1rem;
`;

const shadow = css`
  box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);
`;

export const button = css`
  ${buttonOutline}
  ${buttonLabel}
  ${shadow}
  cursor: pointer;
  :hover {
    ${clickable}
    ${subtleDarken}
  }
  :disabled {
    ${disabled}
  }
`;
