import React from 'react';
import {
  StyledSpinnerContainer,
  StyledSubscriptionInfoContainer,
  StyledSubscriptionInfoMessageBox,
  StyledSubscriptionInfoParagraph,
  StyledSubscriptionInfoSpan,
  SemiBold,
} from './SubscriptionInfo.styles';
import { Palette } from '@/Shared/Constants';
import { StyledGreenButton } from '@/lib/wills/components/styles';
import { Spinner } from '@/Shared/Components';
import {
  checkIfAllowedToSubscribe,
  checkIfAllowedToUnsubscribe,
  isPendingExpiration,
  isSubscriptionActive,
  isTrialPaid,
  isTrialUnpaid,
} from './SubscriptionInfo.helpers';
import { SubscriptionContext } from '../SubscriptionContext';
import { bindFuncToAsync } from '@/Utils';
import { DocumentsContext } from '../../../Documents.context';

export const SubscriptionInfo: React.FC = () => {
  const { willsProfile, unsubscribe } = React.useContext(DocumentsContext);
  const [unsubscribing, setUnsubscribing] = React.useState(false);

  const { openSubscribePopup } = React.useContext(SubscriptionContext);
  const isAllowedToSubscribe = checkIfAllowedToSubscribe(willsProfile);
  const isAllowedToUnsubscribe = checkIfAllowedToUnsubscribe(willsProfile);

  const subscriptionInfo = React.useMemo(() => {
    if (isSubscriptionActive(willsProfile) || isTrialPaid(willsProfile)) {
      return {
        type: 'Subscription',
        value: 'Active',
        valueColor: Palette.SLATE_GREEN,
        description: 'You can freely publish your will',
      };
    }

    if (isTrialUnpaid(willsProfile)) {
      return {
        type: 'Subscription',
        value: 'Trial',
        valueColor: Palette.GOLD_CRAYOLA,
        description: 'You can update your will.',
      };
    }

    if (isPendingExpiration(willsProfile)) {
      return {
        type: 'Subscription',
        value: 'Pending Expiration',
        valueColor: Palette.GOLD_CRAYOLA,
        description: 'You can update your will.',
      };
    }

    return {
      type: 'Subscription',
      value: 'Inactive',
      valueColor: Palette.GOLD_CRAYOLA,
      description: 'An active subscription is required to publish your will',
    };
  }, [willsProfile]);

  const planInfo = React.useMemo(() => {
    if (willsProfile.plan === 'PREMIUM') {
      return {
        type: 'Will Plan',
        value: 'Premium',
        valueColor: Palette.SLATE_GREEN,
        description: 'Your plan includes Power of Attorney documents.',
      };
    }

    return {
      type: 'Will Plan',
      value: 'Essentials',
      valueColor: Palette.GOLD_CRAYOLA,
      description: 'Upgrade now for Power of Attorney documents.',
    };
  }, [willsProfile]);

  const unsubscribeFromWills = bindFuncToAsync(setUnsubscribing)(unsubscribe);

  return (
    <StyledSubscriptionInfoContainer>
      {!willsProfile ? (
        <StyledSpinnerContainer>
          <Spinner />
        </StyledSpinnerContainer>
      ) : (
        <>
          {[subscriptionInfo, planInfo].map(({ type, value, valueColor, description }, index) => (
            <StyledSubscriptionInfoMessageBox key={index}>
              <StyledSubscriptionInfoParagraph>
                <SemiBold>{type}</SemiBold>:&nbsp;
                <StyledSubscriptionInfoSpan color={valueColor}>{value}</StyledSubscriptionInfoSpan>
              </StyledSubscriptionInfoParagraph>
              <StyledSubscriptionInfoParagraph description>{description}</StyledSubscriptionInfoParagraph>
            </StyledSubscriptionInfoMessageBox>
          ))}

          {isAllowedToSubscribe && (
            <StyledGreenButton fullWidth onClick={openSubscribePopup}>
              Subscribe
            </StyledGreenButton>
          )}
          {isAllowedToUnsubscribe && (
            <StyledGreenButton fullWidth onClick={unsubscribeFromWills} disabled={unsubscribing}>
              {unsubscribing ? 'Unsubscribing...' : 'Unsubscribe'}
            </StyledGreenButton>
          )}
        </>
      )}
    </StyledSubscriptionInfoContainer>
  );
};
