export interface IPaymentInfo {
  id: string;
  setupIntentId: string;
  clientSecret: string;
  paymentMethodId: string;
  subscriptionId: string;
  customerId: string;
  userId: string;
}

export enum EPaymentProducts {
  WILLS = 'wills',
  NONE = 'none',
}

export enum ESubscriptionStatus {
  FREE = 'FREE',
  TRIAL_PAID = 'TRIAL_PAID',
  TRIAL_UNPAID = 'TRIAL_UNPAID',
  TRIAL_EXPIRED = 'TRIAL_EXPIRED',
  SUBSCRIBED = 'SUBSCRIBED',
  PENDING_EXPIRATION = 'PENDING_EXPIRATION',
  EXPIRED = 'EXPIRED',
}
