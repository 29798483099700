import React, { useContext, useState, useRef, useEffect } from 'react';

import { SetPoaPersonalCareInput, setPoaPersonalCare } from '@wills/apis';
import { SnackBarContext } from '@/providers';
import { getErrorMessage } from '@/Utils';

import { EstateIntakeContext, EstateIntakeContextActionType } from '../../contexts';

import {
  ElementChooser,
  handleNavigationButtons,
  NavigationButtons,
  RelativeAdder,
  StyledWillsIntroText,
  StyledWillsTitle,
} from '../../components';

import {
  StyledNavigationButtonsWrapper,
  StyledRelativeTipsWrapper,
  StyledPowerOfAttorneyContentContainer,
  StyledPowerOfAttorneyGrid,
  StyledPowerOfAttorneyInfoContainer,
  StyledPowerOfAttorneyPageContainer,
  StyledPowerOfAttorneyTextMessage,
  StyledPowerOfAttorneyChooserContainer,
  StyledPowerOfAttorneyChooserContent,
  StyledBackupPowerOfAttorneyContainer,
  StyledBackupPowerOfAttorneyContent,
  StyledBackupTrusteesContainer,
} from '../PowerOfAttorneyProperty/PowerOfAttorneyProperty.styles';

import {
  StyledExecutorAndEstateChooser,
  StyledExecutorChooserRadioButtonLabel,
  StyledExecutorChooserText,
} from '../../components/ElementChooser/ElementChooser.styles';

import { StyledErrorMessage } from '../ExecutorAndEstateTrustee/ExecutorAndEstateTrustee.styles';
import { Modal } from '@/Shared/Components';
import { PowerOfAttorneyInfo } from '../../components/PowerOfAttorneyInfo';
import { StyledInfoIcon, StyledInfoIconContainer, StyledInfoIconWrapper } from '@/lib/wills/components/styles';
import { getSpouseRelative, getNonSpouseRelatives } from '../../helpers';

export const PowerOfAttorneyPersonalCare: React.FC<{
  onNext: () => void;
  onBack: () => void;
  onSummary: () => void;
}> = ({ onNext, onBack, onSummary }) => {
  const { state, dispatch } = useContext(EstateIntakeContext);
  const { setSnackMessage } = useContext(SnackBarContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const errorMsgRef = useRef<HTMLParagraphElement>(null);
  const [showInfoBlurb, setShowInfoBlurb] = useState(false);
  const [showErrorMessage, setShowErrorMessage] = useState({
    showSpouseOptionError: false,
    showWithoutSpouseOptionError: false,
  });
  const relativeDefinitions = state.relativeDefinitions.filter(
    (relDef) => ['OTHER', 'GENERAL'].includes(relDef.category) && relDef.relationship !== 'charity',
  );
  const spouseRelative = getSpouseRelative(state.adultRelatives);
  const relativesWithoutSpouse = getNonSpouseRelatives(state.adultRelatives);
  const relativesWithoutMainAttorney = relativesWithoutSpouse.filter(
    (relative) => relative.id !== state.poaPersonalCare.main?.id,
  );

  async function onContinueBtnClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (state.poaPersonalCare.isSpouseSelected === null) {
      setShowErrorMessage({ ...showErrorMessage, showSpouseOptionError: true });
      return;
    }

    if (!state.poaPersonalCare.main) {
      setShowErrorMessage({ ...showErrorMessage, showWithoutSpouseOptionError: true });
      return;
    }

    if (isSubmitting) return;

    setSubmitting(true);

    const poaData: SetPoaPersonalCareInput = {
      mainPoaPersonalCareId: state.poaPersonalCare.main.id as number,
      backupPoaPersonalCareId: state.poaPersonalCare.backup ? (state.poaPersonalCare.backup.id as number) : null,
    };

    try {
      const { error } = await setPoaPersonalCare(poaData);
      if (error) {
        throw new Error(error.message);
      }
      handleNavigationButtons(event, onNext, onSummary);
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    } finally {
      setSubmitting(false);
    }
  }

  const hideErrorMessage = (option: string) => {
    setShowErrorMessage({ ...showErrorMessage, [option]: false });
  };

  useEffect(() => {
    if (errorMsgRef.current) {
      errorMsgRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  });

  return (
    <StyledPowerOfAttorneyPageContainer>
      <StyledPowerOfAttorneyContentContainer>
        <StyledPowerOfAttorneyGrid>
          <StyledPowerOfAttorneyInfoContainer>
            <StyledWillsIntroText center>Your estate</StyledWillsIntroText>
            <StyledWillsTitle center>Power of Attorney for Personal Care</StyledWillsTitle>
            {spouseRelative && (
              <>
                <StyledPowerOfAttorneyTextMessage center>
                  Do you choose your spouse
                  {spouseRelative && ` ${spouseRelative.firstName},`}
                  &nbsp;to act in this role?
                </StyledPowerOfAttorneyTextMessage>
                {showErrorMessage.showSpouseOptionError && (
                  <StyledErrorMessage ref={errorMsgRef} marginBottomZero>
                    Please select one of the options
                  </StyledErrorMessage>
                )}
                <StyledPowerOfAttorneyChooserContainer>
                  <StyledPowerOfAttorneyChooserContent>
                    <ElementChooser
                      id="spouse-as-trustee-yes"
                      checked={state.poaPersonalCare.isSpouseSelected === true}
                      onClick={() => {
                        hideErrorMessage('showSpouseOptionError');
                        if (spouseRelative) {
                          dispatch({
                            type: EstateIntakeContextActionType.SET_SPOUSE_POA_PERSONAL_CARE,
                            payload: true,
                          });
                          dispatch({
                            type: EstateIntakeContextActionType.SET_MAIN_POA_PERSONAL_CARE,
                            payload: spouseRelative,
                          });
                        }
                      }}
                      value="Yes"
                    />
                    <ElementChooser
                      id="spouse-as-trustee-no"
                      checked={state.poaPersonalCare.isSpouseSelected === false}
                      onClick={() => {
                        hideErrorMessage('showSpouseOptionError');
                        dispatch({
                          type: EstateIntakeContextActionType.SET_SPOUSE_POA_PERSONAL_CARE,
                          payload: false,
                        });
                        dispatch({
                          type: EstateIntakeContextActionType.SET_MAIN_POA_PERSONAL_CARE,
                          payload: null,
                        });
                      }}
                      value="No"
                    />
                  </StyledPowerOfAttorneyChooserContent>
                </StyledPowerOfAttorneyChooserContainer>
              </>
            )}
            {state.poaPersonalCare.isSpouseSelected === false && (
              <StyledBackupTrusteesContainer>
                <StyledPowerOfAttorneyTextMessage center>
                  Who do you choose to act in this role?
                </StyledPowerOfAttorneyTextMessage>
                {showErrorMessage.showWithoutSpouseOptionError && (
                  <StyledErrorMessage ref={errorMsgRef}>Please select one of the options</StyledErrorMessage>
                )}
                <StyledBackupPowerOfAttorneyContainer>
                  <StyledBackupPowerOfAttorneyContent>
                    {relativesWithoutSpouse.map((relative) => (
                      <ElementChooser
                        key={relative.id}
                        id={`main-attorney-${relative.id}`}
                        checked={relative === state.poaPersonalCare.main}
                        onClick={() => {
                          hideErrorMessage('showWithoutSpouseOptionError');
                          dispatch({
                            type: EstateIntakeContextActionType.SET_MAIN_POA_PERSONAL_CARE,
                            payload: relative,
                          });
                        }}
                        value={`${relative.firstName} ${relative.lastName}`}
                      />
                    ))}
                    <RelativeAdder
                      action={EstateIntakeContextActionType.SET_MAIN_POA_PERSONAL_CARE}
                      btnText="Add a Power of Attorney +"
                      relativeDefinitions={relativeDefinitions}
                    />
                  </StyledBackupPowerOfAttorneyContent>
                </StyledBackupPowerOfAttorneyContainer>
              </StyledBackupTrusteesContainer>
            )}

            {state.poaPersonalCare.main && (
              <StyledBackupTrusteesContainer>
                <StyledPowerOfAttorneyTextMessage center>
                  Backups are recommended, would you like to add one?
                </StyledPowerOfAttorneyTextMessage>
                <StyledBackupPowerOfAttorneyContainer>
                  <StyledBackupPowerOfAttorneyContent>
                    <StyledExecutorAndEstateChooser>
                      <StyledExecutorChooserRadioButtonLabel htmlFor="None">
                        <input
                          id="None"
                          type="radio"
                          checked={!state.poaPersonalCare.backup}
                          onChange={() => {
                            dispatch({
                              type: EstateIntakeContextActionType.SET_BACKUP_POA_PERSONAL_CARE,
                              payload: null,
                            });
                          }}
                          value=""
                        />
                        <StyledExecutorChooserText>None</StyledExecutorChooserText>
                      </StyledExecutorChooserRadioButtonLabel>
                    </StyledExecutorAndEstateChooser>
                    {relativesWithoutMainAttorney.map((relative) => (
                      <ElementChooser
                        key={relative.id}
                        id={`backup-attorney-${relative.id}`}
                        checked={relative === state.poaPersonalCare.backup}
                        onClick={() =>
                          dispatch({
                            type: EstateIntakeContextActionType.SET_BACKUP_POA_PERSONAL_CARE,
                            payload: relative,
                          })
                        }
                        value={`${relative.firstName} ${relative.lastName}`}
                      />
                    ))}
                    <RelativeAdder
                      action={EstateIntakeContextActionType.SET_BACKUP_POA_PERSONAL_CARE}
                      btnText="Add a Power of Attorney +"
                      relativeDefinitions={relativeDefinitions}
                    />
                  </StyledBackupPowerOfAttorneyContent>
                </StyledBackupPowerOfAttorneyContainer>
              </StyledBackupTrusteesContainer>
            )}

            <StyledInfoIconWrapper>
              <StyledInfoIconContainer onClick={() => setShowInfoBlurb(true)}>
                <StyledInfoIcon />
              </StyledInfoIconContainer>
            </StyledInfoIconWrapper>

            {showInfoBlurb && (
              <Modal transparent onCloseCallback={() => setShowInfoBlurb(false)}>
                <PowerOfAttorneyInfo />
              </Modal>
            )}

            <StyledNavigationButtonsWrapper>
              <NavigationButtons onContinue={onContinueBtnClick} onPrevious={onBack} />
            </StyledNavigationButtonsWrapper>
          </StyledPowerOfAttorneyInfoContainer>

          <StyledRelativeTipsWrapper>
            <PowerOfAttorneyInfo />
          </StyledRelativeTipsWrapper>
        </StyledPowerOfAttorneyGrid>
      </StyledPowerOfAttorneyContentContainer>
    </StyledPowerOfAttorneyPageContainer>
  );
};
