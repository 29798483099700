import { ApiResponse, axiosClient, toApiResponse } from '@/lib/axios';
import { IPaymentInfo } from '@/lib/payment/types';
import { snakeToCamelParser } from '@/Utils';

export async function getPaymentInfo(): Promise<ApiResponse<IPaymentInfo>> {
  const { error, data } = await toApiResponse(axiosClient.get('/payment-info'));

  if (error) {
    return { data, error };
  }

  const transformedData: IPaymentInfo = snakeToCamelParser(data);

  return {
    data: transformedData,
    error,
  };
}
