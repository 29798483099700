/* eslint-disable no-console */
import { createContext } from 'react';
import { makeAutoObservable, runInAction } from 'mobx';

import { IError, IUserAddress } from '@/Types';
import { getUserAddress, postUserAddress, putUserAddress } from '@/Api/Address';
import { snakeToCamelParser } from '@/Utils';

export class AddressStore {
  address: IUserAddress | null = null;

  error: IError | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setAddress = (address: IUserAddress) => {
    this.address = address;
  };

  setAddressOrHandleError(data: any) {
    if (data.Code) {
      this.error = data;
      this.address = null;
    } else {
      this.address = data;
      this.error = null;
    }
  }

  loadAddress = async () => {
    try {
      const data = await getUserAddress();
      const address = snakeToCamelParser(data);
      runInAction(() => {
        this.setAddressOrHandleError(address);
      });
    } catch (err) {
      console.error(err);
    }
  };

  createAddress = async (addressData: IUserAddress) => {
    try {
      const data = await postUserAddress(addressData);
      const address = snakeToCamelParser(data);
      runInAction(() => {
        this.setAddressOrHandleError(address);
      });
    } catch (err) {
      console.error(err);
    }
  };

  editAddress = async (address: IUserAddress) => {
    try {
      const data = await putUserAddress(address);
      const updatedAddress = snakeToCamelParser(data);
      runInAction(() => {
        this.setAddressOrHandleError(updatedAddress);
      });
    } catch (err) {
      console.error(err);
    }
  };

  clearAddressStore = () => {
    this.address = null;
    this.error = null;
  };
}

export default createContext(new AddressStore());
