export const Palette = {
  WHITE: '#FFFFFF',
  EGGSHELL_WHITE: '#FCFAF2',
  TERRA_COTTA_ORANGE: '#E07A5F',
  LIGHT_ORANGE: '#FF8C6E',
  DIRTY_ORANGE: '#F0DAC9',
  TAN: '#F4F1DE',
  INDEPENDENCE_BLUE: '#3D405B',
  ROSE_TAUPE_BURGUNDY: '#8F5D5D',
  GOLD_CRAYOLA: '#F2CC8F',
  LIGHT_GOLD_CRAYOLA: '#FBF0DD',
  ORANGE_GOLD: '#FAEBD2',
  LIGHT_GOLD: '#FEFAF4',
  MUSTARD: '#EFCA8C',
  BLACK: '#1E1E1D',
  GREEN_SHEEN: '#81B29A',
  STEEL_TEAL: '#5D886F',
  GREY: '#E4E4E4',
  DARK_GREY: '#555555',
  DOVE_GREY: '#707070',
  AURO_METAL_SAURUS: '#5F797B',
  CHESSE_WHITE: '#DAE8E6',
  SLATE_GREEN: '#61927A',
  SLATE_GREEN_LIGHT: 'rgba(97, 146, 122, 0.3)',
  SLATE_GREEN_DARK: '#57836D',
  FALL_LEAF_BROWN: '#DBAF6B',
  GUN_POWDER: '#3D405B',
  REGENT_GREY: '#8C8EA0',
  NEW_BLUE: '#2A2F44',
  NEW_WHITE: '#F2F3F9',
  NEW_GREY: '#D0DED7',
  AQUA_HAZE: '#EAEDF4',
  CREAM: '#DED1C2',
  CADET_BLUE: '#AFB1C2',
  CADET_BLUE_DARKER: '#7E808C',
  LIGHT_GREY: 'rgb(140, 142, 160, 0.2)',
  PLANET_BLUE: '#555969',
  SMOKY_GREY: '#7F828F',
  PRIMARY_WHITE: '#F7FAF8',
  PRIMARY_WHITE_DARKER: '#DEE1DF',
  INPUT_GREY: '#DOD5DD',
  ATHENS_GREY: '#F9FAFB',
  MELON: '#FF7A59',
  DRIED_LAVENDER: '#EAEAEC',
  DRIED_LAVENDER_DARKER: '#D2D2D4',
  LAVENDER: '#C9D0F2',
  INPUT_BORDER_GREY: '#BFC1C7',
};
