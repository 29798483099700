import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledExecutorAndEstateTrusteePageContainer = styled.div`
  background-color: ${Palette.AQUA_HAZE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledExecutorAndEstateTrusteeGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 0.75fr) minmax(0, 0.25fr);
  gap: 2rem;
  min-height: 30rem;

  @media (max-width: ${Breakpoints.TABLET_MAX_WIDTH}) {
    grid-template-columns: 1fr;
  }
`;

export const StyledExecutorAndEstateTrusteeContentContainer = styled.div`
  width: min(70%, 1366px);
  margin: 5rem auto;

  @media (max-width: ${Breakpoints.TABLET_MAX_WIDTH}) {
    width: 85%;
  }
`;

export const StyledExecutorAndEstateTrusteeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledNavigationButtonsWrapper = styled.div`
  margin-top: auto;
`;

export const StyledRelativeTipsWrapper = styled.div`
  @media (max-width: ${Breakpoints.TABLET_MAX_WIDTH}) {
    display: none;
  }
`;

export const StyledExecutorAndEstateTextMessage = styled.p<{ center?: boolean }>`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
  margin-top: 1rem;
  ${({ center }) => center && 'text-align: center;'}
`;

export const StyledExecutorAndEstateChooserContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

export const StyledExecutorAndEstateChooserContent = styled.div`
  width: 45%;
  min-width: 18rem;
  gap: 2rem;
  display: flex;
`;

export const StyledBackupTrusteesContainer = styled.div`
  margin-top: 2rem;
`;

export const StyledBackupExecutorAndTrusteesContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledBackupExecutorAndTrusteesContent = styled.div`
  min-width: 23rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: ${Breakpoints.TABLET_MAX_WIDTH}) {
    width: 85%;
    min-width: 21rem;
  }
`;

export const StyledErrorMessage = styled.p<{ marginBottomZero ?: boolean }>`
  color: red;
  text-align: center;
  font-family: 'SofiaPro-Regular';
  font-size: 1.1rem;
  margin: 1rem 0;
  margin-bottom: ${({ marginBottomZero }) => marginBottomZero && 0};
`;
