import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { subSectionBody } from '@/components';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledSubscriptionInfoContainer = styled.div`
  padding: 0.8rem;
  border-radius: 0.5rem;
  background-color: ${Palette.WHITE};
  width: 19rem;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledSubscriptionInfoMessageBox = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  padding: 0.8rem;
  border-radius: 0.5rem;
  background-color: ${Palette.PRIMARY_WHITE};
`;

export const StyledSubscriptionInfoParagraph = styled.p<{ description?: boolean }>`
  ${subSectionBody};
  color: ${Palette.NEW_BLUE};

  @media (max-width: ${Breakpoints.MOBILE_MAX_WIDTH}) {
    display: ${({ description }) => description && 'none'};
  }

`;

export const StyledSubscriptionInfoSpan = styled.span<{ color: string }>`
  color: ${({ color }) => color};
`;

export const StyledSpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
`;

export const SemiBold = styled.span`
  font-weight: 500;
`;
