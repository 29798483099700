import React from 'react';
import { StyledSnackBarContainer, StyledSnackBarMessage, StyledCross } from './SnackBar.styles';

interface IProps {
  message: string;
  setVisibility: any;
}

const SnackBar: React.FC<IProps> = ({ message, setVisibility }) => {
  return (
    <StyledSnackBarContainer data-testid="snackBar-message">
      <StyledCross onClick={() => setVisibility(false)} />
      <StyledSnackBarMessage>{message}</StyledSnackBarMessage>
    </StyledSnackBarContainer>
  );
};

export default SnackBar;
