import React, { useContext } from 'react';

import { LocaleContext } from '@/providers';

import {
  ErrorMessage,
  InfoAttributes,
  InfoHeader,
  PhoneIcon,
  SingleInfoWrapper,
  StyledContactNumberField,
  StyledCountryCodeField,
  StyledPhoneInfo,
} from '../../Profile.style';
import { normalizeContact, normalizeCountryCode } from '../../ProfileHelpers';
import contactFormFieldLocales from './ContactFormField.locales.json';
import { IContactFormFieldProps } from './ContactFormField.types';

const ContactFormField = ({ register, errors, contactInfo, setContactInfo }: IContactFormFieldProps) => {
  const { locale } = useContext(LocaleContext);
  const inputLocales = contactFormFieldLocales[locale];

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (
      event.target.name === 'cellPhoneCountryCode' ||
      event.target.name === 'homePhoneCountryCode' ||
      event.target.name === 'primaryPhoneCountryCode'
    ) {
      normalizeCountryCode(event);
      setContactInfo({
        ...contactInfo,
        [event.target.name]: event.target.value,
        primaryPhoneCountryCode: event.target.value,
      });
      return;
    }
    normalizeContact(event);
    setContactInfo({
      ...contactInfo,
      [event.target.name]: event.target.value,
      primaryPhone: event.target.value,
    });
  };

  return (
    <SingleInfoWrapper>
      <PhoneIcon />
      <InfoAttributes>
        <InfoHeader>{inputLocales['label.mobile']}</InfoHeader>
        <StyledPhoneInfo>
          <StyledCountryCodeField
            type="text"
            {...register('cellPhoneCountryCode', {
              required: inputLocales['message.error.phone.country-code'],
              onChange,
            })}
            placeholder="+"
            value={contactInfo.cellPhoneCountryCode}
          />
          <StyledContactNumberField
            type="text"
            {...register('cellPhone', {
              required: inputLocales['message.error.phone.number.required'],
              onChange,
            })}
            value={contactInfo.cellPhone}
          />
        </StyledPhoneInfo>
        {errors.cellPhoneCountryCode && <ErrorMessage>{errors.cellPhoneCountryCode.message?.toString()}</ErrorMessage>}
        {errors.cellPhone && <ErrorMessage>{errors.cellPhone.message?.toString()}</ErrorMessage>}
      </InfoAttributes>
    </SingleInfoWrapper>
  );
};

export default ContactFormField;
