import React from 'react';
import { StyledInfo, StyledTitle, StyledBenefitsSection } from '../PlansBenefits/PlansBenefits.styles';
import { StyledIconContainer, StyledTick, StyledSinglePoint } from './ComparePoints.styles';

interface IComparePointsProps {
  header: string;
  topics: string[];
}

const ComparePoints = ({ header, topics }: IComparePointsProps) => {
  return (
    <>
      <StyledTitle>{header}</StyledTitle>
      {topics.map((topic, index) => (
        <StyledBenefitsSection key={index}>
          <StyledSinglePoint>
            <StyledIconContainer>
              <StyledTick />
            </StyledIconContainer>
            <StyledInfo>{topic}</StyledInfo>
          </StyledSinglePoint>
        </StyledBenefitsSection>
      ))}
    </>
  );
};

export default ComparePoints;
