import styled from 'styled-components';

export const StyledDetailsWrapper = styled.div`
  background-color: #fff;
  padding: 2rem 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  max-width: 35rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
