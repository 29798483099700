import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { AboutYou } from '../../pages/AboutYou';
import { WillsProfileProvider } from '../../components/WillsProfileProvider';

export const createAboutYouRoute = () => (
  <Route
    path="about-you"
    element={
      <WillsProfileProvider>
        <Outlet />
      </WillsProfileProvider>
    }
  >
    <Route index element={<AboutYou />} />
    <Route path="*" element={<Navigate to=".." replace />} />
  </Route>
);
