import styled from 'styled-components';
import { InputLabel } from './typography.styles';
import { Palette } from '@/Shared/Constants';

const NavbarHeight = '6rem';

export const Page = styled.div`
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - ${NavbarHeight});
  align-content: center;
  align-items: center;
  background-color: ${Palette.AQUA_HAZE};
  padding: 2rem 0;
`;

export const InputContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 0.25rem;
  ${InputLabel} {
    margin-bottom: 0.25rem;
  }
`;

export const OptionsFieldSet = styled.fieldset`
  display: flex;
  flex-flow: column nowrap;
  gap: 0.5rem;
  border: none;
`;
