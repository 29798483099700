import styled, { css } from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { FONT_FAMILY } from '@/components';

export const Button = styled.button<{ notVisible?: boolean }>`
  font-family: ${FONT_FAMILY};
  font-size: 0.9rem;
  width: 8rem;
  padding: 0.5rem 0.7rem;
  border: 0;
  border-radius: 0.4rem;
  ${({ notVisible }) =>
    notVisible
      ? css`
          visibility: hidden;
        `
      : ''}
  cursor: pointer;
  :hover {
    opacity: 0.8;
  }
`;

export const PrimaryButton = styled(Button)`
  color: rgba(234, 237, 244, 1);
  background: rgba(42, 47, 68, 1);
`;

export const SecondaryButton = styled(Button)`
  background: rgba(213, 213, 218, 1);
`;

export const Checkbox = styled.input`
  height: 1rem;
  width: 1rem;
  border-color: ${Palette.NEW_BLUE};
  accent-color: ${Palette.NEW_BLUE};
`;

const placeholderCss = css`
  font-size: 0.9rem;
`;
export const Input = styled.input`
  font-family: ${FONT_FAMILY};
  font-size: 1rem;
  padding: 0.6rem 0.8rem;
  background-color: ${Palette.WHITE};
  border: 1px solid #aaabb4;
  border-radius: 0.6rem;

  ::-moz-placeholder {
    ${placeholderCss}
  }
  :-ms-input-placeholder {
    ${placeholderCss}
  }
  ::placeholder {
    ${placeholderCss}
  }
`;
