import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledAppointeesPageContainer = styled.div`
  background-color: ${Palette.AQUA_HAZE};
  padding: 4rem 1rem;
`;

export const StyledAppointeesPageContent = styled.div`
  padding: 2rem 0;

  @media (min-width: ${Breakpoints.TABLET_MIN_WIDTH}) {
    width: 70%;
    margin: 0 auto;
  }
`;

export const StyledAppointeesPageHeaderContainer = styled.div`
  margin: 1rem 0;
`;

export const StyledAppointeesPageParagraph = styled.p<{ mb?: number }>`
  color: ${Palette.PLANET_BLUE};
  font-family: SofiaPro-Regular;
  ${({ mb }) => mb && `margin-bottom: ${mb}rem;`}
`;

export const StyledAppointeesPageTopMargin = styled.div`
  margin-top: 3rem;
`;

export const StyledAppointeesPageNotifyButtonRow = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: space-between;

  @media (min-width: ${Breakpoints.TABLET_MIN_WIDTH}) {
    flex-direction: row;
  }
`;
