import { CANADIAN_AREA_CODES } from '@/lib/invest/pages/Onboarding/Components/PhoneForm/PhoneFormConstants';
import contactInfoLocales from './contactInformationFormLocales.json';

export function validatePhone(cellPhoneCountryCode: string, cellPhoneNumber: string, locale: 'EN' | 'FR' = 'EN') {
  const validationLocales = contactInfoLocales[locale];

  const isCanadianCountryCode = cellPhoneCountryCode === '+1';
  if (!isCanadianCountryCode) throw new Error(validationLocales['message.error.phone.country-code.invalid']);

  const areaCode = cellPhoneNumber.split('(')[1].split(')')[0];
  const isCanadianAreaCode = CANADIAN_AREA_CODES.some((code) => code === areaCode);
  if (!isCanadianAreaCode) throw new Error(validationLocales['message.error.phone.number.invalid']);
}
