import styled from 'styled-components';
import { BiEditAlt } from 'react-icons/bi';
import { BsTrash } from 'react-icons/bs';

import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledBequestDisplayContainer = styled.div`
  background-color: ${Palette.NEW_WHITE};
  border-radius: 0.5rem;
  padding: 1.5rem;
  margin-top: 1.5rem;
  width: 100%;
  margin-inline: auto;

  &:nth-last-child(2) {
    margin-bottom: 1.5rem;
  }

  @media (max-width: ${Breakpoints.TABLET_MAX_WIDTH}) {
    width: 100%;
  }
`;

export const StyledBequestDisplayHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledBequestDisplayName = styled.h2`
  font-family: 'SofiaPro-Bold';
  color: ${Palette.GUN_POWDER};
  text-transform: capitalize;
  margin-top: 1rem;
`;

export const StyledBequestDisplayAllocation = styled.div`
  font-weight: bold;
  border-radius: 0.5rem;
  background-color: white;
  display: grid;
  place-items: center;
  border: 2px solid ${Palette.GUN_POWDER};
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
  align-self: flex-end;
  padding: 0.245rem 1.5rem;

  @media screen and (max-width: ${Breakpoints.TABLET_MAX_WIDTH}) {
    padding: 0rem 1rem;
  }
`;

export const StyledBequestDisplayButtonGroup = styled.div`
  margin-top: 1.5rem;
  display: flex;
  gap: 1.5rem;
`;

export const StyledBequestDisplayButton = styled.button`
  color: black;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
`;

export const StyledBequestDisplayEditIcon = styled(BiEditAlt)`
  color: ${Palette.CADET_BLUE_DARKER};
  font-size: 1rem;
`;

export const StyledBequestDisplayRemoveIcon = styled(BsTrash)`
  color: ${Palette.CADET_BLUE_DARKER};
  font-size: 1rem;
`;

export const StyledBequestAdderButton = styled.button`
  margin-top: 1.5rem;
  width: 100%;
  border: 1px solid ${Palette.LIGHT_GREY};
  border-radius: 0.3rem;
  min-width: 8rem;
  font-family: 'SofiaPro-Regular';
  padding: 0.5rem 1rem;
  font-size: clamp(0.9rem, 1vw, 1rem);
  color: ${Palette.GUN_POWDER};
  background-color: ${Palette.NEW_WHITE};
  cursor: pointer;
`;

export const StyledBequestDisplayRelation = styled.p`
  font-family: 'SofiaPro-Bold';
  font-size: clamp(0.9rem, 1vw, 1rem);
  color: ${Palette.GUN_POWDER};
  text-transform: capitalize;
  margin-bottom: 0.4rem;
`;
