/**
 * Returns 'Valid Date' if the string is a valid date, otherwise returns a string containing the error
 *
 * Should consider using a library to validate instead, dayjs should be able to validate
 *
 * @param dateString
 * @returns
 */
export const dateValidityCheck = (dateString: string): string => {
  if (!/^\d{4}-\d{1,2}-\d{1,2}$/.test(dateString)) {
    return 'Date must be formatted as yyyy-mm-dd';
  }

  const parts = dateString.split('-');
  const year = parseInt(parts[0], 10);
  const month = parseInt(parts[1], 10);
  const day = parseInt(parts[2], 10);

  if (year < 1900 || year > 2022) {
    return 'Invalid Year';
  }

  if (month === 0 || month > 12) {
    return 'Invalid Month';
  }

  const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];
  if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) {
    monthLength[1] = 29;
  }
  if (day === 0 || day > monthLength[month - 1]) {
    return 'Invalid Day';
  }

  return 'Valid Date';
};
