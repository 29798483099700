import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledLoginContainer = styled.div`
  padding: 3rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${Palette.NEW_BLUE};

  @media (max-width: 600px) {
    padding: 3rem 1.5rem;
  }
`;

export const StyledLoginForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledForgotPasswordLink = styled.p`
  font-family: 'SofiaPro-Regular';
  font-size: 0.8rem;
  align-self: start;
  text-decoration: underline;
  margin-bottom: 2rem;
`;
