import React, { useContext, useState } from 'react';
import { StyledBequestAdderContainer, StyledBequestAdderButton } from './BequestAdder.styles';
import { IBequestAdderProps } from './BequestAdder.types';
import { EstateIntakeContext } from '../../../contexts/EstateIntakeContext/EstateIntakeContext';
import { canAddBequest } from './BequestAdder.helpers';
import { SnackBarContext } from '@/providers';
import { CreateBequestForm } from './CreateBequestForm';
import { BequestForm } from '../BequestForm';

const BequestAdder = ({ btnText, relativeDefinitions }: IBequestAdderProps) => {
  const { state } = useContext(EstateIntakeContext);
  const { setSnackMessage } = useContext(SnackBarContext);
  const [addMode, setAddMode] = useState(false);
  const { bequests } = state.allBequests;

  const handleBequestAdder = () => {
    if (canAddBequest(bequests)) {
      setAddMode(true);
      return;
    }
    setSnackMessage('Beneficiaries cannot be more than 10');
  };

  return (
    <StyledBequestAdderContainer>
      {!addMode ? (
        <StyledBequestAdderButton type="button" onClick={handleBequestAdder}>
          {btnText}
        </StyledBequestAdderButton>
      ) : (
        <CreateBequestForm
          onComplete={() => setAddMode(false)}
          renderForm={(onSubmit) => (
            <BequestForm
              bequest={undefined}
              relationshipDefinitions={relativeDefinitions}
              onSubmit={onSubmit}
              onCancel={() => setAddMode(false)}
            />
          )}
        />
      )}
    </StyledBequestAdderContainer>
  );
};

export default BequestAdder;
