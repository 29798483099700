import React, { useState } from 'react';

import { RelativeAdderForm } from '../RelativeAdderForm';
import {
  StyledReviewGreySpan,
  StyledReviewRow,
  StyledReviewRowEditButton,
  StyledReviewRowEditIcon,
  StyledReviewText,
} from './ReviewRow.styles';
import { IReviewRowProps } from './ReviewRow.types';
import { RelativeFormAdderValues } from '../RelativeAdderForm/RelativeAdderForm.types';
import { IRelativeInfo } from '../../WillsFamilyFlow.types';

const ReviewRow = ({ relativeDefinition, relationship, setAllRelatives }: IReviewRowProps) => {
  const [editMode, setEditMode] = useState(false);

  const onEditRelationship = (data: RelativeFormAdderValues) => {
    setAllRelatives((relationships) => {
      const elementIndex = relationships.indexOf(relationship);
      const tempRelations = relationships.slice();
      const updatedData: IRelativeInfo = {
        ...relationship,
        ...data,
      };
      tempRelations.splice(elementIndex, 1, updatedData);
      return tempRelations;
    });

    setEditMode(false);
  };

  const onCancelEditRelationship = () => {
    setEditMode(false);
  };

  return editMode ? (
    <RelativeAdderForm
      relativeDefinitionId={relationship.relationDefinitionId}
      relationship={relationship}
      onCancel={onCancelEditRelationship}
      onSubmit={onEditRelationship}
    />
  ) : (
    <StyledReviewRow>
      <StyledReviewText>
        <StyledReviewGreySpan>
          {relativeDefinition.description}
          :&nbsp;
        </StyledReviewGreySpan>
        {relationship.firstName} {relationship.lastName}
      </StyledReviewText>
      <StyledReviewRowEditButton type="button" onClick={() => setEditMode(true)}>
        Edit
        <StyledReviewRowEditIcon />
      </StyledReviewRowEditButton>
    </StyledReviewRow>
  );
};

export default ReviewRow;
