import React from 'react';
import { ApiResponse } from '@/lib/axios';
import { Loading } from '@/Shared/Components/Loading';
import { useApi } from '@/lib/axios/useApi';
import { getInvestStatus, InvestStatus } from '../../apis';
import { KeyRequired } from '@/Types/KeyRequired';

interface InvestAuthGuardProps {
  render: () => JSX.Element;
  renderOnMissingData: () => JSX.Element;
  initializeInvestProfileCallback?: () => Promise<ApiResponse<InvestStatus>>;
}

export const InvestAuthGuard: React.FC<KeyRequired<InvestAuthGuardProps>> = ({
  render,
  renderOnMissingData,
  initializeInvestProfileCallback = getInvestStatus,
}) => {
  const { data, loading, error } = useApi(initializeInvestProfileCallback);

  if (loading) {
    return <Loading />;
  }

  if (error || !data) {
    throw new Error('bad');
  }

  if (!data.exists) {
    return renderOnMissingData();
  }

  return render();
};
