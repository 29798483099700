import { IRelativeResponse } from '@/lib/wills/pages/WillsFamilyFlow/WillsFamilyFlow.types';

export const getSpouseRelative = (relatives: IRelativeResponse[]): IRelativeResponse | null =>
  relatives.filter((relative) => {
    const relationDefinition = relative.relationDefinition.relationship;
    return relationDefinition === 'wife' || relationDefinition === 'husband';
  })[0] || null;

export const getNonSpouseRelatives = (relatives: IRelativeResponse[]): IRelativeResponse[] =>
  relatives.filter((relative) => {
    const relationDefinition = relative.relationDefinition.relationship;
    return relationDefinition !== 'wife' && relationDefinition !== 'husband';
  });
