import styled, { css } from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const WishFormContainer = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 370px;
  gap: 30px;
`;

export const Title = styled.h3`
  font-family: 'SofiaPro-Regular';
  font-size: 36px;
  font-weight: 500;
  line-height: 46.8px;
  color: ${Palette.NEW_BLUE};
`;

const body = css`
  font-family: 'SofiaPro-Regular';
  font-size: 18px;
  font-weight: 400;
  line-height: 25.2px;
  color: ${Palette.NEW_BLUE};
`;

const inputBorderBox = css`
  border: solid 1px #aaacb4;
  border-radius: 8px;
  background-color: #ffffff;
  padding: 10px 20px 10px 20px;
`;

const placeholder = css`
  color: #aaacb4;
`;
export const TextInput = styled.input`
  height: 45px;
  ${inputBorderBox}
  ${body}
  ::placeholder {
    ${placeholder}
  }
`;

export const Description = styled.p`
  ${body}
`;

export const ErrorLabel = styled.label`
  ${body}
  color: red;
`;
export const TextArea = styled.textarea`
  ${inputBorderBox}
  ${body}
  resize: none;
  ::placeholder {
    ${placeholder}
  }
`;
