import React, { useContext, useState, useRef, useEffect } from 'react';

import { setChildGuardians } from '@wills/apis';
import { SnackBarContext } from '@/providers';
import { getErrorMessage } from '@/Utils';

import { EstateIntakeContext, EstateIntakeContextActionType } from '../../contexts';
import {
  ElementChooser,
  handleNavigationButtons,
  NavigationButtons,
  RelativeAdder,
  StyledWillsIntroText,
  StyledWillsTitle,
} from '../../components';

import {
  StyledNavigationButtonsWrapper,
  StyledRelativeTipsWrapper,
  StyledChildGuardiansContentContainer,
  StyledChildGuardiansGrid,
  StyledChildGuardiansInfoContainer,
  StyledChildGuardiansPageContainer,
  StyledChildGuardiansTextMessage,
  StyledBackupChildGuardiansContainer,
  StyledBackupChildGuardiansContent,
  StyledBackupTrusteesContainer,
} from './ChildGuardians.styles';
import { IGuardianData } from './ChildGuardians.types';
import {
  StyledExecutorAndEstateChooser,
  StyledExecutorChooserRadioButtonLabel,
  StyledExecutorChooserText,
} from '../../components/ElementChooser/ElementChooser.styles';
import { StyledErrorMessage } from '../ExecutorAndEstateTrustee/ExecutorAndEstateTrustee.styles';
import { Modal } from '@/Shared/Components';
import ChildGuardianQuestionInfo from './ChildGuardianQuestionInfo';
import { StyledInfoIcon, StyledInfoIconContainer, StyledInfoIconWrapper } from '@/lib/wills/components/styles';
import { getNonSpouseRelatives } from '../../helpers';

export const ChildGuardians: React.FC<{
  onNext: () => void;
  onBack: () => void;
  onSummary: () => void;
}> = ({ onNext, onBack, onSummary }) => {
  const { state, dispatch } = useContext(EstateIntakeContext);
  const { setSnackMessage } = useContext(SnackBarContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const [showInfoBlurb, setShowInfoBlurb] = useState(false);
  const errorMsgRef = useRef<HTMLParagraphElement>(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const relativesWithoutParents = getNonSpouseRelatives(state.adultRelatives);
  const relativeDefinitions = state.relativeDefinitions.filter(
    (relDef) => ['OTHER', 'GENERAL'].includes(relDef.category) && relDef.relationship !== 'charity',
  );
  const relativesWithoutMainGuardian = relativesWithoutParents.filter(
    (relative) => relative.id !== state.childGuardians.main?.id,
  );

  async function onContinueBtnClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (!state.childGuardians.main) {
      setShowErrorMessage(true);
      return;
    }

    if (isSubmitting) return;

    setSubmitting(true);

    const guardianData: IGuardianData = {
      mainGuardianId: state.childGuardians.main.id as number,
      backupGuardianId: state.childGuardians.backup ? (state.childGuardians.backup.id as number) : null,
    };

    try {
      const { error } = await setChildGuardians(guardianData);
      if (error) {
        throw new Error(error.message);
      }
      handleNavigationButtons(event, onNext, onSummary);
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    } finally {
      setSubmitting(false);
    }
  }

  useEffect(() => {
    if (errorMsgRef.current) {
      errorMsgRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  });

  return (
    <StyledChildGuardiansPageContainer>
      <StyledChildGuardiansContentContainer>
        <StyledChildGuardiansGrid>
          <StyledChildGuardiansInfoContainer>
            <StyledWillsIntroText center>Your estate</StyledWillsIntroText>
            <StyledWillsTitle center>Guardians for your Children</StyledWillsTitle>
            <StyledBackupTrusteesContainer>
              <StyledChildGuardiansTextMessage center>
                Who do you choose to act in this role?
              </StyledChildGuardiansTextMessage>
              {showErrorMessage && (
                <StyledErrorMessage ref={errorMsgRef}>Please select one of the options</StyledErrorMessage>
              )}
              <StyledBackupChildGuardiansContainer>
                <StyledBackupChildGuardiansContent>
                  {relativesWithoutParents.map((relative) => (
                    <ElementChooser
                      key={relative.id}
                      id={`main-guardian-${relative.id}`}
                      checked={relative === state.childGuardians.main}
                      onClick={() => {
                        setShowErrorMessage(false);
                        dispatch({
                          type: EstateIntakeContextActionType.SET_MAIN_GUARDIAN,
                          payload: relative,
                        });
                      }}
                      value={`${relative.firstName} ${relative.lastName}`}
                    />
                  ))}
                  <RelativeAdder
                    action={EstateIntakeContextActionType.SET_MAIN_GUARDIAN}
                    btnText="Add a Guardian +"
                    relativeDefinitions={relativeDefinitions}
                  />
                </StyledBackupChildGuardiansContent>
              </StyledBackupChildGuardiansContainer>
            </StyledBackupTrusteesContainer>

            {state.childGuardians.main && (
              <StyledBackupTrusteesContainer>
                <StyledChildGuardiansTextMessage center>
                  Backups are recommended, would you like to add one?
                </StyledChildGuardiansTextMessage>
                <StyledBackupChildGuardiansContainer>
                  <StyledBackupChildGuardiansContent>
                    <StyledExecutorAndEstateChooser>
                      <StyledExecutorChooserRadioButtonLabel htmlFor="None">
                        <input
                          id="None"
                          type="radio"
                          checked={!state.childGuardians.backup && true}
                          onChange={() =>
                            dispatch({
                              type: EstateIntakeContextActionType.SET_BACKUP_GUARDIAN,
                              payload: null,
                            })
                          }
                          value=""
                        />
                        <StyledExecutorChooserText>None</StyledExecutorChooserText>
                      </StyledExecutorChooserRadioButtonLabel>
                    </StyledExecutorAndEstateChooser>
                    {relativesWithoutMainGuardian.map((relative) => (
                      <ElementChooser
                        key={relative.id}
                        id={`backup-guardian-${relative.id}`}
                        checked={relative === state.childGuardians.backup}
                        onClick={() =>
                          dispatch({
                            type: EstateIntakeContextActionType.SET_BACKUP_GUARDIAN,
                            payload: relative,
                          })
                        }
                        value={`${relative.firstName} ${relative.lastName}`}
                      />
                    ))}
                    <RelativeAdder
                      action={EstateIntakeContextActionType.SET_BACKUP_GUARDIAN}
                      btnText="Add a Guardian +"
                      relativeDefinitions={relativeDefinitions}
                    />
                  </StyledBackupChildGuardiansContent>
                </StyledBackupChildGuardiansContainer>
              </StyledBackupTrusteesContainer>
            )}

            <StyledInfoIconWrapper>
              <StyledInfoIconContainer onClick={() => setShowInfoBlurb(true)}>
                <StyledInfoIcon />
              </StyledInfoIconContainer>
            </StyledInfoIconWrapper>

            {showInfoBlurb && (
              <Modal transparent onCloseCallback={() => setShowInfoBlurb(false)}>
                <ChildGuardianQuestionInfo />
              </Modal>
            )}

            <StyledNavigationButtonsWrapper>
              <NavigationButtons onContinue={onContinueBtnClick} onPrevious={onBack} />
            </StyledNavigationButtonsWrapper>
          </StyledChildGuardiansInfoContainer>

          <StyledRelativeTipsWrapper>
            <ChildGuardianQuestionInfo />
          </StyledRelativeTipsWrapper>
        </StyledChildGuardiansGrid>
      </StyledChildGuardiansContentContainer>
    </StyledChildGuardiansPageContainer>
  );
};
