import React from 'react';

import {
  Image,
  ImageBackground,
  ProductContainer,
  InfoContainer,
  ProductTitle,
  ProductDescription,
  ProductFooter,
} from './Product.styles';

interface IProps {
  image: string;
  title: string;
  description: string;
  footer?: string;
}

const Product: React.FC<IProps> = ({ image, title, description, footer }) => {
  return (
    <ProductContainer>
      <ImageBackground>
        <Image src={image} alt="logo" />
      </ImageBackground>
      <InfoContainer>
        <ProductTitle>{title}</ProductTitle>
        <ProductDescription>{description}</ProductDescription>
        <ProductFooter>{footer}</ProductFooter>
      </InfoContainer>
    </ProductContainer>
  );
};

export default Product;
