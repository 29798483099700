import React, { useContext, useState } from 'react';
import { deleteBequest } from '@wills/apis/bequests/deleteBequest';
import {
  StyledBequestDisplayContainer,
  StyledBequestDisplayName,
  StyledBequestDisplayButtonGroup,
  StyledBequestDisplayButton,
  StyledBequestDisplayEditIcon,
  StyledBequestDisplayRemoveIcon,
  StyledBequestDisplayHeader,
  StyledBequestDisplayAllocation,
  StyledBequestDisplayRelation,
} from './BequestDisplay.styles';
import { EstateIntakeContext, EstateIntakeContextActionType } from '../../../contexts';
import { BequestAdder } from '../BequestAdder';
import { getErrorMessage } from '@/Utils';
import { SnackBarContext } from '@/providers';
import { IBequest } from '@/lib/wills/apis';
import { BequestForm } from '../BequestForm';
import { UpdateBequestForm } from './UpdateBequestForm';

const BequestDisplay = () => {
  const { state, dispatch } = useContext(EstateIntakeContext);
  const { setSnackMessage } = useContext(SnackBarContext);
  const [editableBequest, setEditableBequest] = useState<IBequest | undefined>(undefined);
  const { totalBequestAllocation, bequests } = state.allBequests;

  const editBequest = (id: number) => {
    const bequestForEdit = bequests.find((singleBequest) => singleBequest.id === id);
    setEditableBequest(bequestForEdit);
  };

  const onDelete = async (id: number) => {
    try {
      const deletedBequest = bequests.find((singleBequest) => singleBequest.id === id);
      await deleteBequest(id);
      const bequestAllocationAfterDeletion = totalBequestAllocation - Number(deletedBequest?.allocation);
      const bequestsAfterDeletion = bequests.filter((singleBequest) => singleBequest.id !== id);
      dispatch({
        type: EstateIntakeContextActionType.SET_ALL_BEQUESTS,
        payload: { totalBequestAllocation: bequestAllocationAfterDeletion, bequests: bequestsAfterDeletion },
      });
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    }
  };
  const renderAllBequests =
    bequests.length > 0 &&
    bequests.map((bequest, index) => {
      return (
        <StyledBequestDisplayContainer data-testid="bequest-container" key={index}>
          <StyledBequestDisplayHeader>
            <StyledBequestDisplayName>
              {state.relativeDefinitions.map((singleRelationship) => {
                if (bequest.willsRelationshipId === singleRelationship.id) {
                  return (
                    <StyledBequestDisplayRelation key={index}>
                      {singleRelationship.description}
                    </StyledBequestDisplayRelation>
                  );
                }
                return '';
              })}
              {bequest.relationDefinition.isOrganization
                ? `${bequest.organizationName}`
                : `${bequest.firstName} ${bequest.lastName}`}
            </StyledBequestDisplayName>
            <StyledBequestDisplayAllocation>{bequest.allocation}%</StyledBequestDisplayAllocation>
          </StyledBequestDisplayHeader>
          <StyledBequestDisplayButtonGroup>
            <StyledBequestDisplayButton onClick={() => editBequest(bequest.id!)}>
              <StyledBequestDisplayEditIcon />
              Edit
            </StyledBequestDisplayButton>
            <StyledBequestDisplayButton onClick={() => onDelete(bequest.id!)}>
              <StyledBequestDisplayRemoveIcon />
              Remove
            </StyledBequestDisplayButton>
          </StyledBequestDisplayButtonGroup>
        </StyledBequestDisplayContainer>
      );
    });

  const relativeDefinitions = state.relativeDefinitions.filter((relativeDefinition) =>
    ['OTHER', 'GENERAL'].includes(relativeDefinition.category),
  );
  return !editableBequest ? (
    <>
      {renderAllBequests}
      <BequestAdder relativeDefinitions={relativeDefinitions} btnText="Add Bequest +" />
    </>
  ) : (
    <UpdateBequestForm
      onComplete={() => setEditableBequest(undefined)}
      renderForm={(onSubmit) => (
        <BequestForm
          bequest={editableBequest}
          onSubmit={onSubmit}
          relationshipDefinitions={relativeDefinitions}
          onCancel={() => setEditableBequest(undefined)}
        />
      )}
    />
  );
};

export default BequestDisplay;
