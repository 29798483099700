import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledStepContainer = styled.div<{ active: boolean }>`
  background-color: ${({ active }) => (active ? Palette.NEW_WHITE : Palette.SLATE_GREEN)};
  padding: 1rem;
  border-radius: 0.5rem;
  margin-bottom: 0.7rem;
  max-width: 34rem;
  width: 100%;
  font-family: 'SofiaPro-Regular';
`;

export const StyledStepTitleBar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledStepTitle = styled.h3<{ active: boolean }>`
  color: ${({ active }) => (active ? Palette.GUN_POWDER : Palette.NEW_WHITE)};
`;

export const StyledStepInfoContainer = styled.div`
  margin-top: 1rem;
`;

export const StyledStepParagraph = styled.p`
  max-width: 25rem;
  font-size: clamp(0.9rem, 1vw, 1rem);
  color: ${Palette.DARK_GREY};
  margin-bottom: 0.5rem;
`;

export const StyledStepInfoButtonContainer = styled.div`
  display: flex;
  justify-content: end;

  @media (max-width: 720px) {
    display: block;
  }
`;

export const StyledStepButton = styled.button<{ color?: string }>`
  border-radius: 0.3rem;
  background-color: ${({ color }) => color || Palette.SLATE_GREEN};
  color: ${({ color }) =>
    color === Palette.NEW_WHITE || color === Palette.GOLD_CRAYOLA ? Palette.GUN_POWDER : Palette.NEW_WHITE};
  padding: 0.5rem 1rem;
  cursor: pointer;
  ${({ color }) => (color === Palette.GUN_POWDER || color === Palette.GOLD_CRAYOLA) && 'border: none;'}
  ${({ color }) => color === Palette.NEW_WHITE && 'border: 1px solid rgb(140, 142, 160, 0.2);'}
  ${({ color }) => !color && `border: 1px solid ${Palette.NEW_WHITE};`}

  @media (max-width: 720px) {
    ${({ color }) => color === Palette.GOLD_CRAYOLA && 'width: 100%;'}
  }
`;
