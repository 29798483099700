import React from 'react';
import { InfoIcon } from './assets';
import { Card, CardIcon, CardBody } from './InfoCard.styles';
import locales from './InfoCard.locales.json';

interface InfoCardProps {
  label: string;
}
export const InfoCard: React.FC<InfoCardProps> = ({ label }) => {
  const locale = locales.EN;
  return (
    <Card>
      <CardIcon src={InfoIcon} alt={locale.infoIconAlt} />
      <CardBody>{label}</CardBody>
    </Card>
  );
};
