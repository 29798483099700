import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { button, sectionGap, sectionTitle } from '@/components';
import { Breakpoints } from '@/Utils/breakpoints';
import { primaryColors } from '../styles';

export const StyledAssetsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: baseline;
  min-width: 370px;
  ${sectionGap}
`;

export const StyledAssetsListTitle = styled.h2`
  ${sectionTitle}
  color: ${Palette.NEW_BLUE};
`;

export const StyledPrimaryButton = styled.button`
  ${button}
  ${primaryColors}
    display: block;
  min-width: 370px;

  @media (min-width: ${Breakpoints.TABLET_MIN_WIDTH}) {
    max-width: 40%;
  }
`;

export const SemiBold = styled.span`
  font-weight: 500;
`;
