import { axiosClient, toApiResponse } from '@/lib/axios';

export interface SetEstateExecutorsInput {
  mainExecutorId: number;
  backupExecutorId: number | null;
}

export const setEstateExecutors = (executorData: SetEstateExecutorsInput) => {
  return toApiResponse<never>(axiosClient.put('/wills-set-estate-executors', executorData));
};
