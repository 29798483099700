import React from 'react';
import { popperSvg } from '../../assets';
import { StyledContactUsInfoContainer, StyledImage, StyledFormHeader, StyledContactUsText } from './FormSuccess.style';
import { StyledSubmitBtn } from '../Form/Form.styles';
import { IFormProps } from '../Form/Form.types';

const FormSuccess = ({ setIsModalOpen }: IFormProps) => {
  return (
    <StyledContactUsInfoContainer>
      <StyledFormHeader>Thank You</StyledFormHeader>
      <StyledContactUsText>We will contact with you shortly</StyledContactUsText>
      <StyledImage src={popperSvg} alt="popper svg" />
      <StyledSubmitBtn onClick={() => setIsModalOpen(false)}>Done</StyledSubmitBtn>
    </StyledContactUsInfoContainer>
  );
};

export default FormSuccess;
