import { RelativeDefinitionRelationship, ALL_RELATIONSHIPS } from '../types';

const UNIQUE_RELATIONSHIPS: RelativeDefinitionRelationship[] = ['wife', 'husband', 'mother', 'father'];

const removeUniqueRelationshipsIfUsed = (
  relationships: RelativeDefinitionRelationship[],
  currentRelationships: RelativeDefinitionRelationship[],
): RelativeDefinitionRelationship[] => {
  return relationships.reduce<RelativeDefinitionRelationship[]>((acc, cur) => {
    if (UNIQUE_RELATIONSHIPS.includes(cur) && currentRelationships.includes(cur)) {
      return acc;
    }
    return [...acc, cur];
  }, []);
};

const pipe = <T>(initial: T, fns: ((acc: T) => T)[]): T => {
  return fns.reduce<T>((acc, fn) => {
    return fn(acc);
  }, initial);
};

export const removeRelationshipsByCurrentValues = (
  currentRelationships: RelativeDefinitionRelationship[],
  relationships: RelativeDefinitionRelationship[] = ALL_RELATIONSHIPS,
): RelativeDefinitionRelationship[] => {
  return pipe(relationships, [(acc) => removeUniqueRelationshipsIfUsed(acc, currentRelationships)]);
};
