import React, { useContext, useEffect, useRef } from 'react';

import { Header } from '@/Shared/Components';
import { IUserAddress } from '@/Types';
import { useGooglePlace } from '@/Shared/Hooks';
import { LocaleContext } from '@/providers';

import { HiddenSubmitButton, LongInput } from '../../../../../../Shared/Shared.styles';
import { Prompt } from '../Prompt';
import { LocationInputContainer, LocationInputFormContainer, RequiredInfoIndicator } from './LocationInput.styles';
import './LocationInput.css';
import locationInputLocales from './locationInputLocales.json';

interface IProps {
  setAddressData: React.Dispatch<React.SetStateAction<IUserAddress>>;
  goToNextQuestion: () => void;
}

const LocationInput: React.FC<IProps> = ({ setAddressData, goToNextQuestion }) => {
  const { locale } = useContext(LocaleContext);

  const inputRef = useRef<HTMLInputElement | null>(null);

  const options = {
    componentRestrictions: { country: 'ca' },
  };

  const resetInputField = () => {
    inputRef.current!.value = '';
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      resetInputField();
    }
  };

  const handleDocumentKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      resetInputField();
    }
  };

  const { place } = useGooglePlace(inputRef, options);

  const recordAddress = (name: string, value: string) => {
    setAddressData((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const onSubmit = (event: any) => event.preventDefault();

  const focusInputOnLoad = () => {
    inputRef.current?.focus();
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    document.addEventListener('keydown', handleDocumentKeyDown);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      document.removeEventListener('keydown', handleDocumentKeyDown);
    };
  }, []);
  useEffect(() => {
    if (place) {
      place.forEach((data: any) => {
        if (data.types.includes('route')) recordAddress('streetName', data.long_name);
        if (data.types.includes('street_number')) recordAddress('streetNumber', data.long_name);
        if (data.types.includes('locality')) recordAddress('city', data.long_name);
        if (data.types.includes('administrative_area_level_1')) recordAddress('provinceState', data.short_name);
        if (data.types.includes('country')) recordAddress('country', data.short_name);
        if (data.types.includes('postal_code')) recordAddress('postalZip', data.long_name);
      });

      goToNextQuestion();
    }
  }, [place]);

  useEffect(() => {
    setAddressData({
      streetName: '',
      streetNumber: '',
      postalZip: '',
      provinceState: '',
      country: '',
      unitSuiteApt: '',
      city: '',
    });
    focusInputOnLoad();
  }, []);

  const locationInputLocale = locationInputLocales[locale];

  return (
    <LocationInputContainer>
      <Header alignCenter>
        {locationInputLocale.header}
        <RequiredInfoIndicator>*</RequiredInfoIndicator>
      </Header>
      <LocationInputFormContainer>
        <form onSubmit={onSubmit}>
          <LongInput placeholder={locationInputLocale['input.placeholder']} ref={inputRef} />
          <HiddenSubmitButton type="submit" />
        </form>
        <Prompt disabled onClick={onSubmit} />
      </LocationInputFormContainer>
    </LocationInputContainer>
  );
};

export default LocationInput;
