import React from 'react';
import { InputContainer, OptionsFieldSet } from '../container.styles';
import { InputLabel, ErrorLabel } from '../typography.styles';

interface GroupFieldProps {
  id: string;
  label?: string;
  error?: string;
  children: React.ReactNode;
}

export const GroupField: React.FC<GroupFieldProps> = ({ id, label, error, children }) => {
  const labelId = `${id}-label`;
  const errorId = `${id}-error`;
  return (
    <InputContainer>
      {label && (
        <InputLabel id={labelId} as="p">
          {label}
        </InputLabel>
      )}
      <OptionsFieldSet aria-describedby={`${labelId} ${errorId}`}>{children}</OptionsFieldSet>
      {error && (
        <ErrorLabel id={errorId} as="p">
          {error}
        </ErrorLabel>
      )}
    </InputContainer>
  );
};
