import React from 'react';
import { QuestionInfo } from '@/lib/wills/components/QuestionInfo';

const ExecutorAndEstateQuestionInfo = () => {
  return (
    <QuestionInfo
      question="An Important Note"
      answer={
        <div>
          It is advisable to select someone to act as your Personal Representative who you trust and is personally
          familiar with your wishes. This may be your spouse, a friend, or a trusted community member.
        </div>
      }
    />
  );
};

export default ExecutorAndEstateQuestionInfo;
