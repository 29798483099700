import styled from 'styled-components';
import { BsFillCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';
import { Palette } from '@/Shared/Constants';

export const StyledSubscriptionItemsContainer = styled.div`
  margin: 1rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledSubscriptionItemsGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

export const StyledSubscriptionItemsCheckIcon = styled(BsFillCheckCircleFill)`
  color: #42b342;
  min-height: 1.1rem;
  min-width: 1.1rem;
  max-height: 1.1rem;
  max-width: 1.1rem;
`;

export const StyledSubscriptionItemsCrossIcon = styled(BsFillXCircleFill)`
  color: ${Palette.LIGHT_ORANGE};
  min-height: 1.1rem;
  min-width: 1.1rem;
  max-height: 1.1rem;
  max-width: 1.1rem;
`;
