export const PROVINCE_DATA = [
  {
    id: 1,
    value: 'AB',
    name: {
      EN: 'Alberta',
      FR: 'Alberta',
    },
  },
  {
    id: 2,
    value: 'BC',
    name: {
      EN: 'British Columbia',
      FR: 'Colombie-Britannique',
    },
  },
  {
    id: 3,
    value: 'MB',
    name: {
      EN: 'Manitoba',
      FR: 'Manitoba',
    },
  },
  {
    id: 4,
    value: 'NB',
    name: {
      EN: 'New Brunswick',
      FR: 'Nouveau-Brunswick',
    },
  },
  {
    id: 5,
    value: 'NL',
    name: {
      EN: 'Newfoundland and Labrador',
      FR: 'Terre-Neuve-et-Labrador',
    },
  },
  {
    id: 6,
    value: 'NT',
    name: {
      EN: 'Northwest Territories',
      FR: 'Territoires du Nord-Ouest',
    },
  },
  {
    id: 7,
    value: 'NS',
    name: {
      EN: 'Nova Scotia',
      FR: 'Nouvelle-Écosse',
    },
  },
  {
    id: 8,
    value: 'NU',
    name: {
      EN: 'Nunavut',
      FR: 'Nunavut',
    },
  },
  {
    id: 9,
    value: 'ON',
    name: {
      EN: 'Ontario',
      FR: 'Ontario',
    },
  },
  {
    id: 10,
    value: 'PE',
    name: {
      EN: 'Prince Edward Island',
      FR: 'Île-du-Prince-Édouard',
    },
  },
  {
    id: 11,
    value: 'QC',
    name: {
      EN: 'Quebec',
      FR: 'Québec',
    },
  },
  {
    id: 12,
    value: 'SK',
    name: {
      EN: 'Saskatchewan',
      FR: 'Saskatchewan',
    },
  },
  {
    id: 13,
    value: 'YT',
    name: {
      EN: 'Yukon',
      FR: 'Yukon',
    },
  },
];
