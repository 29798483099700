import React, { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { number, object, string } from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { LiabilityTypeKey } from '@wills/apis';
import {
  StyledLiabilityDetailsForm,
  StyledLiabilityDetailsFormContainer,
  StyledLiabilityDetailsFormErrorText,
  StyledLiabilityRadioButtonContainer,
  StyledLiabilitySelectInput,
  StyledLiabilityTextArea,
  StyledLiabilityTextInput,
} from './LiabilityDetailsForm.styles';
import { StyledGreenButton, StyledSecondaryButton } from '@/lib/wills/components';
import { ILiabilityDetailsFormProps, ILiabilityDetailsFormValues } from './LiabilityDetailsForm.types';
import { LIABILITY_TYPE } from '../../LiabilityListPage.constants';
import { Paragraph, SubHeader } from '../styles';
import { WarningAlert, CurrencyInput } from '@/components';
import { formatCurrency, parseCurrency } from '@/components/CurrencyInput/CurrencyInput.helper';
import { useLiabilityOwnedPercentageController } from './useLiabilityOwnedPercentageController';

const liabilitySchema = object({
  type: string().required('Required'),
  title: string().required('Required'),
  description: string().required('Required'),
  ownedPercentage: number()
    .transform((value, originalValue) => (originalValue === '' ? null : value))
    .nullable()
    .max(100, 'Value cannot be greater than 100')
    .required('Required'),
  valueInDollars: string()
    .transform((value, originalValue) => (originalValue === '0' ? null : value))
    .nullable()
    .required('Required'),
});

const LiabilityDetailsForm = ({ onCancel, liability, onSubmit }: ILiabilityDetailsFormProps) => {
  const {
    register,
    handleSubmit,
    control,
    formState: { errors, isSubmitting },
  } = useForm<ILiabilityDetailsFormValues>({
    defaultValues: {
      title: liability?.title ?? '',
      description: liability?.description ?? '',
      type: liability?.type ?? '',
      ownedPercentage: liability?.ownedPercentage ?? 0,
      valueInDollars: formatCurrency(liability?.valueInDollars ?? 0),
    },
    mode: 'onChange',
    resolver: yupResolver(liabilitySchema),
  });

  const [currencyValue, setCurrencyValue] = useState<number>(0);

  const {
    isTextFieldVisible,
    setOwnedPercentageAndHideTextField,
    setOwnedPercentageAndShowTextField,
    handlePercentageInputChange,
  } = useLiabilityOwnedPercentageController();

  const onSubmitHandler = handleSubmit(async (data) => {
    await onSubmit({
      ...data,
      valueInDollars: currencyValue,
    });
  });

  return (
    <StyledLiabilityDetailsFormContainer>
      <SubHeader>Liability Details</SubHeader>
      <StyledLiabilityDetailsForm onSubmit={onSubmitHandler}>
        <Paragraph>What type of financial liability are you adding?</Paragraph>

        <StyledLiabilitySelectInput {...register('type')}>
          <option value="">Select type of liability</option>
          {Object.keys(LIABILITY_TYPE).map((key, i) => (
            <option key={i} value={key}>
              {LIABILITY_TYPE[key as LiabilityTypeKey]}
            </option>
          ))}
        </StyledLiabilitySelectInput>
        {errors.type && (
          <StyledLiabilityDetailsFormErrorText>{errors.type.message}</StyledLiabilityDetailsFormErrorText>
        )}

        <StyledLiabilityTextInput {...register('title')} placeholder="Title of the liability" />
        {errors.title && (
          <StyledLiabilityDetailsFormErrorText>{errors.title.message}</StyledLiabilityDetailsFormErrorText>
        )}

        <Paragraph>Please share the description and details of the liability which might help your Trustee.</Paragraph>
        <StyledLiabilityTextArea {...register('description')} placeholder="Description of the liability" rows={3} />
        {errors.description && (
          <StyledLiabilityDetailsFormErrorText>{errors.description.message}</StyledLiabilityDetailsFormErrorText>
        )}
        <WarningAlert>Please don&apos;t share passwords and usernames with anyone.</WarningAlert>

        <Paragraph>How much of this liability is owned by you?</Paragraph>
        <Controller
          name="ownedPercentage"
          control={control}
          render={({ field }) => (
            <>
              <StyledLiabilityRadioButtonContainer>
                <input
                  {...field}
                  onChange={(e) => setOwnedPercentageAndHideTextField(e, field)}
                  type="radio"
                  defaultChecked={field.value === 100}
                  value="100"
                />
                <Paragraph>100%</Paragraph>
              </StyledLiabilityRadioButtonContainer>
              <StyledLiabilityRadioButtonContainer>
                <input
                  {...field}
                  onChange={(e) => setOwnedPercentageAndHideTextField(e, field)}
                  type="radio"
                  defaultChecked={field.value === 50}
                  value="50"
                />
                <Paragraph>50%</Paragraph>
              </StyledLiabilityRadioButtonContainer>
              <StyledLiabilityRadioButtonContainer>
                <input {...field} onChange={(e) => setOwnedPercentageAndShowTextField(e, field)} type="radio" />
                <Paragraph>Other</Paragraph>
              </StyledLiabilityRadioButtonContainer>
              {isTextFieldVisible && (
                <StyledLiabilityTextInput {...field} onChange={(e) => handlePercentageInputChange(e, field)} />
              )}
              {errors.ownedPercentage && errors.ownedPercentage.type !== 'typeError' && (
                <StyledLiabilityDetailsFormErrorText>
                  {errors.ownedPercentage.message}
                </StyledLiabilityDetailsFormErrorText>
              )}
            </>
          )}
        />

        <Paragraph>What is the approximate value of this liability?</Paragraph>
        <CurrencyInput
          placeholder="Value In Dollars"
          {...register('valueInDollars', {
            validate: {
              required: (value) => {
                const parsedValue = parseCurrency(value as string);
                return parsedValue === 0 ? 'Required' : true;
              },
            },
            onChange: (event) => {
              setCurrencyValue(parseCurrency(event.target.value));
            },
          })}
        />
        {errors.valueInDollars && errors.valueInDollars.type !== 'typeError' && (
          <StyledLiabilityDetailsFormErrorText>{errors.valueInDollars.message}</StyledLiabilityDetailsFormErrorText>
        )}
        <StyledGreenButton type="submit" disabled={isSubmitting}>
          Save
        </StyledGreenButton>
        <StyledSecondaryButton onClick={onCancel} type="button">
          Cancel
        </StyledSecondaryButton>
      </StyledLiabilityDetailsForm>
    </StyledLiabilityDetailsFormContainer>
  );
};

export default LiabilityDetailsForm;
