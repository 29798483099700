import { BsFillInfoCircleFill } from 'react-icons/bs';
import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { AlertContainer } from '../Alert.styles';

export const InfoIcon = styled(BsFillInfoCircleFill)`
  font-size: 18px;
  color: #228be6;
`;

export const InfoContainer = styled(AlertContainer)`
  color: ${Palette.GUN_POWDER};
  background-color: rgba(34, 139, 230, 0.1);
  border-color: #228be6;
`;
