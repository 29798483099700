import React from 'react';
import { DataCard, DataCardText } from '@wills/components';
import { ILiabilityDataProps } from './LiabilityData.types';
import { LIABILITY_TYPE } from '../../LiabilityListPage.constants';
import { SemiBold } from './LiabilityData.styles';

const LiabilityData: React.FC<ILiabilityDataProps> = ({ liability, hideButtons, onEdit, deleteLiability }) => {
  return (
    <DataCard
      title={liability.title}
      disabled={hideButtons}
      hideActions={hideButtons}
      onEdit={onEdit}
      onDelete={deleteLiability}
    >
      <DataCardText>{liability.description}</DataCardText>
      <DataCardText>
        <SemiBold>Type:</SemiBold> {LIABILITY_TYPE[liability.type]}
      </DataCardText>
      <DataCardText>
        <SemiBold>Ownership:</SemiBold> {liability.ownedPercentage}%
      </DataCardText>
      <DataCardText>
        <SemiBold>Value:</SemiBold> ${Number(liability.valueInDollars).toLocaleString()}
      </DataCardText>
    </DataCard>
  );
};

export default LiabilityData;
