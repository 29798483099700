import styled, { css } from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { ButtonGroup } from '@/lib/wills/components';
import { Breakpoints } from '@/Utils/breakpoints';


export const StyledBequestAdderForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

export const StyledBequestAdderFormName = styled.h2`
  font-family: 'SofiaPro-Regular';
  color: ${Palette.GUN_POWDER};
  margin-bottom: 1rem;
`;

export const InputGroup = styled.div<{ full?: boolean; hidden?: boolean }>`
  display: ${({ hidden }) => (hidden ? 'none' : 'flex')};
  flex-direction: column;
  gap: 0.5rem;
  flex: 1;
  ${({ full }) =>
    full &&
    css`
      min-width: 100%;
    `};
`;

export const StyledBequestAdderFormLabel = styled.label`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
`;

export const StyledBequestAdderFormHelperText = styled.label<{ align?: string }>`
  color: red;
  font-family: 'SofiaPro-Regular';
  font-size: 0.7rem;
  text-align: ${({ align }) => align && 'center'};
`;

export const StyledBequestFormInputContainer = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;

  @media (min-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    flex-flow: row wrap;
  }
`;

export const StyledBequestAdderFormInput = styled.input`
  padding: 0.5rem 0.8rem;
  border-radius: 0.3rem;
  font-size: clamp(0.9rem, 1vw, 1rem);
  border: 1px solid ${Palette.GREY};
  color: ${Palette.GUN_POWDER};
`;

export const StyledBequestAllocationlabel = styled(StyledBequestAdderFormLabel)`
  font-size: 1.5rem;
  text-align: center;
  display: block;
  font-weight: bold;
`;

export const StyledBequestAllocationFormInput = styled(StyledBequestAdderFormInput)`
  display: block;
  width: 5rem;
  margin: 0 auto;

  &::placeholder {
    text-align: center;
  }
`;

export const StyledBequestRelationshipDropdown = styled.select`
  padding: 0.5rem 0.8rem;
  border-radius: 0.3rem;
  font-size: clamp(0.9rem, 1vw, 1rem);
  border: 1px solid ${Palette.GREY};
  color: ${Palette.GUN_POWDER};
`;

export const BequestButtonGroup = styled(ButtonGroup)`
  margin: 1rem 1rem 3rem 1rem;
`;
