import React from 'react';
import { User, UserReasonsForJoining } from './types';
import { useApi } from '@/lib/axios/useApi';
import { getUser } from './apis';
import { Loading } from '@/Shared/Components/Loading';

type UserReducerActions =
  | {
      type: 'SET_USER';
      value: User;
    }
  | {
      type: 'SET_USER_REASONS_FOR_JOINING';
      value: UserReasonsForJoining;
    };

function reducer(state: User, action: UserReducerActions): User {
  switch (action.type) {
    case 'SET_USER':
      return action.value;
    case 'SET_USER_REASONS_FOR_JOINING':
      return {
        ...state,
        reasonsForJoining: {
          ...action.value,
        },
      };
    default:
      throw new Error('UserProvider: Unknown action type');
  }
}
interface UserContextValues {
  user: User;
  dispatch: React.Dispatch<UserReducerActions>;
}

export const UserContext = React.createContext({} as UserContextValues);

export const UserProvider: React.FC<{
  children: React.ReactNode;
  fetchUser?: typeof getUser;
}> = ({ children, fetchUser = getUser }) => {
  const [user, dispatch] = React.useReducer(reducer, {} as User);
  const { loading, error } = useApi(fetchUser, [], {
    onSuccess: (data) => {
      dispatch({
        type: 'SET_USER',
        value: data,
      });
    },
  });

  if (error) {
    throw new Error(error.message);
  }

  if (loading) {
    return <Loading />;
  }

  return (
    <UserContext.Provider
      value={{
        user,
        dispatch,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
