import React from 'react';

import {
  ButtonsContainer,
  Container,
  StyledDocumentCardDescription,
  StyledDocumentCardTitle,
  Image,
} from './ActionCard.styles';

export interface ActionCardProps {
  title: string;
  description: string;
  imageProps: {
    src: string;
    alt: string;
  };
  children: React.ReactNode;
}

export const ActionCard: React.FC<ActionCardProps> = ({ title, description, imageProps, children }) => {
  return (
    <Container>
      <Image {...imageProps} />
      <StyledDocumentCardTitle>{title}</StyledDocumentCardTitle>
      <StyledDocumentCardDescription>{description}</StyledDocumentCardDescription>
      <ButtonsContainer>{children}</ButtonsContainer>
    </Container>
  );
};
