import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const StyledAppointeesRoleListBox = styled.div`
  background-color: ${Palette.WHITE};
  border-radius: 1rem;
  padding: 1rem;
  font-family: SofiaPro-Regular;
  color: ${Palette.NEW_BLUE};
`;

export const StyledAppointeesRoleListHeaderRow = styled.div`
  display: table;
  width: 100%;
  border-bottom: 1px solid ${Palette.LIGHT_GREY};
  padding: 0.5rem 0;
`;

export const StyledAppointeesRoleListTableRow = styled.div`
  display: table;
  width: 100%;
  padding: 0.5rem 0;

  p {
    font-size: 0.9rem;
    color: ${Palette.PLANET_BLUE};
  }
`;

export const StyledAppointeesRoleListTableCell = styled.div`
  display: table-cell;
  max-width: 0;
`;

export const StyledAppointeesRoleListTableCheckBoxContainer = styled.div`
  display: table-cell;
  width: 2rem;
`;
