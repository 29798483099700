import { ChangeEvent } from 'react';

import { IUserAddress } from '@/Types';

export const setAddressValues = (address: IUserAddress) => {
  return {
    streetName: address.streetName,
    streetNumber: address.streetNumber,
    unitSuiteApt: address.unitSuiteApt,
    city: address.city,
    provinceState: address.provinceState,
    postalZip: address.postalZip,
    country: address.country,
  };
};

export const limitAddressValue = (event: ChangeEvent<HTMLInputElement>, charLimit: number) => {
  const { value } = event.target;
  event.target.value = value.slice(0, charLimit);
};
