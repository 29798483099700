import React from 'react';
import { getOneVestHtml } from './getOneVestHtml';
import { bindLoadingToPromise } from '@/Utils';
import { TokenStore } from '@/Stores';
import { LoadingOverlay } from '@/Shared/Components';
import { Iframe, InvestContainer, OnevestContainer } from './OneVestPortal.styles';

export interface OneVestPortalProps {
  accessToken: string;
}

const OneVestIFrame: React.FC<OneVestPortalProps> = ({ accessToken }) => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const oneVestRef = React.useRef<HTMLIFrameElement>(null);

  React.useEffect(() => {
    const messageSubHandler = (e: MessageEvent) => {
      if (oneVestRef?.current?.contentWindow) {
        const { contentWindow } = oneVestRef.current;
        contentWindow.postMessage(e.data, '*');
      }
    };

    window.addEventListener('message', messageSubHandler);

    return () => {
      window.removeEventListener('message', messageSubHandler);
    };
  }, [oneVestRef]);

  React.useEffect(() => {
    if (oneVestRef.current) {
      const iframeElement = oneVestRef.current;
      bindLoadingToPromise(
        getOneVestHtml(accessToken)
          .then((html) => {
            iframeElement.srcdoc = `<!DOCTYPE html>${html}`;
          })
          .catch((e) => {
            throw new Error(e);
          }),
        setLoading,
      );
    }
  }, [oneVestRef, accessToken]);

  return (
    <InvestContainer>
      <OnevestContainer>
        {loading && <LoadingOverlay />}
        <Iframe ref={oneVestRef} title="onevest-card" />
      </OnevestContainer>
    </InvestContainer>
  );
};

const WithAccessToken: React.FC<{
  renderElement: (accessToken: string) => JSX.Element;
}> = ({ renderElement }) => {
  const { token } = React.useContext(TokenStore);

  if (!token) {
    throw new Error('token is undefined');
  }

  return renderElement(token);
};

export const OneVestPortal: React.FC = () => (
  <WithAccessToken renderElement={(accessToken) => <OneVestIFrame accessToken={accessToken} />} />
);
