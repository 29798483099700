import styled from 'styled-components';

import { Dropdown } from '@/lib/wills/components';
import { Palette } from '@/Shared/Constants';

export const StyledRelativeAdderForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

export const StyledRelativeAdderFormName = styled.h2`
  font-family: 'SofiaPro-Regular';
  color: ${Palette.GUN_POWDER};
  margin-bottom: 1rem;
`;

export const StyledRelativeFormInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export const StyledRelativeAdderFormInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const StyledRelativeAdderFormLabel = styled.p`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
`;

export const StyledRelativeAdderFormInput = styled.input`
  padding: 0.5rem 0.8rem;
  border-radius: 0.3rem;
  font-size: clamp(0.9rem, 1vw, 1rem);
  border: 1px solid ${Palette.GREY};
  color: ${Palette.GUN_POWDER};
`;

export const StyledRelativeAdderFormSelect = styled(Dropdown)`
  padding: 0.5rem 0.8rem;
  border-radius: 0.3rem;
  font-size: clamp(0.9rem, 1vw, 1rem);
  border: 1px solid ${Palette.LIGHT_GREY};
  color: ${Palette.GUN_POWDER};
  text-transform: capitalize;
`;

export const StyledRelativeAdderFormButtonGroup = styled.div`
  margin-top: 1.5rem;
`;
