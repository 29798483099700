import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledSavePaymentMethodPageContainer = styled.div`
  background-color: ${Palette.AQUA_HAZE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledSavePaymentMethodFormContainer = styled.div`
  background-color: ${Palette.WHITE};
  border-radius: 1rem;
  margin: 5rem auto 0 auto;
  padding: 1rem;
  width: 90%;

  @media (min-width: ${Breakpoints.MOBILE_MAX_WIDTH}) {
    width: 60%;
  }

  @media (min-width: ${Breakpoints.TABLET_MAX_WIDTH}) {
    width: 50%;
  }
`;

export const StyledSavePaymentMethodHeader = styled.h2`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
  margin-bottom: 1rem;
`;

export const StyledBlueButton = styled.button`
  background-color: ${Palette.GUN_POWDER};
  color: ${Palette.WHITE};
  font-family: 'SofiaPro-Regular';
  border: none;
  border-radius: 0.5rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
`;
