import React, { useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { StyledWillsPaymentContainer } from './WillsPayment.styles';
import { CheckoutUi } from '@/lib/payment/components/CheckoutUi';
import { EPaymentProducts } from '@/lib/payment/types';

const WillsPayment = () => {
  useEffect(() => {
    amplitude.track('Wills Payment Page Viewed');
  }, []);

  return (
    <StyledWillsPaymentContainer>
      <CheckoutUi product={EPaymentProducts.WILLS} />
    </StyledWillsPaymentContainer>
  );
};

export default WillsPayment;
