import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const ParagraphText = styled.p`
  margin-top: 1rem;
  font-family: 'SofiaPro-Regular';
  font-size: 0.9rem;
  color: ${Palette.NEW_BLUE};
  text-align: center;
  padding: 0 1rem;
`;
