import React from 'react';
import { LiabilityData } from '../LiabilityData';
import { StyledLiabilityList } from './LiabilityList.styles';
import { ILiabilityListProps } from './LiabilityList.types';
import { SubHeader } from '../styles';

const LiabilityList = ({ liabilities, liabilityToEdit, onEdit, onDelete }: ILiabilityListProps) => {
  const visibleLiabilities = liabilityToEdit ? liabilities.filter((l) => l.id !== liabilityToEdit.id) : liabilities;

  return (
    <StyledLiabilityList>
      <SubHeader>My Liabilities</SubHeader>
      {visibleLiabilities.map((liability, index) => (
        <LiabilityData
          key={index}
          liability={liability}
          hideButtons={liabilityToEdit !== null}
          onEdit={() => onEdit(liability)}
          deleteLiability={() => onDelete(liability)}
        />
      ))}
    </StyledLiabilityList>
  );
};

export default LiabilityList;
