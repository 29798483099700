import React from 'react';

import { Footer, Navbar } from './Components';
import { LayoutWrapper } from './Layout.styles';

interface IProps {
  children?: React.ReactNode;
}

const Layout: React.FC<IProps> = ({ children }) => {
  return (
    <LayoutWrapper>
      <Navbar />
      {children}
      <Footer />
    </LayoutWrapper>
  );
};

export default Layout;
