import React from 'react';
import { IReviewRowProps } from './ReviewRow.types';
import {
  StyledReviewRow,
  StyledReviewText,
  StyledReviewGreySpan,
  StyledReviewRowEditButton,
  StyledReviewRowEditIcon,
} from './ReviewRow.styles';
import { Badge } from '@/components';

export const ReviewRow: React.FC<IReviewRowProps> = ({ title, value, onEdit, plan }) => {
  return (
    <StyledReviewRow>
      <StyledReviewText>
        <StyledReviewGreySpan>
          {title}
          &nbsp;
        </StyledReviewGreySpan>
        {value}
      </StyledReviewText>
      {
        plan === "ESSENTIALS" ? (
          <Badge bgColor='#F2CC8F' textColor='#3D405B'>
            premium
          </Badge>
        ) : (
          <StyledReviewRowEditButton type="button" onClick={onEdit} hidden={!onEdit}>
          Edit
          <StyledReviewRowEditIcon />
        </StyledReviewRowEditButton>
        )
      }
    </StyledReviewRow>
  );
};
