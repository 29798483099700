import { ISingleAsset } from '@wills/pages/AssetsList/AssetsList.types';
import { ApiResponse, axiosClient, toApiResponse } from '@/lib/axios';

export type UpdateWillsAssetInput = {
  id: number;
  type: string;
  title: string;
  description: string;
  ownedPercentage: number;
  valueInDollars: number;
  assetListId: number;
};

export const updateWillsAssets = async (asset: UpdateWillsAssetInput): Promise<ApiResponse<ISingleAsset>> => {
  const response = await toApiResponse<ISingleAsset>(axiosClient.put(`/wills/assets/asset/${asset.id}`, asset));

  return {
    ...response,
  };
};
