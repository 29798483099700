import React from 'react';
import { InputContainer } from '../container.styles';
import { InputLabel, ErrorLabel } from '../typography.styles';

export interface FieldProps {
  id: string;
  label: string;
  required?: boolean;
  error?: string | undefined;
  render: (props: { id: string; required?: boolean }) => JSX.Element;
}

export const Field: React.FC<FieldProps> = ({ id, label, error, required, render }) => {
  return (
    <InputContainer>
      <InputLabel htmlFor={id} required={required}>
        {label}
      </InputLabel>
      {render({ id, required })}
      {error ? <ErrorLabel htmlFor={id}>{error}</ErrorLabel> : null}
    </InputContainer>
  );
};
