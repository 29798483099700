import React, { useContext, useState } from 'react';

import { StyledWillsIntroText, StyledWillsTitle } from '@wills/components';
import { useRelationships } from '@wills/hooks';
import { upsertRelationships } from '@wills/apis';
import { RelationshipDefinitionContext } from '@wills/pages/WillsFamilyFlow/contexts/RelationshipDefinitionContext';
import { SnackBarContext } from '@/providers';
import { getErrorMessage } from '@/Utils';

import { WillsFamilyFlowContext } from '../../WillsFamilyFlow.context';
import { ConfirmationModal } from '../ConfirmationModal';
import { NavigationButtons } from '../NavigationButtons';
import { RelativeDisplay } from '../RelativeDisplay';
import { RelativeSelector } from '../RelativeSelector';
import {
  StyledNavigationButtonsWrapper,
  StyledPrimaryRelativeInfoContainer,
  StyledPrimaryRelativePageContainer,
  StyledPrimaryReltiveTextMessage,
} from './PrimaryRelativeInfo.styles';
import { EFamilyFlowStep } from '../../WillsFamilyFlow.types';
import { RelativeAdderForm } from '../RelativeAdderForm';
import { RelativeFormAdderValues } from '../RelativeAdderForm/RelativeAdderForm.types';
import { Modal } from '@/Shared/Components';
import { RelativeQuestionInfo } from '../RelativeQuestionInfo';
import { StyledInfoIcon, StyledInfoIconContainer, StyledInfoIconWrapper } from '@/lib/wills/components/styles';
import { InfoContentWrapper } from '@/lib/wills/components/InfoContentWrapper';

const PrimaryRelativeInfo = () => {
  const {
    setStep,
    selectedRelativeDefinition,
    setSelectedRelativeDefinition,
    primaryRelationships,
    setPrimaryRelationships,
  } = useContext(WillsFamilyFlowContext);
  const { setSnackMessage } = useContext(SnackBarContext);
  const { loading: loadingRelationships } = useRelationships('primary', setPrimaryRelationships);
  const {
    getAvailableRelationshipDefinitions,
    getRelationshipDefinitionById,
    relationshipDefinitionsGroups: { primary: relationshipDefinitions },
  } = React.useContext(RelationshipDefinitionContext);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [showInfoBlurb, setShowInfoBlurb] = useState(false);

  function onPreviousButtonClick() {
    setStep(EFamilyFlowStep.INTRODUCTION);
  }

  async function onContinueButtonClick() {
    if (isSubmitting) return;
    setIsSubmitting(true);
    setStep(EFamilyFlowStep.SECONDARY_RELATIVES);
    setIsSubmitting(false);
  }

  const onAddRelationship = (relationDefinitionId: number) => async (data: RelativeFormAdderValues) => {
    if (selectedRelativeDefinition === null) {
      throw new Error('relationDefinitionId is null');
    }
    const relationshipPayload = [{ ...data, relationDefinitionId }];
    try {
      const response = await upsertRelationships(relationshipPayload);
      if (response.error) {
        throw new Error(response.error.message);
      }
      setPrimaryRelationships((relationships) => [
        ...relationships,
        { ...data, relationDefinitionId, id: response.data[0].id },
      ]);
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    }
    setSelectedRelativeDefinition(null);
  };

  const onCancelAddRelationship = () => {
    setSelectedRelativeDefinition(null);
  };

  return (
    <StyledPrimaryRelativePageContainer>
      <InfoContentWrapper InfoContent={RelativeQuestionInfo}>
        <StyledPrimaryRelativeInfoContainer>
          <StyledWillsIntroText center>Your family</StyledWillsIntroText>
          <StyledWillsTitle center>Primary Relatives</StyledWillsTitle>
          <RelativeSelector
            relativeTypes={getAvailableRelationshipDefinitions(relationshipDefinitions, primaryRelationships)}
          />
          {selectedRelativeDefinition && (
            <RelativeAdderForm
              relativeDefinitionId={selectedRelativeDefinition.id}
              title={`${selectedRelativeDefinition.description} Details`}
              onSubmit={onAddRelationship(selectedRelativeDefinition.id)}
              onCancel={onCancelAddRelationship}
            />
          )}
          {loadingRelationships && (
            <StyledPrimaryReltiveTextMessage>Wait a few seconds...</StyledPrimaryReltiveTextMessage>
          )}
          {primaryRelationships.map((relationship, index) => {
            return (
              <RelativeDisplay
                key={index}
                relationship={relationship}
                setRelationships={setPrimaryRelationships}
                relativeDefinition={getRelationshipDefinitionById(
                  relationship.relationDefinitionId,
                  relationshipDefinitions,
                )}
              />
            );
          })}

          <StyledInfoIconWrapper>
            <StyledInfoIconContainer onClick={() => setShowInfoBlurb(true)}>
              <StyledInfoIcon />
            </StyledInfoIconContainer>
          </StyledInfoIconWrapper>

          {showInfoBlurb && (
            <Modal transparent onCloseCallback={() => setShowInfoBlurb(false)}>
              <RelativeQuestionInfo />
            </Modal>
          )}

          <StyledNavigationButtonsWrapper>
            <NavigationButtons onContinue={() => setIsModalVisible(true)} onPrevious={onPreviousButtonClick} />
          </StyledNavigationButtonsWrapper>
        </StyledPrimaryRelativeInfoContainer>
      </InfoContentWrapper>
      {isModalVisible && (
        <ConfirmationModal
          relativeTypes={getAvailableRelationshipDefinitions(relationshipDefinitions, primaryRelationships)}
          onConfirm={onContinueButtonClick}
          continueDisabled={isSubmitting}
          relationships={primaryRelationships}
          setRelationships={setPrimaryRelationships}
          onClose={() => setIsModalVisible(false)}
        />
      )}
    </StyledPrimaryRelativePageContainer>
  );
};

export default PrimaryRelativeInfo;
