import { Stripe, StripeElements } from '@stripe/stripe-js';
import { stripeConfirmIntentSetup } from '@/lib/payment/stripe/stripeConfig';

export async function confirmSetupIntentWithStripe(stripe: Stripe, elements: StripeElements) {
  const { error, setupIntent } = await stripeConfirmIntentSetup(stripe!, elements!);

  if (error && !setupIntent) {
    throw new Error(error.message || 'An unexpected error occurred.');
  }

  return setupIntent;
}
