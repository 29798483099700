import { useCallback, useEffect, useState } from 'react';

export type IScreenSize = 'sm' | 'md' | 'lg';

export type IScreenSizes = { [key in IScreenSize]?: number };

const useScreenSize = (screenSizes?: IScreenSizes) => {
  const [screenSize, setScreenSize] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });

  const handleResize = useCallback(() => {
    const SCREEN_SIZES: IScreenSizes = {
      sm: screenSizes?.sm || 719,
      md: screenSizes?.md || 1023,
    };

    const isSmall = window.matchMedia(`(max-width: ${SCREEN_SIZES.sm}px)`).matches;
    const isMedium = window.matchMedia(
      `(min-width: ${SCREEN_SIZES.sm! + 1}px) and (max-width: ${SCREEN_SIZES.md}px)`,
    ).matches;
    const isLarge = window.matchMedia(`(min-width: ${SCREEN_SIZES.md! + 1}px)`).matches;

    if (isSmall) {
      setScreenSize({ isMobile: true, isTablet: false, isDesktop: false });
    } else if (isMedium) {
      setScreenSize({ isMobile: false, isTablet: true, isDesktop: false });
    } else if (isLarge) {
      setScreenSize({ isMobile: false, isTablet: false, isDesktop: true });
    }
  }, [screenSizes]);

  useEffect(() => {
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return screenSize;
};

export default useScreenSize;
