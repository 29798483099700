import React from 'react';
import {
  StyledComparePlansWrapper,
  StyledCompareTopicsContainer,
  StyledComparePlansContentContainer,
  StyledIconWrapper,
} from './ComparePlans.styles';
import {
  COMPARE_PLANS_HEADERS,
  COMPARE_PLANS_ON_QUANTITY,
  COMPARE_PLANS_POINTS_1,
  COMPARE_PLANS_POINTS_1_ESSENTIAL_COVERAGE,
  COMPARE_PLANS_POINTS_2,
  COMPARE_PLANS_POINTS_1_PREMIUM_COVERAGE,
  COMPARE_PLANS_POINTS_2_ESSENTIAL_COVERAGE,
  COMPARE_PLANS_POINTS_2_PREMIUM_COVERAGE,
} from './ComparePlansConstants';
import { ComparePoints } from '../ComparePoints';
import { StyledTick, StyledCross } from '../PlansBenefits/PlansBenefits.styles';

const ComparePlans = () => {
  return (
    <StyledComparePlansWrapper>
      {COMPARE_PLANS_HEADERS.map((comparePlanHeader, index) => {
        const { type, header } = comparePlanHeader;
        return (
          <StyledCompareTopicsContainer key={index} type={type}>
            {' '}
            {header}{' '}
          </StyledCompareTopicsContainer>
        );
      })}
      {COMPARE_PLANS_ON_QUANTITY.map((comparePlanOnQuantity, index) => {
        const { type, content } = comparePlanOnQuantity;
        return (
          <StyledComparePlansContentContainer type={type} key={index}>
            {content}
          </StyledComparePlansContentContainer>
        );
      })}
      <StyledComparePlansContentContainer type="title">
        {COMPARE_PLANS_POINTS_1.map((comparePlansPoint, index) => {
          const { header, topics } = comparePlansPoint;
          return <ComparePoints key={index} header={header} topics={topics} />;
        })}
      </StyledComparePlansContentContainer>
      <StyledComparePlansContentContainer type="essentials">
        {COMPARE_PLANS_POINTS_1_ESSENTIAL_COVERAGE.map((comparePlanFirstEssentialCoverage, index) => {
          const { content } = comparePlanFirstEssentialCoverage;
          return (
            <StyledIconWrapper key={index} show={content}>
              {content ? <StyledTick /> : <StyledCross />}
            </StyledIconWrapper>
          );
        })}
      </StyledComparePlansContentContainer>
      <StyledComparePlansContentContainer type="premium">
        {COMPARE_PLANS_POINTS_1_PREMIUM_COVERAGE.map((comparePlanFirstPremiumCoverage, index) => {
          const { content } = comparePlanFirstPremiumCoverage;
          return (
            <StyledIconWrapper key={index} show={content}>
              {content ? <StyledTick /> : <StyledCross />}
            </StyledIconWrapper>
          );
        })}
      </StyledComparePlansContentContainer>
      <StyledComparePlansContentContainer type="title">
        {COMPARE_PLANS_POINTS_2.map((comparePlansPoint, index) => {
          const { header, topics } = comparePlansPoint;
          return <ComparePoints key={index} header={header} topics={topics} />;
        })}
      </StyledComparePlansContentContainer>
      <StyledComparePlansContentContainer type="essentials">
        {COMPARE_PLANS_POINTS_2_ESSENTIAL_COVERAGE.map((comparePlanSecondEssentialCoverage, index) => {
          const { content } = comparePlanSecondEssentialCoverage;
          return (
            <StyledIconWrapper key={index} show={content}>
              {content ? <StyledTick /> : <StyledCross />}
            </StyledIconWrapper>
          );
        })}
      </StyledComparePlansContentContainer>
      <StyledComparePlansContentContainer type="premium">
        {COMPARE_PLANS_POINTS_2_PREMIUM_COVERAGE.map((comparePlanSecondPremiumCoverage, index) => {
          const { content } = comparePlanSecondPremiumCoverage;
          return (
            <StyledIconWrapper key={index} show={content}>
              {content ? <StyledTick /> : <StyledCross />}
            </StyledIconWrapper>
          );
        })}
      </StyledComparePlansContentContainer>
    </StyledComparePlansWrapper>
  );
};

export default ComparePlans;
