import React from 'react';
import { IconContainer } from '../Alert.styles';
import { AlertProps } from '../Alert.types';
import { InfoContainer, InfoIcon } from './InfoAlert.styles';

export const InfoAlert: React.FC<AlertProps> = ({ children }) => {
  return (
    <InfoContainer>
      <IconContainer>
        <InfoIcon />
      </IconContainer>
      {children}
    </InfoContainer>
  );
};
