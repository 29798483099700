import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledInvestErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: ${Palette.AQUA_HAZE};
`;

export const StyledInvestErrorTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  width: min(90%, 50rem);
`;

export const StyledInvestErrorHeader = styled.h3`
  color: ${Palette.NEW_BLUE};
  font-family: 'SofiaPro-Bold';
  text-align: center;
  line-height: 1.5rem;
`;

export const StyledMailLink = styled.a`
  color: ${Palette.NEW_BLUE};
  text-decoration: underline;
  text-underline-offset: 0.2rem;
`;
