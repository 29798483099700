import { useNavigate } from 'react-router-dom';

export const useStepsReviewNavigation = (isReview?: boolean) => {
  const navigate = useNavigate();
  const stepsOrReviewPath = `/wills/${isReview ? 'review' : 'steps'}`;
  const addPathPrefix = (pathSuffix: string) => {
    const result = isReview ? `/wills/review/${pathSuffix}` : `/wills/${pathSuffix}`;
    return result;
  };
  const goToStepsOrReview = () => navigate(stepsOrReviewPath);
  const goToReview = () => navigate(`/wills/review`);

  return {
    addPathPrefix,
    goToStepsOrReview,
    goToReview,
  };
};
