import React from 'react';
import { ParagraphText } from './Paragraph.styles';

interface IProps {
  children?: React.ReactNode;
}

const Paragraph: React.FC<IProps> = ({ children }) => {
  return <ParagraphText>{children}</ParagraphText>;
};

export default Paragraph;
