import React from 'react';
import { IntakeStep, useIntakeSteps } from './useIntakeSteps';

/**
 * Added review utilities on top of useIntake.
 * This hook also assumes that the user will go through the entire intake before enabling review mode.
 *
 * @param steps
 * @param context
 */
export const useIntakeStepsWithReview = <T, J extends string>(steps: IntakeStep<T, J>[], context: T) => {
  const intakeSteps = useIntakeSteps(steps, context);
  const { current: history } = React.useRef<IntakeStep<T, J>[]>([{ ...intakeSteps.step }]);
  const [isReview, setIsReview] = React.useState<boolean>(false);

  const nextStep = React.useCallback(() => {
    const newStep = intakeSteps.nextStep();
    if (!isReview) {
      history.push({ ...newStep });
    }
    return newStep;
  }, [intakeSteps.nextStep, isReview]);

  const prevStep = React.useCallback(() => {
    const newStep = intakeSteps.prevStep();
    const index = history.findIndex((s) => s.name === newStep.name);
    if (!isReview) {
      history.splice(index + 1);
    }
    return newStep;
  }, [intakeSteps.prevStep, isReview]);

  return {
    ...intakeSteps,
    nextStep,
    prevStep,
    isReview,
    setIsReview,
    history,
  };
};
