import React from 'react';
import { appointees } from './assets';
import { ActionCard } from '../ActionCard';
import {
  PrimaryLinkDisabled,
  PrimaryLink,
  ActionCardsContainer,
  Section,
  SectionTitle,
} from '../styles';
import locales from './Appointess.locales.json';

const locale = locales.EN;

export interface AppointeesProps {
  disabled?: boolean;
}

export const Appointees: React.FC<AppointeesProps> = ({ disabled }) => {
  return (
    <Section id="appointees">
      <SectionTitle>{locale.title}</SectionTitle>
      <ActionCardsContainer>
        <ActionCard
          title={locale.notifyCard.title}
          description={locale.notifyCard.description}
          imageProps={{
            src: appointees,
            alt: locale.notifyCard.imageAlt,
          }}
        >
          {disabled ? (
            <PrimaryLinkDisabled>{locale.notifyCard.sendButtonLabel}</PrimaryLinkDisabled>
          ) : (
            <PrimaryLink to="appointees">{locale.notifyCard.sendButtonLabel}</PrimaryLink>
          )}
        </ActionCard>
      </ActionCardsContainer>
    </Section>
  );
};
