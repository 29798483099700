import { RelativeDefinitionRelationship } from '../types';

export const removeRelationships = (
  relationships: RelativeDefinitionRelationship[],
  values: RelativeDefinitionRelationship[],
): RelativeDefinitionRelationship[] => {
  return relationships.reduce<RelativeDefinitionRelationship[]>((acc, cur) => {
    if (values.includes(cur)) {
      return acc;
    }
    return [...acc, cur];
  }, []);
};
