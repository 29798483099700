import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledIntroText = styled.p`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
  margin-top: 1rem;
`;
export const StyledIntroductionPageContainer = styled.div`
  background-color: ${Palette.AQUA_HAZE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledIntroductionContentContainer = styled.div`
  width: min(60%, 1366px);
  margin: 5rem auto;

  @media (max-width: 720px) {
    width: 85%;
  }
`;

export const StyledWillsGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1.7fr) minmax(0, 1fr);
  gap: 2rem;
  margin-top: 2rem;

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledWillsImageContainer = styled.div`
  padding-inline: 3rem;

  @media (max-width: 720px) {
    padding: 3rem;
  }
`;

export const StyledWillsImage = styled.img`
  width: 100%;
`;

export const StyledOrderedList = styled.ol`
  font-family: 'SofiaPro-Regular';
  color: ${Palette.GUN_POWDER};
  list-style-position: inside;
  margin-top: 0.5rem;
  padding-left: 0.5rem;
`;

export const StyledNavigationButtonsWrapper = styled.div`
  width: fit-content;
  margin-top: 2rem;

  @media (max-width: 720px) {
    margin-top: 2rem;
    width: 100%;
  }
`;
