import React from 'react';
import { useForm } from 'react-hook-form';

import { PrimaryButton, Title, SecondaryButton, DateInput, Field, TextInput } from '../../../components';
import { UserKycForm, UserKycButtonGroup } from '../styles';

export const englishTextValues = {
  title: 'Enter your basic information.',
  firstNameLabel: 'First Name',
  middleNameLabel: 'Middle Name',
  lastNameLabel: 'Last Name',
  dateOfBirthLabel: 'Date of Birth',
  backButton: 'Back',
  submitButton: 'Submit',
};

type UserKycFormTextValues = typeof englishTextValues;

export interface UserKycBasicFormValues {
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
}

interface UserKycBasicFormProps {
  onSubmit: (values: UserKycBasicFormValues) => Promise<void>;
  textValues?: UserKycFormTextValues;
}

const isValidDate = (input: string): boolean => {
  const regex = /^\d{4}-\d{2}-\d{2}$/;
  if (!regex.test(input)) return false;

  const [year, month, day] = input.split('-').map(Number);
  const date = new Date(year, month - 1, day);

  return date.getFullYear() === year && date.getMonth() === month - 1 && date.getDate() === day;
};

export const UserKycBasicForm: React.FC<UserKycBasicFormProps> = ({ onSubmit, textValues = englishTextValues }) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<UserKycBasicFormValues>({
    defaultValues: {
      firstName: '',
      middleName: '',
      lastName: '',
      dob: '',
    },
    mode: 'onBlur',
  });

  const onSubmitHandler = handleSubmit(onSubmit);

  return (
    <UserKycForm onSubmit={onSubmitHandler}>
      <Title>{textValues.title}</Title>
      <Field
        id="firstName"
        label={textValues.firstNameLabel}
        error={errors.firstName?.message}
        required
        render={({ id, required }) => <TextInput id={id} {...register('firstName', { required })} />}
      />
      <Field
        id="middleName"
        label={textValues.middleNameLabel}
        error={errors.middleName?.message}
        render={({ id }) => <TextInput id={id} {...register('middleName')} />}
      />
      <Field
        id="lastName"
        label={textValues.lastNameLabel}
        error={errors.lastName?.message}
        required
        render={({ id, required }) => <TextInput id={id} {...register('lastName', { required })} />}
      />
      <Field
        id="dob"
        label={textValues.dateOfBirthLabel}
        error={errors.dob?.message}
        required
        render={({ id, required }) => (
          <DateInput
            id={id}
            {...register('dob', {
              required,
              validate: (value) => isValidDate(value) || 'Invalid date',
            })}
          />
        )}
      />
      <UserKycButtonGroup>
        <PrimaryButton type="submit" disabled={isSubmitting}>
          {textValues.submitButton}
        </PrimaryButton>
        <SecondaryButton notVisible type="button" disabled={isSubmitting}>
          {textValues.backButton}
        </SecondaryButton>
      </UserKycButtonGroup>
    </UserKycForm>
  );
};
