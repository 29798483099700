import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledNavigationButtonsContainer = styled.div<{ paddingBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-direction: column;

  @media (min-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    flex-direction: row;
  }
`;

export const StyledNavigationButton = styled.button<{ primary?: boolean }>`
  border: ${({ primary }) => (primary ? 'none' : '1px solid rgb(140, 142, 160, 0.2)')};
  border-radius: 0.3rem;
  padding: 0.5rem 3rem;
  cursor: pointer;
  color: ${Palette.GUN_POWDER};
  background-color: ${({ primary }) => (primary ? Palette.GOLD_CRAYOLA : Palette.NEW_WHITE)};
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
`;
