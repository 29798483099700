import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledReviewContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${Palette.AQUA_HAZE};
`;

export const StyledReviewContentContainer = styled.div`
  margin-top: 5rem;
  margin-inline: auto;
  width: 85%;
  @media (min-width: 721px) {
    width: min(60%, 1366px);
    padding-bottom: 1rem;
  }
`;

export const StyledReviewListContainer = styled.div`
  margin-top: 2rem;
  padding: 1.5rem;
  padding-top: 1rem;
  background-color: ${Palette.WHITE};
  border-radius: 1rem;

  @media screen and (min-width: 921px) {
    max-width: 50rem;
    margin-inline: auto;
  }
`;

export const StyledReviewRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.8rem 0;
  border-bottom: 1px solid ${Palette.LIGHT_GREY};

  &:first-child,
  &:last-child {
    border: none;
  }
`;

export const StyledReviewRowTitle = styled.h2`
  font-family: 'SofiaPro-Regular';
  color: ${Palette.GUN_POWDER};
`;

export const StyledCompletedTag = styled.div`
  font-family: 'SofiaPro-Regular';
  background-color: #faebd2;
  padding: 0.7rem 1.1rem;
  color: ${Palette.NEW_BLUE};
  font-size: 1.125rem;
  display: grid;
  place-items: center;
  border-radius: 0.5rem;
`;

export const StyledReviewNavigationButtonsContainer = styled.div`
  margin-inline: auto;
  margin-top: 3rem;
  width: 100%;
  padding-bottom: 1.5rem;

  @media (min-width: 721px) {
    width: 85%;
  }
`;
