import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Modal } from '@/Shared/Components';
import { StyledDarkButton, StyledPaymentParagraph } from '@/lib/wills/components/styles';
import {
  StyledSubscriptionCardTopContainer,
  StyledSubscriptionCardHeader,
  StyledSubscriptionCardPriceContainer,
  StyledSubscriptionPageBigNumber,
} from '../../../../SelectPlan/Components/Subscription/Subscription.styles';
import { SubscriptionItems } from '../../../../SelectPlan/Components/SubscriptionItems';
import { StyledSubscriptionPopupContentWrapper } from './SubscriptionPopup.styles';

interface SubscriptionPopupProps {
  onClose: () => void;
}
const SubscriptionPopup: React.FC<SubscriptionPopupProps> = ({ onClose }) => {
  const navigate = useNavigate();

  async function onSubscribeBtnClick() {
    navigate('/wills/payment');
  }

  return (
    <Modal width="20rem" onCloseCallback={onClose}>
      <StyledSubscriptionPopupContentWrapper>
        <StyledSubscriptionCardTopContainer>
          <StyledSubscriptionCardHeader>Manzil Wills Membership</StyledSubscriptionCardHeader>

          <StyledSubscriptionCardPriceContainer>
            <StyledSubscriptionPageBigNumber>$20</StyledSubscriptionPageBigNumber>
            <StyledPaymentParagraph bold>per year (after 120 days)</StyledPaymentParagraph>
          </StyledSubscriptionCardPriceContainer>
        </StyledSubscriptionCardTopContainer>

        <SubscriptionItems subscribed />

        <StyledDarkButton fullWidth onClick={onSubscribeBtnClick}>
          Subscribe
        </StyledDarkButton>
      </StyledSubscriptionPopupContentWrapper>
    </Modal>
  );
};

export default SubscriptionPopup;
