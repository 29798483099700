import React from 'react';
import {
  StyledBenefitsSection,
  StyledIconContainer,
  StyledSingleBenefit,
  StyledTick,
  StyledTitle,
  StyledCross,
  StyledInfo,
} from './PlansBenefits.styles';

interface IPlansBenefitsProps {
  type: string;
  featuresTitle: string;
  features: string[];
}

const PlansBenefits = ({ type, featuresTitle, features }: IPlansBenefitsProps) => {
  return (
    <>
      <StyledTitle>{featuresTitle}</StyledTitle>
      <StyledBenefitsSection>
        {features.map((feature, index) => {
          return (
            <StyledSingleBenefit key={index}>
              <StyledIconContainer type={type}>
                {type === 'Premium' ? <StyledTick data-testid="checked-feature" /> : <StyledCross />}
              </StyledIconContainer>
              <StyledInfo>{feature}</StyledInfo>
            </StyledSingleBenefit>
          );
        })}
      </StyledBenefitsSection>
    </>
  );
};

export default PlansBenefits;
