import { IWillsProfile } from '@wills/types';
import { IProfile, IUserAddress } from '@/Types';

export enum EAboutYouSteps {
  LEGAL_NAME_QUESTION,
  DOB_QUESTION,
  MARITAL_STATUS_QUESTION,
  CHILDREN_QUESTION,
  GENDER_QUESTION,
  MADHAB_QUESTION,
  ADDRESS_QUESTION,
  LOCATION_QUESTION,
  ABOUT_YOU_SUMMARY,
}

export interface IWillsProfileContextInterface {
  isOnboarding?: boolean;
  step: EAboutYouSteps;
  goToStep: (renderStep: EAboutYouSteps) => void;
  profileData: IProfile;
  willsProfileData: IWillsProfile;
  recordProfile: (name: string, value: string) => void;
  recordWillsProfile: (name: string, value: string | number) => void;
  createProfile: (data: any) => void;
  updateProfile: (data: any) => void;
  updateWillsProfile: (data: Partial<IWillsProfile>) => void;
  createWillsProfile: (data: Partial<IWillsProfile>) => void;
  willsOnboardingComplete?: boolean;
  addressData: IUserAddress;
  setAddressData: React.Dispatch<React.SetStateAction<IUserAddress>>;
  address: IUserAddress | null;
  createWillsUserAddress: (data: IUserAddress) => void;
  updateWillsUserAddress: (data: IUserAddress) => void;
  createUserAddress: (data: IUserAddress) => void;
  updateUserAddress: (data: IUserAddress) => void;
}

export interface IProps {
  children: React.ReactNode;
}
