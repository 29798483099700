import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledWillsWelcomeBackWrapper = styled.div`
  background-color: ${Palette.AQUA_HAZE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledWillsWelcomeBackContainer = styled.div`
  width: min(60%, 1366px);
  margin-inline: auto;

  @media (max-width: 1366px) {
    width: 70%;
  }

  @media (max-width: 720px) {
    width: 85%;
  }
`;

export const StyledWillsWelcomeBackTitle = styled.h1`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Bold';
  margin-top: 5rem;
  margin-bottom: 2rem;

  @media (max-width: 720px) {
    margin-top: 3rem;
  }
`;

export const StyledWillsWelcomeBackParagraph = styled.p`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
  margin-bottom: 1rem;
`;

export const StyledWillsWelcomeBackGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 0.6fr) minmax(0, 0.4fr);
  gap: 4rem;
  margin-bottom: 2rem;

  @media (max-width: 720px) {
    gap: 2rem;
    grid-template-columns: 1fr;
  }
`;

export const StyledWillsWelcomeBackActionBox = styled.div`
  background-color: ${Palette.NEW_WHITE};
  border-radius: 1rem;
  height: fit-content;
  padding: 1rem;
  margin-top: 1rem;

  @media (max-width: 720px) {
    width: fit-content;
  }
`;

export const StyledWillsWelcomeBackSubheader = styled.h3`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
  margin-bottom: 1rem;
`;

export const StyledWillsWelcomeBackImage = styled.img`
  margin-inline: auto;

  @media (max-width: 720px) {
    grid-row: 1;
  }
`;

export const StyledWillsWelcomeBackBoxImage = styled.img`
  height: 7.5rem;
  margin-inline: auto;
`;

export const StyledWillsWelcomeBackBoxContent = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.2fr;

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledWillsWelcomeBackButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1rem;
`;

export const StyledWillsWelcomeBackPrimaryButton = styled.button`
  background-color: ${Palette.GOLD_CRAYOLA};
  color: ${Palette.GUN_POWDER};
  border-radius: 0.5rem;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
`;

export const StyledWillsWelcomeBackSecondaryButton = styled.button`
  background-color: ${Palette.ATHENS_GREY};
  color: ${Palette.GUN_POWDER};
  border-radius: 0.5rem;
  border: 1px solid ${Palette.LIGHT_GREY};
  padding: 0.5rem 1rem;
  cursor: pointer;
`;
