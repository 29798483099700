import React, { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { isAllFlowsCompleted } from '@wills/helpers/isAllFlowsCompleted';
import { useApi } from '@/lib/axios/useApi';
import { SnackBarContext } from '@/providers';

import { NavigationButtons, ProgressBar, Step } from './Components';
import {
  StyledWillsHelpText,
  StyledWillsStepsContentContainer,
  StyledWillsStepsPageContainer,
  StyledWillsTitle,
} from './WillsSteps.styles';
import { getPreviousStep, getWillsSteps, calculateProgressPercentage, dynamicWillsTitle } from './WillsSteps.helpers';
import { getWillsProfile } from '../../apis/willsProfile/getWillsProfile';
import { IStepData } from './WillsSteps.types';
import { useStepsReviewNavigation } from '../../hooks/useStepsReviewNavigation';
import { Loading } from '@/Shared/Components/Loading';

const WillsSteps: React.FC<{ isReview?: boolean }> = ({ isReview }) => {
  const { data: willsProfile, error, loading } = useApi(getWillsProfile);
  const navigate = useNavigate();
  const { setSnackMessage } = useContext(SnackBarContext);
  const { addPathPrefix } = useStepsReviewNavigation(isReview);
  const willsStepsData = getWillsSteps(willsProfile ?? null, addPathPrefix);
  const TOTAL_STEPS = willsStepsData.length;
  const activeStep = willsStepsData.filter((step) => step.active)[0];
  const indexOfActiveStep = willsStepsData.indexOf(activeStep);
  const percentageCompleted = calculateProgressPercentage(TOTAL_STEPS, indexOfActiveStep);
  const previousStep: IStepData | null = getPreviousStep(willsStepsData, indexOfActiveStep);
  const completedExceptPayment = willsProfile && isAllFlowsCompleted(willsProfile) && !willsProfile.isPaymentComplete;

  useEffect(() => {
    if (error && error.code !== 'DoesNotExist') {
      setSnackMessage(error.message);
    }
  }, [error]);

  if (loading) {
    return <Loading />;
  }

  function onPreviousBtnClick() {
    if (isReview) {
      navigate('/wills/documents');
    } else if (previousStep !== null) {
      navigate(previousStep.link, { state: { lastStep: true } });
    } else {
      navigate(-1);
    }
  }

  function onContinueBtnClick() {
    if (isReview) {
      navigate('/wills/documents');
    } else if (completedExceptPayment) {
      navigate('/wills/payment');
    } else {
      navigate(activeStep.link);
    }
  }

  return (
    <StyledWillsStepsPageContainer>
      <StyledWillsStepsContentContainer>
        {isReview ? null : <ProgressBar progress={percentageCompleted} />}
        <StyledWillsHelpText>We&apos;re here to help</StyledWillsHelpText>
        <StyledWillsTitle>{dynamicWillsTitle(willsProfile!)}</StyledWillsTitle>
        {willsStepsData.map((data, index) => {
          if (data.name.toLowerCase() === 'select a plan' && isReview) {
            return null;
          }
          return <Step data={data} key={index} active={data.active} complete={data.complete} />;
        })}
        <NavigationButtons
          continueBtnText={completedExceptPayment ? 'Pay now' : undefined}
          onContinue={onContinueBtnClick}
          onPrevious={onPreviousBtnClick}
        />
      </StyledWillsStepsContentContainer>
    </StyledWillsStepsPageContainer>
  );
};

export default WillsSteps;
