import React, { useContext } from 'react';

import { StyledNavigationButton, StyledNavigationButtonsContainer } from './NavigationButtons.styles';
import { INavigationButtonsProps } from './NavigationButtons.types';
import { EstateIntakeContext } from '../../contexts/EstateIntakeContext/EstateIntakeContext';

export const handleNavigationButtons = (
  event: React.SyntheticEvent<HTMLButtonElement>,
  onNext: () => void,
  onSummary: () => void = () => {},
) => {
  if (event.target instanceof Element && event.target.id === 'summary-btn') {
    onSummary();
  } else {
    onNext();
  }
};

const NavigationButtons = ({
  onContinue,
  onPrevious,
  continueDisbaled,
  confirmBtnText,
  cancelBtnText,
  confirmBtnType,
  addMode,
}: INavigationButtonsProps) => {
  const { state } = useContext(EstateIntakeContext);
  return (
    <StyledNavigationButtonsContainer>
      <StyledNavigationButton type="button" onClick={onPrevious}>
        {cancelBtnText || 'Previous'}
      </StyledNavigationButton>
      <StyledNavigationButton
        type={confirmBtnType || 'button'}
        primary
        onClick={onContinue}
        disabled={continueDisbaled}
      >
        {confirmBtnText || 'Continue'}
      </StyledNavigationButton>
      {state?.enableEdit && !addMode && (
        <StyledNavigationButton
          type={confirmBtnType || 'button'}
          id="summary-btn"
          primary
          onClick={onContinue}
          disabled={continueDisbaled}
        >
          Go To Summary
        </StyledNavigationButton>
      )}
    </StyledNavigationButtonsContainer>
  );
};

export default NavigationButtons;
