export const normalizeDate = (maybeDate: string) => {
  const delimiter = '-';
  const onlyNums = maybeDate.slice(0, 10).replace(/[^\d]/g, '');

  if (onlyNums.length < 4) {
    return onlyNums;
  }
  if (onlyNums.length === 4) {
    return `${onlyNums}${delimiter}`;
  }
  if (onlyNums.length < 6) {
    return `${onlyNums.slice(0, 4)}${delimiter}${onlyNums.slice(4)}`;
  }
  if (onlyNums.length === 6) {
    return `${onlyNums.slice(0, 4)}${delimiter}${onlyNums.slice(4)}${delimiter}`;
  }

  const dateString = `${onlyNums.slice(0, 4)}${delimiter}${onlyNums.slice(4, 6)}${delimiter}${onlyNums.slice(6, 8)}`;
  const date = new Date(dateString);

  if (dateString.length === 10 && String(date) !== 'Invalid Date') {
    const formattedDate = date.toISOString().slice(0, 10);
    return formattedDate;
  }

  return dateString;
};
