import validator from 'validator';

export const isValueNumericOrEmpty = (value: string) => {
  return validator.isNumeric(value) || value === '';
};

export const isValueText = (value: string) => {
  return !isValueNumericOrEmpty(value);
};

export const replaceTextWithEmptyString = (value: string) => {
  value.replace(/[^a-z]gi/, '');
};
