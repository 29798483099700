import React from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { ProfileStore } from '@/Stores';
import { DeeplinkContext } from '@/providers';
import { Loading } from '@/Shared/Components/Loading';

interface PrivateOnloadProps {
  onboardingRoute?: string;
  children: React.ReactElement;
}

export const PrivateOnload: React.FC<PrivateOnloadProps> = observer(({ onboardingRoute = '/onboarding', children }) => {
  const { profile } = React.useContext(ProfileStore);
  const [loading, setLoading] = React.useState(true);
  const { redirect, shouldRedirect, redirectUrl } = React.useContext(DeeplinkContext);
  const navigate = useNavigate();

  React.useEffect(() => {
    if (shouldRedirect) {
      redirect();
    } else if (!profile && !redirectUrl) {
      navigate(onboardingRoute, { replace: true });
    }
    setLoading(false);
  }, [shouldRedirect, profile, redirectUrl]);

  if (loading) {
    return <Loading />;
  }

  return children;
});
