import React from 'react';
import { HEADER_BUTTONS } from "./DocumentsHeader.constants";
import { StyledDownArrow, StyledHeaderWrapper,
    StyledSectionNameLink, StyledSingleHeaderContainer } from "./DocumentsHeader.styles";

const DocumentsHeader = () => {
  return (
    <StyledHeaderWrapper>
        {
            HEADER_BUTTONS.map((header, index) => {
                return (
                    <StyledSingleHeaderContainer key={index}>
                        <StyledDownArrow />
                        <StyledSectionNameLink href={`#${header.sectionId}`}>{header.sectionTitle}</StyledSectionNameLink>
                    </StyledSingleHeaderContainer>
                );
            })
        }
    </StyledHeaderWrapper>
  );
};
export default DocumentsHeader;