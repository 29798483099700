import React from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import * as amplitude from '@amplitude/analytics-browser';

import { TokenStore } from '@/Stores';
import { LocaleContext } from '@/providers';

import loginLocales from './Login.locales.json';
import { login, otpLogin } from '../../api';
import { AuthForm, AuthFormValues, OtpForm, OtpFormValues } from './components';
import { AuthWrapper } from '../../components/AuthWrapper/AuthWrapper';

export const Login: React.FC = observer(() => {
  const { locale } = React.useContext(LocaleContext);
  const { setToken, setZendeskToken } = React.useContext(TokenStore);
  const navigate = useNavigate();

  const [email, setEmail] = React.useState<string | null>(null);

  // Assumes that having a message means they are on the otp step;
  const [otpMessage, setOtpMessage] = React.useState<string | null>(null);

  const authenticateWithPassword = async (formData: AuthFormValues) => {
    const { data, error } = await login(formData);
    setEmail(formData.email);

    if (error) {
      if (error.code === 'UserNotConfirmedException') {
        navigate(`/not-verified/${formData.email}`);
        return;
      }
      throw new Error(error.message);
    }

    if ('AccessToken' in data) {
      setToken(data.AccessToken);
      setZendeskToken(data.zendeskToken);
    } else {
      setOtpMessage(data.Message);
    }
    amplitude.track('Log In');
    amplitude.setUserId(formData.email);
  };

  const authenticateWithOTP = async (formValues: OtpFormValues) => {
    if (!email) {
      throw new Error('Unexpected missing email');
    }

    const { data, error } = await otpLogin({
      email,
      otp: formValues.otp,
    });

    if (error) {
      if (error.code === 'UserNotConfirmedException') {
        navigate(`/not-verified/${email}`);
        return;
      }

      throw new Error(error.message);
    }
    setToken(data.AccessToken);
    setZendeskToken(data.zendeskToken);
    amplitude.track('OTP auth');
    amplitude.setUserId(email);
  };

  const loginLocale = loginLocales[locale];

  return (
    <AuthWrapper>
      {otpMessage !== null ? (
        <OtpForm title={otpMessage} onSubmit={authenticateWithOTP} localeText={loginLocale} />
      ) : (
        <AuthForm onSubmit={authenticateWithPassword} localeText={loginLocale} />
      )}
    </AuthWrapper>
  );
});
