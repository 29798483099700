import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledBequestIntroPageContainer = styled.div`
  background-color: ${Palette.AQUA_HAZE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledBequestIntroContentContainer = styled.div`
  width: min(60%, 1366px);
  margin: 5rem auto;

  @media (max-width: 720px) {
    width: 85%;
  }
`;

export const StyledWillsGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 1.7fr) minmax(0, 1fr);
  gap: 2rem;
  margin-top: 2rem;

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledWillsImageContainer = styled.div`
  padding-inline: 2rem;

  @media (max-width: 720px) {
    padding-inline: 2rem;
  }
`;

export const StyledWillsImage = styled.img`
  transform: translateY(2rem);
  width: 100%;

  @media (max-width: 720px) {
    transform: translateY(0);
  }
`;

export const StyledNavigationButtonsWrapper = styled.div`
  width: fit-content;
  margin-top: 2rem;

  @media (max-width: 720px) {
    margin-top: 2rem;
    width: 100%;
  }
`;
