import styled from 'styled-components';
import { HiOutlineArrowNarrowLeft } from 'react-icons/hi';
import { Link } from 'react-router-dom';
import { Palette } from '@/Shared/Constants';

export const BackArrowIcon = styled(HiOutlineArrowNarrowLeft)`
  font-size: 1.2rem;
  color: ${Palette.INDEPENDENCE_BLUE};
`;

export const StyledGoBackButton = styled(Link)`
  display: flex;
  align-items: center;
  gap: 1rem;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;
  font-family: SofiaPro-Regular;
  color: ${Palette.INDEPENDENCE_BLUE};
`;
