import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Palette } from '@/Shared/Constants';

export const StyledModalContainer = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyledModal = styled.div`
  background: ${Palette.EGGSHELL_WHITE};
  width: 95vw;
  max-width: 50rem;
  border-radius: 2rem;
  padding: 2rem;
  text-align: center;
`;

export const StyledMessage = styled.p<{ textRight?: boolean }>`
  font-family: 'SofiaPro-Bold';
  color: ${Palette.INDEPENDENCE_BLUE};
  font-size: 1.1rem;
  text-align: ${({ textRight }) => textRight && 'right'};
`;

export const StyledButtonContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
  gap: 2rem;
`;

export const StyledLinebreak = styled.span`
  display: block;
  margin-top: 1rem;
`;

export const StyledLink = styled(Link)`
  display: block;
  width: fit-content;
  padding: 0.9rem 4rem;
  margin: 2rem auto 0 auto;
  font-size: 1rem;
  font-family: 'SofiaPro-Bold';
  color: ${Palette.EGGSHELL_WHITE};
  border: none;
  text-decoration: none;
  border-radius: 2rem;
  background-color: ${({ color }) => color || Palette.NEW_BLUE};
  cursor: pointer;
`;
