export enum EDataTestId {
  AUTH_PAGE = 'auth-page',
  LOADING_PAGE = 'loading-page',
  PRODUCT_SELECTOR = 'product-selector-page',

  USER_CONTACT_DISPLAY = 'contact-display',
  USER_CONTACT_FORM = 'contact-information-form',
  USER_PERSONAL_INFO_DISPLAY = 'personal-information-display',
  USER_PERSONAL_INFO_FORM = 'personal-information-form',
  USER_SECURITY_DISPLAY = 'security-display',
  USER_SECURITY_FORM = 'security-form',
}
