import styled from 'styled-components';

export const ButtonsContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 16px;

  button {
    width: 11.5rem;
  }
`;

export const AlertsContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  gap: 16px;
`;
