import styled from 'styled-components';
import { BsTrash } from 'react-icons/bs';

import { Palette } from '@/Shared/Constants';

export const StyledConfirmationModalContainer = styled.div`
  max-width: 30rem;
  width: 100%;
`;

export const StyledConfirmationModalTitle = styled.h2`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
  text-align: center;
  margin-bottom: 1rem;
`;

export const StyledConfirmationModalInfo = styled.p`
  color: ${Palette.CADET_BLUE_DARKER};
  font-family: 'SofiaPro-Regular';
  text-align: center;
  margin-bottom: 2rem;
`;

export const StyledConfirmationModalNameContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 2rem;
`;

export const StyledConfirmationModalName = styled.h2`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
`;

export const StyledConfirmationModalRemoveIcon = styled(BsTrash)`
  color: ${Palette.CADET_BLUE_DARKER};
  font-size: 1.5rem;
  cursor: pointer;
`;

export const StyledConfirmationModalNavigationButtonsContainer = styled.div`
  position: relative;
`;
