import React from 'react';
import { QuestionInfo } from '@/lib/wills/components/QuestionInfo';

const LegalNameQuestionInfo = () => {
  return (
    <QuestionInfo
      question="Why do we ask?"
      answer={
        <div>
          Your legal name is used in your Manzil Wills documents. This does not have have to be your name at birth and
          is typically the name used in official identification.
        </div>
      }
    />
  );
};

export default LegalNameQuestionInfo;
