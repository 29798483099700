import styled, { css } from 'styled-components';
import { Breakpoints } from '@/Utils/breakpoints';
import { Palette } from '@/Shared/Constants';

export const input = css`
  font-family: 'SofiaPro-Regular';
  padding: 0.5rem 0.8rem;
  border-radius: 0.3rem;
  font-size: clamp(0.9rem, 1vw, 1rem);
  color: ${Palette.GUN_POWDER};
  min-width: 100%;
  border: 1px solid #aaacb4;
`;

export const StyledFormWrapper = styled.div`
  margin-top: 3rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  min-width: 370px;

  @media (min-width: ${Breakpoints.TABLET_MIN_WIDTH}) {
    max-width: 40%;
  }
`;

export const StyledForm = styled.form`
  display: grid;
  gap: 1rem;
`;

export const StyledAssetListFormName = styled.h2`
  font-family: 'SofiaPro-Regular';
  color: ${Palette.GUN_POWDER};
  margin-bottom: 1rem;
  min-width: 100%;
`;

export const StyledAssetListFormLabel = styled.label`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
`;

export const StyledAssetListFormGroup = styled.div`
  display: grid;
  gap: 1rem;
`;

export const StyledAssetListFormInput = styled.input`
  ${input}
  &::placeholder {
    color: ${Palette.SMOKY_GREY};
  }
`;

export const StyledAssetListFormSelect = styled.select`
  ${input}
`;

export const StyledTextArea = styled.textarea`
  ${input}
  resize: none;
  padding: 10px 20px 60px 20px;

  &::placeholder {
    color: ${Palette.SMOKY_GREY};
  }
`;

export const StyledFormBtnContainer = styled.div`
  margin-top: 2rem;
  display: grid;
  gap: 1.5rem;
`;

export const StyledBtn = styled.button<{ save: boolean }>`
  width: 100%;
  border: 1px solid ${Palette.LIGHT_GREY};
  border-radius: 0.3rem;
  min-width: 8rem;
  font-family: 'SofiaPro-Regular';
  padding: 0.5rem 1rem;
  font-size: clamp(0.9rem, 1vw, 1rem);
  color: ${({ save }) => (save ? '#FFFFFF' : `${Palette.GUN_POWDER}`)};
  background-color: ${({ save }) => (save ? `${Palette.SLATE_GREEN}` : `${Palette.NEW_WHITE}`)};
  cursor: pointer;
`;

export const StyledErrorMessage = styled.p`
  color: red;
  font-family: 'SofiaPro-Regular';
  font-size: 18px;
  line-height: 25px;
`;

export const StyledExecutorChooserText = styled.span`
  color: ${Palette.GUN_POWDER};
`;

export const StyledAssetListRadioButtonContainer = styled.label`
  width: fit-content;
  min-width: 110px;
  display: flex;
  gap: 1rem;
  border: 1px solid ${Palette.LIGHT_GREY};
  border-radius: 0.3rem;
  padding: 0.7rem;
  background-color: ${Palette.WHITE};
  cursor: pointer;
  font-family: 'SofiaPro-Regular';
`;
