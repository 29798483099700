import { EstateIntakeContextState } from './EstateIntakeContext.state';

export const ESTATE_FLOW_INITIAL_STATE: EstateIntakeContextState = {
  enableEdit: false,
  adultRelatives: [],
  relativeDefinitions: [],
  hasPoa: false,
  executorAndEstateTrustees: {
    isSpouseSelected: null,
    main: null,
    backup: null,
  },
  poaProperty: {
    isSpouseSelected: null,
    main: null,
    backup: null,
  },
  poaPersonalCare: {
    isSpouseSelected: null,
    main: null,
    backup: null,
  },
  childGuardians: {
    main: null,
    backup: null,
  },
  allBequests: { totalBequestAllocation: 0, bequests: [] },
  profile: {
    marritalStatus: '',
  },
  address: {
    streetName: '',
    streetNumber: '',
    unitSuiteApt: '',
    city: '',
    provinceState: '',
    postalZip: '',
    country: '',
  },
  willsProfile: {
    id: 0,
    plan: 'PREMIUM',
    methodOfCalculation: '',
    sex: '',
    kids: 0,
    isComplete: false,
    isEstateComplete: false,
    isFamilyComplete: false,
    isPaymentComplete: false,
    isSubscribed: false,
    isPlanComplete: false,
    userId: 0,
  },
  directChildren: [],
  ageOfMajority: 0,
  relatives: [],
};
