import { IRelationshipDefinition } from '@wills/apis';
import { calculateAge } from '@/Utils/dateOfBirthHelpers';
import { IRelativeInfo } from '../../WillsFamilyFlow.types';

export const getAge = (dob: string) => {
  const [year, month, day] = dob.split('-');
  return calculateAge(year, month, day);
};

export const findRelationshipDefinitionId = (
  relativeDefinitions: IRelationshipDefinition[],
  relationshipName: string,
) => {
  return relativeDefinitions.find(
    (relativeDefinition) => relativeDefinition.relationship === relationshipName.toLowerCase(),
  );
};

export const isOlderOrSameAge = (
  relativeDefinitions: IRelationshipDefinition[],
  relationships: IRelativeInfo[],
  newEntryAge: number,
  relationshipName: string,
) => {
  const getRecord = findRelationshipDefinitionId(relativeDefinitions, relationshipName);
  const isRelativeAdded = relationships.find((relationship) => relationship.relationDefinitionId === getRecord?.id);
  if (isRelativeAdded) {
    const relativesAge = getAge(isRelativeAdded.dob);
    if (newEntryAge < relativesAge) {
      return false;
    }
    return true;
  }
  return null;
};

export const checkIfParent = (
  relativeDefinitions: IRelationshipDefinition[],
  relativeDefinitionId: number | undefined,
) => {
  return relativeDefinitions.find((relativeDefinition) => {
    return (
      (relativeDefinition.id === relativeDefinitionId && relativeDefinition.relationship === 'father') ||
      (relativeDefinition.id === relativeDefinitionId && relativeDefinition.relationship === 'mother')
    );
  });
};

export const checkIfChildren = (
  relativeDefinitions: IRelationshipDefinition[],
  relativeDefinitionId: number | undefined,
) => {
  return relativeDefinitions.find((relativeDefinition) => {
    return (
      (relativeDefinition.id === relativeDefinitionId && relativeDefinition.relationship === 'son') ||
      (relativeDefinition.id === relativeDefinitionId && relativeDefinition.relationship === 'daughter')
    );
  });
};

export const checkIfGrandChildren = (
  relativeDefinitions: IRelationshipDefinition[],
  relativeDefinitionId: number | undefined,
) => {
  return relativeDefinitions.find((relativeDefinition) => {
    return (
      (relativeDefinition.id === relativeDefinitionId && relativeDefinition.relationship === 'gson') ||
      (relativeDefinition.id === relativeDefinitionId && relativeDefinition.relationship === 'gdaughter')
    );
  });
};

export const checkIfGrandParent = (
  relativeDefinitions: IRelationshipDefinition[],
  relativeDefinitionId: number | undefined,
) => {
  return relativeDefinitions.find((relativeDefinition) => {
    return (
      (relativeDefinition.id === relativeDefinitionId && relativeDefinition.relationship === 'gfather') ||
      (relativeDefinition.id === relativeDefinitionId && relativeDefinition.relationship === 'gmotherF') ||
      (relativeDefinition.id === relativeDefinitionId && relativeDefinition.relationship === 'gmotherM')
    );
  });
};

export const isDobValid = (
  newEntryDob: string,
  relativeDefinitions: IRelationshipDefinition[],
  userDob: string,
  relativeDefinitionId: number | undefined,
  relationships: IRelativeInfo[],
) => {
  const userAge = getAge(userDob);
  const newEntryAge = getAge(newEntryDob);
  const isCurrentEntryParent = checkIfParent(relativeDefinitions, relativeDefinitionId);
  const isCurrentEntryChildren = checkIfChildren(relativeDefinitions, relativeDefinitionId);
  const isCurrentEntryGrandParent = checkIfGrandParent(relativeDefinitions, relativeDefinitionId);

  if (isCurrentEntryParent) {
    if (newEntryAge - userAge < 0 || newEntryAge - userAge === 0) {
      return {
        isValid: false,
        message: 'Parents must be older than children',
      };
    }

    switch (isCurrentEntryParent.relationship) {
      case 'father':
      case 'mother':
        if (isOlderOrSameAge(relativeDefinitions, relationships, newEntryAge, 'gfather') === true) {
          return {
            isValid: false,
            message: 'Children must be younger than parent',
          };
        }
        break;
      default:
        return {
          isValid: true,
          message: '',
        };
    }
  }

  if (isCurrentEntryChildren && (newEntryAge - userAge > 0 || newEntryAge - userAge === 0)) {
    return {
      isValid: false,
      message: 'Children must be younger than parents',
    };
  }

  if (isCurrentEntryGrandParent) {
    if (newEntryAge - userAge < 0 || newEntryAge - userAge === 0) {
      return {
        isValid: false,
        message: 'Grand parents must be older than grand children',
      };
    }

    switch (isCurrentEntryGrandParent?.relationship) {
      case 'gfather':
      case 'gmotherF':
        if (isOlderOrSameAge(relativeDefinitions, relationships, newEntryAge, 'father') === false) {
          return {
            isValid: false,
            message: 'Parents must be older than children',
          };
        }
        break;

      case 'gmotherM':
        if (isOlderOrSameAge(relativeDefinitions, relationships, newEntryAge, 'mother') === false) {
          return {
            isValid: false,
            message: 'Parents must be older than children',
          };
        }
        break;

      default:
        return {
          isValid: true,
          message: '',
        };
    }
  }
  return {
    isValid: true,
    message: '',
  };
};
