import styled, { css } from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-grow: 1;
  background-color: ${Palette.AQUA_HAZE};
  gap: 1rem;
  height: 100vh;
`;

const width = css`
  width: min(90%, 50rem);
`;

export const Heading = styled.h3`
  color: ${Palette.NEW_BLUE};
  font-family: 'SofiaPro-Bold';
  text-align: center;
  line-height: 1.5rem;
  ${width}
`;

export const Message = styled.p`
  color: red;
  font-family: 'SofiaPro-Bold';
  text-align: center;
  ${width}
`;

export const Ref = styled.a`
  color: ${Palette.NEW_BLUE};
  text-decoration: underline;
  text-underline-offset: 0.2rem;
  ${width}
`;
