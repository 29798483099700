import { IoIosWarning } from 'react-icons/io';
import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { AlertContainer } from '../Alert.styles';

export const WarningIcon = styled(IoIosWarning)`
  font-size: 20px;
  color: ${Palette.MUSTARD};
`;

export const WarningContainer = styled(AlertContainer)`
  color: ${Palette.GUN_POWDER};
  background-color: ${Palette.LIGHT_GOLD};
  border-color: ${Palette.MUSTARD};
`;
