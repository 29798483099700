import { css } from 'styled-components';

export const pageGap = css`
  gap: 50px;
`;

export const sectionGap = css`
  gap: 30px;
`;

export const subSectionGap = css`
  gap: 20px;
`;
