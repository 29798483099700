import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const HeaderText = styled.h1<{ alignCenter?: boolean; italic?: boolean; bold?: boolean }>`
  font-family: ${({ bold }) => (bold ? 'SofiaPro-Bold' : 'SofiaPro-Regular')};
  font-style: ${({ italic }) => italic && 'italic'};
  margin-bottom: 0.5rem;
  color: ${Palette.NEW_BLUE};
  white-space: pre-line;
  ${({ alignCenter }) =>
    alignCenter &&
    `
        text-align: center;
  `}
`;
