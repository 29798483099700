import React, { useContext, useEffect, useState } from 'react';
import { getWillsBequests } from '@wills/apis/bequests/getWillsBequests';
import { toApiResponseError } from '@/lib/axios/toApiResponseError';
import { SnackBarContext } from '@/providers/SnackBarProvider/SnackBarProvider';
import {
  StyledSpecifyBequestPageContainer,
  StyledSpecifyBequestContentContainer,
  StyledSpecifyBequestGrid,
  StyledSpecifyBequestInfoContainer,
  StyledBequestText,
  StyledRelativeTipsWrapper,
  StyledBequestListContainer,
  StyledBequestContainer,
  StyledNavigationButtonsWrapper,
} from './SpecifyBequest.styles';

import { BequestDisplay } from './BequestDisplay';
import { EstateIntakeContext, EstateIntakeContextActionType } from '../../contexts';
import { NavigationButtons, StyledWillsIntroText, StyledWillsTitle, handleNavigationButtons } from '../../components';
import { Modal } from '@/Shared/Components';
import SpecifyBequestQuestionInfo from './SpecifyBequestQuestionInfo';
import { StyledInfoIcon, StyledInfoIconContainer, StyledInfoIconWrapper } from '@/lib/wills/components/styles';

export const SpecifyBequest: React.FC<{
  onNext: () => void;
  onBack: () => void;
  onSummary: () => void;
}> = ({ onNext, onBack, onSummary }) => {
  const { state, dispatch } = useContext(EstateIntakeContext);
  const { setSnackMessage } = useContext(SnackBarContext);
  const [showInfoBlurb, setShowInfoBlurb] = useState(false);

  const onContinueBtnClick = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    handleNavigationButtons(event, onNext, onSummary);
  };

  const getBequests = async () => {
    const { totalBequestAllocation } = state.allBequests;
    try {
      const { data, error } = await getWillsBequests();
      if (error) {
        throw new Error(error.message);
      } else if (data.length === 0) {
        dispatch({
          type: EstateIntakeContextActionType.SET_ALL_BEQUESTS,
          payload: { totalBequestAllocation, bequests: [] },
        });
      } else {
        const sumOfBequestAllocation = data.reduce((accumulator, currentBequest) => {
          return accumulator + parseInt(currentBequest.allocation!, 10);
        }, 0);
        dispatch({
          type: EstateIntakeContextActionType.SET_ALL_BEQUESTS,
          payload: { totalBequestAllocation: sumOfBequestAllocation, bequests: data },
        });
      }
    } catch (error) {
      const errorObject = toApiResponseError(error);
      setSnackMessage(errorObject.message);
    }
  };

  useEffect(() => {
    getBequests();
  }, []);

  return (
    <StyledSpecifyBequestPageContainer>
      <StyledSpecifyBequestContentContainer>
        <StyledSpecifyBequestGrid>
          <StyledSpecifyBequestInfoContainer>
            <StyledWillsIntroText center>Your estate</StyledWillsIntroText>
            <StyledWillsTitle center>Do you want to specify any charities, donations, or bequests?</StyledWillsTitle>

            <StyledBequestText role="paragraph">
              The total of all your bequests can not exceed 1/3 of your estate. If you do not wish to give a bequest,
              click continue.
            </StyledBequestText>
            <StyledBequestContainer>
              <StyledBequestListContainer>
                <BequestDisplay />
              </StyledBequestListContainer>
            </StyledBequestContainer>

            <StyledInfoIconWrapper>
              <StyledInfoIconContainer onClick={() => setShowInfoBlurb(true)}>
                <StyledInfoIcon />
              </StyledInfoIconContainer>
            </StyledInfoIconWrapper>

            {showInfoBlurb && (
              <Modal transparent onCloseCallback={() => setShowInfoBlurb(false)}>
                <SpecifyBequestQuestionInfo />
              </Modal>
            )}

            <StyledNavigationButtonsWrapper>
              <NavigationButtons onContinue={onContinueBtnClick} onPrevious={onBack} />
            </StyledNavigationButtonsWrapper>
          </StyledSpecifyBequestInfoContainer>

          <StyledRelativeTipsWrapper>
            <SpecifyBequestQuestionInfo />
          </StyledRelativeTipsWrapper>
        </StyledSpecifyBequestGrid>
      </StyledSpecifyBequestContentContainer>
    </StyledSpecifyBequestPageContainer>
  );
};
