import { ApiResponse, axiosClient, toApiResponse } from '@/lib/axios';
import { ApiUser, User } from '../types';
import { snakeToCamelParser } from '@/Utils';

export const getUser = async (): Promise<ApiResponse<User>> => {
  const response = await toApiResponse(axiosClient.get('/current-user'));

  if (response.error) return response;

  const formatedData: ApiUser = snakeToCamelParser(response.data);
  return {
    ...response,
    data: {
      ...formatedData,
      profile: formatedData.profile.pop(),
      address: formatedData.address.pop(),
      contact: formatedData.contact.pop(),
    },
  };
};
