import React from 'react';
import { IWish } from '@wills/apis';
import { DataCard, DataCardText } from '@wills/components';
import { MyWishesContainer, Title } from './MyWishes.styles';
import locales from './MyWishes.locales.json';

export interface MyWishesProps {
  wishes: IWish[];
  onEdit: (wish: IWish) => void;
  onDelete: (wish: IWish) => Promise<void>;
  disabled?: boolean;
  hideActions?: boolean;
}

export const MyWishes: React.FC<MyWishesProps> = ({ wishes, onEdit, onDelete, disabled, hideActions }) => {
  const locale = locales.EN;
  const disableAction = disabled || hideActions;
  return (
    <MyWishesContainer>
      <Title>{locale.title}</Title>
      {wishes.map((wish, i) => (
        <DataCard
          key={i}
          title={wish.title}
          disabled={disableAction}
          hideActions={hideActions}
          onEdit={() => onEdit(wish)}
          onDelete={() => onDelete(wish)}
        >
          <DataCardText>{wish.description}</DataCardText>
        </DataCard>
      ))}
    </MyWishesContainer>
  );
};
