import React from 'react';

import { LoadingOverlay } from '@/Shared/Components';
import { PageContainer, PageContent } from './Page.styles';

export interface IPagePrpps {
  children: React.ReactNode;
  loading?: boolean;
}

export const Page: React.FC<IPagePrpps> = ({ children, loading }) => {
  return (
    <PageContainer>
      {loading && <LoadingOverlay type="fixed" />}
      <PageContent>{children}</PageContent>
    </PageContainer>
  );
};
