import React from 'react';
import { upsertWillsBequest } from '@wills/apis/bequests/upsertWillsBequest';
import { NewBequestValues } from '../BequestForm/BequestForm';
import { EstateIntakeContext, EstateIntakeContextActionType } from '../../../contexts';
import { SnackBarContext } from '@/providers';
import { isBequestAllocationValid } from '../BequestForm/BequestForm.helpers';

export interface CreateBequestFormProps {
  onComplete: () => void;
  renderForm: (onSubmit: (data: NewBequestValues) => Promise<void>) => JSX.Element;
}

export const CreateBequestForm: React.FC<CreateBequestFormProps> = ({ onComplete, renderForm }) => {
  const { state, dispatch } = React.useContext(EstateIntakeContext);
  const { setSnackMessage } = React.useContext(SnackBarContext);
  const onSubmit = async (data: NewBequestValues): Promise<void> => {
    const { totalBequestAllocation, bequests } = state.allBequests;
    const updatedTotalAllocation = bequests.length === 0 ? 0 : totalBequestAllocation;
    if (!isBequestAllocationValid(updatedTotalAllocation, Number(data.allocation))) {
      setSnackMessage('Total beneficiary allocation cannot be more than 33');
      return;
    }

    const { data: bequest, error } = await upsertWillsBequest({
      ...data,
    });

    if (error) {
      setSnackMessage(error.message);
      return;
    }

    dispatch({
      type: EstateIntakeContextActionType.ADD_TO_BEQUESTS,
      payload: {
        totalBequestAllocation: state.allBequests.totalBequestAllocation + Number(bequest.allocation),
        bequest,
      },
    });

    onComplete();
  };

  return renderForm(onSubmit);
};
