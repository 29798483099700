/* eslint-disable no-console */

import React, { useEffect, useRef, useState } from 'react';

const useGooglePlace = (inputRef: React.MutableRefObject<HTMLInputElement | null>, options: any) => {
  const placeRef = useRef<any>();
  const [place, setPlace] = useState<any>();

  const loadPlacesFromGoogle = () => {
    placeRef.current = new (window as any).google.maps.places.Autocomplete(inputRef.current, options);

    placeRef.current.addListener('place_changed', async () => {
      const response = await placeRef.current.getPlace();
      if (response) {
        setPlace(response.address_components);
      }
    });
  };

  const handleKeyDown = async (e: any) => {
    if (e.key === 'Enter') {
      const dropdownContainers = document.querySelectorAll('.pac-container');
      const firstDropDownContainer = dropdownContainers[dropdownContainers.length - 1];
      const firstItem = firstDropDownContainer.querySelector('.pac-item')?.textContent;
      if (inputRef.current?.value) {
        inputRef.current.value = firstItem!;
        const event = new Event('place_changed');
        const random = new (window as any).google.maps.places.Trigger('place_changed');
        console.log(event, random);
      }
    }
  };

  useEffect(() => {
    loadPlacesFromGoogle();
  }, []);

  return { place, handleKeyDown };
};

export default useGooglePlace;
