import { axiosClient } from '@/lib/axios';

interface IData {
  plan?: string;
  isPlanComplete?: boolean;
  isSubscribed?: boolean;
}

export const completeSelectPlan = (data: IData) => {
  return axiosClient.post('/select-plan', data);
};
