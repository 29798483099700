import React from 'react';
import { useForm } from 'react-hook-form';
import { IWish } from '@wills/apis';
import locales from './WishForm.locales.json';
import { WishFormContainer, Description, Title, TextInput, TextArea, ErrorLabel } from './WishForm.styles';
import { InfoCard } from '../InfoCard';
import { PrimaryButton, SecondaryButton } from '../styles';

export type WishFormValues = Pick<IWish, 'title' | 'description'>;

interface WishFormProps {
  defaultValues?: Partial<WishFormValues>;
  onSubmit: (values: WishFormValues) => void;
  onCancel: () => void;
}

export const WishForm: React.FC<WishFormProps> = React.forwardRef<HTMLFormElement, WishFormProps>(
  ({ defaultValues = {}, onSubmit, onCancel }, ref) => {
    const locale = locales.EN;
    const {
      handleSubmit,
      register,
      formState: { errors, isSubmitting },
    } = useForm<WishFormValues>({
      defaultValues: {
        title: defaultValues.title ?? '',
        description: defaultValues.description ?? '',
      },
    });

    const onSubmitHandler = handleSubmit((values) => {
      onSubmit(values);
    });

    return (
      <WishFormContainer onSubmit={onSubmitHandler} ref={ref}>
        <Title>{locale.formTitle}</Title>
        <TextInput
          {...register('title', { required: locale.requiredError })}
          placeholder={locale.titlePlaceholder}
          id="title"
          aria-label={locale.titleLabel}
          maxLength={50}
        />
        {errors.title && <ErrorLabel htmlFor="title">{errors.title.message}</ErrorLabel>}
        <Description>{locale.descriptionInfo}</Description>
        <TextArea
          {...register('description', {
            required: locale.requiredError,
          })}
          id="description"
          aria-label={locale.descriptionLabel}
          placeholder={locale.descriptionPlaceholder}
          rows={4}
          maxLength={300}
        />
        {errors.description && <ErrorLabel htmlFor="description">{errors.description.message}</ErrorLabel>}
        <InfoCard label={locale.info} />
        <PrimaryButton disabled={isSubmitting} type="submit">
          {locale.saveButtonLabel}
        </PrimaryButton>
        <SecondaryButton disabled={isSubmitting} type="button" onClick={onCancel}>
          {locale.cancelButtonLabel}
        </SecondaryButton>
      </WishFormContainer>
    );
  },
);
