import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledMfaContainer = styled.div`
  padding: 3.5rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 15rem;

  @media (max-width: 720px) {
    padding: 2.5rem 1rem 1rem 1rem;
  }
`;

export const StyledModalParagraph = styled.div`
  font-size: clamp(1.2rem, 1vw, 1.5rem);
  text-align: center;
  color: ${Palette.GUN_POWDER};
`;

export const StyledModalButtonGroup = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  width: 100%;
`;

export const StyledModalButton = styled.button<{ secondary?: boolean; disabled?: boolean }>`
  padding: 0.5rem 1rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  background-color: ${({ secondary }) => (secondary ? Palette.CADET_BLUE : Palette.GUN_POWDER)};
  color: ${({ secondary }) => (secondary ? Palette.GUN_POWDER : Palette.NEW_WHITE)};
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
`;
