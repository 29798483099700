import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const ProductContainer = styled.div`
  width: 100%;
  background-color: ${Palette.NEW_WHITE};
  margin: 1.5rem auto;
  border-radius: 1rem;
  padding: 2rem;
  text-align: left;
  display: flex;
  gap: 1.5rem;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.05);
  position: relative;
`;
export const ImageBackground = styled.div`
  background-color: ${Palette.INDEPENDENCE_BLUE};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 1.8rem;
  height: 3rem;
  width: 3rem;
`;

export const Image = styled.img`
  height: max(1.5rem, 2vh);
  //   filter: invert(20%) sepia(4%) saturate(4928%) hue-rotate(197deg) brightness(26%) contrast(79%);
`;

export const InfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: space-around;
  color: ${Palette.NEW_BLUE};
  gap: 0.7rem;
`;

export const ProductTitle = styled.h3`
  font-family: 'SofiaPro-Bold';
  font-size: clamp(2rem, 2vw+1rem, 4rem);
`;

export const ProductFooter = styled.h4`
  font-family: 'SofiaPro-Bold';
  font-style: italic;
  position: absolute;
  bottom: 5px;
  right: 10px;
  font-size: clamp(1.5rem, 1vw+1rem, 2rem);
`;

export const ProductDescription = styled.p`
  font-family: 'SofiaPro-Light';
  font-weight: 100;
  font-size: clamp(1rem, 0.8vw + 0.5rem, 1.5rem);

  @media (max-width: 600px) {
    line-height: 1.5;
  }
`;
