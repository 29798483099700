import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledContactUsPageContainer = styled.div`
  background-color: ${Palette.AQUA_HAZE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  padding: 5rem 0;
`;

export const StyledContactUsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  min-height: 30rem;
  gap: 2rem;
`;

export const StyledContactUsContentContainer = styled.div`
  width: min(55%, 1366px);

  @media (max-width: ${Breakpoints.MOBILE_MAX_WIDTH}) {
    width: 85%;
  }
`;

export const StyledContactUsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledQueryAndLearnMoreSectionContainer = styled.div`
  width: 90%;
  margin: 1rem auto;
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  @media screen and (max-width: ${Breakpoints.MOBILE_MAX_WIDTH}) {
    width: 100%;
  }
`;

export const StyledQueryInfoContainer = styled.div`
  background-color: ${Palette.PRIMARY_WHITE};
  padding: 1.5rem;
  margin-top: 1rem;
  border-radius: 1.25rem;
`;

export const StyledLearnMoreContainer = styled(StyledQueryInfoContainer)``;

export const StyledContactUsText = styled.h2`
  font-family: 'SofiaPro-Regular';
  font-size: 1.5rem;
  color: ${Palette.NEW_BLUE};
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  gap: 1.5rem;

  @media screen and (max-width: ${Breakpoints.MOBILE_MAX_WIDTH}) {
    flex-direction: column;
    gap: 0rem;
  }
`;

export const StyledButton = styled.button<{
  fullWidth?: boolean;
  borderColor?: string;
  bgColor: string;
  textColor: string;
}>`
  display: inline-block;
  color: ${({ textColor }) => textColor && textColor};
  padding: 0.625rem 1.125rem;
  background-color: ${({ bgColor }) => bgColor && bgColor};
  margin-top: 1.5rem;
  border: ${({ borderColor }) => (borderColor ? `0.08rem solid ${borderColor}` : 'none')};
  border-radius: 0.7rem;
  cursor: pointer;
  width: ${({ fullWidth }) => fullWidth && '100%'};

  @media screen and (max-width: ${Breakpoints.MOBILE_MAX_WIDTH}) {
    width: 100%;
  }
`;
