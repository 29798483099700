import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledLiabilityDetailsFormContainer = styled.div`
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (min-width: ${Breakpoints.TABLET_MIN_WIDTH}) {
    max-width: 370px;
  }
`;

export const StyledLiabilityDetailsForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledLiabilitySelectInput = styled.select`
  padding: 0.7rem;
  border: none;
  border-radius: 0.3rem;
  background-color: ${Palette.WHITE};
  font-family: 'SofiaPro-Regular';
  font-size: 1rem;
  color: ${Palette.NEW_BLUE};
`;

export const StyledLiabilityTextInput = styled.input`
  border: none;
  border-radius: 0.3rem;
  padding: 0.7rem;
  background-color: ${Palette.WHITE};
  font-family: 'SofiaPro-Regular';
  font-size: 1rem;
  color: ${Palette.NEW_BLUE};
`;

export const StyledLiabilityTextArea = styled.textarea`
  border: none;
  border-radius: 0.3rem;
  padding: 0.7rem;
  background-color: ${Palette.WHITE};
  font-family: 'SofiaPro-Regular';
  font-size: 1rem;
  color: ${Palette.NEW_BLUE};
`;

export const StyledLiabilityRadioButtonContainer = styled.label`
  width: fit-content;
  min-width: 110px;
  display: flex;
  gap: 1rem;
  border: 1px solid ${Palette.LIGHT_GREY};
  border-radius: 0.3rem;
  padding: 0.7rem;
  background-color: ${Palette.WHITE};
  cursor: pointer;
`;

export const StyledLiabilityDetailsFormErrorText = styled.p`
  color: red;
  font-family: 'SofiaPro-Regular';
  font-size: 18px;
  line-height: 25px;
`;
