import React, { useContext } from 'react';

import { ProfileStore } from '@/Stores';
import { LocaleContext } from '@/providers';

import { DisplayInfo } from '../DisplayInfo';
import {
  UserIcon,
  CalendarIcon,
  RingIcon,
  CitizenIcon,
  LanguageIcon,
  InfoWrapper,
  FormContent,
  FormContentHeader,
  ContentHeading,
  EditIconContainer,
  EditIcon,
} from '../../Profile.style';
import profileDisplayLocales from './profileDisplayLocales.json';
import { IProfileDisplayProps } from './ProfileDisplay.types';
import { EDataTestId } from '@/test-utils/EDataTestId';

const ProfileDisplay = ({ isEditMode, setIsEditMode }: IProfileDisplayProps) => {
  const { locale } = useContext(LocaleContext);
  const { profile } = useContext(ProfileStore);

  const profileDisplayLocale = profileDisplayLocales[locale];

  return (
    <FormContent data-testid={EDataTestId.USER_PERSONAL_INFO_DISPLAY}>
      <FormContentHeader>
        <ContentHeading>{profileDisplayLocale['card.heading']}</ContentHeading>
        <ContentHeading>
          <EditIconContainer show={isEditMode}>
            <EditIcon onClick={() => setIsEditMode(true)} title="edit" aria-label="edit" />
          </EditIconContainer>
        </ContentHeading>
      </FormContentHeader>

      <InfoWrapper>
        <DisplayInfo
          icon={<UserIcon />}
          header={profileDisplayLocale['label.first-and-middle-name']}
          value={profile ? `${profile.firstName} ${profile.middleName}` : ''}
        />
        <DisplayInfo
          icon={<UserIcon />}
          header={profileDisplayLocale['label.last-name']}
          value={profile ? `${profile.lastName}` : ''}
        />
        <DisplayInfo
          icon={<CalendarIcon />}
          header={profileDisplayLocale['label.dob']}
          value={profile ? profile?.dob : ''}
        />
        <DisplayInfo
          icon={<RingIcon />}
          header={profileDisplayLocale['label.marital-status']}
          value={profile ? profile?.marritalStatus : ''}
        />
        <DisplayInfo
          icon={<CitizenIcon />}
          header={profileDisplayLocale['label.citizenship']}
          value={profile ? profile.citizenship : ''}
        />
        <DisplayInfo
          icon={<LanguageIcon />}
          header={profileDisplayLocale['label.language']}
          value={profile ? profile.preferredLanguage : ''}
        />
      </InfoWrapper>
    </FormContent>
  );
};

export default ProfileDisplay;
