import React from 'react';

import {
  PopulatedDocumentType,
  PopulatedDocument,
  getWillsDocuments,
  createDocuments,
  getDocumentDownloadUrl,
  getDocumentViewUrl,
} from '@wills/apis';
import { Navigate } from 'react-router-dom';
import { ApiResponse, useApi } from '@/lib/axios';
import { Loading } from '@/Shared/Components/Loading';
import { useErrorHandler } from '@/hooks';

export interface PopulatedDocumentContextValues {
  documents: Record<PopulatedDocumentType, PopulatedDocument>;
  regenerateDocuments: (documentTypes: PopulatedDocumentType[]) => Promise<ApiResponse<PopulatedDocument[]>>;
  getDownloadUrl: (documentTypes: PopulatedDocumentType) => Promise<ApiResponse<string>>;
  getViewUrl: (documentTypes: PopulatedDocumentType) => Promise<ApiResponse<string>>;
}

export const PopulatedDocumentContext = React.createContext({} as PopulatedDocumentContextValues);

export const PopulatedDocumentProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const {
    data: willsDocuments,
    setData: setWillsDocuments,
    loading: willsDocumentsLoading,
    error: willsDocumentsError,
  } = useApi<PopulatedDocument[]>(getWillsDocuments);

  const { handleAsyncError } = useErrorHandler();

  const regenerateDocuments = (currentDocuments: PopulatedDocument[]) =>
    handleAsyncError(async (documentTypes: PopulatedDocumentType[]) => {
      const response = await createDocuments(documentTypes);

      if (response.error) return response;
      const updatedDocs = Object.values(
        [...currentDocuments, ...response.data].reduce<Record<string, PopulatedDocument>>((acc, doc) => {
          return {
            ...acc,
            [doc.id]: doc,
          };
        }, {}),
      );

      setWillsDocuments(updatedDocs);
      return response;
    });

  const getDownloadUrl = handleAsyncError(getDocumentDownloadUrl);
  const getViewUrl = handleAsyncError(getDocumentViewUrl);

  if (willsDocumentsLoading) {
    return <Loading />;
  }

  if (willsDocumentsError) {
    if (willsDocumentsError.message === 'Payment not complete') {
      return <Navigate to="/wills/landing" />;
    }
    throw new Error(willsDocumentsError.message);
  }

  const documents = willsDocuments.reduce(
    (acc, doc) => {
      return {
        ...acc,
        [doc.documentType]: doc,
      };
    },
    {} as Record<PopulatedDocumentType, PopulatedDocument>,
  );

  return (
    <PopulatedDocumentContext.Provider
      value={{
        documents,
        getDownloadUrl,
        getViewUrl,
        regenerateDocuments: regenerateDocuments(willsDocuments),
      }}
    >
      {children}
    </PopulatedDocumentContext.Provider>
  );
};
