import { useCallback, useContext, useEffect, useState } from 'react';
import { axiosClient, toApiResponse } from '@/lib/axios';
import { SnackBarContext } from '@/providers';
import { IAppointeeRole } from '../types';

export interface IAppointeeNotification {
  id: number;
  lastNotified: string;
  userId: number;
}

export function useAppointeeNotifier() {
  const { setSnackMessage } = useContext(SnackBarContext);
  const [lastNotified, setLastNotified] = useState<string | null>(null);
  const [notifying, setNotifying] = useState(false);

  async function notifyAppointees(emailsToNotify: IAppointeeRole[]) {
    setNotifying(true);

    const { data, error } = await toApiResponse<IAppointeeNotification>(
      axiosClient.post('/notify-appointees', emailsToNotify),
    );

    if (error || !data) {
      setSnackMessage(error.message);
      return;
    }

    setLastNotified(data.lastNotified);
    setSnackMessage('Your appointees have been notified');

    setNotifying(false);
  }

  const loadAppointeeNotifications = useCallback(async () => {
    const { data, error } = await toApiResponse<IAppointeeNotification>(axiosClient.get('/appointee-notifications'));

    if (error || !data) {
      if (error.code === 'NotFound') return;

      setSnackMessage(error.message);
      return;
    }

    setLastNotified(data.lastNotified);
  }, []);

  useEffect(() => {
    loadAppointeeNotifications();
  }, [loadAppointeeNotifications]);

  return {
    lastNotified,
    notifying,
    notifyAppointees,
  };
}
