import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const Message = styled.div`
  width: 100%;
  background-color: ${Palette.GOLD_CRAYOLA};
  border-radius: 0.3rem;
  font-family: 'SofiaPro-Regular';
  color: ${Palette.INDEPENDENCE_BLUE};
  padding: 0.5rem;
  margin-bottom: 1rem;
`;
