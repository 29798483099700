import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { updateCompleteEstateFlag } from '@wills/apis';
import {
  StyledReviewContainer,
  StyledReviewContentContainer,
  StyledReviewListContainer,
  StyledReviewRow,
  StyledReviewRowTitle,
  StyledReviewNavigationButtonsContainer,
  StyledCompletedTag,
} from './Review.styles';
import { StyledWillsIntroText, StyledWillsTitle } from '../../components/styles';
import { NavigationButtons } from '../../components/NavigationButtons';
import { getErrorMessage } from '@/Utils';
import { SnackBarContext } from '@/providers';
import { ReviewRow } from '../../components';
import { BequestSummary } from '../../components/BequestSummary';
import { EstateIntakeContext } from '../../contexts/EstateIntakeContext/EstateIntakeContext';

export interface ReviewProps {
  goToExecutorAndEstateTrustee: undefined | (() => void);
  goToPowerOfAttorneyProperty: undefined | (() => void);
  goToPowerOfAttorneyPersonalCare: undefined | (() => void);
  goToChildGuardians: undefined | (() => void);
  goToSpecifyBequest: undefined | (() => void);
  onLoad: () => void;
  onBack: () => void;
  onComplete: () => void;
}

export const Review: React.FC<ReviewProps> = ({
  goToExecutorAndEstateTrustee,
  goToPowerOfAttorneyProperty,
  goToPowerOfAttorneyPersonalCare,
  goToChildGuardians,
  goToSpecifyBequest,
  onLoad,
  onBack,
  onComplete,
}) => {
  const { state } = React.useContext(EstateIntakeContext);
  const { plan } = state.willsProfile;

  const { setSnackMessage } = React.useContext(SnackBarContext);

  React.useEffect(() => {
    onLoad();
  }, []);
  const onPreviousBtnClick = () => {
    onBack();
  };

  const onLooksGoodBtnClick = async () => {
    try {
      await updateCompleteEstateFlag();
      amplitude.track('Wills Estate Flow Complete');
      onComplete();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    }
  };

  return (
    <StyledReviewContainer>
      <StyledReviewContentContainer>
        <StyledWillsIntroText center>Your Estate</StyledWillsIntroText>
        <StyledWillsTitle center>Great! Review your answers</StyledWillsTitle>
        <StyledReviewListContainer>
          <StyledReviewRow>
            <StyledReviewRowTitle>Your Estate</StyledReviewRowTitle>
            <StyledCompletedTag>Completed</StyledCompletedTag>
          </StyledReviewRow>
          <ReviewRow
            title="Primary Executors:"
            value={
              state.executorAndEstateTrustees.main
                ? `${state.executorAndEstateTrustees.main.firstName} ${state.executorAndEstateTrustees.main.lastName}`
                : 'None'
            }
            onEdit={goToExecutorAndEstateTrustee}
          />
          <ReviewRow
            title="Backup Executors:"
            value={
              state.executorAndEstateTrustees.backup
                ? // eslint-disable-next-line max-len
                  `${state.executorAndEstateTrustees.backup.firstName} ${state.executorAndEstateTrustees.backup.lastName}`
                : 'None'
            }
            onEdit={goToExecutorAndEstateTrustee}
          />
          <ReviewRow
            title="Is your spouse your Attorney for Property:"
            value={state.poaProperty.isSpouseSelected === true ? 'Yes' : 'No'}
            onEdit={goToPowerOfAttorneyProperty}
            plan={plan}
          />
          <ReviewRow
            title="Primary Attorney for Property:"
            value={
              state.poaProperty.main ? `${state.poaProperty.main.firstName} ${state.poaProperty.main.lastName}` : 'None'
            }
            onEdit={goToPowerOfAttorneyProperty}
            plan={plan}
          />
          <ReviewRow
            title="Backup Attorney for Property:"
            value={
              state.poaProperty.backup
                ? `${state.poaProperty.backup.firstName} ${state.poaProperty.backup.lastName}`
                : 'None'
            }
            onEdit={goToPowerOfAttorneyProperty}
            plan={plan}
          />
          <ReviewRow
            title="Is your spouse your Attorney for Personal Care:"
            value={state.poaPersonalCare.isSpouseSelected === true ? 'Yes' : 'No'}
            onEdit={goToPowerOfAttorneyPersonalCare}
            plan={plan}
          />
          <ReviewRow
            title="Primary Attorney for Personal Care:"
            value={
              state.poaPersonalCare.main
                ? `${state.poaPersonalCare.main.firstName} ${state.poaPersonalCare.main.lastName}`
                : 'None'
            }
            onEdit={goToPowerOfAttorneyPersonalCare}
            plan={plan}
          />
          <ReviewRow
            title="Backup Attorney for Personal Care:"
            value={
              state.poaPersonalCare.backup
                ? `${state.poaPersonalCare.backup.firstName} ${state.poaPersonalCare.backup.lastName}`
                : 'None'
            }
            onEdit={goToPowerOfAttorneyPersonalCare}
            plan={plan}
          />
          <ReviewRow
            title="Childrens Guardian:"
            value={
              state.childGuardians.main
                ? `${state.childGuardians.main.firstName} ${state.childGuardians.main.lastName}`
                : 'None'
            }
            onEdit={goToChildGuardians}
          />
          <ReviewRow
            title="Backup Guardian:"
            value={
              state.childGuardians.backup
                ? `${state.childGuardians.backup.firstName} ${state.childGuardians.backup.lastName}`
                : 'None'
            }
            onEdit={goToChildGuardians}
          />
        </StyledReviewListContainer>
        <StyledReviewListContainer>
          <BequestSummary onEdit={goToSpecifyBequest} />
        </StyledReviewListContainer>
        <StyledReviewNavigationButtonsContainer>
          <NavigationButtons
            confirmBtnText="Looks Good"
            onContinue={onLooksGoodBtnClick}
            onPrevious={onPreviousBtnClick}
          />
        </StyledReviewNavigationButtonsContainer>
      </StyledReviewContentContainer>
    </StyledReviewContainer>
  );
};
