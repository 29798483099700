import styled from 'styled-components';
import { HiOutlineCheck } from 'react-icons/hi';

import { Palette } from '@/Shared/Constants';

export const StyledSubmitButtonContainer = styled.div<{ disabled: boolean }>`
  border: none;
  position: absolute;
  top: 10px;
  right: 14px;
  width: fit-content;
  ${({ disabled }) => disabled && 'opacity: 0.5;'}

  @media screen and (max-width: 600px) {
    right: 6px;
  }
`;

export const StyledCheck = styled(HiOutlineCheck)`
  width: 2.5rem;
  padding: 0.2rem;
  height: 2.5rem;
  color: ${Palette.SLATE_GREEN};
  cursor: pointer;
`;
