export const COMPARE_PLANS_HEADERS = [
  {
    type: 'title',
    header: 'Compare Plans',
  },
  {
    type: 'essentials',
    header: 'Legal Essentials',
  },
  {
    type: 'premium',
    header: 'Premium Coverage',
  },
];

export const COMPARE_PLANS_ON_QUANTITY = [
  {
    type: 'title',
    content: 'Average savings compared to visiting a lawyer',
  },
  {
    type: 'essentials',
    content: '$1000+',
  },
  {
    type: 'premium',
    content: '$1500+',
  },
];

export const COMPARE_PLANS_POINTS_1 = [
  {
    header: 'LAST WILL AND TESTAMENT',
    topics: [
      'Create a legally-valid, Shariah-Compliant will, with detailed signing instructions',
      'Receive Asset List template document',
      'Funeral and Burial wishes according to Quran',
    ],
  },
];

export const COMPARE_PLANS_POINTS_1_ESSENTIAL_COVERAGE = [
  {
    content: 'None',
  },
  {
    content: true,
  },
  {
    content: true,
  },
  {
    content: true,
  },
];

export const COMPARE_PLANS_POINTS_1_PREMIUM_COVERAGE = [
  {
    content: 'None',
  },
  {
    content: true,
  },
  {
    content: true,
  },
  {
    content: true,
  },
];

export const COMPARE_PLANS_POINTS_2 = [
  {
    header: 'POWER OF ATTORNEY',
    topics: [
      'Managing your property and finances if you are incapacitated',
      'Making health care decisions for you if you are unable (living will)',
    ],
  },
];

export const COMPARE_PLANS_POINTS_2_ESSENTIAL_COVERAGE = [
  {
    content: 'None',
  },
  {
    content: false,
  },
  {
    content: false,
  },
];

export const COMPARE_PLANS_POINTS_2_PREMIUM_COVERAGE = [
  {
    content: 'None',
  },
  {
    content: true,
  },
  {
    content: true,
  },
];
