import axios from 'axios';

import { API_URL } from '@/config';

function getTokenFromStorage() {
  return localStorage.getItem('accessToken');
}

function getLanguagePreferenceFromStorage() {
  return localStorage.getItem('locale');
}

const instance = axios.create({
  baseURL: API_URL,
  timeout: 30000,
});

instance.interceptors.request.use((config) => {
  const token = getTokenFromStorage();
  const locale = getLanguagePreferenceFromStorage() as 'EN' | 'FR';

  config.headers = {
    Authorization: token!,
    Language: locale,
  };

  return config;
});

export const axiosClient = instance;
