import React, { useEffect, KeyboardEvent, useRef, ChangeEvent, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { HiddenSubmitButton, InputGroup } from '@/Shared/Shared.styles';
import { IUserAddress } from '@/Types';
import { Header } from '@/Shared/Components';
import { LocaleContext } from '@/providers';

import { Prompt } from '../Prompt';
import {
  StyledAddressFormContainer,
  StyledAddressForm,
  StyledAddressInput,
  StyledAddressShortInput,
  StyledAddressShortSelect,
  StyledAddressLongSelect,
} from './AddressForm.styles';
import { setAddressValues, limitAddressValue } from './AddressHelpers';
import { PROVINCE_DATA } from '../../../../../../Shared/Constants/provinceData';
import { RequiredInfoIndicator } from '../Question/Question.styles';
import addressFormLocales from './addressFormLocales.json';

interface IProps {
  recordAddress: React.Dispatch<React.SetStateAction<IUserAddress>>;
  goToNextQuestion: () => void;
  address: IUserAddress;
}

const AddressForm: React.FC<IProps> = ({ recordAddress, goToNextQuestion, address }) => {
  const { locale } = useContext(LocaleContext);

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IUserAddress>();

  const streetNameInputRef = useRef<HTMLInputElement | null>(null);

  const onAddressSubmit: SubmitHandler<IUserAddress> = (data) => {
    recordAddress(data);
    goToNextQuestion();
  };

  const { name } = register('streetName', { required: true });

  const onStreetNameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    limitAddressValue(event, 60);
    setValue('streetName', event.target.value);
    streetNameInputRef.current!.value = event.target.value;
    recordAddress((currentAddress) => ({
      ...currentAddress,
      streetName: event.target.value,
    }));
  };

  const onTabPressInLastInput = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      streetNameInputRef.current?.focus();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    limitAddressValue(e, 19);
    recordAddress((currentAddress) => ({
      ...currentAddress,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    streetNameInputRef.current?.focus();
    reset(setAddressValues(address));
    streetNameInputRef.current!.value = address.streetName;
  }, []);

  useEffect(() => {
    if (streetNameInputRef.current?.value === '') streetNameInputRef.current?.focus();
  }, [errors.streetName]);

  const addressFormLocale = addressFormLocales[locale];

  return (
    <StyledAddressFormContainer>
      <Header alignCenter>
        {addressFormLocale.header}
        <RequiredInfoIndicator>*</RequiredInfoIndicator>
      </Header>
      <StyledAddressForm onSubmit={handleSubmit(onAddressSubmit)}>
        <StyledAddressInput
          ref={streetNameInputRef}
          placeholder={addressFormLocale['placeholder.street-name']}
          name={name}
          onChange={onStreetNameChangeHandler}
          error={!!errors.streetName}
        />
        <InputGroup>
          <StyledAddressShortInput
            placeholder={addressFormLocale['placeholder.street-number']}
            {...register('streetNumber', { required: true, onChange })}
            error={!!errors.streetNumber}
          />
          <StyledAddressShortInput
            placeholder={addressFormLocale['placeholder.apt']}
            {...register('unitSuiteApt', { onChange })}
          />
        </InputGroup>
        <StyledAddressInput
          placeholder={addressFormLocale['placeholder.city']}
          {...register('city', { required: true, onChange })}
          error={!!errors.city}
        />
        <InputGroup>
          <StyledAddressShortSelect {...register('provinceState', { required: true })}>
            {PROVINCE_DATA.map((province) => (
              <option key={province.id} value={province.value}>
                {province.name[locale]}
              </option>
            ))}
          </StyledAddressShortSelect>
          <StyledAddressShortInput
            placeholder={addressFormLocale['placeholder.zip']}
            {...register('postalZip', { required: true, onChange })}
            error={!!errors.postalZip}
          />
        </InputGroup>
        <StyledAddressLongSelect {...register('country', { required: true })} onKeyDown={onTabPressInLastInput}>
          <option value="CA">Canada</option>
        </StyledAddressLongSelect>
        <HiddenSubmitButton type="submit" />
      </StyledAddressForm>
      <Prompt onClick={handleSubmit(onAddressSubmit)} />
    </StyledAddressFormContainer>
  );
};

export default AddressForm;
