import { IntakeStep } from '../../hooks/useIntakeSteps';
import { EstateIntakeContextState } from './contexts/EstateIntakeContext';

export type StepName =
  | 'introduction'
  | 'executorAndEstateTrustee'
  | 'powerOfAttorneyProperty'
  | 'powerOfAttorneyPersonalCare'
  | 'childGuardians'
  | 'bequestIntro'
  | 'specifyBequest'
  | 'review';

export interface StepContext extends EstateIntakeContextState {}

export const steps: IntakeStep<StepContext, StepName>[] = [
  {
    name: 'introduction',
    shouldSkip: () => {
      return false;
    },
  },
  {
    name: 'executorAndEstateTrustee',
    shouldSkip: () => {
      return false;
    },
  },
  {
    name: 'powerOfAttorneyProperty',
    shouldSkip: (context) => {
      return !context.hasPoa;
    },
  },
  {
    name: 'powerOfAttorneyPersonalCare',
    shouldSkip: (context) => {
      return !context.hasPoa;
    },
  },
  {
    name: 'childGuardians',
    shouldSkip: (context) => {
      return context.directChildren.length === 0;
    },
  },
  {
    name: 'bequestIntro',
    shouldSkip: () => {
      return false;
    },
  },
  {
    name: 'specifyBequest',
    shouldSkip: () => {
      return false;
    },
  },
  {
    name: 'review',
    shouldSkip: () => {
      return false;
    },
  },
];
