import React, { useContext } from 'react';
import { ReviewRow } from '../ReviewRow';
import { IBequestSummaryProps } from './BequestSummary.types';
import { StyledReviewText } from '../ReviewRow/ReviewRow.styles';
import { StyledBequestTextWrapper, StyledBequestSpan } from './BequestSummary.styles';
import { EstateIntakeContext } from '../../contexts';

const BequestSummary = ({ onEdit }: IBequestSummaryProps) => {
  const { state } = useContext(EstateIntakeContext);
  // const beneficiaryNumber = `Beneficiar${state.allBequests.bequests.length === 1 ? 'y' : 'ies'}`;
  return (
    <>
      <ReviewRow title="Bequests:" onEdit={onEdit} />
      <StyledBequestTextWrapper>
        {state.allBequests.bequests.map((bequest, index) => {
          return (
            <StyledReviewText key={index} data-testid="allocation-text">
              <StyledBequestSpan>{`${bequest.allocation}%`}</StyledBequestSpan>

              {bequest.relationDefinition.isOrganization
                ? `${bequest.organizationName}`
                : `${bequest.firstName} ${bequest.lastName}`}
            </StyledReviewText>
          );
        })}
      </StyledBequestTextWrapper>
    </>
  );
};

export default BequestSummary;
