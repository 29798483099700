import styled, { css } from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { button } from '@/components';
import { Breakpoints } from '@/Utils/breakpoints';

export const primaryColors = css`
  color: ${Palette.WHITE};
  border-color: ${Palette.SLATE_GREEN};
  background-color: ${Palette.SLATE_GREEN};
`;

const secondaryColors = css`
  color: ${Palette.NEW_BLUE};
  border-color: ${Palette.DRIED_LAVENDER};
  background-color: ${Palette.PRIMARY_WHITE};
`;

export const PrimaryButton = styled.button`
  ${button}
  ${primaryColors}
`;

export const SecondaryButton = styled.button`
  ${button}
  ${secondaryColors}
  min-width: 370px;

  @media (min-width: ${Breakpoints.TABLET_MIN_WIDTH}) {
    max-width: 40%;
  }
`;
