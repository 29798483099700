import { axiosClient } from '@/lib/axios';
import { IUserAddress } from '@/Types';

export const getUserAddress = async () => {
  const response = await axiosClient.get('/address');

  return response.data;
};

export const putUserAddress = async (address: IUserAddress) => {
  const response = await axiosClient.put('/address', address);

  return response.data;
};

export const putWillsUserAddress = async (willsUserAddress: IUserAddress) => {
  const response = await axiosClient.put('/wills/address', willsUserAddress);

  return response.data;
};

export const postUserAddress = async (address: IUserAddress) => {
  const response = await axiosClient.post('/address', address);

  return response.data;
};

export const postWillsUserAddress = async (willsUserAddress: IUserAddress) => {
  const response = await axiosClient.post('/wills/address', willsUserAddress);

  return response.data;
};
