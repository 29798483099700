import React, { useContext } from 'react';
import { Routes } from 'react-router-dom';

import { AuthContext } from '@/providers';

import { privateRoutes } from './private';
import { publicRoutes } from './public';

const AppRoutes: React.FC = () => {
  const { loggedIn } = useContext(AuthContext);

  const routes = loggedIn ? privateRoutes : publicRoutes;

  return <Routes>{routes}</Routes>;
};

export default AppRoutes;
