import { ApiResponse, axiosClient, toApiResponse } from '@/lib/axios';
import { ISingleAsset } from './types';

export type CreateWillsAssetInput = {
  id?: number | undefined;
  type: string;
  title: string;
  description: string;
  ownedPercentage: number;
  valueInDollars: number;
  assetListId: number;
};

export const createWillsAssets = async (asset: CreateWillsAssetInput): Promise<ApiResponse<ISingleAsset>> => {
  const response = await toApiResponse<ISingleAsset>(axiosClient.post('/wills/assets', asset));

  return {
    ...response,
  };
};
