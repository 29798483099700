import React, { useContext } from 'react';
import {
  StyledDarkButton,
  StyledPaymentParagraph,
  StyledSecondaryButton,
  StyledSmallHeader,
} from '@/lib/wills/components/styles';
import {
  StyledSubscriptionCard,
  StyledSubscriptionCardHeader,
  StyledSubscriptionCardPriceContainer,
  StyledSubscriptionCardTopContainer,
  StyledSubscriptionContent,
  StyledSubscriptionGreenText,
  StyledSubscriptionPageBigNumber,
  StyledSubscriptionPageContainer,
  StyledSubscriptionParagraph,
  StyledToggleButtonContainer,
  StyleSubscriptiondHeader,
} from './Subscription.styles';
import { ToggleButton } from '@/Shared/Components/ToggleButton';
import { SubscriptionItems } from '../SubscriptionItems';
import { WillsSelectPlanContext } from '../../WillsSelectPlan.context';

const Subscription = () => {
  const { subscribed, setSubscribed, goToSummaryPage, goToPlansPage } = useContext(WillsSelectPlanContext);

  return (
    <StyledSubscriptionPageContainer>
      <StyledSubscriptionContent>
        <StyledSmallHeader>SELECT A PLAN</StyledSmallHeader>
        <StyleSubscriptiondHeader center>
          Make sure you can update your documents as life changes
        </StyleSubscriptiondHeader>

        <StyledSubscriptionCard>
          <StyledSubscriptionCardTopContainer>
            <StyledSubscriptionCardHeader>Manzil Wills Membership</StyledSubscriptionCardHeader>

            <StyledSubscriptionParagraph center>
              Free for 4 months, then only $20/yr per adult.
            </StyledSubscriptionParagraph>

            <StyledSubscriptionCardPriceContainer>
              <StyledSubscriptionPageBigNumber>$20</StyledSubscriptionPageBigNumber>
              <StyledPaymentParagraph bold>per year (after 120 days)</StyledPaymentParagraph>
            </StyledSubscriptionCardPriceContainer>

            <StyledToggleButtonContainer>
              <ToggleButton on={subscribed} onToggle={() => setSubscribed(!subscribed)} />
              <StyledSubscriptionGreenText>Opt-In</StyledSubscriptionGreenText>
            </StyledToggleButtonContainer>
          </StyledSubscriptionCardTopContainer>

          <SubscriptionItems subscribed={subscribed} />

          <StyledDarkButton fullWidth onClick={goToSummaryPage}>
            Continue
          </StyledDarkButton>
        </StyledSubscriptionCard>

        <StyledSecondaryButton onClick={goToPlansPage}>Back</StyledSecondaryButton>
      </StyledSubscriptionContent>
    </StyledSubscriptionPageContainer>
  );
};

export default Subscription;
