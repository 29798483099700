import { axiosClient, toApiResponse } from '@/lib/axios';
import { Relationship } from './types';

export interface CreateRelationshipInput {
  relationDefinitionId: number;
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  phone?: string;
}

export interface UpdateRelationshipInput extends CreateRelationshipInput {
  id: number;
}

export function upsertRelationships(relativeInfo: (CreateRelationshipInput | UpdateRelationshipInput)[]) {
  return toApiResponse<Relationship[]>(axiosClient.post('/wills-relationships', relativeInfo));
}
