import React from 'react';
import { QuestionInfo } from '@/lib/wills/components/QuestionInfo';

const RelativeQuestionInfo: React.FC = () => {
  return (
    <QuestionInfo
      question="An Important Note"
      answer={
        <div>
          These relatives may or may not inherit based on the collective information you enter.
          <br />
          <br />
          Enter the name of your family who are:
          <br />
          a) Alive
          <br />
          b) Muslim
          <br />
          <br />
          For any of your non-Muslim relatives who you would still like to be an heir, you can still list them in the
          Bequests section.
        </div>
      }
    />
  );
};

export default RelativeQuestionInfo;
