import styled, { css } from 'styled-components';
import { MdDeleteOutline, MdOutlineModeEdit } from 'react-icons/md';

import { Palette } from '@/Shared/Constants';
import { subSectionBody, subSectionGap, subSectionTitle } from '@/components';

export const Card = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #f4f5f6;
  padding: 20px;
  border-radius: 10px;
  min-width: 340px;
  ${subSectionGap}
`;

export const Title = styled.h3`
  flex: 1;
  ${subSectionTitle}
  color: ${Palette.NEW_BLUE};
`;

export const DataCardText = styled.p`
  ${subSectionBody}
  color: ${Palette.PLANET_BLUE};
`;

export const ClickableIcon = styled.div<{ hidden?: boolean; disabled?: boolean }>`
  ${({ hidden }) =>
    hidden
      ? css`
          visibility: hidden;
        `
      : null}
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  cursor: pointer;

  :hover {
    filter: brightness(0);
  }

  ${({ disabled }) =>
    disabled
      ? css`
          cursor: not-allowed;
        `
      : null}
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 8px;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
`;

export const EditIcon = styled(MdOutlineModeEdit)`
  font-size: 24px;
  line-height: 1;
  color: ${Palette.SMOKY_GREY};
`;

export const DeleteIcon = styled(MdDeleteOutline)`
  font-size: 24px;
  line-height: 1;
  color: ${Palette.SMOKY_GREY};
`;
