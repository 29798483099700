import styled from 'styled-components';
import { GrFormClose } from 'react-icons/gr';

import { Palette } from '../../Constants';

export const StyledModalBackground = styled.div`
  height: 100dvh;
  width: 100dvw;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 1rem;
`;

export const StyledModalContainer = styled.div<{ width?: string; transparent?: boolean }>`
  background-color: ${({ transparent }) => (transparent ? 'transparent' : Palette.AQUA_HAZE)};
  min-height: 10rem;
  min-width: 10rem;
  max-width: 40rem;
  ${({ width }) => width && `width: ${width}`};
  border-radius: 1rem;
  padding: 2rem;
  position: relative;
`;

export const CloseIcon = styled(GrFormClose)`
  font-size: 1.5rem;
  color: ${Palette.GUN_POWDER};
  position: absolute;
  right: 1rem;
  top: 1rem;
  cursor: pointer;
`;
