import React from 'react';
import { Button } from './BigButton.styles';

interface IProps {
  btnText: string;
  disabled?: boolean;
  color?: string;
  onClick?: () => void;
}

const BigButton: React.FC<IProps> = ({ btnText, disabled, color, onClick }) => {
  return (
    <Button color={color} disabled={disabled} onClick={onClick}>
      {btnText}
    </Button>
  );
};

export default BigButton;
