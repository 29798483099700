import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const Card = styled.div`
  display: flex;
  flex-direction: row;
  border-radius: 8px;
  border: 1px solid ${Palette.GOLD_CRAYOLA};
  background-color: ${Palette.LIGHT_GOLD_CRAYOLA};
  padding: 10px 20px 10px 10px;
  gap: 10px;
`;

export const CardBody = styled.p`
  font-family: 'SofiaPro-Regular';
  font-weight: 400;
  font-size: 18px;
  line-height: 25.2px;
  color: ${Palette.PLANET_BLUE};
`;

export const CardIcon = styled.img`
  margin-top: 1.2px; // match body line height
  height: 24px;
  width: 24px;
`;
