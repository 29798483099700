import styled from 'styled-components';
import { Palette } from '@/Shared/Constants/Palette';

export const StyledSubscriptionPageContainer = styled.div`
  background-color: ${Palette.AQUA_HAZE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
`;

export const StyledSubscriptionContent = styled.div`
  width: min(30rem, 40%);
  margin-top: 4rem;
  padding-bottom: 4rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (max-width: 1024px) {
    width: 50%;
  }

  @media (max-width: 720px) {
    width: 85%;
  }
`;

export const StyledSubscriptionCard = styled.div`
  margin: 2rem 0;
  background-color: ${Palette.WHITE};
  padding: 2rem;
  border-radius: 1rem;
  width: min(100%, 23rem);
`;

export const StyledSubscriptionCardTopContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
`;

export const StyledSubscriptionCardHeader = styled.h3`
  color: ${Palette.NEW_BLUE};
  font-family: 'SofiaPro-Regular';
  text-align: center;
`;

export const StyleSubscriptiondHeader = styled.h2<{ center?: boolean }>`
  font-family: 'SofiaPro-Regular';
  color: ${Palette.NEW_BLUE};
  font-size: clamp(1.875rem, 2vw, 2.25rem);
  text-align: ${({ center }) => center && 'center'};
  margin-top: 1rem;
`;

export const StyledSubscriptionParagraph = styled.p<{ center?: boolean }>`
  color: ${Palette.CADET_BLUE_DARKER};
  margin-bottom: 0.3rem;
  font-family: 'SofiaPro-Regular';
  font-size: clamp(0.9rem, 1vw, 1rem);

  ${({ center }) =>
    center &&
    `
    text-align: center;
  `}
`;

export const StyledSubscriptionCardPriceContainer = styled.div`
  display: flex;
  align-items: end;
  gap: 0.5rem;
`;

export const StyledSubscriptionPageBigNumber = styled.h1`
  color: ${Palette.NEW_BLUE};
  font-family: 'SofiaPro-Bold';
  font-size: 3rem;
  transform: translateY(0.5rem);
`;

export const StyledToggleButtonContainer = styled.div`
  background-color: ${Palette.SLATE_GREEN_LIGHT};
  border-radius: 1rem;
  width: 80%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  gap: 0.5rem;
`;

export const StyledSubscriptionGreenText = styled.p`
  color: ${Palette.SLATE_GREEN};
  font-family: 'SofiaPro-Regular';
  font-size: clamp(0.9rem, 1vw, 1rem);
`;
