import styled from 'styled-components';
import { BsX } from 'react-icons/bs';
import { Palette } from '@/Shared/Constants';

export const StyledSnackBarContainer = styled.div`
  padding: 1.8rem 3.5rem;
  box-sizing: border-box;
  background-color: ${Palette.INDEPENDENCE_BLUE};
  border-radius: 0.5rem;
  font-family: 'SofiaPro-Regular';
  font-size: 1.2rem;
  position: relative;
`;

export const StyledSnackBarMessage = styled.p`
  color: ${Palette.WHITE};
`;

export const StyledCross = styled(BsX)`
  pointer-events: all;
  z-index: 999999;
  color: ${Palette.WHITE};
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  right: 1rem;
  font-weight: bold;
  top: 0.5rem;
`;
