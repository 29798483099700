import React, { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { LocaleContext, SnackBarContext } from '@/providers';
import { AddressStore, ContactStore } from '@/Stores';

import {
  BackToProfileContainer,
  ContentHeading,
  EditIcon,
  EditIconContainer,
  Form,
  FormContent,
  GoBackButton,
  ProfileContainer,
  ProfileForm,
} from '../../Profile.style';
import { ContactFormField } from '../ContactFormField';
import { SubmitButton } from '../SubmitButton';
import { splitCountryCodePhoneNumber } from '../../ProfileHelpers';
import contactInfoLocales from './contactInformationFormLocales.json';
import { validatePhone } from './ContactInformationForm.helpers';
import { IContactInformationFormProps, IEditContact } from './ContactInformationForm.types';
import { AddressFormFields } from '../AddressFormFields';
import { EDataTestId } from '@/test-utils/EDataTestId';

const ContactInformationForm = ({ isEditMode, setIsEditMode }: IContactInformationFormProps) => {
  const {
    register,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm();

  const { locale } = useContext(LocaleContext);
  const { setSnackMessage } = useContext(SnackBarContext);
  const { contact, createContact } = useContext(ContactStore);
  const { address, editAddress } = useContext(AddressStore);

  const newContact = splitCountryCodePhoneNumber(contact!);

  const [contactInfo, setContactInfo] = useState<IEditContact>({ ...newContact });
  const [addressInfo, setAddressInfo] = useState<IEditContact>({ ...address });
  const [isSubmitting, setIsSubmitting] = useState(false);

  const contactInfoFormLocale = contactInfoLocales[locale];

  const onSubmit: SubmitHandler<IEditContact> = async (data) => {
    if (isSubmitting) return;

    setIsSubmitting(true);

    const newContactData = {
      cellPhone: `${data.cellPhoneCountryCode}${data.cellPhone}`,
      primaryPhone: `${data.cellPhoneCountryCode}${data.cellPhone}`,
    };

    const newAddress = {
      city: `${data.city}`,
      country: `${data.country}`,
      provinceState: `${data.provinceState}`,
      postalZip: `${data.postalZip}`,
      streetName: `${data.streetName}`,
      streetNumber: `${data.streetNumber}`,
      unitSuiteApt: `${data.unitSuiteApt}`,
    };

    try {
      validatePhone(data.cellPhoneCountryCode as string, data.cellPhone as string, locale);
    } catch (validationError: any) {
      setError('cellPhoneCountryCode', { type: 'custom', message: validationError.message });
      setIsSubmitting(false);
      return;
    }

    try {
      await createContact(newContactData);
      await editAddress(newAddress);
      setIsEditMode(false);
      setSnackMessage(contactInfoFormLocale['message.form.success']);
    } catch (error: any) {
      setSnackMessage(error.message);
    }
  };

  return (
    <ProfileContainer data-testid={EDataTestId.USER_CONTACT_FORM}>
      <ProfileForm>
        <FormContent>
          <ContentHeading>Contact Information</ContentHeading>
          <ContentHeading>
            <BackToProfileContainer show={isEditMode}>
              <GoBackButton onClick={() => setIsEditMode(false)} title="exit" aria-label="exit" />
            </BackToProfileContainer>
            <EditIconContainer show={isEditMode}>
              <EditIcon onClick={() => setIsEditMode(true)} title="edit" aria-label="edit" />
            </EditIconContainer>
          </ContentHeading>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <ContactFormField
              register={register}
              errors={errors}
              contactInfo={contactInfo}
              setContactInfo={setContactInfo}
            />
            <AddressFormFields
              register={register}
              errors={errors}
              addressInfo={addressInfo}
              setAddressInfo={setAddressInfo}
            />
            <SubmitButton onClick={handleSubmit(onSubmit)} disabled={isSubmitting} />
          </Form>
        </FormContent>
      </ProfileForm>
    </ProfileContainer>
  );
};

export default ContactInformationForm;
