import { IContactUsFormData } from './Form.types';

export function createZendeskTicketFormBody(data: IContactUsFormData) {
  const body = {
    request: {
      subject: data.subject,
      custom_fields: [
        {
          id: '18228416776859',
          value: 'wills',
        },
      ],
      comment: {
        body: data.message,
      },
      requester: {
        name: `${data.firstName} ${data.lastName}`,
        email: data.email,
      },
      ticket_form_id: 18228416776859,
    },
  };

  return body;
}
