import React from 'react';
import { QuestionInfo } from '@/lib/wills/components/QuestionInfo';

const MadhabQuestionInfo = () => {
  return (
    <QuestionInfo
      question="An Important Note"
      answer={
        <div>
          Not every Heir situation is listed within the Quran and Sunnah. In these instances, the Scholars interpret the
          estate division to the best of their ability which leads to different perspectives in some instances. However,
          majority of Scholars are in agreement with each other about most Heir situations.
        </div>
      }
    />
  );
};

export default MadhabQuestionInfo;
