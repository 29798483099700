import { IUserAddress, IUserContact, INewUserContact } from '@/Types';

export const normalizeCountryCode = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = e.target;
  const onlyNums = value.replace(/[^\d]/g, '');
  e.target.value = `+${onlyNums}`;
};

export const getNewUserContactInfo = (contactObject: INewUserContact): IUserContact => {
  const { cellPhone, cellPhoneCountryCode, homePhone, homePhoneCountryCode, primaryPhone, primaryPhoneCountryCode } =
    contactObject;

  const newContactObject = {
    cellPhone: `${cellPhoneCountryCode}${cellPhone}`,
    homePhone: `${homePhoneCountryCode}${homePhone}`,
    primaryPhone: `${primaryPhoneCountryCode}${primaryPhone}`,
  };
  return newContactObject;
};

export const normalizeContact = (e: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = e.target;
  const onlyNums = value.replace(/[^\d]/g, '');
  if (onlyNums.length <= 3) {
    e.target.value = onlyNums;
    return;
  }
  if (onlyNums.length <= 7) {
    e.target.value = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3)}`;
    return;
  }
  e.target.value = `(${onlyNums.slice(0, 3)}) ${onlyNums.slice(3, 6)}-${onlyNums.slice(6, 10)}`;
};

export const isEmpty = (value: string) => {
  return value.trim() === '';
};

export const isValidAddress = (userAddressInfo: IUserAddress): boolean => {
  const { streetName, streetNumber, city, provinceState, country, postalZip } = userAddressInfo;

  if (
    isEmpty(streetName) ||
    isEmpty(streetNumber) ||
    isEmpty(city) ||
    isEmpty(provinceState) ||
    isEmpty(country) ||
    isEmpty(postalZip)
  ) {
    return false;
  }
  return true;
};

export const splitCountryCodePhoneNumber = (contactObj: IUserContact | null): INewUserContact => {
  let newContactObj = {
    cellPhone: '',
    cellPhoneCountryCode: '',
    homePhone: '',
    homePhoneCountryCode: '',
    primaryPhone: '',
    primaryPhoneCountryCode: '',
  };
  if (!contactObj) return newContactObj;
  const { cellPhone, primaryPhone } = contactObj;

  if (cellPhone && cellPhone.includes('(')) {
    newContactObj = {
      ...newContactObj,
      cellPhone: `(${cellPhone.split('(')[1]}`,
      cellPhoneCountryCode: cellPhone.split('(')[0],
    };
  }

  if (primaryPhone && primaryPhone.includes('(')) {
    newContactObj = {
      ...newContactObj,
      primaryPhone: `(${primaryPhone.split('(')[1]}`,
      primaryPhoneCountryCode: primaryPhone.split('(')[0],
    };
  }
  return newContactObj;
};

export const limitAddressValue = (name: string, event: React.ChangeEvent<HTMLInputElement>) => {
  const { value } = event.target;
  if (name === 'streetNumber' || name === 'unitSuiteApt' || name === 'postalZip') {
    event.target.value = value.slice(0, 19);
  } else {
    event.target.value = value.slice(0, 60);
  }
};
