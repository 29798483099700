import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

import { LocaleContext } from '@/providers';

import { ProfileContainer, ProfileForm, Heading, Subheading } from './Profile.style';
import {
  BackButton,
  ContactDisplay,
  ContactInformationForm,
  PaymentDisplay,
  PaymentForm,
  ProfileDisplay,
  ProfileInformationForm,
  SecurityDisplay,
  SecurityForm,
} from './Components';
import profileLocales from './profileLocales.json';

const Profile = () => {
  const { locale } = useContext(LocaleContext);

  const [isProfileEditMode, setIsProfileEditMode] = useState(false);
  const [isContactEditMode, setIsContactEditMode] = useState(false);
  const [isSecurityEditMode, setIsSecurityEditMode] = useState(false);
  const [isPaymentEditMode, setIsPaymentMode] = useState(false);

  const navigate = useNavigate();

  const profileLocale = profileLocales[locale];

  return (
    <>
      <ProfileContainer>
        <ProfileForm>
          <BackButton goBack={() => navigate(-1)} />
          <Heading>{profileLocale.header}</Heading>
          <Subheading>{profileLocale.subheader}</Subheading>
          {!isProfileEditMode ? (
            <ProfileDisplay isEditMode={isProfileEditMode} setIsEditMode={setIsProfileEditMode} />
          ) : (
            <ProfileInformationForm isEditMode={isProfileEditMode} setIsEditMode={setIsProfileEditMode} />
          )}
        </ProfileForm>
      </ProfileContainer>

      {!isContactEditMode ? (
        <ContactDisplay isEditMode={isContactEditMode} setIsEditMode={setIsContactEditMode} />
      ) : (
        <ContactInformationForm isEditMode={isContactEditMode} setIsEditMode={setIsContactEditMode} />
      )}

      {!isSecurityEditMode ? (
        <SecurityDisplay isEditMode={isSecurityEditMode} setIsEditMode={setIsSecurityEditMode} />
      ) : (
        <SecurityForm isEditMode={isSecurityEditMode} setIsEditMode={setIsSecurityEditMode} />
      )}

      {!isPaymentEditMode ? (
        <PaymentDisplay isEditMode={isPaymentEditMode} setIsEditMode={setIsPaymentMode} />
      ) : (
        <PaymentForm isEditMode={isPaymentEditMode} setIsEditMode={setIsPaymentMode} />
      )}
    </>
  );
};

export default observer(Profile);
