export const PLANS = [
  {
    type: 'Premium',
    price: '$149',
    info: 'Create your legal, Islamic will and power of attorney documents, plan for your assets and funeral wishes.',
    featuresTitle: 'WITH Power Of Attorney, YOU WILL BE ABLE TO:',
    features: [
      'Plan for medical emergencies should you become incapacitated ',
      'Name your power of attorney to manage your finances and property',
      'Name your power of attorney to handle healthcare decisions (living will)',
    ],
  },
  {
    type: 'Essentials',
    price: '$99',
    info: 'Create a legal, Islamic will and notify key contacts named in your will.',
    featuresTitle: 'WITHOUT Power Of Attorney, YOU WILL NOT BE ABLE TO:',
    features: [
      'Plan for medical emergencies should you become incapacitated',
      'Name your power of attorney to manage your finances and property',
      'Name your power of attorney to handle healthcare decisions (living will)',
    ],
  },
];
