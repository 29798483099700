import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const Content = styled.div`
  max-width: 60%;
  z-index: 1;
  opacity: 1;
  padding: 0.5rem;
  border: 2px solid gray;
  border-radius: 5px;
  background-color: ${Palette.NEW_WHITE};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-shadow: 5px 5px 2px lightgray;
`;
