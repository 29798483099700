import React from 'react';
import { useForm } from 'react-hook-form';
import { BigButton, Header, SubHeader } from '@/Shared/Components';

import { AuthLink } from '../../../components/AuthLink';
import { Input } from '../../../components/Input';
import { Paragraph } from '../../../components/Paragraph';
import { StyledForgotPasswordLink, StyledLoginContainer, StyledLoginForm } from '../Login.styles';
import { FormMessage } from '../../../components/FormMessage';

export type OtpFormValues = {
  otp: string;
};

export interface OtpFormProps {
  title: string;
  onSubmit: (data: OtpFormValues) => Promise<void>;
  localeText: Record<string, string>;
}

export const OtpForm: React.FC<OtpFormProps> = ({ title, onSubmit, localeText }) => {
  const [message, setMessage] = React.useState<string | null>(null);
  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<OtpFormValues>({
    mode: 'onChange',
  });

  const onSubmitHandler = handleSubmit(async (data) => {
    try {
      await onSubmit(data);
    } catch (e) {
      setMessage(String(e));
    }
  });

  const { ref: otpRef, onChange: onOtpChange, onBlur: onOtpBlur } = register('otp');

  return (
    <StyledLoginContainer>
      <Header alignCenter>{localeText.header}</Header>
      <SubHeader alignCenter>{localeText.subheader}</SubHeader>
      <FormMessage>{message || title}</FormMessage>
      <StyledLoginForm onSubmit={onSubmitHandler}>
        <Input
          type="text"
          name="otp"
          placeholder="Enter OTP"
          inputRef={otpRef}
          onChange={onOtpChange}
          onBlur={onOtpBlur}
        />
        <StyledForgotPasswordLink>
          <AuthLink to="/forgot-password">{localeText['link.forgotPassword']}</AuthLink>
        </StyledForgotPasswordLink>
        <BigButton disabled={isSubmitting || !isValid} btnText={localeText['button.login']} />
      </StyledLoginForm>
      <Paragraph>
        {localeText['question.no-account']}
        &nbsp;
        <AuthLink bold to="/signup">
          {localeText['link.signup']}
        </AuthLink>
      </Paragraph>
    </StyledLoginContainer>
  );
};
