import { axiosClient, toApiResponse } from '@/lib/axios';

export interface SignupInput {
  email: string;
  password: string;
  urlParams?: {
    redirectUrl?: string;
    tag?: string;
  };
}

export type SignupDto =
  | {
      UserSub: string;
      Message: never;
    }
  | {
      UserSub: never;
      Message: string;
    };

export const signup = async (input: SignupInput) => {
  return toApiResponse<SignupDto>(axiosClient.post('/signup', input));
};
