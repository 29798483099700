import React from 'react';
import { SubHeaderText } from './Subheader.styles';

interface IProps {
  alignCenter?: boolean;
  children?: React.ReactNode;
  mb?: number;
}

const Subheader: React.FC<IProps> = ({ children, alignCenter, mb }) => {
  return (
    <SubHeaderText alignCenter={alignCenter} mb={mb}>
      {children}
    </SubHeaderText>
  );
};

export default Subheader;
