import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledSignupContainer = styled.div`
  padding: 3rem 5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${Palette.NEW_BLUE};

  @media (max-width: 600px) {
    padding: 3rem 1.5rem;
  }
`;

export const StyledSignupForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledButtonContainer = styled.div`
  margin-top: 2rem;
`;

export const StyledErrorMessage = styled.span`
  color: red;
  font-family: 'SofiaPro-Regular';
  margin-bottom: 1rem;
`;
