import React, { useContext } from 'react';
import { StyledButton, StyledButtonContainer, WillsProfileContext, EAboutYouSteps } from '@wills/components';
import * as amplitude from '@amplitude/analytics-browser';

import { AuthContext } from '@/providers';
import { AddressStore, ProfileStore } from '@/Stores';
import {
  StyledFormSummaryContentWrapper,
  StyledFormSummaryHeader,
  StyledQuestion,
  StyledAnswer,
  StyledFormSummaryRowWrapper,
  StyledEditIcon,
  StyledCompletedTag,
} from './FormSummary.styles';
import { FormHeader, AboutYouContainerWithContent } from '../../shared';
import { completeWillsOnboarding } from './FormSummaryApi';
import { updateOnboardingToFalse } from './updateOnboardingToFalse';

interface IFormSummary {
  goBack: (step?: EAboutYouSteps) => void;
  onComplete: () => void;
  setIsEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

const FormSummary: React.FC<IFormSummary> = ({ goBack, setIsEdit, onComplete }) => {
  const { willsProfileData, profileData, addressData, goToStep } = useContext(WillsProfileContext);
  const { setUser } = useContext(AuthContext);
  const { loadProfile } = useContext(ProfileStore);
  const { loadAddress } = useContext(AddressStore);

  const methodOfCalculation = willsProfileData.methodOfCalculation?.toLowerCase();
  const sex = willsProfileData.sex === 'M' ? 'Male' : 'Female';

  const editField = (step: EAboutYouSteps) => {
    setIsEdit(true);
    goToStep(step);
  };

  const updateWillsOnboardingToTrue = async () => {
    await updateOnboardingToFalse();
    await completeWillsOnboarding();
    await loadProfile();
    await loadAddress();
    setUser((currentUser) => ({
      ...currentUser!,
      isOnboarding: false,
      profile: [profileData!],
      address: [addressData!],
    }));
    amplitude.track('Wills About You Flow Complete');
    onComplete();
  };

  return (
    <AboutYouContainerWithContent>
      <FormHeader subHeader="ABOUT YOU" header="Great! Review your answers" />
      <StyledFormSummaryContentWrapper>
        <StyledFormSummaryRowWrapper>
          <StyledFormSummaryHeader>About You</StyledFormSummaryHeader>
          <StyledCompletedTag>Completed</StyledCompletedTag>
        </StyledFormSummaryRowWrapper>

        <StyledFormSummaryRowWrapper marginTop borderBottom>
          <div>
            <StyledQuestion>Full Legal Name : </StyledQuestion>
            <StyledAnswer>
              {profileData.firstName} {profileData.middleName} {profileData.lastName}
            </StyledAnswer>
          </div>
          <div>
            <StyledEditIcon onClick={() => editField(EAboutYouSteps.LEGAL_NAME_QUESTION)} />
          </div>
        </StyledFormSummaryRowWrapper>

        <StyledFormSummaryRowWrapper marginTop borderBottom>
          <div>
            <StyledQuestion>Date of Birth : </StyledQuestion>
            <StyledAnswer>{profileData.dob}</StyledAnswer>
          </div>
          <div>
            <StyledEditIcon onClick={() => editField(EAboutYouSteps.DOB_QUESTION)} />
          </div>
        </StyledFormSummaryRowWrapper>

        <StyledFormSummaryRowWrapper marginTop borderBottom>
          <div>
            <StyledQuestion>Marital Status : </StyledQuestion>
            <StyledAnswer capitalize>{profileData.marritalStatus.toLowerCase()}</StyledAnswer>
          </div>
          <div>
            <StyledEditIcon onClick={() => editField(EAboutYouSteps.MARITAL_STATUS_QUESTION)} />
          </div>
        </StyledFormSummaryRowWrapper>

        <StyledFormSummaryRowWrapper marginTop borderBottom>
          <div>
            <StyledQuestion>Do you have kids : </StyledQuestion>
            <StyledAnswer>{willsProfileData.kids === 1 ? 'Yes' : 'No'}</StyledAnswer>
          </div>
          <div>
            <StyledEditIcon onClick={() => editField(EAboutYouSteps.CHILDREN_QUESTION)} />
          </div>
        </StyledFormSummaryRowWrapper>

        <StyledFormSummaryRowWrapper marginTop borderBottom>
          <div>
            <StyledQuestion>What is your sex : </StyledQuestion>
            <StyledAnswer>{sex}</StyledAnswer>
          </div>
          <div>
            <StyledEditIcon onClick={() => editField(EAboutYouSteps.GENDER_QUESTION)} />
          </div>
        </StyledFormSummaryRowWrapper>

        <StyledFormSummaryRowWrapper marginTop borderBottom>
          <div>
            <StyledQuestion>Chosen Madh&apos;Hab/School of Thought : </StyledQuestion>
            <StyledAnswer capitalize>
              {methodOfCalculation === 'jumhour' ? `${methodOfCalculation} (majority)` : methodOfCalculation}
            </StyledAnswer>
          </div>
          <div>
            <StyledEditIcon onClick={() => editField(EAboutYouSteps.MADHAB_QUESTION)} />
          </div>
        </StyledFormSummaryRowWrapper>

        <StyledFormSummaryRowWrapper marginTop>
          <div>
            <StyledQuestion>What is your address : </StyledQuestion>
            <StyledAnswer>
              {addressData.unitSuiteApt !== '' ? `${addressData.unitSuiteApt}, ` : ''}
              {addressData.streetNumber}, {addressData.streetName !== '' ? `${addressData.streetName}, ` : ''}
              {addressData.provinceState}
            </StyledAnswer>
          </div>
          <div>
            <StyledEditIcon onClick={() => editField(EAboutYouSteps.LOCATION_QUESTION)} />
          </div>
        </StyledFormSummaryRowWrapper>
      </StyledFormSummaryContentWrapper>
      <StyledButtonContainer margin justifyBetween>
        <StyledButton bgGrey onClick={() => goBack(EAboutYouSteps.LOCATION_QUESTION)} padding>
          Previous
        </StyledButton>
        <StyledButton padding onClick={() => updateWillsOnboardingToTrue()}>
          Looks Good
        </StyledButton>
      </StyledButtonContainer>
    </AboutYouContainerWithContent>
  );
};

export default FormSummary;
