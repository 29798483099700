import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';
import { ZIndex } from '@/Utils/zIndexes';

export const Nav = styled.div`
  height: 6rem;
  background-color: ${Palette.NEW_BLUE};
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 5px ${Palette.GREY};
  padding: 0 min(3rem, 8%);
  z-index: ${ZIndex.NAVBAR};
  color: ${Palette.NEW_WHITE};

  @media screen and (max-width: 600px) {
    flex-direction: column;
    gap: 0.8rem;
    justify-content: center;
    position: sticky;
    top: 0;
    right: 0;
    left: 0;
    min-width: 100%;
  }
`;

export const NavLinkContainer = styled.div`
  display: flex;
  gap: 0 1rem;
  align-items: center;
`;

export const LogoImage = styled.img`
  height: 1.5rem;
`;

export const NavLink = styled.a`
  font-family: 'SofiaPro-Bold';
  cursor: pointer;
`;

export const StyledExternalLink = styled.a`
  font-family: 'SofiaPro-Bold';
  cursor: pointer;
  text-decoration: none;
  color: ${Palette.NEW_WHITE};
`;

export const StyledLanguageGroup = styled.div`
  display: flex;
  margin-right: 2rem;

  @media screen and (max-width: 600px) {
    margin-right: 1rem;
  }
`;

export const StyledLanguageButton = styled.button<{ active: boolean }>`
  border: 1px solid ${Palette.NEW_WHITE};
  padding: 0.4rem 2rem;
  background-color: ${({ active }) => (active ? Palette.NEW_WHITE : Palette.NEW_BLUE)};
  color: ${({ active }) => (active ? Palette.NEW_BLUE : Palette.NEW_WHITE)};
  font-family: 'SofiaPro-Bold';
  cursor: pointer;

  @media screen and (max-width: 600px) {
    padding: 0.4rem 1.5rem;
  }
`;
