import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const StyledOnboardingNoteWrapper = styled.div`
  max-width: 75%;
  height: 100%;

  @media screen and (max-width: 1024px) {
    max-width: 90%;
  }

  @media screen and (min-width: 921px) {
    display: flex;
    gap: 1rem;
    justify-content: space-between;
  }
`;

export const StyledOnboardingNoteLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  @media screen and (min-width: 921px) {
    width: 70%;
  }
`;

export const StyledOnboardingNoteRightWrapper = styled.div`
  display: none;

  @media screen and (min-width: 921px) {
    display: block;
    width: 30%;
  }
`;

export const StyledCheckboxContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledSingleCheckboxContainer = styled.div`
  display: flex;
  gap: 0.5rem;
`;

export const StyledCheckbox = styled.input`
  transform: translateY(0.07rem);
`;

export const StyledLabel = styled.label`
  font-family: 'SofiaPro-Regular';
  font-weight: 400;
  font-size: 1.125rem;
  color: ${Palette.PLANET_BLUE};
  display: flex;
  gap: 0.8rem;

  input {
    border-radius: 0.75px;
    accent-color: ${Palette.SLATE_GREEN};
  }
`;
