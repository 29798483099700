import { ONEVEST_ORG_ID, ONEVEST_PARTNER_URL } from '@/config';

export const getOneVestHtml = (accessToken: string): Promise<string> => {
  const xhr = new XMLHttpRequest();

  xhr.open('GET', ONEVEST_PARTNER_URL!);

  xhr.responseType = 'document';
  const headers = [
    ['org-id', ONEVEST_ORG_ID!],
    ['org-authorization', accessToken],
  ];
  headers.forEach((header) => {
    xhr.setRequestHeader(header[0], header[1]);
  });

  return new Promise((resolve, reject) => {
    xhr.onreadystatechange = function handler() {
      if (this.readyState === this.DONE && this.status === 200) {
        const html = this.response.documentElement.innerHTML;
        resolve(html);
      }
    };
    xhr.onerror = function errorHandler(e) {
      reject(e);
    };
    xhr.send();
  });
};
