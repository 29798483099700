import React from 'react';
import { EAboutYouSteps } from '@wills/components/WillsProfileProvider/WillsProfileProvider.types';
import { StyledButton, StyledButtonContainer } from '../../../../components/styles';
import useScreenSize from '@/Shared/Hooks/UseScreenSize/useScreenSize';

interface IFormFooter {
  previousStep?: EAboutYouSteps;
  goBack: (step?: EAboutYouSteps) => void;
  handleSubmit: Function;
  onFormSubmit: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isEdit?: boolean;
  isDisabled?: boolean;
}

const FormFooter = ({ goBack, handleSubmit, onFormSubmit, isEdit, isDisabled, previousStep }: IFormFooter) => {
  const { isMobile } = useScreenSize();

  return (
    <StyledButtonContainer margin={!isMobile}>
      <StyledButton bgGrey onClick={() => goBack(previousStep)} padding>
        Previous
      </StyledButton>
      <StyledButton onClick={handleSubmit(onFormSubmit)} padding disabled={isDisabled}>
        Continue
      </StyledButton>
      {isEdit && (
        <StyledButton id="summary-btn" onClick={onFormSubmit} padding disabled={isDisabled}>
          Go to summary
        </StyledButton>
      )}
    </StyledButtonContainer>
  );
};

export default FormFooter;
