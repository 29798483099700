import React, { useContext, useState } from 'react';

import { QRCodeSVG } from 'qrcode.react';
import { SnackBarContext } from '@/providers';
import { getErrorMessage } from '@/Utils';

import {
  StyledMfaModalContainer,
  StyledModalButton,
  StyledModalButtonGroup,
  StyledModalParagraph,
  StyledQrCodeContainer,
} from './AuthenticatorMfaModal.styles';
import { EModalStep, IAuthenticatorMfaModalProps } from './AuthenticatorMfaModal.types';
import { generateAuthenticatorKeyApi } from './AuthenticatorMfaModal.api';
import { StyledAuthCodeContainer, StyledModalInput } from '../../Profile.style';

const AuthenticatorMfaModal = ({
  onConfirmMfaBtnClick,
  onCancel,
  disabled,
  authCode,
  onAuthCodeInputChange,
}: IAuthenticatorMfaModalProps) => {
  const { setSnackMessage } = useContext(SnackBarContext);

  const [step, setStep] = useState(EModalStep.MESSAGE);
  const [authenticationKey, setAuthenticationKey] = useState<string | null>(null);
  const [isQrCodeGenerating, setIsQrCodeGenerating] = useState(false);

  async function generateQRCode() {
    if (isQrCodeGenerating) return;

    setIsQrCodeGenerating(true);

    try {
      const result = await generateAuthenticatorKeyApi();
      setAuthenticationKey(result.data.SecretCode);
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    }

    setIsQrCodeGenerating(false);
  }

  async function onMessageStepConfirmBtnClick() {
    await generateQRCode();
    setStep(EModalStep.QR_CODE);
  }

  return (
    <StyledMfaModalContainer>
      {step === EModalStep.MESSAGE && (
        <StyledModalParagraph>
          Are you sure you want to use an authenticator app for Multi-factor Authentication?
        </StyledModalParagraph>
      )}

      {step === EModalStep.QR_CODE && (
        <StyledQrCodeContainer>
          <QRCodeSVG value={authenticationKey as string} />
          <StyledModalParagraph>
            Click Next once you&rsquo;ve successfully added the Manzil to your authenticator
          </StyledModalParagraph>
        </StyledQrCodeContainer>
      )}

      {step === EModalStep.AUTH_CODE && (
        <StyledAuthCodeContainer>
          <StyledModalParagraph>Enter the code you see in your authenticator app</StyledModalParagraph>
          <StyledModalInput onChange={onAuthCodeInputChange} value={authCode || ''} />
        </StyledAuthCodeContainer>
      )}

      <StyledModalButtonGroup>
        <StyledModalButton secondary type="button" onClick={onCancel}>
          Cancel
        </StyledModalButton>
        {step === EModalStep.MESSAGE && (
          <StyledModalButton type="button" disabled={isQrCodeGenerating} onClick={onMessageStepConfirmBtnClick}>
            Yes
          </StyledModalButton>
        )}

        {step === EModalStep.QR_CODE && (
          <StyledModalButton type="button" disabled={disabled} onClick={() => setStep(EModalStep.AUTH_CODE)}>
            Next
          </StyledModalButton>
        )}

        {step === EModalStep.AUTH_CODE && (
          <StyledModalButton type="button" disabled={disabled || !authCode} onClick={onConfirmMfaBtnClick}>
            Confirm
          </StyledModalButton>
        )}
      </StyledModalButtonGroup>
    </StyledMfaModalContainer>
  );
};

export default AuthenticatorMfaModal;
