import React, { useContext, useEffect } from 'react';
import './App.css';
import { observer } from 'mobx-react-lite';
import { Layout } from '@/Shared/Layout';
import AppRoutes from '@/Routes';
import { Loading } from '@/Shared/Components/Loading';
import { ENV } from '@/config';
import { AuthContext } from '@/providers/AuthProvider';
import { TokenStore } from '@/Stores';
import {
  initializeAmplitude,
  loadGoogleTagManagerNoScript,
  loadGoogleTagManagerScript,
  loadHubspotScript,
} from './AppHelpers';

const App = () => {
  const { loadingUser } = useContext(AuthContext);
  useContext(TokenStore);

  useEffect(() => {
    if (ENV === 'production') {
      loadHubspotScript();
      loadGoogleTagManagerScript();
      loadGoogleTagManagerNoScript();
    }
    if (ENV === 'production' || ENV === 'staging') {
      initializeAmplitude();
    }
  }, []);

  return (
    <div className="App">
      <Layout>{loadingUser ? <Loading /> : <AppRoutes />}</Layout>
    </div>
  );
};

export default observer(App);
