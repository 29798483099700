import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PopulatedDocumentContext } from '@wills/pages/Documents/PopulatedDocumentContext';
import { useAssetList } from '@wills/hooks/useAssetList';
import { DataCard, DataCardText } from '@wills/components';

import { StyledAssetsInfoContainer, StyledAssetsListTitle, StyledPrimaryButton, SemiBold } from './AssetsInfo.styles';
import { ISingleAsset } from '../../AssetsList.types';

import { SecondaryButton } from '../styles';
import { bindFuncToAsync } from '@/Utils';
import { LoadingOverlay } from '@/Shared/Components';
import { AssetForm, IAssetMutableFields } from '../AssetForm';

type AssetsInfoMode =
  | {
      type: 'view';
    }
  | {
      type: 'create';
    }
  | {
      type: 'update';
      asset: ISingleAsset;
    };

const AssetsInfo = () => {
  const navigate = useNavigate();
  const [mode, setMode] = React.useState<AssetsInfoMode>({ type: 'view' });
  const { regenerateDocuments } = useContext(PopulatedDocumentContext);
  const { assetList, completeAssetList, createAsset, updateAsset, deleteAsset } = useAssetList();
  const [loading, setLoading] = useState<boolean>(false);
  const bindToLoading = bindFuncToAsync(setLoading);

  const onComplete = bindToLoading(async () => {
    await completeAssetList();
    await regenerateDocuments(['ASSETS_AND_LIABILITIES']);
    navigate('/wills/documents');
  });

  const resetMode = () => {
    setMode({ type: 'view' });
  };

  const onCreateAsset = async (data: IAssetMutableFields) => {
    await createAsset({
      assetListId: assetList.id,
      ...data,
    });
    resetMode();
  };

  const onUpdateAsset = (assetId: number) => async (data: IAssetMutableFields) => {
    await updateAsset({
      id: assetId,
      assetListId: assetList.id,
      ...data,
    });
    resetMode();
  };

  const onDeleteAsset = async (assetId: number) => bindToLoading(deleteAsset)(assetList.id, assetId);

  const visibleAssets = assetList.assets.filter((asset) => !(mode.type === 'update' && mode.asset.id === asset.id));

  return (
    <StyledAssetsInfoContainer>
      {loading && <LoadingOverlay type="fixed" />}
      <SecondaryButton disabled={mode.type !== 'view'} onClick={() => setMode({ type: 'create' })}>
        Add an asset +
      </SecondaryButton>
      {mode.type === 'create' && <AssetForm key="create-asset" onCancel={resetMode} onSubmit={onCreateAsset} />}

      {mode.type === 'update' && (
        <AssetForm
          key="update-asset"
          defaultValues={mode.asset}
          onCancel={resetMode}
          onSubmit={onUpdateAsset(mode.asset.id)}
        />
      )}

      {visibleAssets.length > 0 && (
        <>
          <StyledAssetsListTitle>My Assets</StyledAssetsListTitle>
          {visibleAssets.map((asset) => {
            return (
              <DataCard
                title={asset.title}
                disabled={loading}
                hideActions={mode.type === 'update'}
                onEdit={() => setMode({ type: 'update', asset })}
                onDelete={() => {
                  onDeleteAsset(asset.id);
                }}
              >
                <DataCardText>{asset.description}</DataCardText>
                <DataCardText>
                  <SemiBold>Type:</SemiBold> {asset.type}
                </DataCardText>
                <DataCardText>
                  <SemiBold>Ownership:</SemiBold> {asset.ownedPercentage}%
                </DataCardText>
                <DataCardText>
                  <SemiBold>Value:</SemiBold> ${Number(asset.valueInDollars).toLocaleString()}
                </DataCardText>
              </DataCard>
            );
          })}
        </>
      )}
      <StyledPrimaryButton
        onClick={onComplete}
        disabled={loading || assetList?.assets.length === 0 || mode.type !== 'view'}
      >
        Update Assets List
      </StyledPrimaryButton>
    </StyledAssetsInfoContainer>
  );
};
export default AssetsInfo;
