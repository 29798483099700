import React, { useContext } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { observer } from 'mobx-react-lite';

import { manzilLogo } from '@/Assets/images';
import { AuthContext, LocaleContext } from '@/providers';
import {
  LogoImage,
  Nav,
  NavLink,
  NavLinkContainer,
  StyledExternalLink,
  StyledLanguageButton,
  StyledLanguageGroup,
} from './Navbar.styles';
import navbarLocales from './navbarLocales.json';

const Navbar = () => {
  const { locale, setLocale } = useContext(LocaleContext);
  const { loggedIn, logout, user } = useContext(AuthContext);

  const navigate = useNavigate();

  const goToProfilePage = () => {
    navigate('/profile');
  };

  const navbarLocale = navbarLocales[locale];

  return (
    <Nav>
      <Link to="/">
        <LogoImage src={manzilLogo} alt="Manzil logo" />
      </Link>
      <NavLinkContainer>
        <StyledLanguageGroup>
          <StyledLanguageButton active={locale === 'EN'} onClick={() => setLocale('EN')}>
            EN
          </StyledLanguageButton>
          <StyledLanguageButton active={locale === 'FR'} onClick={() => setLocale('FR')}>
            FR
          </StyledLanguageButton>
        </StyledLanguageGroup>
        {user?.profile[0] && <NavLink onClick={goToProfilePage}>{navbarLocale['navitem.profile']}</NavLink>}
        <StyledExternalLink href="https://manzil.zendesk.com/hc/en-us" target="_blank">
          {navbarLocale['navitem.help']}
        </StyledExternalLink>
        {loggedIn && <NavLink onClick={logout}>{navbarLocale['navitem.logout']}</NavLink>}
      </NavLinkContainer>
    </Nav>
  );
};
export default observer(Navbar);
