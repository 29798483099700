import React from 'react';
import { Subtitle, Title } from './LoadingOverlay.styles';
import { Spinner } from '../Spinner';
import { Overlay } from '../Overlay';

interface LoadingOverlayProps {
  title?: string;
  subtitle?: string;
  type?: 'fixed' | 'absolute';
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({
  title,
  subtitle,
  type = 'absolute',
}: LoadingOverlayProps) => {
  return (
    <Overlay type={type}>
      <div data-testid="loading">
        <Spinner />
        {title ? <Title>{title}</Title> : null}
        {subtitle ? <Subtitle>{subtitle}</Subtitle> : null}
      </div>
    </Overlay>
  );
};
