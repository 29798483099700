import React from 'react';
import { ApiResponse } from '@/lib/axios';
import { IRelativeInfo, RelationshipCategory } from '@/lib/wills';
import { SnackBarContext } from '@/providers';
import { getRelationships } from '../apis/relationship';
import { useApi } from '@/lib/axios/useApi';
import { getFormattedDate } from '@/Utils/dateOfBirthHelpers';

const loadRelationships = async (category: RelationshipCategory): Promise<ApiResponse<IRelativeInfo[]>> => {
  const { data, error } = await getRelationships(category);

  if (!data || error) {
    return {
      data: undefined,
      error,
    };
  }

  const transformedRelationships = data.map((transformedRelationship) => {
    const relativeInfo: IRelativeInfo = {
      id: transformedRelationship.id!,
      firstName: transformedRelationship.firstName,
      lastName: transformedRelationship.lastName,
      dob: getFormattedDate(transformedRelationship.dob),
      relationDefinitionId: transformedRelationship.willsRelationshipId,
      email: transformedRelationship.contact?.email || '',
      phone: transformedRelationship.contact?.primaryPhone || '',
    };

    return relativeInfo;
  });

  return {
    data: transformedRelationships,
    error,
  };
};

export const useRelationships = (category: RelationshipCategory, onSuccess?: (data: IRelativeInfo[]) => void) => {
  const { setSnackMessage } = React.useContext(SnackBarContext);
  const response = useApi(() => loadRelationships(category), [category], {
    onSuccess,
    onError: (err) => setSnackMessage(err.message),
  });

  return response;
};
