import { axiosClient, toApiResponse } from '@/lib/axios';
import { IWish } from './types';

export interface CreateWishInput {
  title: string;
  description: string;
}
export const createWish = (input: CreateWishInput) => {
  return toApiResponse<IWish>(axiosClient.post(`/wills/wish-list/wish`, input));
};
