import React from 'react';
import { Navigate, Route } from 'react-router-dom';

import { ForgotPassword, Signup, Login, NotVerified, Verification } from '@/lib/auth';

export const publicRoutes = [
  <Route key="public-index" index element={<Navigate to="login" replace />} />,
  <Route key="login" path="login" element={<Login />} />,
  <Route key="signup" path="signup" element={<Signup />} />,
  <Route key="forgot-password" path="forgot-password" element={<ForgotPassword />} />,
  <Route key="reset-password" path="forgot-password/:userSub" element={<ForgotPassword />} />,
  <Route key="verification" path="verify/:userSub" element={<Verification />} />,
  <Route key="not-verified" path="not-verified/:email" element={<NotVerified />} />,
  <Route key="public-fallback-route" path="*" element={<Navigate to="../login" replace />} />,
];
