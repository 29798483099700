import * as yup from 'yup';
import { ApiResponse, axiosClient, toApiResponse } from '@/lib/axios';
import { IProfile } from '@/Types';
import { snakeToCamelParser } from '@/Utils';

export type PartialUserProfile = {
  marritalStatus: IProfile['marritalStatus'];
};

export const getUserProfile = async (): Promise<ApiResponse<IProfile>> => {
  const response = await toApiResponse<IProfile>(axiosClient.get('/profile'));

  if (response.error === undefined) {
    try {
      const parsedData = snakeToCamelParser(response.data);

      return {
        data: parsedData,
        error: undefined,
      };
    } catch (e: unknown) {
      return {
        data: undefined,
        error: {
          code: 'ValidationError',
          message: yup.ValidationError.isError(e) ? e.message : String(e),
        },
      };
    }
  }
  return {
    data: undefined,
    error: response.error,
  };
};
