import styled, { css } from 'styled-components';
import { FaArrowRight } from "react-icons/fa6";
import { Palette } from '@/Shared/Constants';
import { subSectionBody } from '@/components';

export const CheckboxIcon = styled.div<{ checked?: boolean }>`
  width: 20px;
  height: 20px;
  border-radius: 50%;

  align-content: center;
  text-align: center;

  ${({ checked }) =>
    checked
      ? css`
          border: 1px solid ${Palette.SLATE_GREEN};
          background-color: ${Palette.SLATE_GREEN};
        `
      : css`
          border: 1px solid ${Palette.INPUT_BORDER_GREY};
          background-color: ${Palette.PRIMARY_WHITE};
        `}

  ::after {
    content: '\u2713'; /* Checkmark Unicode character */
    color: white;
    font-family: system-ui;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    display: ${({ checked }) => (checked ? 'block' : 'none')};
  }
`;

export const CheckboxContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 20px;
  align-items: center;
`;

export const CheckboxLabel = styled.p`
  ${subSectionBody}
  font-weight: 500;
  color: ${Palette.PLANET_BLUE};
`;

export const RightArrow = styled(FaArrowRight)`
  color: ${Palette.PLANET_BLUE};
  font-size: 0.75rem;
`;
