import React, { useState } from 'react';

import { RelativeAdderForm } from './RelativeAdderForm';
import { StyledRelativeAdderButton, StyledRelativeAdderContainer } from './RelativeAdder.styles';
import { IRelativeAdderProps } from './RelativeAdder.types';

const RelativeAdder = ({ action, btnText, relativeDefinitions }: IRelativeAdderProps) => {
  const [addMode, setAddMode] = useState(false);

  return (
    <StyledRelativeAdderContainer>
      {!addMode ? (
        <StyledRelativeAdderButton type="button" onClick={() => setAddMode(true)}>
          {btnText}
        </StyledRelativeAdderButton>
      ) : (
        <RelativeAdderForm
          action={action}
          addMode={addMode}
          setAddMode={setAddMode}
          relativeDefinitions={relativeDefinitions}
        />
      )}
    </StyledRelativeAdderContainer>
  );
};

export default RelativeAdder;
