import styled from 'styled-components';
import { BsCheck, BsFillCheckCircleFill, BsFillXCircleFill } from 'react-icons/bs';
import { Palette } from '@/Shared/Constants';

export const StyledIconContainer = styled.div`
  min-width: 1.5rem;
  height: 1.5rem;
  border-radius: 100px;
  border: 2px solid ${Palette.SLATE_GREEN};
  position: relative;
  margin-top: 0.25rem;
`;

export const StyledSinglePoint = styled.div`
  display: flex;
  gap: 1rem;

  @media screen and (max-width: 600px) {
    gap: 0.5rem;
    padding-left: 0.3rem;
  }
`;

export const StyledCompareSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  margin: 2rem 0;
`;

export const StyledTick = styled(BsCheck)`
  color: ${Palette.SLATE_GREEN};
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
`;

export const StyledCross = styled(BsFillXCircleFill)`
  color: ${Palette.LIGHT_ORANGE};
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  position: absolute;
`;

export const StyledPlanSummaryCheckIcon = styled(BsFillCheckCircleFill)`
  color: ${Palette.SLATE_GREEN};
  min-height: 1.5rem;
  min-width: 1.5rem;
  max-height: 1.5rem;
  max-width: 1.5rem;
`;

export const StyledPlanSummaryCrossIcon = styled(BsFillXCircleFill)`
  color: ${Palette.LIGHT_ORANGE};
  min-height: 1.5rem;
  min-width: 1.5rem;
  max-height: 1.5rem;
  max-width: 1.5rem;
`;
