import { IBequest } from '@wills/apis';
import { IRelativeResponse } from '@wills/pages/WillsFamilyFlow/WillsFamilyFlow.types';
import { calculateAge, dateParser, getAgeOfMajority } from '@/Utils/dateOfBirthHelpers';
import { EstateIntakeContextAction, EstateIntakeContextActionType } from './EstateIntakeContext.actions';
import { EstateIntakeContextState } from './EstateIntakeContext.state';

interface TransformedRelatives {
  directChildren: IRelativeResponse[];
  adultRelatives: IRelativeResponse[];
}
const transformRelatives = (relatives: IRelativeResponse[], ageOfMajority: number): TransformedRelatives => {
  return relatives.reduce<TransformedRelatives>(
    (acc, relative) => {
      const isUsersChild = ['son', 'daughter'].includes(relative.relationDefinition.relationship);
      if (isUsersChild) {
        const { year, month, day } = dateParser(relative.dob);
        if (calculateAge(year, month, day) <= ageOfMajority) {
          return {
            ...acc,
            directChildren: [...acc.directChildren, relative],
          };
        }
      }

      return {
        ...acc,
        adultRelatives: [...acc.adultRelatives, relative],
      };
    },
    { directChildren: [], adultRelatives: [] },
  );
};

export const estateIntakeContextReducer = (
  state: EstateIntakeContextState,
  action: EstateIntakeContextAction,
): EstateIntakeContextState => {
  const { type, payload } = action;

  switch (type) {
    case EstateIntakeContextActionType.SET_RELATIVES: {
      const ageOfMajority = getAgeOfMajority(state.address.provinceState);

      const { directChildren, adultRelatives } = transformRelatives(payload, ageOfMajority);
      return { ...state, relatives: payload, adultRelatives, directChildren, ageOfMajority };
    }

    case EstateIntakeContextActionType.SET_RELATIVE_DEFINITIONS:
      return { ...state, relativeDefinitions: payload };

    case EstateIntakeContextActionType.ADD_TO_RELATIVES:
      return { ...state, adultRelatives: [...state.adultRelatives, payload] };

    case EstateIntakeContextActionType.SET_POA_AVAILABILITY:
      return { ...state, hasPoa: payload };

    case EstateIntakeContextActionType.SET_MAIN_EXECUTOR: {
      const stateClone = { ...state };
      const relative = payload;

      if (
        stateClone.executorAndEstateTrustees.backup &&
        stateClone.executorAndEstateTrustees.backup.id === relative?.id
      ) {
        stateClone.executorAndEstateTrustees.backup = null;
      }

      stateClone.executorAndEstateTrustees.main = relative;

      return stateClone;
    }

    case EstateIntakeContextActionType.SET_BACKUP_EXECUTOR: {
      const stateClone = { ...state };
      const relative = payload;

      if (stateClone.executorAndEstateTrustees.main && stateClone.executorAndEstateTrustees.main.id === relative?.id) {
        stateClone.executorAndEstateTrustees.main = null;
      }

      stateClone.executorAndEstateTrustees.backup = relative;

      return stateClone;
    }

    case EstateIntakeContextActionType.SET_SPOUSE_EXECUTOR:
      return {
        ...state,
        executorAndEstateTrustees: {
          ...state.executorAndEstateTrustees,
          isSpouseSelected: payload,
        },
      };

    case EstateIntakeContextActionType.SET_MAIN_POA_PROPERTY: {
      const stateClone = { ...state };
      const relative = payload;

      if (stateClone.poaProperty.backup && stateClone.poaProperty.backup.id === relative?.id) {
        stateClone.poaProperty.backup = null;
      }

      stateClone.poaProperty.main = relative;

      return stateClone;
    }

    case EstateIntakeContextActionType.SET_BACKUP_POA_PROPERTY: {
      const stateClone = { ...state };
      const relative = payload;

      if (stateClone.poaProperty.main && stateClone.poaProperty.main.id === relative?.id) {
        stateClone.poaProperty.main = null;
      }

      stateClone.poaProperty.backup = relative;

      return stateClone;
    }

    case EstateIntakeContextActionType.SET_SPOUSE_POA_PROPERTY:
      return {
        ...state,
        poaProperty: {
          ...state.poaProperty,
          isSpouseSelected: payload,
        },
      };

    case EstateIntakeContextActionType.SET_MAIN_POA_PERSONAL_CARE: {
      const stateClone = { ...state };
      const relative = payload;

      if (stateClone.poaPersonalCare.backup && stateClone.poaPersonalCare.backup.id === relative?.id) {
        stateClone.poaPersonalCare.backup = null;
      }

      stateClone.poaPersonalCare.main = relative;

      return stateClone;
    }

    case EstateIntakeContextActionType.SET_BACKUP_POA_PERSONAL_CARE: {
      const stateClone = { ...state };
      const relative = payload;

      if (stateClone.poaPersonalCare.main && stateClone.poaPersonalCare.main.id === relative?.id) {
        stateClone.poaPersonalCare.main = null;
      }

      stateClone.poaPersonalCare.backup = relative;

      return stateClone;
    }

    case EstateIntakeContextActionType.SET_SPOUSE_POA_PERSONAL_CARE:
      return {
        ...state,
        poaPersonalCare: {
          ...state.poaPersonalCare,
          isSpouseSelected: payload,
        },
      };

    case EstateIntakeContextActionType.SET_MAIN_GUARDIAN: {
      const stateClone = { ...state };
      const relative = payload;

      if (stateClone.childGuardians.backup && stateClone.childGuardians.backup.id === relative?.id) {
        stateClone.childGuardians.backup = null;
      }

      stateClone.childGuardians.main = payload;
      return stateClone;
    }

    case EstateIntakeContextActionType.SET_BACKUP_GUARDIAN: {
      const stateClone = { ...state };
      const relative = payload;

      if (stateClone.childGuardians.main && stateClone.childGuardians.main.id === relative?.id) {
        stateClone.childGuardians.main = null;
      }

      stateClone.childGuardians.backup = payload;
      return stateClone;
    }

    case EstateIntakeContextActionType.SET_ALL_BEQUESTS: {
      const stateClone = { ...state };
      const { totalBequestAllocation, bequests } = payload;
      stateClone.allBequests.totalBequestAllocation = totalBequestAllocation;
      stateClone.allBequests.bequests = bequests;
      return stateClone;
    }

    case EstateIntakeContextActionType.ADD_TO_BEQUESTS: {
      const stateClone = { ...state };
      const { totalBequestAllocation, bequest } = payload;
      stateClone.allBequests = {
        totalBequestAllocation,
        bequests: [...stateClone.allBequests.bequests, bequest as IBequest],
      };
      return stateClone;
    }

    case EstateIntakeContextActionType.SET_ENABLE_EDITING:
      return { ...state, enableEdit: payload };

    case EstateIntakeContextActionType.SET_WILLS_PROFILE:
      return {
        ...state,
        willsProfile: payload,
      };
    case EstateIntakeContextActionType.SET_PROFILE:
      return {
        ...state,
        profile: payload,
      };
    case EstateIntakeContextActionType.SET_ADDRESS: {
      const ageOfMajority = getAgeOfMajority(payload.provinceState);
      const { directChildren, adultRelatives } = transformRelatives(state.relatives, ageOfMajority);
      return {
        ...state,
        address: payload,
        directChildren,
        adultRelatives,
        ageOfMajority,
      };
    }
    default:
      throw new Error('Something went wrong');
  }
};
