export enum Breakpoints {
  SM_MOBILE_MIN_WIDTH = '0px',
  SM_MOBILE_MAX_WIDTH = '600px',
  MOBILE_MIN_WIDTH = '601px',
  MOBILE_MAX_WIDTH = '920px',
  TABLET_MIN_WIDTH = '921px',
  TABLET_MAX_WIDTH = '1023px',
  DESKTOP_MIN_WIDTH = '1024px',
  DESKTOP_MAX_WIDTH = '1365px',
}
