import { observer } from 'mobx-react-lite';
import React, { createContext, useContext, useEffect, useState } from 'react';
import { IWillsProfile } from '@wills/types';
import { axiosClient } from '@/lib/axios';
import { AddressStore, ProfileStore } from '@/Stores';
import { IProfile, IUserAddress } from '@/Types';
import {
  postUserAddress,
  postUserProfile,
  postWillsProfile,
  postWillsUserAddress,
  putUserAddress,
  putWillsUserAddress,
  putWillsUserProfile,
} from '@/Api';
import { EAboutYouSteps, IWillsProfileContextInterface, IProps } from './WillsProfileProvider.types';
import { getWillsProfile } from '../../apis';
import { AuthContext } from '@/providers';
import { bindFuncToAsync } from '@/Utils';

export const WillsProfileContext = createContext<IWillsProfileContextInterface>({} as IWillsProfileContextInterface);

export const WillsProfileProvider: React.FC<IProps> = observer(({ children }) => {
  const { user } = useContext(AuthContext);
  const { profile } = useContext(ProfileStore);
  const { address } = useContext(AddressStore);
  const [step, setStep] = useState<EAboutYouSteps>(EAboutYouSteps.LEGAL_NAME_QUESTION);
  const [profileData, setProfileData] = useState<IProfile>(
    profile || {
      firstName: '',
      middleName: '',
      lastName: '',
      dob: '',
      citizenship: '',
      preferredLanguage: 'ENGLISH',
      marritalStatus: '',
    },
  );
  const [willsProfileData, setWillsProfileData] = useState<IWillsProfile>({
    id: 0,
    plan: 'PREMIUM',
    methodOfCalculation: '',
    sex: '',
    kids: 0,
    isComplete: false,
    isEstateComplete: false,
    isFamilyComplete: false,
    isPaymentComplete: false,
    isSubscribed: false,
    isPlanComplete: false,
    userId: 0,
  });

  const [addressData, setAddressData] = useState<IUserAddress>(
    address || {
      streetName: '',
      streetNumber: '',
      unitSuiteApt: '',
      city: '',
      provinceState: '',
      postalZip: '',
      country: '',
    },
  );

  const [initializing, setInitializing] = useState(true);

  const recordProfile = (name: string, value: string) => {
    setProfileData({
      ...profileData,
      [name]: value,
    });
  };

  const recordWillsProfile = (name: string, value: string | number) => {
    setWillsProfileData({
      ...willsProfileData,
      [name]: value,
    });
  };

  const createProfile = async (data: any) => {
    await postUserProfile(data);
  };

  const updateProfile = async (data: any) => {
    await axiosClient.put('/profile', data);
  };

  const updateWillsProfile = async (data: Partial<IWillsProfile>) => {
    await putWillsUserProfile(data);
  };

  const createWillsProfile = async (data: Partial<IWillsProfile>) => {
    await postWillsProfile(data);
  };

  const createUserAddress = async (data: IUserAddress) => {
    await postUserAddress(data);
  };

  const updateUserAddress = async (data: IUserAddress) => {
    await putUserAddress(data);
  };

  const createWillsUserAddress = async (data: IUserAddress) => {
    await postWillsUserAddress(data);
  };

  const updateWillsUserAddress = async (data: IUserAddress) => {
    await putWillsUserAddress(data);
  };

  const loadWillsProfile = async () => {
    const { data } = await getWillsProfile();
    if (data) {
      setWillsProfileData(data);
    }
  };

  const goToStep = (renderStep: EAboutYouSteps) => {
    setStep(renderStep);
  };

  useEffect(() => {
    bindFuncToAsync(setInitializing)(loadWillsProfile)();
    if (!user?.isOnboarding) {
      setProfileData(profile!);
    }
  }, []);

  if (initializing) {
    return null;
  }

  const value = {
    isOnboarding: user?.isOnboarding,
    willsOnboardingComplete: willsProfileData.isComplete,
    profileData,
    step,
    goToStep,
    recordProfile,
    createProfile,
    updateProfile,
    willsProfileData,
    recordWillsProfile,
    createWillsProfile,
    updateWillsProfile,
    addressData,
    setAddressData,
    address,
    createWillsUserAddress,
    updateWillsUserAddress,
    createUserAddress,
    updateUserAddress,
  };
  return <WillsProfileContext.Provider value={value}>{children}</WillsProfileContext.Provider>;
});
