import React from 'react';
import { Navigate } from 'react-router-dom';

import { getWillsProfile } from '@wills/apis';
import { Loading } from '@/Shared/Components/Loading';
import { useApi } from '@/lib/axios/useApi';

export const WillsLanding: React.FC = () => {
  const { data, error, loading } = useApi(getWillsProfile);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    if (error.code === 'DoesNotExist') {
      return <Navigate to="/wills/getting-started" replace />;
    }
    throw new Error(error.message);
  }

  if (data?.isComplete === false) {
    return <Navigate to="/wills/getting-started" replace />;
  }

  if (data?.isPaymentComplete) {
    return <Navigate to="/wills/documents" replace />;
  }

  return <Navigate to="/wills/welcome-back" replace />;
};
