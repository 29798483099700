import React from 'react';

import { AuthContainer, AuthContainerWrapper } from './AuthWrapper.styles';

import { EDataTestId } from '@/test-utils/EDataTestId';

export const AuthWrapper: React.FC<{
  children: React.ReactNode;
  footer?: JSX.Element;
}> = ({ children, footer }) => {
  return (
    <AuthContainerWrapper data-testid={EDataTestId.AUTH_PAGE}>
      <AuthContainer>{children}</AuthContainer>
      {footer}
    </AuthContainerWrapper>
  );
};
