import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';

import { StyledSmallHeader, StyledDescription, StyledButton, StyledLayout } from '@wills/components';
import { willOnboardingIntroSvg } from '@/Assets/images';
import { LocaleContext } from '@/providers';
import {
  StyledHeader,
  StyledImg,
  StyledIntroductionContainer,
  StyledIntroductionTopContainer,
  StyledIntroductionTopContentWrapper,
  StyledHeaderDescriptionContainer,
  StyledIntroductionBottomContainer,
  StyledIntroductionBottomContentWrapper,
  StyledAllReviews,
} from './Introduction.styles';
import introductionLocals from './introductionLocales.json';
import { REVIEWS } from './Introduction.constants';
import { Reviews } from './components';
import { IIntroduction } from './Introduction.types';

export const Introduction = ({ nextRoute }: IIntroduction) => {
  const { locale } = useContext(LocaleContext);
  const navigate = useNavigate();
  const introductionLocale = introductionLocals[locale];

  return (
    <StyledLayout>
      <StyledIntroductionContainer data-testid="getting-started-introduction">
        <StyledIntroductionTopContainer>
          <StyledIntroductionTopContentWrapper>
            <StyledImg src={willOnboardingIntroSvg} alt="wills-onboarding-img" />
            <StyledSmallHeader>{introductionLocale['sub-header']}</StyledSmallHeader>
            <StyledHeaderDescriptionContainer>
              <StyledHeader center maxWidthSm>
                {introductionLocale.header}
              </StyledHeader>
              <StyledDescription center>{introductionLocale.description}</StyledDescription>
              <StyledButton
                center
                onClick={() => {
                  navigate(nextRoute);
                }}
              >
                {introductionLocale['button.text']}
              </StyledButton>
            </StyledHeaderDescriptionContainer>
          </StyledIntroductionTopContentWrapper>
        </StyledIntroductionTopContainer>
        <StyledIntroductionBottomContainer>
          <StyledIntroductionBottomContentWrapper>
            <StyledSmallHeader fontSm newBlue>
              {introductionLocale['review-sub-header']}
            </StyledSmallHeader>
            <StyledAllReviews>
              {REVIEWS.map((singleReview) => {
                const { id, title, description, author } = singleReview;
                return <Reviews key={id} title={title} description={description} author={author} />;
              })}
            </StyledAllReviews>
          </StyledIntroductionBottomContentWrapper>
        </StyledIntroductionBottomContainer>
      </StyledIntroductionContainer>
    </StyledLayout>
  );
};
