import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

interface DeeplinkContextValues {
  redirectUrl?: string;
  tag?: string;
  shouldRedirect: boolean;
  redirect: () => void;
}

export const DeeplinkContext = React.createContext<DeeplinkContextValues>({} as DeeplinkContextValues);

export const DeeplinkProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { search } = useLocation();
  const [redirected, setRedirected] = React.useState(false);
  const query = React.useMemo(() => new URLSearchParams(search), []);
  const redirectUrl = query.get('redirect-url') ?? undefined;
  const tag = query.get('tag') ?? undefined;

  const navigate = useNavigate();
  const shouldRedirect = Boolean(!redirected && redirectUrl);
  // Should only be able to redirect once
  const redirect = React.useCallback(() => {
    if (redirectUrl) {
      navigate(redirectUrl, { replace: true });
      setRedirected(true);
    }
  }, [redirectUrl]);

  return (
    <DeeplinkContext.Provider
      value={{
        redirectUrl,
        tag,
        shouldRedirect,
        redirect,
      }}
    >
      {children}
    </DeeplinkContext.Provider>
  );
};
