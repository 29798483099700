import styled from 'styled-components';
import { BsInfoCircle } from 'react-icons/bs';
import { Palette } from '@/Shared/Constants';

export const StyledQuestionInfoContainer = styled.div`
  margin-top: 2rem;
  background-color: ${Palette.PRIMARY_WHITE};
  border-radius: 8px;
  border: none;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 1rem;
`;

export const StyledInfoIcon = styled(BsInfoCircle)`
  width: 1.7rem;
  height: 1.7rem;
`;

export const StyledQuestion = styled.div`
  font-family: 'SofiaPro-Regular';
  font-weight: 600;
  font-size: 1rem;
  color: ${Palette.NEW_BLUE};
  line-height: 22.4px;
`;

export const StyledAnswer = styled(StyledQuestion)`
  font-weight: 400;
  color: ${Palette.PLANET_BLUE};
`;
