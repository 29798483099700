import { ApiResponse, axiosClient, toApiResponse } from '@/lib/axios';
import { PopulatedDocument } from './types';

export const getWillsDocuments = async (): Promise<ApiResponse<PopulatedDocument[]>> => {
  const response = await toApiResponse<PopulatedDocument[]>(axiosClient.get('/wills/documents'));

  if (response.error === undefined) {
    return {
      data: response.data,
      error: undefined,
    };
  }

  return {
    data: undefined,
    error: response.error,
  };
};
