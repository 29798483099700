import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Palette } from '@/Shared/Constants';

export const StyledVerificationContainer = styled.div`
  width: 100%;
  background-color: ${Palette.AQUA_HAZE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const StyledMessage = styled.p<{ mb?: number; size?: number }>`
  font-family: 'SofiaPro-Regular';
  color: ${Palette.GUN_POWDER};
  text-align: center;
  ${({ mb }) => mb && `margin-bottom: ${mb}rem;`}
  ${({ size }) => size && `font-size: ${size}px;`}
`;

export const StyledLink = styled(Link)`
  color: ${Palette.GUN_POWDER};
  font-weight: bold;
`;
