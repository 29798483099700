import { IProfile } from '@/Types';

export interface IWillsFullProfile extends IProfile, IWillsProfile {}
export interface IWillsProfile {
  id: number;
  plan: WillsPlanType;
  methodOfCalculation: string;
  sex: string;
  kids: 0 | 1;
  isComplete: boolean;
  isEstateComplete: boolean;
  isFamilyComplete: boolean;
  isPaymentComplete: boolean;
  isSubscribed: boolean;
  isPlanComplete: boolean;
  subscriptionStatus?: string;
  userId: number;
  planFlowStartedAt?: string;
  familyFlowStartedAt?: string;
  estateFlowStartedAt?: string;
}

export type WillsPlanType = 'PREMIUM' | 'ESSENTIALS';

// Relationship
export interface IRelativeInfo {
  id: number;
  relationDefinitionId: number;
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  phone: string;
}

export interface IAsset {
  id: number;
  assetType: string;
  assetTitle: string;
  assetDescription: string;
  assetOwnership: string;
  assetApproxValue: string;
}

export const ALL_RELATIONSHIPS = [
  'wife',
  'husband',
  'father',
  'mother',
  'daughter',
  'son',
  'gdaughter',
  'brother',
  'sister',
  'gson',
  'gfather',
  'gmotherF',
  'gmotherM',
  'brotherF',
  'siblingM',
  'nephewF',
  'uncleF',
  'cousinF',
  'sisterF',
  'nephew',
  'uncle',
  'cousin',
  'relativeM',
  'freed',
  'other',
  'charity',
  'relative',
  'businessP',
  'friend',
];

export type RelativeDefinitionRelationship = (typeof ALL_RELATIONSHIPS)[number];

export type RelationshipCategory = 'primary' | 'secondary' | 'general' | 'all';

export type AppointeeFilterKey =
  | 'stateExecutor'
  | 'backupStateExecutor'
  | 'primaryChildGuardian'
  | 'secondaryChildGuardian'
  | 'poaProperty'
  | 'backupPoaProperty'
  | 'poaPersonalCare'
  | 'backupPoaPersonalCare';

export type RelationShipList = IAppointeeRole[] | null;

export interface IAppointeeRole {
  name: string;
  email: string;
  role: AppointeeRole;
}

export type AppointeeRole =
  | 'Estate Executor'
  | 'Backup Estate Executor'
  | 'Primary Child Guardian'
  | 'Secondary Child Guardian'
  | 'Power of Attorney for Property'
  | 'Backup Power of Attorney for Property'
  | 'Power of Attorney for Personal Care'
  | 'Backup Power of Attorney for Personal Care';
