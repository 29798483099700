import React, { FC } from 'react';
import { InfoAttributes, InfoContent, InfoHeader, SingleInfoWrapper } from '../../Profile.style';

interface IProps {
  icon: React.ReactElement;
  header: String;
  value: String | null;
  spanFullWidth?: boolean;
}

const DisplayInfo: FC<IProps> = ({ icon, header, value, spanFullWidth }) => {
  return (
    <SingleInfoWrapper spanFullWidth={spanFullWidth}>
      {icon}
      <InfoAttributes>
        <InfoHeader>{header}</InfoHeader>
        <InfoContent>{value}</InfoContent>
      </InfoAttributes>
    </SingleInfoWrapper>
  );
};

export default DisplayInfo;
