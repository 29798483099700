import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CardElement } from '@/lib/payment/components/CardElement';
import {
  StyledBlueButton,
  StyledSavePaymentMethodFormContainer,
  StyledSavePaymentMethodHeader,
  StyledSavePaymentMethodPageContainer,
} from './SavePaymentMethod.styles';
import { IPaymentInfo } from '@/lib/payment/types';
import { SnackBarContext } from '@/providers';
import { useApi } from '@/lib/axios/useApi';
import { getPaymentInfofromDb } from './SavePaymentMethod.helpers';
import { ISavePaymentMethodProps } from './SavePaymentMethod.types';
import { Loading } from '@/Shared/Components/Loading';

const SavePaymentMethod: React.FC<ISavePaymentMethodProps> = ({ onSuccess }) => {
  const navigate = useNavigate();
  const { setSnackMessage } = useContext(SnackBarContext);
  const [paymentMethodExists, setPaymentMethodExists] = useState(false);
  const { loading } = useApi<IPaymentInfo>(getPaymentInfofromDb, [], {
    onError: (error) => setSnackMessage(error.message),
    onSuccess: (info) => {
      if (info.paymentMethodId) {
        setPaymentMethodExists(true);
      }
    },
  });

  const goBack = () => navigate(-1);

  if (loading) return <Loading />;

  return (
    <StyledSavePaymentMethodPageContainer>
      <StyledSavePaymentMethodFormContainer>
        {paymentMethodExists ? (
          <>
            <StyledSavePaymentMethodHeader>
              You already have a saved payment method. Please go to profile page to update it
            </StyledSavePaymentMethodHeader>
            <StyledBlueButton type="button" onClick={goBack}>
              Go back
            </StyledBlueButton>
          </>
        ) : (
          <CardElement onSuccess={onSuccess} />
        )}
      </StyledSavePaymentMethodFormContainer>
    </StyledSavePaymentMethodPageContainer>
  );
};

export default SavePaymentMethod;
