import { ChangeEvent } from 'react';

export interface IAuthenticatorMfaModalProps {
  onConfirmMfaBtnClick: () => Promise<void>;
  onCancel: () => void;
  disabled?: boolean;
  authCode: string | null;
  onAuthCodeInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
}

export enum EModalStep {
  MESSAGE = 'MESSAGE',
  QR_CODE = 'QR_CODE',
  AUTH_CODE = 'AUTH_CODE',
}
