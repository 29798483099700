import { ApiResponse, axiosClient, toApiResponse } from '@/lib/axios';
import { IAssetList } from './types';

export const completeAssetFlow = async (): Promise<ApiResponse<IAssetList>> => {
  const response = await toApiResponse<IAssetList>(axiosClient.put(`/wills/assets/complete`));

  return {
    ...response,
  };
};
