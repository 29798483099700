export async function createZendeskTicket(body: any) {
  const config = {
    method: 'POST',
    url: 'https://manzil.zendesk.com/api/v2/requests.json',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  };
  fetch('https://manzil.zendesk.com/api/v2/requests.json', config);
}
