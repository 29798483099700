/* eslint-disable no-console */

import { makeAutoObservable, runInAction } from 'mobx';
import { createContext } from 'react';

import { getUserProfile, postUserProfile, putUserProfile } from '@/Api/Profile';
import { IError, IProfile } from '@/Types';
import { snakeToCamelParser } from '@/Utils';

export class ProfileStore {
  profile: IProfile | null = null;

  error: IError | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setProfile = (profile: IProfile) => {
    this.profile = profile;
  };

  setProfileOrHandleError(data: any) {
    if (data.Code) {
      this.error = data;
      this.profile = null;
    } else {
      this.profile = data;
      this.error = null;
    }
  }

  loadProfile = async () => {
    try {
      const data = await getUserProfile();
      const profile = snakeToCamelParser(data);
      runInAction(() => {
        this.setProfileOrHandleError(profile);
      });
    } catch (err) {
      console.error(err);
    }
  };

  createProfile = async (profileData: IProfile) => {
    try {
      const data = await postUserProfile(profileData);
      const createdProfile = snakeToCamelParser(data);
      runInAction(() => {
        this.setProfileOrHandleError(createdProfile);
      });
    } catch (err) {
      console.error(err);
    }
  };

  editProfile = async (profileData: any) => {
    try {
      const data = await putUserProfile(profileData);
      const updatedProfile = snakeToCamelParser(data);
      runInAction(() => {
        this.setProfileOrHandleError(updatedProfile);
      });
    } catch (err) {
      console.error(err);
    }
  };

  clearProfileStore = () => {
    this.profile = null;
    this.error = null;
  };
}

export default createContext(new ProfileStore());
