import React from 'react';

import { INavigationButtonsProps } from './NavigationButtons.types';

import { StyledNavigationButton, StyledNavigationButtonsContainer } from './NavigationButtons.styles';

const NavigationButtons = ({ onContinue, onPrevious, continueBtnText, previousBtnText }: INavigationButtonsProps) => {
  return (
    <StyledNavigationButtonsContainer>
      <StyledNavigationButton type="button" onClick={onPrevious}>
        {previousBtnText || 'Previous'}
      </StyledNavigationButton>
      <StyledNavigationButton type="button" primary onClick={onContinue}>
        {continueBtnText || 'Continue'}
      </StyledNavigationButton>
    </StyledNavigationButtonsContainer>
  );
};

export default NavigationButtons;
