import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const CitizenshipInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const CitizenshipInputFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const RequiredInfoIndicator = styled.span`
  color: #ca6e56;
  margin-left: 0.2rem;
`;

export const StyledMessage = styled.p`
  font-family: 'SofiaPro-Regular';
  font-size: 0.8rem;
  color: ${Palette.NEW_BLUE};
  text-align: center;
  padding: 0 1rem;
`;
