import React from 'react';
import { SubHeader } from '@/Shared/Components';
import {
  StyledAppointeesRoleListBox,
  StyledAppointeesRoleListHeaderRow,
  StyledAppointeesRoleListTableCell,
  StyledAppointeesRoleListTableCheckBoxContainer,
  StyledAppointeesRoleListTableRow,
} from './AppointeesRoleList.styles';
import useScreenSize from '@/Shared/Hooks/UseScreenSize/useScreenSize';
import { Breakpoints } from '@/Utils/breakpoints';
import { IAppointeesRoleList } from './AppointeesRoleList.types';
import useAppointeeChecker from './useAppointeeChecker';
import useAllAppointeeChecker from './useAllAppointeeChecker';

const AppointeesRoleList = ({ title, appointees, emailsToNotify, setEmailsToNotify }: IAppointeesRoleList) => {
  const { isMobile } = useScreenSize({
    sm: Number(Breakpoints.MOBILE_MAX_WIDTH.split('px')[0]),
  });
  const { isChecked, onChangeHandler } = useAppointeeChecker(emailsToNotify, setEmailsToNotify);
  const { isChecked: isAllChecked, onChangeHandler: onSelectAllChangeHandler } = useAllAppointeeChecker(
    emailsToNotify,
    setEmailsToNotify,
  );

  return (
    <>
      <SubHeader mb={1}>{title}</SubHeader>
      <StyledAppointeesRoleListBox>
        <StyledAppointeesRoleListHeaderRow>
          <StyledAppointeesRoleListTableCheckBoxContainer>
            <input
              type="checkbox"
              checked={isAllChecked(appointees)}
              onChange={(e) => onSelectAllChangeHandler(e, appointees)}
            />
          </StyledAppointeesRoleListTableCheckBoxContainer>
          {!isMobile ? (
            <>
              <StyledAppointeesRoleListTableCell>Name</StyledAppointeesRoleListTableCell>
              <StyledAppointeesRoleListTableCell>Role</StyledAppointeesRoleListTableCell>
              <StyledAppointeesRoleListTableCell>Email</StyledAppointeesRoleListTableCell>
            </>
          ) : (
            <StyledAppointeesRoleListTableCell>Select All</StyledAppointeesRoleListTableCell>
          )}
        </StyledAppointeesRoleListHeaderRow>
        {appointees.map((appointee, index) => (
          <StyledAppointeesRoleListTableRow key={index}>
            <StyledAppointeesRoleListTableCheckBoxContainer>
              <input type="checkbox" checked={isChecked(appointee)} onChange={(e) => onChangeHandler(e, appointee)} />
            </StyledAppointeesRoleListTableCheckBoxContainer>
            <StyledAppointeesRoleListTableCell>
              {appointee.name}
              {isMobile && (
                <>
                  <p>{appointee.role}</p>
                  <p>{appointee.email}</p>
                </>
              )}
            </StyledAppointeesRoleListTableCell>
            {!isMobile && (
              <>
                <StyledAppointeesRoleListTableCell>{appointee.role}</StyledAppointeesRoleListTableCell>
                <StyledAppointeesRoleListTableCell>{appointee.email}</StyledAppointeesRoleListTableCell>
              </>
            )}
          </StyledAppointeesRoleListTableRow>
        ))}
      </StyledAppointeesRoleListBox>
    </>
  );
};

export default AppointeesRoleList;
