import styled from 'styled-components';
import { InputContainer, Title } from '../../components';

export const UserKycButtonGroup = styled.div`
  display: flex;
  flex-flow: row-reverse wrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  gap: 1rem;
  width: 100%;
`;

export const UserKycForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  min-width: 375px;
  max-width: 600px;
  padding: 0 2rem;

  ${InputContainer} {
    width: 100%;
    padding: 0 clamp(0px, calc((100vw - 640px) / 2), 2rem);
  }

  ${Title} {
    margin-bottom: 1rem;
    padding: 0 clamp(0px, calc((100vw - 640px) / 2), 2rem);
  }

  ${UserKycButtonGroup} {
    margin-top: 0.5rem;
  }
`;
