import React, { useContext, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { IRelativeInfo, IRelativeResponse } from '@/lib/wills/pages/WillsFamilyFlow/WillsFamilyFlow.types';
import { normalizeDateOnChange } from '@/lib/user/pages/Profile/helpers/normalizeDateOnChange';

import { getErrorMessage } from '@/Utils';
import { AuthContext, SnackBarContext } from '@/providers';

import { NavigationButtons } from '../../NavigationButtons';
import {
  StyledRelativeAdderForm,
  StyledRelativeAdderFormButtonGroup,
  StyledRelativeAdderFormInput,
  StyledRelativeAdderFormInputGroup,
  StyledRelativeAdderFormLabel,
  StyledRelativeAdderFormName,
  StyledRelativeAdderFormSelect,
  StyledRelativeFormInputContainer,
} from './RelativeAdderForm.styles';
import { IRelativeAdderFormProps } from './RelativeAdderForm.types';
import { EstateIntakeContext, EstateIntakeContextActionType } from '../../../contexts';
import { addWillsRelationshipToDb } from './RelativeAdderForm.api';
import { searchRelationshipDefinitionById } from '@/lib/wills/pages/WillsFamilyFlow/WillsFamilyFlow.helpers';
import { normalizeDate } from '@/lib/user/pages/Profile/helpers/normalizeDateOnChange/normalizeDate';
import { isDobValid } from './RelativeAdderForm.helper';
import { ProfileStore } from '@/Stores';
import { StyledHelperText } from '@/lib/wills/components';
import { formatPhoneNumber } from '@/Utils/phone/formatPhoneNumber';
import { isValidEmail } from '@/Utils/isValidEmail';
import { maskPhoneNumber } from '@/Utils/phone/maskPhoneNumber';

const RelativeAdderForm = ({ setAddMode, action, addMode, relativeDefinitions }: IRelativeAdderFormProps) => {
  const { user } = useContext(AuthContext);
  const { profile } = useContext(ProfileStore);
  const { dispatch } = useContext(EstateIntakeContext);
  const { setSnackMessage } = useContext(SnackBarContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<IRelativeInfo>();
  const relationDefinitionId = watch('relationDefinitionId');

  const onPhoneNoChangeHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    formatPhoneNumber(event);
  };

  const onSubmit: SubmitHandler<IRelativeInfo> = async (data) => {
    if (isSubmitting) return;
    setIsSubmitting(true);
    const requestPayload: IRelativeInfo = {
      ...data,
      relationDefinitionId: Number(data.relationDefinitionId || '1'),
    };

    try {
      const { data: responseData } = await addWillsRelationshipToDb(requestPayload);

      const transformedData: IRelativeInfo = {
        ...data,
        id: responseData.id,
        relationDefinitionId: Number(data.relationDefinitionId || '1'),
      };

      const relationDefinition = searchRelationshipDefinitionById(
        relativeDefinitions,
        transformedData.relationDefinitionId,
      );
      const relativeResponse: IRelativeResponse = {
        ...transformedData,
        middleName: '',
        stateExecutor: action === EstateIntakeContextActionType.SET_MAIN_EXECUTOR,
        backupStateExecutor: action === EstateIntakeContextActionType.SET_BACKUP_EXECUTOR,
        poaProperty: action === EstateIntakeContextActionType.SET_MAIN_POA_PROPERTY,
        backupPoaProperty: action === EstateIntakeContextActionType.SET_BACKUP_POA_PROPERTY,
        poaPersonalCare: action === EstateIntakeContextActionType.SET_MAIN_POA_PERSONAL_CARE,
        backupPoaPersonalCare: action === EstateIntakeContextActionType.SET_BACKUP_POA_PERSONAL_CARE,
        primaryChildGuardian: action === EstateIntakeContextActionType.SET_MAIN_GUARDIAN,
        secondaryChildGuardian: action === EstateIntakeContextActionType.SET_BACKUP_GUARDIAN,
        willsRelationshipId: transformedData.relationDefinitionId,
        contact: null,
        relationDefinition,
        userId: user!.id,
      };

      dispatch({ type: EstateIntakeContextActionType.ADD_TO_RELATIVES, payload: relativeResponse });
      dispatch({ type: action, payload: relativeResponse });
      setAddMode(false);
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <>
      <StyledRelativeAdderFormName>Trustee Details</StyledRelativeAdderFormName>
      <StyledRelativeAdderForm onSubmit={handleSubmit(onSubmit)}>
        <StyledRelativeAdderFormInputGroup>
          <StyledRelativeAdderFormLabel>Relation</StyledRelativeAdderFormLabel>
          <StyledRelativeAdderFormSelect {...register('relationDefinitionId')}>
            {relativeDefinitions.map((data) => (
              <option key={data.id} value={data.id}>
                {data.description}
              </option>
            ))}
          </StyledRelativeAdderFormSelect>
        </StyledRelativeAdderFormInputGroup>
        <StyledRelativeFormInputContainer>
          <StyledRelativeAdderFormInputGroup>
            <StyledRelativeAdderFormLabel>First Name</StyledRelativeAdderFormLabel>
            <StyledRelativeAdderFormInput
              placeholder="First Name"
              {...register('firstName', { required: 'First name is required' })}
            />
            {errors.firstName ? <StyledHelperText>{errors.firstName.message}</StyledHelperText> : null}
          </StyledRelativeAdderFormInputGroup>
          <StyledRelativeAdderFormInputGroup>
            <StyledRelativeAdderFormLabel>Last Name</StyledRelativeAdderFormLabel>
            <StyledRelativeAdderFormInput
              placeholder="Last Name"
              {...register('lastName', { required: 'Last name is required' })}
            />
            {errors.lastName ? <StyledHelperText>{errors.lastName.message}</StyledHelperText> : null}
          </StyledRelativeAdderFormInputGroup>
        </StyledRelativeFormInputContainer>
        <StyledRelativeFormInputContainer>
          <StyledRelativeAdderFormInputGroup>
            <StyledRelativeAdderFormLabel>Date of birth</StyledRelativeAdderFormLabel>
            <StyledRelativeAdderFormInput
              placeholder="yyyy-mm-dd"
              {...register('dob', {
                required: 'Date of birth is required',
                onChange: (e) => {
                  normalizeDateOnChange(e);
                },
                validate: (data) => {
                  const getRelationshipDefiniton = relativeDefinitions.find(
                    (definition) => definition.id === Number(relationDefinitionId),
                  );
                  const date: string = normalizeDate(data);
                  const { isValid, message } = isDobValid(getRelationshipDefiniton?.relationship, data, profile?.dob);
                  if (String(new Date(date ?? 'bad date')) === 'Invalid Date') {
                    return 'Invalid date';
                  }
                  if (!isValid) {
                    return message;
                  }
                  setValue("dob", date);
                  return true;
                },
              })}
            />
            {errors.dob ? <StyledHelperText htmlFor="dob">{errors.dob.message}</StyledHelperText> : null}
          </StyledRelativeAdderFormInputGroup>
          <StyledRelativeAdderFormInputGroup>
            <StyledRelativeAdderFormLabel>Email</StyledRelativeAdderFormLabel>
            <StyledRelativeAdderFormInput
              placeholder="Email"
              {...register('email', {
                required: 'Email is required',
                validate: (data) => {
                  return isValidEmail(data) || 'Invalid email address';
                },
              })}
            />
            {errors.email ? (
              <StyledHelperText htmlFor="email" type="error">
                {errors.email.message}
              </StyledHelperText>
            ) : (
              <StyledHelperText htmlFor="email" type="info">
                If underage, use parents email
              </StyledHelperText>
            )}
          </StyledRelativeAdderFormInputGroup>
        </StyledRelativeFormInputContainer>
        <StyledRelativeAdderFormInputGroup>
          <StyledRelativeAdderFormLabel>Phone (optional)</StyledRelativeAdderFormLabel>
          <StyledRelativeAdderFormInput
            placeholder="Phone"
            {...register('phone', {
              onChange: onPhoneNoChangeHandler,
              validate: (data) => {
                const phone = maskPhoneNumber(data ?? '');

                if (phone === '') {
                  return true;
                }
                return /^\(\d{3}\) \d{3}-\d{4}$/.test(phone) || 'Invalid phone number';
              },
            })}
          />
          {errors.phone && <StyledHelperText>{errors.phone.message}</StyledHelperText>}
        </StyledRelativeAdderFormInputGroup>

        <StyledRelativeAdderFormButtonGroup>
          <NavigationButtons
            addMode={addMode}
            cancelBtnText="Cancel"
            confirmBtnText="Save"
            confirmBtnType="submit"
            onContinue={handleSubmit(onSubmit)}
            onPrevious={() => setAddMode(false)}
          />
        </StyledRelativeAdderFormButtonGroup>
      </StyledRelativeAdderForm>
    </>
  );
};

export default RelativeAdderForm;
