import React from 'react';
import { StyledNoRelativesModalInfoContainer, StyledNoRelatviesModalInfoHeader } from './NoRelativesModalInfo.styles';
import { INoRelativesModalInfo } from './NoRelativesModalInfo.types';

const NoRelativesModalInfo = ({ category }: INoRelativesModalInfo) => {
  return (
    <StyledNoRelativesModalInfoContainer>
      <StyledNoRelatviesModalInfoHeader>NO SURVIVING, {category}, MUSLIM, RELATIVES</StyledNoRelatviesModalInfoHeader>
    </StyledNoRelativesModalInfoContainer>
  );
};

export default NoRelativesModalInfo;
