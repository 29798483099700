import React, { useRef, SyntheticEvent, useEffect, KeyboardEvent, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  StyledForm,
  StyledFormFieldContainer,
  StyledFormLabel,
  StyledInfoIcon,
  StyledInfoIconContainer,
  StyledInfoIconWrapper,
  StyledInputGroup,
  StyledShortInputField,
  HiddenSubmitButton,
  ErrorMessage,
  InfoContentWrapper,
  WillsProfileContext,
  EAboutYouSteps,
} from '@wills/components';
import { isValueText, replaceTextWithEmptyString } from '@/Shared/SharedUtils';
import {
  yearInputCompleted,
  monthInputCompleted,
  validateDateForm,
  isNotAllowedToOpenAnAccount,
} from '@/Utils/dateOfBirthHelpers';
import { AboutYouContainer, Content, FormHeader, FormFooter, IWillsProps } from '../../shared';
import { Modal } from '@/Shared/Components';
import DobQuestionInfo from './DobQuestionInfo';

const DobQuestion: React.FC<IWillsProps> = ({ goBack, isEdit, goToSummaryPage }) => {
  const { profileData, recordProfile, isOnboarding, updateProfile, createProfile, goToStep } =
    useContext(WillsProfileContext);
  const year = profileData.dob ? profileData.dob.split('-')[0] : '';
  const month = profileData.dob ? profileData.dob.split('-')[1] : '';
  const day = profileData.dob ? profileData.dob.split('-')[2] : '';
  const [dobError, setDobError] = useState(false);
  const errorMsgRef = useRef<HTMLParagraphElement>(null);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [showInfoBlurb, setShowInfoBlurb] = useState(false);

  const { handleSubmit } = useForm();
  const [dob, setDob] = useState({
    year,
    month,
    day,
  });
  const yearInputRef = useRef<HTMLInputElement | null>(null);
  const monthInputRef = useRef<HTMLInputElement | null>(null);
  const dayInputRef = useRef<HTMLInputElement | null>(null);

  const onDobSubmit = async (_: any, event?: React.BaseSyntheticEvent) => {
    const formEvent = event as React.KeyboardEvent<HTMLFormElement>;
    const valid = validateDateForm(dob);

    if (!valid) {
      setDobError(true);
      return;
    }

    if (isNotAllowedToOpenAnAccount(dob)) {
      setShowErrorMessage(true);
      return;
    }

    const formattedDate = `${dob.year}-${dob.month}-${dob.day}`;

    recordProfile('dob', formattedDate);

    if (!isOnboarding) {
      updateProfile({ dob: formattedDate });
    } else {
      createProfile({ dob: formattedDate });
    }
    if (formEvent && formEvent.target?.id === 'summary-btn') {
      goToSummaryPage!();
    } else {
      goToStep(EAboutYouSteps.MARITAL_STATUS_QUESTION);
    }
  };

  const onChangeHandler = (e: SyntheticEvent<HTMLInputElement>) => {
    setDobError(false);

    const { name, value } = e.currentTarget;

    if (isValueText(value)) {
      replaceTextWithEmptyString(value);
      return;
    }

    if (yearInputCompleted(name, value)) monthInputRef.current?.focus();

    if (monthInputCompleted(name, value)) dayInputRef.current?.focus();

    setDob({ ...dob, [name]: value });
  };

  const onTabPressInDayInput = (event: KeyboardEvent<HTMLInputElement>): void => {
    if (event.key === 'Tab') {
      event.preventDefault();
      yearInputRef.current?.focus();
    }
  };

  useEffect(() => {
    yearInputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (errorMsgRef.current) {
      errorMsgRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  });

  return (
    <AboutYouContainer>
      <InfoContentWrapper InfoContent={DobQuestionInfo}>
        <Content>
          <FormHeader subHeader="ABOUT YOU" header="What is your date of birth?" />
          <StyledForm onSubmit={(event) => handleSubmit(onDobSubmit)(event)}>
            {showErrorMessage && (
              <ErrorMessage ref={errorMsgRef}>You must be at least 18 to create a muslim will</ErrorMessage>
            )}
            <StyledInputGroup>
              <StyledFormFieldContainer>
                <StyledFormLabel>Year</StyledFormLabel>
                <StyledShortInputField
                  sm
                  ref={yearInputRef}
                  onChange={onChangeHandler}
                  value={dob.year}
                  name="year"
                  maxLength={4}
                  placeholder="yyyy"
                />
              </StyledFormFieldContainer>
              <StyledFormFieldContainer>
                <StyledFormLabel>Month</StyledFormLabel>
                <StyledShortInputField
                  sm
                  ref={monthInputRef}
                  onChange={onChangeHandler}
                  value={dob.month}
                  name="month"
                  maxLength={2}
                  placeholder="mm"
                />
              </StyledFormFieldContainer>
              <StyledFormFieldContainer>
                <StyledFormLabel>Day</StyledFormLabel>
                <StyledShortInputField
                  sm
                  ref={dayInputRef}
                  onChange={onChangeHandler}
                  value={dob.day}
                  name="day"
                  maxLength={2}
                  onKeyDown={(event) => onTabPressInDayInput(event)}
                  placeholder="dd"
                />
              </StyledFormFieldContainer>
            </StyledInputGroup>
            <HiddenSubmitButton type="submit" />
          </StyledForm>

          <StyledInfoIconWrapper>
            <StyledInfoIconContainer onClick={() => setShowInfoBlurb(true)}>
              <StyledInfoIcon />
            </StyledInfoIconContainer>
          </StyledInfoIconWrapper>

          {showInfoBlurb && (
            <Modal transparent onCloseCallback={() => setShowInfoBlurb(false)}>
              <DobQuestionInfo />
            </Modal>
          )}

          <FormFooter
            previousStep={EAboutYouSteps.LEGAL_NAME_QUESTION}
            goBack={() => goBack(EAboutYouSteps.LEGAL_NAME_QUESTION)}
            handleSubmit={handleSubmit}
            onFormSubmit={handleSubmit(onDobSubmit)}
            isEdit={isEdit}
            isDisabled={dobError}
          />
        </Content>
      </InfoContentWrapper>
    </AboutYouContainer>
  );
};

export default DobQuestion;
