import React, { useContext, useEffect, useState } from 'react';
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import { useNavigate } from 'react-router-dom';
import { axiosClient, toApiResponse } from '@/lib/axios';
import { SnackBarContext } from '@/providers';
import { Loading } from '@/Shared/Components/Loading';
import { EPaymentProducts } from '../../types';
import { getStripe } from '@/lib/payment/stripe/stripeConfig';

interface ICheckoutFormProps {
  product: EPaymentProducts;
}

const CheckoutUi = ({ product }: ICheckoutFormProps) => {
  const navigate = useNavigate();
  const { setSnackMessage } = useContext(SnackBarContext);
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();
  const [checkoutSession, setCheckoutSession] = useState<any | null>(null);

  async function loadCheckoutSession(signal: AbortSignal) {
    const { data, error } = await toApiResponse<any>(
      axiosClient.post('/create-checkout-session', { product }, { signal }),
    );

    if (error) {
      const isCanceledError = error.message === 'CanceledError';

      if (isCanceledError) return;

      if (error.code === 'redirect') {
        navigate(error.message);
        return;
      }

      setSnackMessage(error.message);
    }

    if (data) {
      setCheckoutSession(data);
    }
  }

  useEffect(() => {
    setStripePromise(getStripe());
  }, []);

  useEffect(() => {
    const controller = new AbortController();

    loadCheckoutSession(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  if (!stripePromise || !checkoutSession) return <Loading />;

  return (
    <div>
      {checkoutSession.client_secret && (
        <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret: checkoutSession.client_secret }}>
          <EmbeddedCheckout />
        </EmbeddedCheckoutProvider>
      )}
    </div>
  );
};

export default CheckoutUi;
