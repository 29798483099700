import React from 'react';
import { QuestionInfo } from '@/lib/wills/components/QuestionInfo';

const ChildGuardianQuestionInfo = () => {
  return (
    <QuestionInfo
      question="An Important Note"
      answer={
        <div>
          A guardian is the person nominated in your Will to have custody of your minor children, only if the minor
          children have no surviving parents.
          <br />
          <br />
          If you choose to name a guardian for your minor children, the named individual will be named as the
          decision-maker and the property guardian. This means that the named individual will have custody over your
          minor children and their property.
          <br />
          <br />
          It is advisable to name a guardian, unless you have no minor children and you do not expect to have any more
          children.
        </div>
      }
    />
  );
};

export default ChildGuardianQuestionInfo;
