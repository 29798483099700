import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { SnackBarContext } from '@/providers';
import { useScrollToTop } from '@/Shared/Hooks';
import { ESelectPlanSteps } from './WillsSelectPlan.types';
import { PlansDescription } from './Components/PlansDescription';
import { WillsPlanSummary } from './Components/WillsPlanSummary';
import { WillsSelectPlanContext } from './WillsSelectPlan.context';
import { Subscription } from './Components/Subscription';
import { useStepsReviewNavigation } from '../../hooks/useStepsReviewNavigation';
import { setPlanFlowStartedTime } from '../../apis/willsProfile/setPlanFlowStartedTime';

const WillsSelectPlan: React.FC<{ isReview?: boolean }> = ({ isReview }) => {
  const { goToStepsOrReview } = useStepsReviewNavigation(isReview);
  const { setSnackMessage } = useContext(SnackBarContext);
  const { state } = useLocation();
  const [step, setStep] = useState(
    state && state.lastStep ? ESelectPlanSteps.SUMMARY : ESelectPlanSteps.PLANS_DESCRIPTION,
  );
  const [plan, setPlan] = useState('');
  const [subscribed, setSubscribed] = useState(false);
  useScrollToTop([step]);

  const goToPlansPage = () => {
    setStep(ESelectPlanSteps.PLANS_DESCRIPTION);
  };

  const goToSubscriptionPage = () => {
    setStep(ESelectPlanSteps.SUBSCRIPTION);
  };

  const handlePlanSelect = (type: string) => {
    setPlan(type);
    goToSubscriptionPage();
  };

  const goToSummaryPage = () => {
    setStep(ESelectPlanSteps.SUMMARY);
  };

  const value = {
    plan,
    goToPlansPage,
    handlePlanSelect,
    subscribed,
    setSubscribed,
    goToSummaryPage,
    goToSubscriptionPage,
  };

  async function getOrSetPlanStartTime() {
    const { data, error } = await setPlanFlowStartedTime();
    if (error) {
      setSnackMessage(error.message);
      return;
    }
    setSubscribed(data.isSubscribed);
  }

  useEffect(() => {
    getOrSetPlanStartTime();
  }, []);

  return (
    <WillsSelectPlanContext.Provider value={value}>
      {step === ESelectPlanSteps.PLANS_DESCRIPTION && <PlansDescription onBack={goToStepsOrReview} />}
      {step === ESelectPlanSteps.SUBSCRIPTION && <Subscription />}
      {step === ESelectPlanSteps.SUMMARY && <WillsPlanSummary onComplete={goToStepsOrReview} />}
    </WillsSelectPlanContext.Provider>
  );
};

export default WillsSelectPlan;
