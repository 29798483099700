import validator from 'validator';

import { IOnboardingDob } from '@/lib/invest/pages/Onboarding/Types';

export const isValidYear = (year: string) => {
  return validator.isLength(year, { min: 4 }) && !validator.isEmpty(year);
};

export const isValidMonth = (month: string) => {
  return validator.isInt(month, { min: 1, max: 12 }) && !validator.isEmpty(month);
};

export const isValidDay = (day: string) => {
  return validator.isInt(day, { min: 1, max: 31 }) && !validator.isEmpty(day);
};

export const validateDateForm = (dob: IOnboardingDob) => {
  if (!isValidYear(dob.year)) {
    return false;
  }

  if (!isValidMonth(dob.month)) {
    return false;
  }

  if (!isValidDay(dob.day)) {
    return false;
  }

  return true;
};

export const isStringLenghtOne = (value: string) => {
  return validator.isLength(value, { min: 1, max: 1 });
};

export const formatLenghtOneDateInput = (value: string) => {
  if (isStringLenghtOne(value)) {
    return `0${value}`;
  }
  return value;
};

export const formatDate = (year: string, month: string, day: string) => {
  return `${year}-${month}-${day}`;
};

export const dateParser = (value: string) => {
  const dob = value.split('-');

  const year = dob[0];
  const month = dob[1];
  const day = dob[2];

  return { year, month, day };
};

export const yearInputCompleted = (name: string, value: string) => {
  return name === 'year' && value.length === 4;
};

export const monthInputCompleted = (name: string, value: string) => {
  return name === 'month' && value.length === 2;
};

export const calculateAge = (birthYear: string, birthMonth: string, birthDay: string) => {
  const presentDate = new Date();
  let age = presentDate.getFullYear() - parseInt(birthYear, 10);
  const month = presentDate.getMonth() + 1 - parseInt(birthMonth, 10);

  if (month < 0 || (month === 0 && presentDate.getDate() < parseInt(birthDay, 10))) {
    age -= 1;
  }
  return age;
};

export const isNotAllowedToOpenAnAccount = (dob: IOnboardingDob) => {
  const { year, month, day } = dob;
  const age = calculateAge(year, month, day);

  return age < 18;
};

export const getFormattedDate = (utcDateString: string) => {
  const date = new Date(utcDateString);
  const year = date.getUTCFullYear().toString().padStart(4, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(2, '0');
  const day = date.getUTCDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const getAgeOfMajority = (provinceCode: string) => {
  if (['BC', 'NB', 'NL', 'NS', 'NT', 'YT', 'NU'].includes(provinceCode)) return 19;
  return 18;
};
