import React from 'react';

import {
  StyledInfoContainer,
  StyledBequestIntroContentContainer,
  StyledBequestIntroPageContainer,
  StyledNavigationButtonsWrapper,
  StyledWillsGrid,
  StyledWillsImage,
  StyledWillsImageContainer,
} from './BequestIntro.styles';
import {
  StyledWillsIntroText,
  StyledWillsText,
  StyledWillsTitle,
  NavigationButtons,
  handleNavigationButtons,
} from '../../components';
import { gift } from './assets';

export const BequestIntro: React.FC<{
  onNext: () => void;
  onBack: () => void;
  onSummary: () => void;
}> = ({ onNext, onBack, onSummary }) => {
  function onContinueBtnClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    handleNavigationButtons(event, onNext, onSummary);
  }

  return (
    <StyledBequestIntroPageContainer>
      <StyledBequestIntroContentContainer>
        <StyledWillsIntroText>Up next</StyledWillsIntroText>
        <StyledWillsTitle>Bequests</StyledWillsTitle>
        <StyledWillsGrid>
          <StyledInfoContainer>
            <StyledWillsText>
              Bequests and gifts to people who do not normally inherit are permitted. Keeping this in mind, Islamic Law
              mandates that no more than 1/3 of the estate, after expenses are paid, can be given away to those that are
              not entitled to inherit. So while your son and daughter cannot take an additional share from the 1/3 gift,
              your cousin or best friend may. These distributions will be given priority distribution rights.
              <br />
              <br />
              The Prophet (PBUH) visited Sa’ad ibn Abi Waqqas while he was sick. When Sa’ad inquired about how much he
              should give away asking if 1/3 was permitted, the Prophet instructed him that while 1/3 is permitted, “1/3
              is a lot, and for you to leave your dependents wealthy is better than to leave them poor, begging of
              others.
            </StyledWillsText>
          </StyledInfoContainer>
          <StyledWillsImageContainer>
            <StyledWillsImage src={gift} alt="gift" />
          </StyledWillsImageContainer>
        </StyledWillsGrid>
        <StyledNavigationButtonsWrapper>
          <NavigationButtons
            confirmBtnText="Name your benificiaries"
            onContinue={onContinueBtnClick}
            onPrevious={onBack}
          />
        </StyledNavigationButtonsWrapper>
      </StyledBequestIntroContentContainer>
    </StyledBequestIntroPageContainer>
  );
};
