import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { StyledCross } from '@/Shared/Components/SnackBar/SnackBar.styles';
import { ZIndex } from '@/Utils/zIndexes';

export const StyledModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  z-index: ${ZIndex.MODAL};
`;

export const StyledModalContainer = styled.div`
  background: #ffffff;
  border-radius: 10px;
  width: 90%;
  position: relative;

  @media screen and (min-width: 700px) {
    width: 450px;
  }
`;

export const StyledModalContent = styled.div`
  padding: 1.5rem 0;
`;

export const StyledModalFormContent = styled.div`
  width: 90%;
  margin: 1rem auto;
`;

export const StyledModalCancelBtn = styled(StyledCross)`
  color: ${Palette.NEW_BLUE};
  font-weight: bolder;
  cursor: pointer;
`;
