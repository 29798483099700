import { PaymentIntent } from '@stripe/stripe-js';

class BrowserStorage {
  paymentIntent: string;

  constructor() {
    this.paymentIntent = 'payment_intent';
  }

  setPaymentIntent(paymentIntent: PaymentIntent) {
    localStorage.setItem(this.paymentIntent, JSON.stringify(paymentIntent));
  }

  getPaymentIntent() {
    const paymentIntent = localStorage.getItem(this.paymentIntent);

    if (!paymentIntent) return null;

    return JSON.parse(paymentIntent) as PaymentIntent;
  }

  removePaymentIntent() {
    localStorage.removeItem(this.paymentIntent);
  }
}

const browserStorage = new BrowserStorage();

export default browserStorage;
