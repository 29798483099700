import React, { ReactElement } from 'react';
import { Page, SupportingDocumentHero } from '@wills/components';
import { interpolate, possessive } from '@wills/helpers';
import { ProfileStore } from '@/Stores';
import { AssetsInfo } from './Components';

import locales from './AssetsList.locales.json';

const locale = locales.EN;

type Props = {
  renderNav : () => ReactElement;
}

export const AssetsList: React.FC<Props> = ({renderNav}: Props) => {
  const { profile } = React.useContext(ProfileStore);

  return (
    <Page>
      {renderNav()}
      <SupportingDocumentHero
        tag={locale.tag}
        title={interpolate(locale.title, {
          name: possessive(profile?.firstName ?? ''),
        })}
        description={locale.description}
        warning={locale.warning}
      />
      <AssetsInfo />
    </Page>
  );
};
