import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';
import { StyledWillsText } from '../../components/styles';

export const StyledSpecifyBequestPageContainer = styled.div`
  background-color: ${Palette.AQUA_HAZE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledSpecifyBequestGrid = styled.div`
  display: grid;
  grid-template-columns: minmax(0, 0.75fr) minmax(0, 0.25fr);
  gap: 2rem;
  min-height: 30rem;

  @media (max-width: 720px) {
    grid-template-columns: 1fr;
  }
`;

export const StyledSpecifyBequestContentContainer = styled.div`
  width: min(70%, 1366px);
  margin: 5rem auto;

  @media (max-width: 720px) {
    width: 85%;
  }
`;

export const StyledSpecifyBequestInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledBequestText = styled(StyledWillsText)`
  text-align: center;
  color: ${Palette.PLANET_BLUE};

  @media screen and (min-width: 700px) {
    margin-top: 1.5rem;
  }
`;

export const StyledNavigationButtonsWrapper = styled.div`
  margin-top: auto;
`;

export const StyledRelativeTipsWrapper = styled.div`
  @media (max-width: 720px) {
    display: none;
  }
`;

export const StyledChildGuardiansTextMessage = styled.p<{ center?: boolean }>`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
  margin-top: 1rem;
  ${({ center }) => center && 'text-align: center;'}
`;

export const StyledChildGuardiansChooserContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
`;

export const StyledChildGuardiansChooserContent = styled.div`
  width: 45%;
  min-width: 18rem;
  gap: 2rem;
  display: flex;
`;

export const StyledBackupTrusteesContainer = styled.div`
  margin-top: 2rem;
`;

export const StyledBackupChildGuardiansContainer = styled.div`
  margin-top: 1.5rem;
  display: flex;
  justify-content: center;
`;

export const StyledBackupChildGuardiansContent = styled.div`
  width: 80%;
  min-width: 23rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;

  @media (max-width: 720px) {
    width: 85%;
    min-width: 21rem;
  }
`;

export const StyledBequestContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1.5rem;
`;

export const StyledBequestListContainer = styled.div`
  width: 60%;
  margin: 0 auto;

  @media screen and (max-width: 600px) {
    width: 100%;
  }
`;
