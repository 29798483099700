/* eslint-disable no-console */

import { makeAutoObservable, runInAction } from 'mobx';
import { createContext } from 'react';

import { getUserContact, postUserContact, putUserContact } from '@/Api/Contact';
import { IError, IUserContact, IUpdateContact } from '@/Types';
import { snakeToCamelParser } from '@/Utils';

export class ContactStore {
  contact: IUserContact | null = null;

  error: IError | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  setContact = (contact: IUserContact) => {
    this.contact = contact;
  };

  setContactOrHandleError(data: any) {
    if (data.Code) {
      this.error = data;
      this.contact = null;
    } else {
      this.contact = data;
      this.error = null;
    }
  }

  loadContact = async () => {
    try {
      const data = await getUserContact();
      const contact = snakeToCamelParser(data);
      runInAction(() => {
        this.setContactOrHandleError(contact);
      });
    } catch (err) {
      console.error(err);
    }
  };

  createContact = async (contactData: IUserContact) => {
    try {
      const data = await postUserContact(contactData);
      const contact = snakeToCamelParser(data);
      runInAction(() => {
        this.setContactOrHandleError(contact);
      });
    } catch (err) {
      console.error(err);
    }
  };

  editContact = async (contactData: IUpdateContact) => {
    try {
      const data = await putUserContact(contactData);
      const updatedContact = snakeToCamelParser(data);
      runInAction(() => {
        this.setContactOrHandleError(updatedContact);
      });
    } catch (err) {
      console.error(err);
    }
  };

  clearContactStore = () => {
    this.contact = null;
    this.error = null;
  };
}

export default createContext(new ContactStore());
