import React, { useContext } from 'react';

import { LocaleContext } from '@/providers';

import {
  StyledInvestErrorContainer,
  StyledInvestErrorHeader,
  StyledInvestErrorTextWrapper,
  StyledMailLink,
} from './InvestError.styles';
import investErrorLocales from './investErrorLocales.json';

const InvestError = () => {
  const { locale } = useContext(LocaleContext);
  const investErrorLocale = investErrorLocales[locale];

  return (
    <StyledInvestErrorContainer>
      <StyledInvestErrorTextWrapper>
        <StyledInvestErrorHeader>
          {investErrorLocale['text.error-message-part-one']}
          &nbsp;
          <StyledMailLink href="mailto:support@manzil.ca">support@manzil.ca</StyledMailLink>
          &nbsp;
          {investErrorLocale['text.error-message-part-two']}
        </StyledInvestErrorHeader>
      </StyledInvestErrorTextWrapper>
    </StyledInvestErrorContainer>
  );
};

export default InvestError;
