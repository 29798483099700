import { axiosClient, toApiResponse } from '@/lib/axios';

export interface LoginInput {
  email: string;
  password: string;
}

export type LoginDto =
  | {
      Message: string;
    }
  | {
      AccessToken: string;
      zendeskToken: string;
    };

export const login = (input: LoginInput) => {
  return toApiResponse<LoginDto>(axiosClient.post('/login', input, { withCredentials: true }));
};
