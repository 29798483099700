import React from 'react';

import { StyledNavigationButton, StyledNavigationButtonsContainer } from './NavigationButtons.styles';
import { INavigationButtonsProps } from './NavigationButtons.types';

const NavigationButtons = ({
  onContinue,
  onPrevious,
  continueDisbaled,
  confirmBtnText,
  cancelBtnText,
  confirmBtnType,
}: INavigationButtonsProps) => {
  return (
    <StyledNavigationButtonsContainer>
      <StyledNavigationButton type="button" onClick={onPrevious}>
        {cancelBtnText || 'Previous'}
      </StyledNavigationButton>
      <StyledNavigationButton
        type={confirmBtnType || 'button'}
        primary
        onClick={onContinue}
        disabled={continueDisbaled}
      >
        {confirmBtnText || 'Continue'}
      </StyledNavigationButton>
    </StyledNavigationButtonsContainer>
  );
};

export default NavigationButtons;
