import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Palette } from '@/Shared/Constants';

export const ProductSelectorWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${Palette.AQUA_HAZE};
  align-items: center;
  min-height: 100vh;
`;

export const HeaderWrapper = styled.div`
  margin-top: 4rem;
  margin-bottom: 2rem;
`;

export const ProductListContainer = styled.div`
  width: min(35rem, 100%);
  margin: 0 auto;
  padding: 2rem 1rem;
`;

export const StyledBlockLetter = styled.div`
  display: inline-block;
  position: relative;
`;

export const StyledLetter = styled.span`
  &:before {
    position: absolute;
    content: '';
    width: 80%;
    height: 3px;
    top: 15%;
    left: 25%;
    background: ${Palette.GUN_POWDER};
  }
`;

export const ProductLink = styled(Link)`
  text-decoration: none;
`;

export const ProductLinkExternal = styled.a`
  text-decoration: none;
`;
