import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  StyledForm,
  StyledInputField,
  StyledFormLabel,
  StyledInputGroup,
  StyledInfoIconWrapper,
  StyledInfoIconContainer,
  StyledInfoIcon,
  WillsProfileContext,
  HiddenSubmitButton,
  EAboutYouSteps,
  InfoContentWrapper,
} from '@/lib/wills/components';
import { AboutYouContainer, Content, RadioButtonContainer, FormHeader, FormFooter, IWillsProps } from '../../shared';

import MaritalStatusQuestionInfo from './MaritalStatusQuestionInfo';
import { Modal } from '@/Shared/Components';

const MaritalStatusQuestion = ({ goBack, isEdit, goToSummaryPage }: IWillsProps) => {
  const { profileData, recordProfile, updateProfile, isOnboarding, createProfile, goToStep } =
    useContext(WillsProfileContext);
  const isSingleSelected = profileData?.marritalStatus === 'SINGLE';
  const isMarriedSelected = profileData?.marritalStatus === 'MARRIED';
  const isNoneSelected = !(isSingleSelected || isMarriedSelected);
  const [showInfoBlurb, setShowInfoBlurb] = useState(false);

  const { handleSubmit, register } = useForm();

  const onMaritalStatusSubmit = (_: any, event?: React.BaseSyntheticEvent) => {
    const formEvent = event as React.KeyboardEvent<HTMLFormElement>;
    if (isNoneSelected) return;

    if (!isOnboarding) {
      updateProfile({ marritalStatus: profileData?.marritalStatus });
    } else {
      createProfile({ marritalStatus: profileData?.marritalStatus });
    }
    if (formEvent && formEvent.target?.id === 'summary-btn') {
      goToSummaryPage!();
    } else {
      goToStep(EAboutYouSteps.CHILDREN_QUESTION);
    }
  };

  return (
    <AboutYouContainer>
      <InfoContentWrapper InfoContent={MaritalStatusQuestionInfo}>
        <Content>
          <FormHeader subHeader="ABOUT YOU" header="What is your marital status today?" />
          <StyledForm onSubmit={handleSubmit(onMaritalStatusSubmit)}>
            <StyledInputGroup flexCol>
              <RadioButtonContainer
                onClick={() => recordProfile('marritalStatus', 'SINGLE')}
                selected={isSingleSelected}
              >
                <StyledInputField
                  {...register('marritalStatus')}
                  radio
                  type="radio"
                  value="SINGLE"
                  checked={isSingleSelected}
                  data-testid="single-radio-btn"
                />
                <StyledFormLabel>Single</StyledFormLabel>
              </RadioButtonContainer>
              <RadioButtonContainer
                onClick={() => recordProfile('marritalStatus', 'MARRIED')}
                selected={isMarriedSelected}
              >
                <StyledInputField
                  {...register('marritalStatus')}
                  radio
                  type="radio"
                  value="MARRIED"
                  checked={isMarriedSelected}
                  data-testid="married-radio-btn"
                />
                <StyledFormLabel>Married</StyledFormLabel>
              </RadioButtonContainer>
            </StyledInputGroup>
            <HiddenSubmitButton type="submit" />
          </StyledForm>

          <StyledInfoIconWrapper>
            <StyledInfoIconContainer onClick={() => setShowInfoBlurb(true)}>
              <StyledInfoIcon />
            </StyledInfoIconContainer>
          </StyledInfoIconWrapper>

          {showInfoBlurb && (
            <Modal transparent onCloseCallback={() => setShowInfoBlurb(false)}>
              <MaritalStatusQuestionInfo />
            </Modal>
          )}

          <FormFooter
            previousStep={EAboutYouSteps.DOB_QUESTION}
            goBack={goBack}
            handleSubmit={handleSubmit}
            onFormSubmit={handleSubmit(onMaritalStatusSubmit)}
            isEdit={isEdit}
          />
        </Content>
      </InfoContentWrapper>
    </AboutYouContainer>
  );
};

export default MaritalStatusQuestion;
