import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 1rem 0.8rem;
  gap: 1rem;

  background-color: ${Palette.WHITE};
  border-radius: 0.5rem;
  border: 1px solid ${Palette.LIGHT_GREY};
  width: 320px;
`;

export const Image = styled.img`
  height: 6rem;
`;

export const StyledDocumentCardTitle = styled.p`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Bold';
`;

export const StyledDocumentCardDescription = styled.p`
  color: ${Palette.CADET_BLUE_DARKER};
  font-family: 'SofiaPro-Regular';
`;

export const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 8px;
`;
