import { toApiResponse, axiosClient } from '@/lib/axios';
import { PopulatedDocumentType } from './types';

export const getDocumentViewUrl = async (type: PopulatedDocumentType) => {
  return toApiResponse<string>(
    axiosClient.post(
      '/wills/document/view',
      {},
      {
        params: {
          'document-type': type,
        },
      },
    ),
  );
};
