import React from 'react';
import { Message } from './FormMessage.styles';

interface IProps {
  children: string;
}

const FormMessage: React.FC<IProps> = ({ children }) => {
  return <Message>{children}</Message>;
};

export default FormMessage;
