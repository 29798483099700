import styled from 'styled-components';

import { HtmlHTMLAttributes } from 'react';
import { Palette } from '@/Shared/Constants';
import { StyledRelativeAdderInputHelperTextProps } from './RelativeAdderForm.types';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledRelativeFormContainer = styled.div`
  margin: 2rem auto 2rem auto;
  width: 90%;

  @media (min-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    width: fit-content;
  }
`;

export const StyledRelativeAdderForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.5rem;
`;

export const StyledRelativeAdderFormName = styled.h2`
  font-family: 'SofiaPro-Regular';
  color: ${Palette.GUN_POWDER};
  margin-bottom: 1rem;
`;

export const StyledRelativeFormInputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  min-width: 50%;
  flex-direction: column;

  @media (min-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    flex-direction: row;
  }
`;

export const StyledRelativeAdderFormInputGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const StyledRelativeAdderFormLabel = styled.label<HtmlHTMLAttributes<HTMLLabelElement>>`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
  margin-bottom: 0.5rem;
`;

export const StyledRelativeAdderInputHelperText = styled.label<StyledRelativeAdderInputHelperTextProps>`
  color: ${({ type }) => (type && type !== 'error' ? 'grey' : 'red')};
  font-family: 'SofiaPro-Regular';
  font-size: 0.7rem;
`;

export const StyledRelativeAdderFormInput = styled.input`
  padding: 0.5rem 0.8rem;
  border-radius: 0.3rem;
  font-family: 'SofiaPro-Regular';
  font-size: clamp(0.9rem, 1vw, 1rem);
  border: 1px solid ${Palette.GREY};
  color: ${Palette.GUN_POWDER};
`;

export const StyledRelativeAdderFormSelect = styled.select`
  padding: 0.5rem 0.8rem;
  border-radius: 0.3rem;
  font-size: clamp(0.9rem, 1vw, 1rem);
  border: 1px solid ${Palette.LIGHT_GREY};
  color: ${Palette.GUN_POWDER};
  text-transform: capitalize;
`;

export const StyledRelativeAdderFormButtonGroup = styled.div`
  margin-top: 1.5rem;
`;
