import React, { createContext, useEffect, useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';

import { axiosClient } from '@/lib/axios';
import { AddressStore, ContactStore, ProfileStore, TokenStore } from '@/Stores';
import { IUser } from '@/Types';
import browserStorage from '@/Utils/browserStorage';

import { getUser } from './AuthProviderHelpers';

export interface AuthContextInterface {
  loggedIn: boolean;
  user: IUser | null;
  loadingUser: boolean;
  logout: () => void;
  setUser: React.Dispatch<React.SetStateAction<IUser | null>>;
}

export const AuthContext = createContext<AuthContextInterface>({} as AuthContextInterface);

interface IProps {
  children: React.ReactNode;
}

export const AuthProvider: React.FC<IProps> = observer(({ children }) => {
  const { token, removeToken, removeZendeskToken } = useContext(TokenStore);
  const { setProfile, clearProfileStore } = useContext(ProfileStore);
  const { setAddress, clearAddressStore } = useContext(AddressStore);
  const { setContact, clearContactStore } = useContext(ContactStore);

  const [user, setUser] = useState<IUser | null>(null);
  const [loadingUser, setLoadingUser] = useState(true);

  const setCurrentUser = async () => {
    try {
      const currentUser = await getUser();
      if (currentUser.email) {
        setUser(currentUser);
        if (currentUser.profile[0]) setProfile(currentUser.profile[0]);
        if (currentUser.contact[0]) setContact(currentUser.contact[0]);
        if (currentUser.address[0]) setAddress(currentUser.address[0]);
      } else {
        setUser(null);
      }
    } catch (err) {
      setUser(null);
    }
  };

  const logout = () => {
    setUser(null);
    removeToken();
    removeZendeskToken();
    clearProfileStore();
    clearAddressStore();
    clearContactStore();
    browserStorage.removePaymentIntent();
  };

  useEffect(() => {
    setLoadingUser(true);
    if (token) {
      setCurrentUser().finally(() => setLoadingUser(false));
    } else {
      logout();
      setLoadingUser(false);
    }
  }, [token]);

  useEffect(() => {
    const authInterceptor = axiosClient.interceptors.response.use(
      (value) => value,
      (error) => {
        // Naive check since error has no good typing
        try {
          if (error.response?.data?.Code === 'NotAuthorizedException') {
            logout();
          }
        } catch (e) {
          // Do nothing
        }
        return Promise.reject(error);
      },
    );
    return () => {
      axiosClient.interceptors.response.eject(authInterceptor);
    };
  }, []);

  const loggedIn = Boolean(user);

  const value = {
    loggedIn,
    user,
    loadingUser,
    logout,
    setUser,
  };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
});
