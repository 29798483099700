import React from 'react';

import {
  BannerImage,
  Container,
  Description,
  Title,
  ContentContainer,
  IconContainer,
  CollapseStateIcon,
  TextContainer,
} from './ExpandableCard.styles';

import { rightChevron } from './assets';

export interface ExpandableCardProps {
  bannerImageSrc?: string;
  expand?: boolean;
  locked?: boolean;
  onClick?: () => void;
  title: string;
  description: string;
  renderLeftIcon: () => JSX.Element;
  children?: React.ReactNode;
}

export const ExpandableCard: React.FC<ExpandableCardProps> = ({
  bannerImageSrc,
  expand = false,
  locked = false,
  onClick,
  renderLeftIcon,
  title,
  description,
  children,
}) => {
  return (
    <Container isExpanded={expand} onClick={onClick}>
      {bannerImageSrc && <BannerImage src={bannerImageSrc} />}
      <ContentContainer>
        <IconContainer>{renderLeftIcon()}</IconContainer>
        <TextContainer>
          <Title>{title}</Title>
          <Description isExpanded={expand}>{description}</Description>
          {expand && children}
        </TextContainer>
        <IconContainer>
          <CollapseStateIcon
            src={rightChevron}
            alt={expand ? 'collapse' : 'expand'}
            isExpanded={expand}
            isHidden={locked}
          />
        </IconContainer>
      </ContentContainer>
    </Container>
  );
};
