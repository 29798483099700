import React, { useContext, useEffect, useState } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { SetupIntent, Stripe } from '@stripe/stripe-js';
import { SnackBarContext } from '@/providers';
import { Loading } from '@/Shared/Components/Loading';
import { getStripe } from '@/lib/payment/stripe/stripeConfig';
import { getOrCreateSetupIntent } from './CardElement.api';
import { CardForm } from '../CardForm';

interface ICardElementProps {
  onSuccess?: () => void;
}

const CardElement = ({ onSuccess }: ICardElementProps) => {
  const { setSnackMessage } = useContext(SnackBarContext);
  const [intent, setIntent] = useState<SetupIntent | null>(null);
  const [stripePromise, setStripePromise] = useState<Promise<Stripe | null>>();

  async function createIntent(signal: AbortSignal) {
    try {
      const paymentIntent = await getOrCreateSetupIntent(signal);
      setIntent(paymentIntent);
    } catch (error) {
      const isErrorInstance = error instanceof Error;
      const isCanceledError = isErrorInstance && error.message === 'CanceledError';

      if (isCanceledError) return;

      const errorMessage = isErrorInstance ? error.message : 'Something went wrong';
      setSnackMessage(errorMessage);
    }
  }

  async function onSavePaymentFormSubmit() {
    if (onSuccess) {
      onSuccess();
    }
  }

  useEffect(() => {
    const controller = new AbortController();

    createIntent(controller.signal);

    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    setStripePromise(getStripe());
  }, []);

  if (!stripePromise || !intent) return <Loading />;

  return (
    <Elements options={{ clientSecret: intent.client_secret as string }} stripe={stripePromise}>
      <CardForm intent={intent} onSubmit={onSavePaymentFormSubmit} />
    </Elements>
  );
};

export default CardElement;
