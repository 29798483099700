import React, { FC } from 'react';
import { StyledCheck, StyledSubmitButtonContainer } from './SubmitButton.styles';

interface IProps {
  onClick: any;
  disabled: boolean;
}

const SubmitButton: FC<IProps> = ({ onClick, disabled }) => {
  return (
    <StyledSubmitButtonContainer disabled={disabled}>
      <StyledCheck onClick={onClick} />
    </StyledSubmitButtonContainer>
  );
};

export default SubmitButton;
