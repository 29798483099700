import React from 'react';
import { Link } from 'react-router-dom';
import {
  StyledPaymentCardContainer,
  StyledPaymentConfirmedSection,
  StyledPaymentContainer,
  StyledPaymentContent,
  StyledPaymentIconContainer,
  StyledPaymentSuccessIcon,
  StyledPaymentSummaryHeader,
  StyledPaymentSummaryHeaderContainer,
  StyledPaymentSummaryParagraph,
  StyledPaymentSummarySubtitle,
} from './PaymentSummary.styles';
import { StyledPaymentCard, StyledPrimaryButton } from '../../components/styles';

const PaymentSummary = () => {
  return (
    <StyledPaymentContainer>
      <StyledPaymentContent>
        <StyledPaymentConfirmedSection>
          <StyledPaymentIconContainer>
            <StyledPaymentSuccessIcon />
          </StyledPaymentIconContainer>
          <StyledPaymentSummaryHeaderContainer>
            <StyledPaymentSummaryHeader>Payment Successful!</StyledPaymentSummaryHeader>
          </StyledPaymentSummaryHeaderContainer>
        </StyledPaymentConfirmedSection>

        <StyledPaymentSummaryHeaderContainer>
          <StyledPaymentSummaryHeader>What are my next steps?</StyledPaymentSummaryHeader>
        </StyledPaymentSummaryHeaderContainer>

        <StyledPaymentCardContainer>
          <StyledPaymentCard>
            <StyledPaymentSummarySubtitle>Download and print your documents</StyledPaymentSummarySubtitle>
            <StyledPaymentSummaryParagraph>
              Your documents are now ready to download. You must print, sign and store your documents to make them
              legal. Click the link below to go to your documents page.
            </StyledPaymentSummaryParagraph>
            <Link to="/wills/documents">
              <StyledPrimaryButton type="button">View documents</StyledPrimaryButton>
            </Link>
          </StyledPaymentCard>
        </StyledPaymentCardContainer>
      </StyledPaymentContent>
    </StyledPaymentContainer>
  );
};

export default PaymentSummary;
