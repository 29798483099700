import React from 'react';
import { WarningAlert } from '@/components';
import { Hero, HeroContent, Tagline, Title, Body, HeroImage, CenteredRow } from './SupportingDocumentHero.styles';
import locales from './SupportingDocumentHero.locales.json';
import { Illustration } from './assets';

const locale = locales.EN;
export interface ISupportingDocumentHeroProps {
  tag: string;
  title: string;
  description: string;
  warning: string;
}

export const SupportingDocumentHero: React.FC<ISupportingDocumentHeroProps> = ({
  tag,
  title,
  description,
  warning,
}) => {
  return (
    <Hero>
      <Tagline>{tag}</Tagline>
      <CenteredRow>
        <HeroContent>
          <Title>{title}</Title>
          <Body>{description}</Body>
          <WarningAlert>{warning}</WarningAlert>
        </HeroContent>
        <HeroImage src={Illustration} alt={locale.imageAlt} />
      </CenteredRow>
    </Hero>
  );
};
