import React, { useContext, useEffect, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { SnackBarContext } from '@/providers';
import { ComparePlans } from '../ComparePlans';
import { WillsPlans } from '../WillsPlans';
import {
  StyledWillsPlanContainer,
  StyledSmallHeader,
  StyledHeader,
  StyledWillsPlanWrapper,
} from './PlansDescription.styles';
import { StyledSecondaryButton } from '@/lib/wills/components/styles';
import { getWillsProfile } from '@/Api';
import { ProfileStore } from '@/Stores';
import { getErrorMessage } from '@/Utils';

const PlansDescription: React.FC<{ onBack: () => void }> = ({ onBack }) => {
  const [title, setTitle] = useState<string>('');
  const { profile } = useContext(ProfileStore);
  const { setSnackMessage } = useContext(SnackBarContext);
  const loadProfile = async () => {
    try {
      const willsProfileData = await getWillsProfile();
      if (profile?.marritalStatus === 'MARRIED' && willsProfileData.kids === 1) {
        setTitle('We recommend the Premium plan for you');
      } else {
        setTitle('We recommend the Essentials plan for you');
      }
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    }
  };

  useEffect(() => {
    loadProfile();
  }, []);

  useEffect(() => {
    amplitude.track('Wills Plan Flow Started');
  }, []);

  return (
    <StyledWillsPlanWrapper>
      <StyledWillsPlanContainer>
        <StyledSmallHeader>SELECT A PLAN</StyledSmallHeader>
        <StyledHeader>{title}</StyledHeader>
        <WillsPlans />
        <ComparePlans />
      </StyledWillsPlanContainer>
      <StyledSecondaryButton onClick={onBack}>Back</StyledSecondaryButton>
    </StyledWillsPlanWrapper>
  );
};

export default PlansDescription;
