import React from 'react';
import { PopulatedDocumentType } from '@wills/apis';
import { useStepsReviewNavigation } from '@wills/hooks';
import { Page } from '@wills/components';
import { useAnalytics } from '@/lib/analytics';
import {
  LegalDocuments,
  Appointees,
  DocumentsChecklist,
  SupportingDocuments,
  OpenPdfProvider,
  OpenPdfContext,
  SubscriptionInfo,
  SubscriptionContext,
  SubscriptionProvider,
} from './components';
import {
  RowWrap,
  PageInfoContainer,
  PageTitle,
  PageDescription,
  SubscriptionInfoMobileWrapper,
  SubscriptionInfoTabletWrapper,
} from './Documents.styles';
import { SnackBarContext } from '@/providers';
import { bindFuncToAsync } from '@/Utils';
import { DocumentsContext } from './Documents.context';
import { DocumentsHeader } from './components/DocumentsHeader';
import { PopulatedDocumentContext } from './PopulatedDocumentContext';

const withContexts = <P extends object>(Component: React.ComponentType<P>) => {
  const WrappedComponent: React.FC<P> = (props) => {
    return (
      <SubscriptionProvider>
        <OpenPdfProvider>
          <Component {...props} />
        </OpenPdfProvider>
      </SubscriptionProvider>
    );
  };

  return WrappedComponent;
};

const Documents: React.FC = withContexts(() => {
  const { goToReview } = useStepsReviewNavigation();
  const { documents, regenerateDocuments, getDownloadUrl, getViewUrl } = React.useContext(PopulatedDocumentContext);
  const { setSnackMessage } = React.useContext(SnackBarContext);
  const { openUrl } = React.useContext(OpenPdfContext);
  const { userProfile, willsProfile, isSubscriptionFeatureSetEnabled } = React.useContext(DocumentsContext);
  const { openSubscribePopup } = React.useContext(SubscriptionContext);

  const { sendEventMessage } = useAnalytics({
    messageOnload: 'Documents page viewed.',
  });

  const [isLoading, setIsLoading] = React.useState(false);

  const bindLoadingToAsync = bindFuncToAsync(setIsLoading);

  const downloadDocumentAndOpen = bindLoadingToAsync(async (type: PopulatedDocumentType) => {
    const { data, error } = await getDownloadUrl(type);

    if (error) {
      setSnackMessage('Operation failed. Please regenerate the document, then try again.');
      return;
    }

    sendEventMessage(`Downloaded ${type} document.`);
    openUrl({
      type: 'download',
      url: data,
    });
  });

  const viewDocumentAndOpen = bindLoadingToAsync(async (type: PopulatedDocumentType) => {
    const { data, error } = await getViewUrl(type);

    if (error) {
      setSnackMessage('Operation failed. Please regenerate the document, then try again.');
      return;
    }

    sendEventMessage(`Viewed ${type} document.`);

    openUrl({
      type: 'view',
      url: data,
    });
  });

  const regenerateLegalDocuments = bindLoadingToAsync(async () => {
    if (
      documents.LAST_WILL_AND_TESTAMENT &&
      documents.POA_FOR_PERSONAL_CARE &&
      documents.POA_FOR_PROPERTY &&
      !isSubscriptionFeatureSetEnabled
    ) {
      openSubscribePopup();
      return;
    }

    const { error } = await regenerateDocuments([
      'LAST_WILL_AND_TESTAMENT',
      'POA_FOR_PROPERTY',
      'POA_FOR_PERSONAL_CARE',
    ]);

    setSnackMessage(error ? 'Failed to update document' : 'Successfully updated document');
  });

  return (
    <Page>
      <RowWrap>
        <PageInfoContainer>
          <PageTitle>{userProfile.firstName}&apos;s documents</PageTitle>
          <SubscriptionInfoMobileWrapper>
            <SubscriptionInfo />
          </SubscriptionInfoMobileWrapper>
          <PageDescription>
            Here is where the important stuff is kept. View, download, and edit the latest versions of your documents
            here.
          </PageDescription>
          <DocumentsHeader />
        </PageInfoContainer>
        <SubscriptionInfoTabletWrapper>
          <SubscriptionInfo />
        </SubscriptionInfoTabletWrapper>
      </RowWrap>
      <DocumentsChecklist disabled={isLoading} />

      <LegalDocuments
        documents={documents}
        viewDocument={viewDocumentAndOpen}
        downloadDocument={downloadDocumentAndOpen}
        regenerateDocuments={regenerateLegalDocuments}
        updateWills={goToReview}
        plan={willsProfile.plan}
        disabled={isLoading}
      />
      <SupportingDocuments documents={documents} viewDocument={downloadDocumentAndOpen} disabled={isLoading} />
      <Appointees disabled={isLoading} />
    </Page>
  );
});

export default Documents;
