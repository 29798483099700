import { ApiResponse, axiosClient, toApiResponse } from '@/lib/axios';
import { ISingleAsset } from './types';

export type DeleteWillsAssetInput = {
  id: number;
};

export const deleteWillsAssets = async (listId: number, id: number): Promise<ApiResponse<ISingleAsset>> => {
  const response = await toApiResponse<ISingleAsset>(axiosClient.delete(`/wills/assets/asset/${id}`));

  return {
    ...response,
  };
};
