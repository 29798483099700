import React from 'react';
import {
  StyledReviewHeaderContainer,
  StyledSingleReviewWrapper,
  StyledHeaderText,
  StyledReviewDescriptionContainer,
  StyledInfo,
  StyledTitle,
  StyledAuthor,
} from './Reviews.styles';
import { IReview } from './Reviews.types';
import { StyledImg } from '../../Introduction.styles';
import { checkboxSvg, starsSvg } from '@/Assets/images';

const Reviews = ({ title, description, author, active }: IReview) => {
  return (
    <StyledSingleReviewWrapper active={active}>
      <StyledReviewHeaderContainer>
        <StyledImg src={starsSvg} alt="stars svg" />
        <StyledImg src={checkboxSvg} alt="checkbox svg" />
        <StyledHeaderText>Invited</StyledHeaderText>
      </StyledReviewHeaderContainer>
      <StyledReviewDescriptionContainer>
        <StyledTitle>{title}</StyledTitle>
        <StyledInfo>{description}</StyledInfo>
        <span>
          <StyledAuthor>- {author}</StyledAuthor>
        </span>
      </StyledReviewDescriptionContainer>
    </StyledSingleReviewWrapper>
  );
};

export default Reviews;
