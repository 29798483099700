import styled from 'styled-components';

export const StyledBequestTextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
`;

export const StyledBequestSpan = styled.span`
  display: inline-block;

  width: 2.5rem;
`;
