import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  StyledForm,
  StyledInputField,
  StyledFormLabel,
  StyledInputGroup,
  StyledInfoIconWrapper,
  StyledInfoIconContainer,
  StyledInfoIcon,
  InfoContentWrapper,
  EAboutYouSteps,
  WillsProfileContext,
  HiddenSubmitButton,
} from '@wills/components';

import { AboutYouContainer, Content, RadioButtonContainer, FormHeader, FormFooter, IWillsProps } from '../../shared';

import { METHOD_OF_CALCULATIONS } from './MadhabQuestion.constants';
import { IMadhabQuestionFormValue } from './MadhabQuestion.types';
import MadhabQuestionInfo from './MadhabQuestionInfo';
import { Modal } from '@/Shared/Components';

const MadhabQuestion = ({ goBack, isEdit, goToSummaryPage }: IWillsProps) => {
  const {
    willsProfileData,
    recordWillsProfile,
    createWillsProfile,
    updateWillsProfile,
    goToStep,
    willsOnboardingComplete,
    addressData,
  } = useContext(WillsProfileContext);
  const [showInfoBlurb, setShowInfoBlurb] = useState(false);

  const { handleSubmit, register, watch } = useForm<IMadhabQuestionFormValue>({
    defaultValues: {
      methodOfCalculation: willsProfileData.methodOfCalculation ?? METHOD_OF_CALCULATIONS[0].name,
    },
  });

  const onSubmit = handleSubmit((formData, event) => {
    const data = {
      methodOfCalculation: formData.methodOfCalculation,
    };
    if (willsOnboardingComplete) {
      updateWillsProfile({ ...data });
    } else {
      createWillsProfile({ ...data });
    }

    recordWillsProfile('methodOfCalculation', formData.methodOfCalculation);

    if (event && event.target?.id === 'summary-btn') {
      goToSummaryPage!();
      return;
    }
    if (addressData.country === '') {
      goToStep(EAboutYouSteps.ADDRESS_QUESTION);
    } else {
      goToStep(EAboutYouSteps.LOCATION_QUESTION);
    }
  });

  const currentValue = watch().methodOfCalculation;

  return (
    <AboutYouContainer>
      <InfoContentWrapper InfoContent={MadhabQuestionInfo}>
        <Content>
          <FormHeader
            subHeader="ABOUT YOU"
            header="Which Madh'hab (School of Thought) would you like to follow for inheritance
            calculator?"
          />
          <StyledForm onSubmit={onSubmit}>
            <StyledInputGroup flexColSm>
              {METHOD_OF_CALCULATIONS.map((method, index) => {
                return (
                  <RadioButtonContainer key={index} selected={currentValue === method.name} htmlFor={method.name}>
                    <StyledInputField
                      {...register('methodOfCalculation')}
                      radio
                      id={method.name}
                      type="radio"
                      value={method.name}
                    />
                    <StyledFormLabel>{method.info}</StyledFormLabel>
                  </RadioButtonContainer>
                );
              })}
            </StyledInputGroup>
            <HiddenSubmitButton type="submit" />
          </StyledForm>

          <StyledInfoIconWrapper>
            <StyledInfoIconContainer onClick={() => setShowInfoBlurb(true)}>
              <StyledInfoIcon />
            </StyledInfoIconContainer>
          </StyledInfoIconWrapper>

          {showInfoBlurb && (
            <Modal transparent onCloseCallback={() => setShowInfoBlurb(false)}>
              <MadhabQuestionInfo />
            </Modal>
          )}

          <FormFooter
            previousStep={EAboutYouSteps.GENDER_QUESTION}
            goBack={goBack}
            handleSubmit={handleSubmit}
            onFormSubmit={onSubmit}
            isEdit={isEdit}
          />
        </Content>
      </InfoContentWrapper>
    </AboutYouContainer>
  );
};

export default MadhabQuestion;
