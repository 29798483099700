import country from 'country-list-js';
import productSelectorLocales from './productSelectorLocales.json';

export const containsOnlyUppercase = (citizenshipString: string) => {
  return /^[A-Z]+$/.test(citizenshipString);
};

export const isValidCitizenship = (citizenship: string) => {
  if (containsOnlyUppercase(citizenship.trim()) && citizenship.trim().length === 2) {
    const countryInfo = country.findByIso2(citizenship.trim());
    if (countryInfo === undefined) return false;
    return true;
  }
  return false;
};

export const splitContactNumber = (contactNumber: string) => {
  const countryCode = contactNumber.split('(')[0];
  const phoneNumber = `(${contactNumber.split('(')[1]}`;
  return { countryCode, phoneNumber };
};

export const renderGreetingsText = (locale: 'EN' | 'FR' = 'EN'): string => {
  const productSelectorLocale = productSelectorLocales[locale];
  const currentHour = new Date().getHours();
  const currentMinutes = new Date().getMinutes();

  if ((currentHour >= 5 && currentHour < 12) || (currentHour === 12 && currentMinutes === 0)) {
    return productSelectorLocale['header.morning'];
  }
  if (currentHour >= 12 && currentHour < 17) {
    return productSelectorLocale['header.noon'];
  }
  return productSelectorLocale['header.evening'];
};
