import React, { ReactElement } from 'react';

import { IWish } from '@wills/apis';
import { useNavigate } from 'react-router-dom';
import { Page, SupportingDocumentHero } from '@wills/components';
import { interpolate, possessive } from '@wills/helpers';
import locales from './AdditionalWishes.locales.json';

import { WishesContainer } from './AdditionalWishes.styles';

import { WishForm, MyWishes, SecondaryButton, PrimaryButton, WishFormValues } from './components';
import { useScrollToTop } from '@/Shared/Hooks';
import { ProfileStore } from '@/Stores';

import { bindFuncToAsync } from '@/Utils';
import { withScrollIntoViewOnLoad } from './hoc';
import { PopulatedDocumentContext } from '../Documents/PopulatedDocumentContext';
import { useWishList } from '../../hooks/useWishList';

enum FormModeType {
  HIDDEN,
  ADD,
  EDIT,
}

type Props = {
  renderNav: () => ReactElement;
};

type FormMode =
  | {
      type: FormModeType.HIDDEN;
    }
  | {
      type: FormModeType.ADD;
    }
  | {
      type: FormModeType.EDIT;
      wish: IWish;
    };

const FocusedWishForm = withScrollIntoViewOnLoad(WishForm);

export const AdditionalWishes: React.FC<Props> = ({ renderNav }: Props) => {
  const navigate = useNavigate();
  const { profile } = React.useContext(ProfileStore);
  const { regenerateDocuments } = React.useContext(PopulatedDocumentContext);

  const [formMode, setFormMode] = React.useState<FormMode>({
    type: FormModeType.HIDDEN,
  });
  const { wishList, createWish, updateWish, deleteWish, completeWishList } = useWishList();
  const [acting, setActing] = React.useState(false);
  useScrollToTop();
  const bindActingToAsync = bindFuncToAsync(setActing);

  const showAddForm = () => {
    setFormMode({
      type: FormModeType.ADD,
    });
  };

  const showEditForm = (wish: IWish) => {
    setFormMode({
      type: FormModeType.EDIT,
      wish,
    });
  };

  const hideForm = () => {
    setFormMode({
      type: FormModeType.HIDDEN,
    });
  };

  const onComplete = bindActingToAsync(async () => {
    await completeWishList();
    await regenerateDocuments(['LETTER_OF_WISHES']);
    navigate('/wills/documents');
  });

  const onCreateWish = bindActingToAsync(async (values: WishFormValues) => {
    await createWish(values);
    hideForm();
  });

  const onUpdateWish = (id: number) =>
    bindActingToAsync(async (values: WishFormValues) => {
      await updateWish(id, values);
      hideForm();
    });

  const onDeleteWish = bindActingToAsync(async (wish: IWish) => {
    await deleteWish(wish.id);
  });

  const locale = locales.EN;
  const filteredWishes =
    formMode.type === FormModeType.EDIT ? wishList.wishes.filter(({ id }) => id !== formMode.wish.id) : wishList.wishes;

  return (
    <Page loading={acting}>
      {renderNav()}
      <SupportingDocumentHero
        tag={locale.header}
        title={interpolate(locale.title, {
          name: possessive(profile?.firstName ?? ''),
        })}
        description={locale.description}
        warning={locale.documentInfo}
      />
      <WishesContainer>
        <SecondaryButton onClick={showAddForm} disabled={acting || formMode.type !== FormModeType.HIDDEN}>
          {locale.addButtonLabel}
        </SecondaryButton>
        {formMode.type === FormModeType.EDIT && (
          <FocusedWishForm
            defaultValues={formMode.wish}
            onSubmit={onUpdateWish(formMode.wish.id)}
            onCancel={hideForm}
          />
        )}
        {formMode.type === FormModeType.ADD && <WishForm onSubmit={onCreateWish} onCancel={hideForm} />}
        {filteredWishes.length > 0 && (
          <MyWishes
            wishes={filteredWishes}
            onEdit={showEditForm}
            onDelete={onDeleteWish}
            disabled={acting}
            hideActions={formMode.type !== FormModeType.HIDDEN}
          />
        )}
        <PrimaryButton onClick={onComplete} disabled={acting || formMode.type !== FormModeType.HIDDEN}>
          {locale.updateButtonLabel}
        </PrimaryButton>
      </WishesContainer>
    </Page>
  );
};
