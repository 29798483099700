import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  StyledForm,
  StyledInfoIcon,
  StyledInfoIconContainer,
  StyledInfoIconWrapper,
  StyledInputGroup,
  EAboutYouSteps,
  InfoContentWrapper,
  WillsProfileContext,
  HiddenSubmitButton,
} from '@wills/components';

import { femaleSvgInactive, femaleSvgActive, maleSvgActive, maleSvgInactive } from '@/Assets/images';

import { AboutYouContainer, Content, FormHeader, FormFooter, IWillsProps } from '../../shared';

import { StyledSingleOptionContainer, StyledOptionButton } from './GenderQuestion.styles';
import GenderQuestionInfo from './GenderQuestionInfo';
import { Modal } from '@/Shared/Components';

const GenderQuestion = ({ goBack, isEdit, goToSummaryPage }: IWillsProps) => {
  const { handleSubmit } = useForm();
  const {
    willsOnboardingComplete,
    willsProfileData,
    goToStep,
    recordWillsProfile,
    createWillsProfile,
    updateWillsProfile,
  } = useContext(WillsProfileContext);
  const [isActive, setIsActive] = useState({ maleCardActive: false, femaleCardActive: false });
  const [showInfoBlurb, setShowInfoBlurb] = useState(false);
  const isNoneSelected = !(isActive.maleCardActive || isActive.femaleCardActive);

  const onGenderQuerySubmit = (_: any, event?: React.BaseSyntheticEvent) => {
    const formEvent = event as React.KeyboardEvent<HTMLFormElement>;
    if (isNoneSelected) return;

    if (willsOnboardingComplete) {
      updateWillsProfile({ sex: willsProfileData?.sex });
    } else {
      createWillsProfile({ sex: willsProfileData?.sex });
    }
    if (formEvent && formEvent.target?.id === 'summary-btn') {
      goToSummaryPage!();
    } else {
      goToStep(EAboutYouSteps.MADHAB_QUESTION);
    }
  };

  const onGenderChangeHandler = (type: string, activeCard: string, inActiveCard: string) => {
    recordWillsProfile('sex', type);

    setIsActive({
      ...isActive,
      [activeCard]: true,
      [inActiveCard]: false,
    });
  };

  useEffect(() => {
    setIsActive({
      maleCardActive: willsProfileData.sex === 'M',
      femaleCardActive: willsProfileData.sex === 'F',
    });
  }, []);

  return (
    <AboutYouContainer>
      <InfoContentWrapper InfoContent={GenderQuestionInfo}>
        <Content>
          <FormHeader subHeader="ABOUT YOU" header="What is your sex?" />
          <StyledForm onSubmit={handleSubmit(onGenderQuerySubmit)}>
            <StyledInputGroup>
              <StyledSingleOptionContainer
                onClick={() => onGenderChangeHandler('M', 'maleCardActive', 'femaleCardActive')}
              >
                <StyledOptionButton active={willsProfileData?.sex === 'M'}>Male</StyledOptionButton>
                <img src={isActive.maleCardActive ? maleSvgActive : maleSvgInactive} alt="male svg" />
              </StyledSingleOptionContainer>
              <StyledSingleOptionContainer
                onClick={() => onGenderChangeHandler('F', 'femaleCardActive', 'maleCardActive')}
              >
                <StyledOptionButton active={willsProfileData?.sex === 'F'}>Female</StyledOptionButton>
                <img src={isActive.femaleCardActive ? femaleSvgActive : femaleSvgInactive} alt="female svg" />
              </StyledSingleOptionContainer>
            </StyledInputGroup>
            <HiddenSubmitButton type="submit" />
          </StyledForm>

          <StyledInfoIconWrapper>
            <StyledInfoIconContainer onClick={() => setShowInfoBlurb(true)}>
              <StyledInfoIcon />
            </StyledInfoIconContainer>
          </StyledInfoIconWrapper>

          {showInfoBlurb && (
            <Modal transparent onCloseCallback={() => setShowInfoBlurb(false)}>
              <GenderQuestionInfo />
            </Modal>
          )}

          <FormFooter
            previousStep={EAboutYouSteps.CHILDREN_QUESTION}
            goBack={goBack}
            handleSubmit={handleSubmit}
            onFormSubmit={handleSubmit(onGenderQuerySubmit)}
            isEdit={isEdit}
          />
        </Content>
      </InfoContentWrapper>
    </AboutYouContainer>
  );
};

export default GenderQuestion;
