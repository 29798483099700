import React from 'react';
import { QuestionInfo } from '@/lib/wills/components/QuestionInfo';

const SpecifyBequestQuestionInfo = () => {
  return (
    <QuestionInfo
      question="An Important Note"
      answer={
        <div>
          You can leave bequests and gifts to people who may not inherit based on Shariah calculation. Keep in mind
          that, Islamic Law mandates that no more than 1/3 of the estate, after expenses are paid, can be given away to
          those that are not entitled to inherit.
        </div>
      }
    />
  );
};

export default SpecifyBequestQuestionInfo;
