import React, { ReactNode } from 'react';
import { StyledAnswer, StyledQuestionInfoContainer, StyledQuestion, StyledInfoIcon } from './QuestionInfo.styles';

export interface IQuestionInfo {
  question: string;
  answer: ReactNode;
}

export const QuestionInfo = ({ question, answer }: IQuestionInfo) => {
  return (
    <StyledQuestionInfoContainer>
      <StyledInfoIcon />
      <StyledQuestion>{question}</StyledQuestion>
      <StyledAnswer>{answer}</StyledAnswer>
    </StyledQuestionInfoContainer>
  );
};
