import { IRelationshipDefinition } from '@wills/apis';

export enum EFamilyFlowStep {
  INTRODUCTION,
  PRIMARY_RELATIVES,
  SECONDARY_RELATIVES,
  REVIEW,
}

export interface IWillsFamilyFlowContext {
  setStep: React.Dispatch<React.SetStateAction<EFamilyFlowStep>>;
  selectedRelativeDefinition: IRelationshipDefinition | null;
  setSelectedRelativeDefinition: React.Dispatch<React.SetStateAction<IRelationshipDefinition | null>>;
  primaryRelationships: IRelativeInfo[];
  setPrimaryRelationships: React.Dispatch<React.SetStateAction<IRelativeInfo[]>>;
  secondaryRelationships: IRelativeInfo[];
  setSecondaryRelationships: React.Dispatch<React.SetStateAction<IRelativeInfo[]>>;
}

export interface IRelativeInfo {
  id?: number;
  relationDefinitionId: number;
  firstName: string;
  lastName: string;
  dob: string;
  email: string;
  phone?: string;
}

export interface IRelativeResponse {
  id?: number;
  willsRelationshipId: number;
  firstName: string;
  middleName: string;
  lastName: string;
  dob: string;
  poaProperty: boolean;
  backupPoaProperty: boolean;
  poaPersonalCare: boolean;
  backupPoaPersonalCare: boolean;
  primaryChildGuardian: boolean;
  secondaryChildGuardian: boolean;
  stateExecutor: boolean;
  backupStateExecutor: boolean;
  userId: number;
  contact: IRelativeContactResponse | null;
  relationDefinition: IRelationshipDefinition;
}

export interface IRelativeContactResponse {
  cellPhone?: string;
  email: string;
  homePhone?: string;
  id: number;
  primaryPhone?: string;
  willsRelationshipId: number;
}

export interface IRelativeType {
  relation: string;
}
