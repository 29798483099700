import styled from "styled-components";

export const StyledBadge = styled.div<{ bgColor: string, textColor: string }>`
  background-color: ${({ bgColor }) => bgColor};
  font-family: 'SofiaPro';
  font-weight: bold;
  color: ${({ textColor }) => textColor};
  font-size: 0.6rem;
  text-align: center;
  text-transform: capitalize;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
`;