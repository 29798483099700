import React from 'react';
import {
  ContentHeading,
  EditIcon,
  EditIconContainer,
  FormContent,
  FormContentHeader,
  InfoWrapper,
  ProfileContainer,
  ProfileForm,
  UserIcon,
} from '../../Profile.style';
import { DisplayInfo } from '../DisplayInfo';
import { IPaymentDispayProps } from './PaymentDisplay.types';
import { usePaymentInfo } from '@/lib/payment/hooks/usePaymentInfo';

const PaymentDisplay = ({ isEditMode, setIsEditMode }: IPaymentDispayProps) => {
  const { paymentInfo } = usePaymentInfo();

  return (
    <ProfileContainer>
      <ProfileForm>
        <FormContent>
          <FormContentHeader>
            <ContentHeading>Payment and Subscription</ContentHeading>
            <ContentHeading>
              <EditIconContainer show={isEditMode}>
                <EditIcon onClick={() => setIsEditMode(true)} aria-label="edit" />
              </EditIconContainer>
            </ContentHeading>
          </FormContentHeader>

          <InfoWrapper>
            <DisplayInfo
              icon={<UserIcon />}
              header="Payment Information"
              value={paymentInfo && paymentInfo.paymentMethodId ? 'Credit Card' : 'None'}
            />
          </InfoWrapper>
        </FormContent>
      </ProfileForm>
    </ProfileContainer>
  );
};

export default PaymentDisplay;
