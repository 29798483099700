import { ApiResponse, axiosClient, toApiResponse } from '@/lib/axios';

export interface Invest {
  isOnboarding: boolean;
  profile: {
    firstName: string;
    middleName: string | null;
    lastName: string;
    dob: string;
    citizenship: string;
    preferredLanguage: string;
    marritalStatus: string;
  };
  contact: {
    primaryPhone: string;
    cellPhone: string;
  };
  address: {
    streetName: string;
    streetNumber: string;
    unitSuiteApt: string;
    city: string;
    provinceState: string;
    postalZip: string;
    country: string;
  };
}

export interface CreateInvestInput {
  profile: {
    firstName: string;
    middleName?: string;
    lastName: string;
    dob: string;
    citizenship: string;
    preferredLanguage: string;
    marritalStatus: string;
  };
  contact: {
    primaryPhone: string;
    cellPhone: string;
  };
  address: {
    streetName: string;
    streetNumber: string;
    unitSuiteApt: string;
    city: string;
    provinceState: string;
    postalZip: string;
    country: string;
  };
}

export const createInvest = async (input: CreateInvestInput): Promise<ApiResponse<Invest>> => {
  /**
   * Needs to:
   * 1. Update profile, contact, address, and set onboarding to false(might not need this field)
   * 2. Create the user in onevest
   * 3. Add onevest as a product for the user
   */
  return toApiResponse<Invest>(axiosClient.post('/invest/create', input));
};
