import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledNavigationButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 75%;
  margin-top: 2rem;
  gap: 1rem;

  @media (max-width: ${Breakpoints.TABLET_MAX_WIDTH}) {
    width: 100%;
    flex-direction: column;
  }
`;

export const StyledNavigationButton = styled.button<{ primary?: boolean }>`
  border: ${({ primary }) => (primary ? 'none' : '1px solid rgb(140, 142, 160, 0.2)')};
  border-radius: 0.3rem;
  padding: 0.5rem 3rem;
  color: ${Palette.GUN_POWDER};
  background-color: ${({ primary }) => (primary ? Palette.GOLD_CRAYOLA : Palette.NEW_WHITE)};
  cursor: pointer;
`;
