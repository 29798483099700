import { ApiResponse, axiosClient, toApiResponse } from '@/lib/axios';
import { UserReasonsForJoining } from '../types';

export type CreateUserReasonsForJoiningInput = Omit<UserReasonsForJoining, 'id'>;

export const createUserReasonsForJoining = async (
  input: CreateUserReasonsForJoiningInput,
): Promise<ApiResponse<UserReasonsForJoining>> => {
  return toApiResponse<UserReasonsForJoining>(axiosClient.post('/user/reasons-for-joining', input));
};
