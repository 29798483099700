import React from 'react';
import { SubscriptionPopup } from './SubscriptionPopup';

interface SubscriptionContextValue {
  openSubscribePopup: () => void;
}

export const SubscriptionContext = React.createContext({} as SubscriptionContextValue);

export const SubscriptionProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [showSubsciptionPopup, setShowSubscribePopup] = React.useState<boolean>(false);
  return (
    <SubscriptionContext.Provider
      value={{
        openSubscribePopup: () => setShowSubscribePopup(true),
      }}
    >
      {showSubsciptionPopup && <SubscriptionPopup onClose={() => setShowSubscribePopup(false)} />}
      {children}
    </SubscriptionContext.Provider>
  );
};
