import React, { useState } from 'react';
import { Header } from '@/Shared/Components';
import {
  StyledAppointeesPageContainer,
  StyledAppointeesPageContent,
  StyledAppointeesPageHeaderContainer,
  StyledAppointeesPageNotifyButtonRow,
  StyledAppointeesPageParagraph,
  StyledAppointeesPageTopMargin,
} from './AppointeesPage.styles';
import { AppointeesRoleList } from './components';
import { GoBackButton, StyledSecondaryButton } from '../../components';
import { useAppointees, useAppointeeNotifier } from '../../hooks';
import { IAppointeeRole } from '../../types';

const AppointeesPage: React.FC = () => {
  const [emailsToNotify, setEmailsToNotify] = useState<IAppointeeRole[]>([]);
  const { appointees } = useAppointees();
  const { lastNotified, notifyAppointees, notifying } = useAppointeeNotifier();

  async function onNotifyButtonClick() {
    await notifyAppointees(emailsToNotify);
    setEmailsToNotify([]);
  }

  return (
    <StyledAppointeesPageContainer>
      <StyledAppointeesPageContent>
        <GoBackButton />
        <StyledAppointeesPageHeaderContainer>
          <Header>Notify Appointees</Header>
        </StyledAppointeesPageHeaderContainer>
        <StyledAppointeesPageParagraph mb={1}>
          Send an email to the people whom you listed in the will so they can be aware of their role and how they can
          prepare for it.
        </StyledAppointeesPageParagraph>
        <StyledAppointeesPageParagraph>
          They will be provided resources which they can review and ask us any questions they may have. You should also
          follow up with them, especially if they are needed to complete the will.
        </StyledAppointeesPageParagraph>
        <StyledAppointeesPageTopMargin>
          <AppointeesRoleList
            key="executors"
            title="Executors"
            appointees={appointees.executors ?? []}
            emailsToNotify={emailsToNotify}
            setEmailsToNotify={setEmailsToNotify}
          />
        </StyledAppointeesPageTopMargin>
        <StyledAppointeesPageTopMargin>
          <AppointeesRoleList
            key="guardians"
            title="Guardians"
            emailsToNotify={emailsToNotify}
            appointees={appointees.guardians ?? []}
            setEmailsToNotify={setEmailsToNotify}
          />
        </StyledAppointeesPageTopMargin>
        <StyledAppointeesPageTopMargin>
          <AppointeesRoleList
            key="poaProperty"
            title="Power of Attorney Property"
            emailsToNotify={emailsToNotify}
            appointees={appointees.poaProperty ?? []}
            setEmailsToNotify={setEmailsToNotify}
          />
        </StyledAppointeesPageTopMargin>
        <StyledAppointeesPageTopMargin>
          <AppointeesRoleList
            key="poaPersonalCare"
            title="Power of Attorney Personal Care"
            emailsToNotify={emailsToNotify}
            appointees={appointees.poaPersonalCare ?? []}
            setEmailsToNotify={setEmailsToNotify}
          />
        </StyledAppointeesPageTopMargin>
        <StyledAppointeesPageTopMargin>
          <StyledAppointeesPageNotifyButtonRow>
            <StyledSecondaryButton disabled={notifying} onClick={onNotifyButtonClick}>
              Notify selected appointees
            </StyledSecondaryButton>
            <StyledAppointeesPageParagraph>Last Notified on: {lastNotified}</StyledAppointeesPageParagraph>
          </StyledAppointeesPageNotifyButtonRow>
        </StyledAppointeesPageTopMargin>
      </StyledAppointeesPageContent>
    </StyledAppointeesPageContainer>
  );
};

export default AppointeesPage;
