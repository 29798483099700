import styled from 'styled-components';
import { CiEdit } from 'react-icons/ci';
import { MdDelete } from 'react-icons/md';
import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledLiabilityDataContainer = styled.div`
  padding: 1rem;
  background-color: ${Palette.NEW_WHITE};
  border-radius: 0.5rem;

  @media (min-width: ${Breakpoints.TABLET_MIN_WIDTH}) {
    max-width: 350px;
  }
`;

export const StyledLiabilityDataTitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledLiabilityDataHeader = styled.h3`
  color: ${Palette.NEW_BLUE};
  font-family: 'SofiaPro-Regular';
`;

export const StyledLiabilityDataEditIcon = styled(CiEdit)`
  font-size: 1.5rem;
  color: ${Palette.NEW_BLUE};
  cursor: pointer;
`;

export const StyledLiabilityDataDeleteIcon = styled(MdDelete)`
  font-size: 1.5rem;
  color: ${Palette.NEW_BLUE};
  cursor: pointer;
`;

export const SemiBold = styled.span`
  font-weight: 500;
`;
