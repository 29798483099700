import styled, { css } from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { button, sectionBody, sectionTitle } from '@/components';

const primaryColors = css`
  color: ${Palette.WHITE};
  border-color: ${Palette.SLATE_GREEN};
  background-color: ${Palette.SLATE_GREEN};
`;

const secondaryColors = css`
  color: ${Palette.NEW_BLUE};
  border-color: ${Palette.DRIED_LAVENDER};
  background-color: ${Palette.PRIMARY_WHITE};
`;

export const PrimaryButton = styled.button`
  ${button}
  ${primaryColors}
`;

export const SecondaryButton = styled.button`
  ${button}
  ${secondaryColors}
`;

export const Paragraph = styled.p`
  ${sectionBody}
  color: ${Palette.PLANET_BLUE};
`;

export const SubHeader = styled.h2`
  ${sectionTitle}
  color: ${Palette.NEW_BLUE};
`;
