import { KeyboardEvent } from 'react';

export function isEnterKeyPressed(event: KeyboardEvent) {
  return event.key === 'Enter';
}

export function firstDropdownElement() {
  const firstItem = document.getElementsByClassName('pac-item')[0];
  return firstItem?.textContent?.toString();
}
