import { IProfile, IUserAddress, IUserContact } from '@/Types';

export const setModelValues = (model: IProfile | IUserAddress | IUserContact) => {
  return {
    firstName: (model as IProfile).firstName,
    middleName: (model as IProfile).middleName,
    lastName: (model as IProfile).lastName,
    dob: (model as IProfile).dob,
    citizenship: (model as IProfile).citizenship,
    marritalStatus: (model as IProfile).marritalStatus,
    cellPhone: (model as IUserContact).cellPhone,
    homePhone: (model as IUserContact).homePhone,
    primaryPhone: (model as IUserContact).primaryPhone,
  };
};
