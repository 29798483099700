import React, { useContext } from 'react';

import { ContactStore, AddressStore } from '@/Stores';
import { LocaleContext } from '@/providers';

import { DisplayInfo } from '../DisplayInfo';
import {
  PhoneIcon,
  AddressIcon,
  InfoWrapper,
  FormContent,
  FormContentHeader,
  ContentHeading,
  EditIconContainer,
  EditIcon,
  ProfileForm,
  ProfileContainer,
} from '../../Profile.style';
import { splitCountryCodePhoneNumber } from '../../ProfileHelpers';
import contactDisplayLocales from './contactDisplayLocales.json';
import { IContactDisplayProps } from './ContactDisplay.types';
import { EDataTestId } from '@/test-utils/EDataTestId';

const ContactDisplay = ({ isEditMode, setIsEditMode }: IContactDisplayProps) => {
  const { locale } = useContext(LocaleContext);
  const { contact } = useContext(ContactStore);
  const { address } = useContext(AddressStore);

  const newContact = contact ? splitCountryCodePhoneNumber(contact) : null;
  const cellPhoneNumber = newContact && `${newContact.cellPhoneCountryCode} ${newContact.cellPhone}`;

  const contactDisplayLocale = contactDisplayLocales[locale];

  return (
    <ProfileContainer data-testid={EDataTestId.USER_CONTACT_DISPLAY}>
      <ProfileForm>
        <FormContent>
          <FormContentHeader>
            <ContentHeading>{contactDisplayLocale['card.heading']}</ContentHeading>
            <ContentHeading>
              <EditIconContainer show={isEditMode}>
                <EditIcon onClick={() => setIsEditMode(true)} title="edit" aria-label="edit" />
              </EditIconContainer>
            </ContentHeading>
          </FormContentHeader>

          <InfoWrapper>
            <DisplayInfo icon={<PhoneIcon />} header={contactDisplayLocale['label.mobile']} value={cellPhoneNumber} />
            <DisplayInfo
              icon={<AddressIcon />}
              header={contactDisplayLocale['label.address']}
              value={address ? `${address.streetNumber}, ${address.streetName}` : ''}
            />
            <DisplayInfo
              icon={<AddressIcon />}
              header={contactDisplayLocale['label.city']}
              value={address ? address.city : ''}
            />
            <DisplayInfo
              icon={<AddressIcon />}
              header={contactDisplayLocale['label.province']}
              value={address ? address.provinceState : ''}
            />
            <DisplayInfo
              icon={<AddressIcon />}
              header={contactDisplayLocale['label.country']}
              value={address ? address.country : ''}
            />
            <DisplayInfo
              icon={<AddressIcon />}
              header={contactDisplayLocale['label.zip']}
              value={address ? address.postalZip : ''}
            />
          </InfoWrapper>
        </FormContent>
      </ProfileForm>
    </ProfileContainer>
  );
};

export default ContactDisplay;
