import React from 'react';

import {
  StyledProgresBarReading,
  StyledProgressBarContainer,
  StyledProgressBarLine,
  StyledProgressBarTrack,
} from './ProgressBar.styles';
import { IProgressBarProps } from './ProgressBar.types';

const ProgressBar = ({ progress }: IProgressBarProps) => {
  return (
    <StyledProgressBarContainer>
      <StyledProgressBarTrack>
        <StyledProgressBarLine progress={progress} />
      </StyledProgressBarTrack>
      <StyledProgresBarReading>{progress}%</StyledProgresBarReading>
    </StyledProgressBarContainer>
  );
};

export default ProgressBar;
