import React from 'react';
import { ExpandableCard } from './ExpandableCard';
import { documents } from './assets';
import locales from './DocumentsChecklist.locales.json';
import { CheckboxContainer, CheckboxIcon, CheckboxLabel, RightArrow } from './DocumentsChecklist.styles';
import { ButtonContainer, PrimaryButton, SecondaryButton, PrimaryLink, Section, SectionTitle } from '../styles';
import { WarningAlert } from '@/components';
import { useDocumentsChecklist } from './useDocumentsChecklist';
import { bindFuncToAsync } from '@/Utils';
import { DocumentsContext } from '../../Documents.context';

const locale = locales.EN;

export interface DocumentsChecklistProps {
  disabled?: boolean;
}

export const DocumentsChecklist: React.FC<DocumentsChecklistProps> = ({ disabled }) => {
  const { assetList, liabilityList, wishList } = React.useContext(DocumentsContext);

  const [loading, setLoading] = React.useState<boolean>(false);
  const { currentStep, setCurrentStep, completeStep, completedSteps } = useDocumentsChecklist();

  const completeStepWithLoading = bindFuncToAsync(setLoading)(completeStep);

  const isDisabled = disabled || loading;
  return (
    <Section>
      <SectionTitle>{locale.title}</SectionTitle>
      <WarningAlert>{locale.warning}</WarningAlert>
      <ExpandableCard
        expand={currentStep === 'PAYMENT'}
        bannerImageSrc={documents}
        title={locale.completePurchase.title}
        description={locale.completePurchase.description}
        renderLeftIcon={() => <CheckboxIcon checked={completedSteps.includes('PAYMENT')} />}
      />

      <ExpandableCard
        expand={currentStep === 'LEGAL_DOCUMENTS'}
        title={locale.reviewDocuments.title}
        description={locale.reviewDocuments.description}
        onClick={() => setCurrentStep('LEGAL_DOCUMENTS')}
        renderLeftIcon={() => <CheckboxIcon checked={completedSteps.includes('LEGAL_DOCUMENTS')} />}
      >
        <ButtonContainer>
          <PrimaryLink as="a" href="#legal-documents">
            {locale.reviewDocuments.goToReviewButton}
            <RightArrow />
          </PrimaryLink>
          <SecondaryButton onClick={() => completeStepWithLoading('LEGAL_DOCUMENTS')}>
            {locale.reviewDocuments.reviewedButton}
          </SecondaryButton>
        </ButtonContainer>
      </ExpandableCard>

      <ExpandableCard
        expand={currentStep === 'SUPPORTING_DOCUMENTS'}
        title={locale.completeDocuments.title}
        description={locale.completeDocuments.description}
        onClick={() => setCurrentStep('SUPPORTING_DOCUMENTS')}
        renderLeftIcon={() => <CheckboxIcon checked={completedSteps.includes('SUPPORTING_DOCUMENTS')} />}
      >
        <CheckboxContainer>
          <CheckboxIcon checked={Boolean(assetList?.completedAt)} />
          <CheckboxLabel>{locale.completeDocuments.assetsListLabel}</CheckboxLabel>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckboxIcon checked={Boolean(liabilityList?.completedAt)} />
          <CheckboxLabel>{locale.completeDocuments.liabilitiesListLabel}</CheckboxLabel>
        </CheckboxContainer>
        <CheckboxContainer>
          <CheckboxIcon checked={Boolean(wishList?.completedAt)} />
          <CheckboxLabel>{locale.completeDocuments.letterOfWishesLabel}</CheckboxLabel>
        </CheckboxContainer>
        <ButtonContainer>
          {assetList?.completedAt && liabilityList?.completedAt && wishList?.completedAt ? (
            <PrimaryButton onClick={() => completeStepWithLoading('SUPPORTING_DOCUMENTS')}>
              {locale.completeDocuments.completeDocsButton}
            </PrimaryButton>
          ) : (
            <>
              <PrimaryLink as="a" href="#supporting-documents">
                {locale.completeDocuments.completeDocsButton}
              </PrimaryLink>
              <SecondaryButton onClick={() => completeStepWithLoading('SUPPORTING_DOCUMENTS')} disabled={isDisabled}>
                {locale.completeDocuments.continueButton}
              </SecondaryButton>
            </>
          )}
        </ButtonContainer>
      </ExpandableCard>

      <ExpandableCard
        expand={currentStep === 'SIGN'}
        title={locale.signDocuments.title}
        description={locale.signDocuments.description}
        onClick={() => setCurrentStep('SIGN')}
        renderLeftIcon={() => <CheckboxIcon checked={completedSteps.includes('SIGN')} />}
      >
        <PrimaryButton onClick={() => completeStepWithLoading('SIGN')} disabled={isDisabled}>
          {locale.signDocuments.completeButton}
        </PrimaryButton>
      </ExpandableCard>

      <ExpandableCard
        expand={currentStep === 'STORE'}
        title={locale.storeYourDocuments.title}
        description={locale.storeYourDocuments.description}
        onClick={() => setCurrentStep('STORE')}
        renderLeftIcon={() => <CheckboxIcon checked={completedSteps.includes('STORE')} />}
      >
        <PrimaryButton onClick={() => completeStepWithLoading('STORE')} disabled={isDisabled}>
          {locale.storeYourDocuments.completeButton}
        </PrimaryButton>
      </ExpandableCard>
    </Section>
  );
};
