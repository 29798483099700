import { getWillsProfile, getUserProfile, IRelationshipDefinition, getRelationshipDefinitions } from '@wills/apis';
import React from 'react';
import { IRelativeInfo } from '../WillsFamilyFlow.types';
import { IWillsProfile, RelativeDefinitionRelationship } from '@/lib/wills/types';
import { filterRelationshipsByProfile } from '@/lib/wills/helpers/filterRelationshipsByProfile';
import { removeRelationshipsByCurrentValues } from '@/lib/wills/helpers/filterRelationshipsByCurrentValues';
import { useApi } from '@/lib/axios/useApi';
import { IProfile } from '@/Types/Profile';
import { Loading } from '@/Shared/Components/Loading';

export interface IRelationshipDefinitionContext {
  willsProfile: IWillsProfile;
  userProfile: IProfile;
  relationshipDefinitionsGroups: {
    all: IRelationshipDefinition[];
    primary: IRelationshipDefinition[];
    secondary: IRelationshipDefinition[];
  };
  getRelationshipDefinitionById: (
    id: number,
    relationshipDefinitions: IRelationshipDefinition[],
  ) => IRelationshipDefinition;
  getAvailableRelationshipDefinitions: (
    relationshipDefinitions: IRelationshipDefinition[],
    currentRelationships: Pick<IRelativeInfo, 'relationDefinitionId'>[],
  ) => IRelationshipDefinition[];
}

export const RelationshipDefinitionContext = React.createContext({} as IRelationshipDefinitionContext);

export const RelationshipDefinitionProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const getWillsProfileRes = useApi(getWillsProfile);
  const getUserProfileRes = useApi(getUserProfile);
  const getRelationshipDefinitionsRes = useApi(() => getRelationshipDefinitions('all'));

  if (getWillsProfileRes.loading || getUserProfileRes.loading || getRelationshipDefinitionsRes.loading) {
    return <Loading />;
  }

  if (getWillsProfileRes.error || getUserProfileRes.error || getRelationshipDefinitionsRes.error) {
    throw new Error(
      getWillsProfileRes.error?.message ||
        getUserProfileRes.error?.message ||
        getRelationshipDefinitionsRes.error?.message,
    );
  }

  const getRelationshipDefinitionById = (
    id: number,
    relationshipDefinitions: IRelationshipDefinition[],
  ): IRelationshipDefinition => {
    const item = relationshipDefinitions.find((relationshipDefinition) => relationshipDefinition.id === id);

    if (item === undefined) {
      throw new Error('getRelationshipDefinitionById: relationshipDefinition not found');
    }

    return item;
  };

  const willsProfile = getWillsProfileRes.data;
  const userProfile = getUserProfileRes.data;
  const allRelationshipDefinitions = getRelationshipDefinitionsRes.data;

  const relationshipDefinitionsGroups = {
    all: allRelationshipDefinitions,
    primary: allRelationshipDefinitions.filter((definition) => definition.category === 'PRIMARY'),
    secondary: allRelationshipDefinitions.filter((definition) => definition.category === 'SECONDARY'),
  };

  const getAvailableRelationshipDefinitions = (
    relationshipDefinitions: IRelationshipDefinition[],
    currentRelationships: Pick<IRelativeInfo, 'relationDefinitionId'>[],
  ): IRelationshipDefinition[] => {
    const currentRelationshipTypes: RelativeDefinitionRelationship[] = currentRelationships.map(
      (relationship) =>
        getRelationshipDefinitionById(relationship.relationDefinitionId, relationshipDefinitions).relationship,
    );

    const keepRelationships = removeRelationshipsByCurrentValues(
      currentRelationshipTypes,
      filterRelationshipsByProfile(userProfile, willsProfile),
    );

    const output = relationshipDefinitions.filter((definition) => keepRelationships.includes(definition.relationship));

    return output;
  };

  return (
    <RelationshipDefinitionContext.Provider
      value={{
        willsProfile,
        userProfile,
        relationshipDefinitionsGroups,
        getRelationshipDefinitionById,
        getAvailableRelationshipDefinitions,
      }}
    >
      {children}
    </RelationshipDefinitionContext.Provider>
  );
};
