import React from 'react';
import { PopulatedDocument, PopulatedDocumentType } from '@wills/apis';
import { LEGAL_DOCUMENTS_DATA } from './LegalDocuments.constants';
import { AlertsContainer, ButtonsContainer } from './LegalDocuments.styles';
import { WillsPlanType } from '@/lib/wills/types';
import { ActionCardsContainer, PrimaryButton, SecondaryButton, Section, SectionTitle } from '../styles';
import { InfoAlert, WarningAlert } from '@/components';
import { ActionCard } from '../ActionCard';
import locales from './LegalDocuments.locales.json';

const locale = locales.EN;

interface LegalDocumentsProps {
  documents: Record<PopulatedDocumentType, PopulatedDocument | undefined>;
  viewDocument: (type: PopulatedDocumentType) => Promise<void>;
  downloadDocument: (type: PopulatedDocumentType) => Promise<void>;
  regenerateDocuments: (types: PopulatedDocumentType[]) => Promise<void>;
  updateWills: () => void;
  disabled: boolean;
  plan: WillsPlanType;
}

const LegalDocuments: React.FC<LegalDocumentsProps> = ({
  documents,
  viewDocument,
  downloadDocument,
  regenerateDocuments,
  updateWills,
  disabled,
  plan,
}) => {
  const needToRegenerate =
    plan === 'PREMIUM'
      ? !(documents.POA_FOR_PERSONAL_CARE && documents.LAST_WILL_AND_TESTAMENT && documents.POA_FOR_PROPERTY)
      : !documents.POA_FOR_PERSONAL_CARE;

  const filteredDocuments =
    plan === 'PREMIUM'
      ? LEGAL_DOCUMENTS_DATA
      : LEGAL_DOCUMENTS_DATA.filter((data) => {
          return data.documentType === 'LAST_WILL_AND_TESTAMENT';
        });

  return (
    <Section id="legal-documents">
     <SectionTitle>{locale.title}</SectionTitle>
      <AlertsContainer>
        <InfoAlert>{locale.infoAlertText}</InfoAlert>
        <WarningAlert>{locale.warningAlertLabel}</WarningAlert>
      </AlertsContainer>
      <ButtonsContainer>
        <PrimaryButton type="button" onClick={updateWills} disabled={disabled}>
          {locale.updateWillButtonLabel}
        </PrimaryButton>
        <PrimaryButton
          type="button"
          onClick={() => regenerateDocuments(['LAST_WILL_AND_TESTAMENT', 'POA_FOR_PROPERTY', 'POA_FOR_PERSONAL_CARE'])}
          disabled={disabled}
        >
          {locale.publishWillButtonLabel}
        </PrimaryButton>
      </ButtonsContainer>
      {needToRegenerate && <InfoAlert>{locale.missingDocuments}</InfoAlert>}
      <ActionCardsContainer>
        {filteredDocuments.map(({ title, description, image, documentType }, index) => {
          return (
            <ActionCard
              key={index}
              title={title}
              description={description}
              imageProps={{
                src: image,
                alt: 'image',
              }}
            >
              <PrimaryButton onClick={() => viewDocument(documentType)} disabled={needToRegenerate || disabled}>
                {locale.viewButtonLabel}
              </PrimaryButton>
              <SecondaryButton onClick={() => downloadDocument(documentType)} disabled={needToRegenerate || disabled}>
                {locale.downloadButtonLabel}
              </SecondaryButton>
            </ActionCard>
          );
        })}
      </ActionCardsContainer>
    </Section>
  );
};

export default LegalDocuments;
