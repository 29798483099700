import React, { ChangeEvent, useContext, useState } from 'react';

import { AuthContext, SnackBarContext } from '@/providers';
import { IUser } from '@/Types';
import { Modal } from '@/Shared/Components';
import { getErrorMessage } from '@/Utils';

import { InfoAttributes, InfoHeader, UserIcon } from '../../Profile.style';
import { setMfaTypeApi } from './MFAFormField.api';
import { IMFAFormFieldProps, IMFATypeData } from './MFAFormField.types';
import {
  SingleInfoWrapper,
  StyledMfaButton,
  StyledMfaInfoContent,
  StyledMfaRadioButtonGroup,
} from './MFAFormField.styles';
import { EmailMfaModal } from '../EmailMfaModal';
import { RemoveMfaModal } from '../RemoveMfaModal';
import { AuthenticatorMfaModal } from '../AuthenticatorMfaModal';
import { MFA_TYPES } from './MFAFormField.constants';

const MFAFormField = ({ setIsEditMode }: IMFAFormFieldProps) => {
  const { user, setUser } = useContext(AuthContext);
  const { setSnackMessage } = useContext(SnackBarContext);

  const [mfaType, setMfaType] = useState(user?.mfaType ? user.mfaType : MFA_TYPES.none);
  const [authCode, setAuthCode] = useState<string | null>(null);
  const [isSaving, setIsSaving] = useState(false);
  const [isModelOpen, setIsModalOpen] = useState(false);

  function onAuthCodeInputChange(event: ChangeEvent<HTMLInputElement>) {
    setAuthCode(event.target.value);
  }

  async function onConfirmMfaBtnClick() {
    if (isSaving) return;

    setIsSaving(true);

    const mfaData: IMFATypeData = {
      email_mfa: mfaType === MFA_TYPES.email,
      authenticator_mfa: mfaType === MFA_TYPES.authenticator,
      auth_code: authCode as string,
    };

    clearInput();

    try {
      await setMfaTypeApi(mfaData);

      setSnackMessage('MFA method set successfully');
      setUser({ ...(user as IUser), mfaType });
      setIsEditMode(false);
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    }

    setIsSaving(false);
    setIsModalOpen(false);
  }

  function onChangeHandler(e: ChangeEvent<HTMLInputElement>) {
    const { value } = e.target;

    setMfaType(value);
  }

  function clearInput() {
    setAuthCode(null);
  }

  function onNextBtnClick() {
    setIsModalOpen(true);
  }

  function closeModal() {
    setIsModalOpen(false);
    clearInput();
  }

  const existingMfaType = user?.mfaType === null ? MFA_TYPES.none : user?.mfaType;
  const isNextButtonVisible = mfaType !== existingMfaType;

  return (
    <SingleInfoWrapper>
      <UserIcon />
      <InfoAttributes>
        <InfoHeader>MFA method</InfoHeader>

        <StyledMfaRadioButtonGroup>
          <StyledMfaInfoContent>
            <label htmlFor="none-input">
              <input
                id="none-input"
                type="radio"
                name="mfaType"
                value={MFA_TYPES.none}
                checked={mfaType === MFA_TYPES.none}
                onChange={onChangeHandler}
              />
              None
            </label>
          </StyledMfaInfoContent>
          <StyledMfaInfoContent>
            <label htmlFor="email-input">
              <input
                id="email-input"
                type="radio"
                name="mfaType"
                value={MFA_TYPES.email}
                checked={mfaType === MFA_TYPES.email}
                onChange={onChangeHandler}
              />
              Email
            </label>
          </StyledMfaInfoContent>
          <StyledMfaInfoContent>
            <label htmlFor="authenticator-input">
              <input
                id="authenticator-input"
                type="radio"
                name="mfaType"
                value={MFA_TYPES.authenticator}
                checked={mfaType === MFA_TYPES.authenticator}
                onChange={onChangeHandler}
              />
              Authenticator
            </label>
          </StyledMfaInfoContent>
        </StyledMfaRadioButtonGroup>

        {isNextButtonVisible && (
          <StyledMfaButton short type="button" onClick={onNextBtnClick}>
            Next
          </StyledMfaButton>
        )}
      </InfoAttributes>
      {isModelOpen && (
        <Modal onCloseCallback={closeModal}>
          {mfaType === MFA_TYPES.none && (
            <RemoveMfaModal disabled={isSaving} onConfirmMfaBtnClick={onConfirmMfaBtnClick} onCancel={closeModal} />
          )}
          {mfaType === MFA_TYPES.email && (
            <EmailMfaModal
              disabled={isSaving}
              onConfirmMfaBtnClick={onConfirmMfaBtnClick}
              authCode={authCode}
              onAuthCodeInputChange={onAuthCodeInputChange}
              onCancel={closeModal}
            />
          )}
          {mfaType === MFA_TYPES.authenticator && (
            <AuthenticatorMfaModal
              disabled={isSaving}
              onConfirmMfaBtnClick={onConfirmMfaBtnClick}
              authCode={authCode}
              onAuthCodeInputChange={onAuthCodeInputChange}
              onCancel={closeModal}
            />
          )}
        </Modal>
      )}
    </SingleInfoWrapper>
  );
};

export default MFAFormField;
