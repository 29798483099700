import styled from 'styled-components';
import { BsInfoCircle } from 'react-icons/bs';
import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';
import downCaret from '@/Assets/images/angle-arrow-down.png';

export const StyledLayout = styled.div<{ sameLevel?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  ${({ sameLevel }) => !sameLevel && 'justify-content: center;'}
  background-color: ${Palette.AQUA_HAZE};
  padding: 5rem 0;
  position: relative;
`;

export const StyledHeader = styled.h2<{ maxWidthSm?: boolean; center?: boolean; fsLg?: boolean }>`
  font-family: 'SofiaPro-Regular';
  font-weight: 600;
  color: ${Palette.NEW_BLUE};
  line-height: 3.5rem;
  font-size: ${({ fsLg }) => (fsLg ? 'clamp(1.875rem, 3vw, 3rem)' : 'clamp(1.875rem, 2vw, 2.25rem)')};
  text-align: ${({ center }) => center && 'center'};

  @media screen and (min-width: 600px) {
    max-width: ${({ maxWidthSm }) => maxWidthSm && '25em'};
  }
`;

export const StyledSmallHeader = styled.h4<{ fontSm?: boolean; newBlue?: boolean; center?: boolean }>`
  font-family: 'SofiaPro-Regular';
  font-size: ${({ fontSm }) => (fontSm ? 'clamp(1.5rem, 1.2vw, 1.125rem)' : '20px')};
  color: ${({ newBlue }) => (newBlue ? `${Palette.NEW_BLUE}` : `${Palette.SLATE_GREEN}`)};
  text-align: ${(props) => props.center && 'center'};
`;

export const StyledDescription = styled.p<{ center?: boolean; marginTop?: boolean }>`
  font-family: 'SofiaPro-Regular';
  font-size: clamp(1rem, 1.1vw, 1.5rem);
  color: ${Palette.PLANET_BLUE};
  text-align: ${({ center }) => center && 'center'};
  margin-top: ${({ marginTop }) => marginTop && '1.5rem'};
`;

export const StyledButtonContainer = styled.div<{ margin?: boolean; justifyBetween?: boolean }>`
  margin-top: ${({ margin }) => (margin ? '3rem' : '1rem')};
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding-bottom: 1.5rem;

  @media screen and (min-width: 800px) {
    flex-direction: row;
    justify-content: space-around;
    justify-content: ${({ justifyBetween }) => justifyBetween && 'space-between'};
  }
`;

export const StyledButton = styled.button<{ center?: boolean; padding?: boolean; bgGrey?: boolean }>`
  display: block;
  width: 100%;
  cursor: pointer;
  font-weight: 400;
  text-align: center;
  padding: 0.625rem 1.125rem;
  background-color: ${({ bgGrey }) => (bgGrey ? `${Palette.ATHENS_GREY}` : `${Palette.GOLD_CRAYOLA}`)};
  border-radius: 0.5rem;
  border: none;
  margin-top: 1rem;

  @media screen and (min-width: 800px) {
    margin: ${({ center }) => (center ? '0 auto' : '1rem 0')};
    padding: ${({ padding }) => padding && '0.625rem 3rem'};
    width: fit-content;
  }
`;

export const StyledFormLabel = styled.p`
  font-family: 'SofiaPro-Regular';
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
`;

interface StyledInputFieldProps {
  error?: boolean;
  radio?: boolean;
  widthFull?: boolean;
}
export const StyledInputField = styled.input<StyledInputFieldProps>`
  width: 20rem;
  padding: 0.625rem 0.875rem;
  border: ${(props) => (props.error ? '1px solid red' : '1px solid white')};
  border-radius: 0.5rem;
  color: ${Palette.INPUT_GREY};
  width: ${({ widthFull }) => widthFull && '100%'};
  font-size: 1rem;
  ${({ radio }) =>
    radio &&
    `
    width: inherit;
    padding: 0rem;
    border: none;
    border-radius: 0;
    flex: 0;
  `}

  &:focus {
    outline: ${(props) => props.error && 'none'};
  }

  @media screen and (max-width: 800px) {
    min-width: ${({ radio }) => !radio && '100%'};
  }
`;

export const StyledShortInputField = styled(StyledInputField)<{ sm?: boolean }>`
  min-width: 10rem;

  ${({ sm }) =>
    sm &&
    `
    width: 6rem;
    min-width: 0;
  `}
`;

export const StyledForm = styled.form`
  margin-top: 3rem;
  display: grid;
  place-items: center;

  @media screen and (max-width: 800px) {
    display: block;
  }
`;

export const StyledFormFieldContainer = styled.div<{ widthFull?: boolean }>`
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
  width: ${({ widthFull }) => widthFull && '100%'};
`;

export const StyledInputGroup = styled.div<{ flexCol?: boolean; flexColSm?: boolean }>`
  display: flex;
  gap: 1.5rem;

  ${({ flexColSm }) => flexColSm && 'flex-direction: column;'}

  @media screen and (max-width: 800px) {
    ${({ flexCol }) => flexCol && 'flex-direction: column;'}
    justify-content: center;
  }
`;

export const StyledPaymentCard = styled.div`
  background-color: ${Palette.WHITE};
  border-radius: 0.5rem;
  padding: 1rem;
`;

export const StyledPaymentCardDivider = styled.div`
  border-bottom: 1px solid ${Palette.GREY};
  margin-bottom: 1rem;
`;

export const StyledPaymentRecordGroup = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const StyledPaymentParagraph = styled.p<{ bold?: boolean; big?: boolean }>`
  color: ${Palette.NEW_BLUE};
  font-family: ${({ bold }) => (bold ? 'SofiaPro-Bold' : 'SofiaPro-Regular')};
  margin-bottom: 0.3rem;
  font-size: clamp(0.9rem, 1vw, 1rem);
  ${({ big }) => big && 'font-size: 1.1rem;'}
`;

export const StyledPrimaryButton = styled.button<{ fullWidth?: boolean, disabled?: boolean }>`
  background-color: ${Palette.GOLD_CRAYOLA};
  padding: 0.5rem 2rem;
  color: ${Palette.NEW_BLUE};
  font-family: 'SofiaPro-Regular';
  font-size: clamp(0.95rem, 1vw, 1.05rem);
  border: none;
  border-radius: 0.5rem;
  cursor: pointer;

  ${({ fullWidth }) => fullWidth && 'width: 100%;'}
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
`;

export const StyledDarkButton = styled(StyledPrimaryButton)`
  background-color: ${Palette.NEW_BLUE};
  color: ${Palette.WHITE};
`;

export const StyledGreenButton = styled(StyledPrimaryButton)`
  background-color: ${Palette.SLATE_GREEN};
  color: ${Palette.WHITE};
    border-radius: 0.3rem;
`;

export const StyledSecondaryButton = styled(StyledPrimaryButton)`
  background-color: ${Palette.WHITE};
  color: ${Palette.NEW_BLUE};
  box-shadow: 1px 1px 3px ${Palette.LIGHT_GREY};
  border-radius: 0.3rem;
`;

export const StyledInfoIconWrapper = styled.div<{ noMargin?: boolean }>`
  display: flex;
  justify-content: end;

  ${({ noMargin }) =>
    !noMargin &&
    `
    margin-top: 1rem;
    margin-bottom: 1rem;
  `}

  @media (min-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    display: none;
  }
`;

export const StyledInfoIconContainer = styled.div`
  width: 2.3rem;
  height: 2.3rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${Palette.WHITE};
  cursor: pointer;
`;

export const StyledInfoIcon = styled(BsInfoCircle)`
  width: 1.7rem;
  height: 1.7rem;
`;

export const HiddenSubmitButton = styled.input`
  position: absolute;
  left: -9999px;
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  font-family: 'SofiaPro-Regular';
  font-size: 1.1rem;
  margin: 1rem 0;
`;

export const StyledWillsIntroText = styled.h4<{ center?: boolean }>`
  color: ${Palette.SLATE_GREEN};
  text-transform: uppercase;
  font-family: 'SofiaPro-Regular';
  margin-bottom: 1rem;
  ${({ center }) => center && 'text-align: center;'}
`;

export const StyledWillsTitle = styled.h1<{ center?: boolean }>`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
  ${({ center }) => center && 'text-align: center;'}

  @media (max-width: 720px) {
    margin-bottom: 2rem;
  }
`;

export const Dropdown = styled.select`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-image: url(${downCaret});
  background-repeat: no-repeat, repeat;
  background-position:
    right 0.7em top 50%,
    0 0;
  background-size:
    0.65em auto,
    100%;
`;

export const ButtonGroup = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;

  @media (max-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    flex-direction: column;
    gap: 1rem;
  }
`;

export const Button = styled.button<{ primary?: boolean }>`
  border-radius: 0.3rem;
  padding: 0.5rem 3rem;
  cursor: pointer;
  color: ${Palette.GUN_POWDER};
  border: ${({ primary }) => (primary ? 'none' : '1px solid rgb(140, 142, 160, 0.2)')};
  background-color: ${({ primary }) => (primary ? Palette.GOLD_CRAYOLA : Palette.NEW_WHITE)};
  ${({ disabled }) => disabled && 'opacity: 0.5;'}
`;

export const StyledHelperText = styled.label<{
  type?: 'error' | 'info';
}>`
  color: ${({ type }) => (type && type !== 'error' ? 'grey' : 'red')};
  font-family: 'SofiaPro-Regular';
  font-size: 0.7rem;
`;
