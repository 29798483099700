import { SetupIntent } from '@stripe/stripe-js';
import { axiosClient, toApiResponse } from '@/lib/axios';

export async function getOrCreateSetupIntent(signal?: AbortSignal) {
  const { data, error } = await toApiResponse<SetupIntent>(axiosClient.get('/make-intent-setup', { signal }));

  if (error) {
    throw new Error(error.message);
  }

  return data;
}
