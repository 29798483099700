import React from 'react';
import { StepDescription, StepInfoContainer, StepItem, StepNumber, StepTile } from './StepInfo.styles';

interface IProps {
  stepNumber: number;
  title: string;
  description: string;
  active?: boolean;
}

const StepInfo: React.FC<IProps> = ({ stepNumber, title, description, active }) => {
  return (
    <StepInfoContainer>
      <StepItem>
        <StepNumber active={active}>{stepNumber}</StepNumber>
        <StepTile>{title}</StepTile>
      </StepItem>
      <StepDescription>{description}</StepDescription>
    </StepInfoContainer>
  );
};

export default StepInfo;
