import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Header } from '@/Shared/Components';
import { LocaleContext, SnackBarContext } from '@/providers';

import { StyledLink, StyledNotVerifiedPageWrapper } from './NotVerified.styles';
import { resendVerficationEmail } from '../../api/resendVerificationEmail';
import notVerifiedLocales from './NotVerified.locales.json';

const NotVerified: React.FC = () => {
  const { email } = useParams();
  const navigate = useNavigate();
  const { locale } = useContext(LocaleContext);
  const { setSnackMessage } = useContext(SnackBarContext);
  const [responseMessage, setResponseMessage] = useState('');

  const notVerifiedLocale = notVerifiedLocales[locale];

  const onClick = async () => {
    try {
      if (!email) {
        throw new Error('Missing email');
      }
      const { data, error } = await resendVerficationEmail(email);
      if (error) {
        setSnackMessage(error.message);
        return;
      }
      setResponseMessage(data.Message);
    } catch (error: any) {
      setSnackMessage(String(error));
    }
  };

  useEffect(() => {
    if (!email) navigate('/');
  }, []);

  return (
    <StyledNotVerifiedPageWrapper>
      <Header>{responseMessage || notVerifiedLocale.title}</Header>
      <StyledLink onClick={onClick}>{notVerifiedLocale.subtitle}</StyledLink>
    </StyledNotVerifiedPageWrapper>
  );
};

export default NotVerified;
