import React, { useContext } from 'react';
import { Link } from 'react-router-dom';

import useScreenSize from '@/Shared/Hooks/UseScreenSize/useScreenSize';
import { ProfileStore } from '@/Stores';
import { useApi } from '@/lib/axios/useApi';
import { SnackBarContext } from '@/providers/SnackBarProvider/SnackBarProvider';

import {
  StyledWillsWelcomeBackContainer,
  StyledWillsWelcomeBackParagraph,
  StyledWillsWelcomeBackTitle,
  StyledWillsWelcomeBackWrapper,
  StyledWillsWelcomeBackGrid,
  StyledWillsWelcomeBackImage,
  StyledWillsWelcomeBackActionBox,
  StyledWillsWelcomeBackSubheader,
  StyledWillsWelcomeBackBoxImage,
  StyledWillsWelcomeBackPrimaryButton,
  StyledWillsWelcomeBackBoxContent,
  StyledWillsWelcomeBackButtonsContainer,
} from './WillsWelcomeBack.styles';
import { helloBro, testament } from './Assets';
import { getWillsProfile } from '../../apis/willsProfile/getWillsProfile';
import { isAllFlowsCompleted } from '../../helpers/isAllFlowsCompleted';

const WillsWelcomeBack = () => {
  const { profile } = useContext(ProfileStore);
  const { setSnackMessage } = useContext(SnackBarContext);
  const { isMobile } = useScreenSize();
  const { data, error } = useApi(getWillsProfile);
  const isWillsComplete = data ? isAllFlowsCompleted(data) : false;

  if (error) {
    setSnackMessage(error.message);
  }

  return (
    <StyledWillsWelcomeBackWrapper>
      <StyledWillsWelcomeBackContainer>
        <StyledWillsWelcomeBackTitle>Welcome back, {profile?.firstName}</StyledWillsWelcomeBackTitle>
        <StyledWillsWelcomeBackParagraph>What would you like to do today?</StyledWillsWelcomeBackParagraph>

        <StyledWillsWelcomeBackGrid>
          <div>
            <StyledWillsWelcomeBackActionBox>
              <StyledWillsWelcomeBackBoxContent>
                <div>
                  <StyledWillsWelcomeBackSubheader>Your will</StyledWillsWelcomeBackSubheader>
                  {isMobile && <StyledWillsWelcomeBackBoxImage src={testament} alt="wills-documents" />}
                  <StyledWillsWelcomeBackParagraph>
                    Complete your legally valid, Islamic will
                  </StyledWillsWelcomeBackParagraph>
                  <StyledWillsWelcomeBackButtonsContainer>
                    <Link to="/wills/steps">
                      <StyledWillsWelcomeBackPrimaryButton>
                        {isWillsComplete ? 'Update' : 'Complete'}
                        &nbsp;your will
                      </StyledWillsWelcomeBackPrimaryButton>
                    </Link>
                  </StyledWillsWelcomeBackButtonsContainer>
                </div>
                {!isMobile && <StyledWillsWelcomeBackBoxImage src={testament} alt="wills-documents" />}
              </StyledWillsWelcomeBackBoxContent>
            </StyledWillsWelcomeBackActionBox>
          </div>

          <StyledWillsWelcomeBackImage src={helloBro} alt="wills-documents" />
        </StyledWillsWelcomeBackGrid>
      </StyledWillsWelcomeBackContainer>
    </StyledWillsWelcomeBackWrapper>
  );
};

export default WillsWelcomeBack;
