import React, { useContext } from 'react';

import { LocaleContext } from '@/providers';

import { Container, Heading, Message } from './ErrorPage.styles';
import errorLocales from './ErrorPage.locales.json';
import { ErrorBoundaryState } from '../ErrorBoundary';

export const ErrorPage: React.FC<ErrorBoundaryState> = ({ message }) => {
  const { locale } = useContext(LocaleContext);
  const t = errorLocales[locale];

  return (
    <Container>
      <Heading>{t.header}</Heading>
      <Message>{message}</Message>
    </Container>
  );
};
