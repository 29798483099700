import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledExecutorAndEstateChooser = styled.div`
  width: 100%;
  min-width: 8rem;
  padding: 0.7rem;
  border-radius: 0.3rem;
  background-color: ${Palette.WHITE};
`;

export const StyledExecutorChooserRadioButtonLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: 'SofiaPro-Regular';

  input {
    accent-color: ${Palette.SLATE_GREEN};
  }
`;

export const StyledExecutorChooserText = styled.span`
  color: ${Palette.GUN_POWDER};
`;

export const StyledNullExecutorChecker = styled(StyledExecutorAndEstateChooser)<{ display: boolean }>`
  display: ${({ display }) => (display ? 'block' : 'none')};
`;
