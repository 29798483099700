import React from 'react';
import { QuestionInfo } from '@/lib/wills/components/QuestionInfo';

const PowerOfAttorneyInfo = () => {
  return (
    <QuestionInfo
      question="An Important Note"
      answer={
        <div>
          People commonly choose a spouse, relative or close friend for the task.
          <br />
          This person should be:
          <br />
          a) Over the age of majority in your province
          <br />
          b) Understand what is asked of them
          <br />
          <br />
          Adding a backup is recommended in case your first choice refuses or is unable to perform the task for any
          reason.
        </div>
      }
    />
  );
};

export default PowerOfAttorneyInfo;
