import React, { KeyboardEvent, ChangeEvent, useEffect, useContext } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { HiddenSubmitButton, ShortInput } from '@/Shared/Shared.styles';
import { IUserContact } from '@/Types';
import { LocaleContext, SnackBarContext } from '@/providers';

import { Prompt } from '../Prompt';
import { PhoneFormContainer, PhoneFormStyled, PhoneInputStyled } from './PhoneForm.styles';
import {
  getCountryCode,
  getPhoneNumberWithoutCountryCode,
  isNotEmptyString,
  validatePhoneNumber,
} from './PhoneFormHelpers';
import phoneFormLocales from './phoneFormLocales.json';
import { formatPhoneNumber } from '@/Utils/phone/formatPhoneNumber';

type PhoneInput = {
  countryCode: string;
  phoneNumber: string;
};

interface IProps {
  goToNextQuestion: () => void;
  recordAnswer: (name: string, value: string) => void;
  attributeName: 'cellPhone' | 'homePhone' | 'primaryPhone';
  contact: IUserContact;
}

const PhoneForm: React.FC<IProps> = ({ goToNextQuestion, recordAnswer, attributeName, contact }) => {
  const { locale } = useContext(LocaleContext);
  const { setSnackMessage } = useContext(SnackBarContext);

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    setFocus,
    formState: { errors },
    clearErrors,
  } = useForm({
    defaultValues: {
      countryCode: '+1',
      phoneNumber: '',
    },
  });

  const onChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    formatPhoneNumber(event);
    setValue('phoneNumber', event.target.value);
    if (event.target.value !== '') clearErrors('phoneNumber');
  };

  const onSubmit: SubmitHandler<PhoneInput> = (data) => {
    try {
      validatePhoneNumber(data.countryCode, data.phoneNumber);
    } catch (error: any) {
      setSnackMessage(error.message);
      return;
    }

    const phone = data.countryCode + data.phoneNumber;

    if (isNotEmptyString(data.phoneNumber)) {
      recordAnswer(attributeName, phone);
    }
    recordAnswer(attributeName, phone);

    goToNextQuestion();
  };

  const { onBlur, ref } = register('phoneNumber', { required: true });

  const registerReference = (element: HTMLElement | null) => {
    element?.focus();
    ref(element);
  };

  const onTabPressInPhoneInput = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setFocus('countryCode');
    }
  };

  useEffect(() => {
    const countryCode = getCountryCode(contact[attributeName]!);
    const phoneNumber = getPhoneNumberWithoutCountryCode(contact[attributeName]!);
    reset({ countryCode, phoneNumber });
  }, [attributeName]);

  const phoneFormLocale = phoneFormLocales[locale];

  return (
    <PhoneFormContainer>
      <PhoneFormStyled onSubmit={handleSubmit(onSubmit)}>
        <ShortInput sm placeholder="+1" {...register('countryCode')} />
        <PhoneInputStyled
          placeholder={phoneFormLocale['input.placeholder']}
          ref={(element) => registerReference(element)}
          onChange={onChangeHandler}
          onBlur={onBlur}
          name="phoneNumber"
          onKeyDown={onTabPressInPhoneInput}
        />
        <HiddenSubmitButton type="submit" />
      </PhoneFormStyled>
      <Prompt disabled={!!errors.phoneNumber} onClick={handleSubmit(onSubmit)} />
    </PhoneFormContainer>
  );
};

export default PhoneForm;
