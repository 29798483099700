import React from 'react';

import { completeAssetFlow, createWillsAssets, updateWillsAssets, deleteWillsAssets } from '@wills/apis';
import { useErrorHandler } from '@/hooks';
import { DocumentsContext } from '../pages/Documents/Documents.context';

export const useAssetList = () => {
  const { assetList, setAssetList } = React.useContext(DocumentsContext);
  const { handleAsyncError } = useErrorHandler();

  const createAsset: typeof createWillsAssets = handleAsyncError(async (...props) => {
    const response = await createWillsAssets(...props);
    if (response.data) {
      setAssetList({
        ...assetList,
        assets: [...assetList.assets, response.data],
      });
    }
    return response;
  });

  const updateAsset: typeof updateWillsAssets = handleAsyncError(async (...props) => {
    const response = await updateWillsAssets(...props);
    if (response.data) {
      setAssetList({
        ...assetList,
        assets: assetList.assets.map((asset) => (asset.id === response.data.id ? response.data : asset)),
      });
    }
    return response;
  });

  const deleteAsset: typeof deleteWillsAssets = handleAsyncError(async (assetListId, assetId) => {
    const response = await deleteWillsAssets(assetListId, assetId);
    if (response.data) {
      setAssetList({
        ...assetList,
        assets: assetList.assets.filter((asset) => asset.id !== assetId),
      });
    }
    return response;
  });

  const completeAssetList: typeof completeAssetFlow = handleAsyncError(async () => {
    const response = await completeAssetFlow();
    if (response.data) {
      setAssetList(response.data);
    }
    return response;
  });

  return {
    assetList,
    createAsset,
    updateAsset,
    deleteAsset,
    completeAssetList,
  };
};
