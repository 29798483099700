import React from 'react';

import {
  getRelationshipDefinitions,
  getRelationships,
  IRelationshipDefinition,
  setEstateFlowStartedTime,
} from '@wills/apis';
import { getUser } from '@user/apis';
import { useApi, ApiResponseError } from '@/lib/axios';
import { ESTATE_FLOW_INITIAL_STATE } from './EstateIntakeContext.constants';
import { Loading } from '@/Shared/Components/Loading';
import { estateIntakeContextReducer } from './EstateIntakeContext.reducer';
import { EstateIntakeContextState } from './EstateIntakeContext.state';
import { EstateIntakeContextAction, EstateIntakeContextActionType } from './EstateIntakeContext.actions';
import { IRelativeResponse } from '../../../WillsFamilyFlow/WillsFamilyFlow.types';
import { getSpouseRelative } from '../../helpers';

export const updateAllStatesWithRelationships = (
  dispatch: React.Dispatch<EstateIntakeContextAction>,
  relativeResponses: IRelativeResponse[],
) => {
  dispatch({ type: EstateIntakeContextActionType.SET_RELATIVES, payload: relativeResponses });

  if (getSpouseRelative(relativeResponses) === null) {
    dispatch({ type: EstateIntakeContextActionType.SET_SPOUSE_EXECUTOR, payload: false });
    dispatch({ type: EstateIntakeContextActionType.SET_SPOUSE_POA_PROPERTY, payload: false });
    dispatch({ type: EstateIntakeContextActionType.SET_SPOUSE_POA_PERSONAL_CARE, payload: false });
  }

  const isSpouse = (relationDefinition: string) => ['wife', 'husband'].includes(relationDefinition);

  relativeResponses.forEach((relative) => {
    const relationDefinition = relative.relationDefinition.relationship;

    if (relative.stateExecutor) {
      dispatch({ type: EstateIntakeContextActionType.SET_MAIN_EXECUTOR, payload: relative });
      dispatch({ type: EstateIntakeContextActionType.SET_SPOUSE_EXECUTOR, payload: isSpouse(relationDefinition) });
    }
    if (relative.backupStateExecutor) {
      dispatch({ type: EstateIntakeContextActionType.SET_BACKUP_EXECUTOR, payload: relative });
    }

    if (relative.poaProperty) {
      dispatch({ type: EstateIntakeContextActionType.SET_MAIN_POA_PROPERTY, payload: relative });
      dispatch({ type: EstateIntakeContextActionType.SET_SPOUSE_POA_PROPERTY, payload: isSpouse(relationDefinition) });
    }

    if (relative.backupPoaProperty) {
      dispatch({ type: EstateIntakeContextActionType.SET_BACKUP_POA_PROPERTY, payload: relative });
    }

    if (relative.poaPersonalCare) {
      dispatch({ type: EstateIntakeContextActionType.SET_MAIN_POA_PERSONAL_CARE, payload: relative });
      dispatch({
        type: EstateIntakeContextActionType.SET_SPOUSE_POA_PERSONAL_CARE,
        payload: isSpouse(relationDefinition),
      });
    }

    if (relative.backupPoaPersonalCare) {
      dispatch({ type: EstateIntakeContextActionType.SET_BACKUP_POA_PERSONAL_CARE, payload: relative });
    }

    if (relative.primaryChildGuardian) {
      dispatch({ type: EstateIntakeContextActionType.SET_MAIN_GUARDIAN, payload: relative });
    }
    if (relative.secondaryChildGuardian) {
      dispatch({ type: EstateIntakeContextActionType.SET_BACKUP_GUARDIAN, payload: relative });
    }
  });
};

interface EstateIntakeContextValues {
  state: EstateIntakeContextState;
  dispatch: React.Dispatch<EstateIntakeContextAction>;
}

export const EstateIntakeContext = React.createContext({} as EstateIntakeContextValues);

export const EstateIntakeProvider: React.FC<{
  children: React.ReactElement;
  initialState?: EstateIntakeContextState;
}> = ({ children, initialState = ESTATE_FLOW_INITIAL_STATE }) => {
  const [state, dispatch] = React.useReducer(estateIntakeContextReducer, initialState);
  const [error, setError] = React.useState<ApiResponseError>();

  if (error) {
    throw new Error(error.message);
  }

  // TODO: should probably seperate the data fectching from the provider
  const { loading: relationshipsLoading } = useApi(() => getRelationships('all'), [], {
    onSuccess: (data) => {
      updateAllStatesWithRelationships(dispatch, data);
    },
    onError: setError,
  });

  const { loading: willsProfileLoading } = useApi(setEstateFlowStartedTime, [], {
    onSuccess: (data) => {
      dispatch({ type: EstateIntakeContextActionType.SET_WILLS_PROFILE, payload: data });
      dispatch({ type: EstateIntakeContextActionType.SET_POA_AVAILABILITY, payload: data.plan === 'PREMIUM' });
    },
    onError: setError,
  });

  const { loading: relDefLoading } = useApi(() => getRelationshipDefinitions('all'), [], {
    onSuccess: (data) => {
      dispatch({
        type: EstateIntakeContextActionType.SET_RELATIVE_DEFINITIONS,
        payload: data as IRelationshipDefinition[],
      });
    },
    onError: setError,
  });

  const { loading: profileLoading } = useApi(getUser, [], {
    onSuccess: (data) => {
      dispatch({ type: EstateIntakeContextActionType.SET_PROFILE, payload: data.profile! });
      dispatch({ type: EstateIntakeContextActionType.SET_ADDRESS, payload: data.address! });
    },
    onError: setError,
  });

  if (profileLoading || relDefLoading || relationshipsLoading || willsProfileLoading) {
    return <Loading />;
  }

  return (
    <EstateIntakeContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </EstateIntakeContext.Provider>
  );
};
