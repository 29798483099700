import React, { useContext, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import { StyledWillsIntroText, StyledWillsTitle } from '@wills/components';
import { useRelationships } from '@wills/hooks';
import { upsertRelationships, updateCompleteRelationshipFlag } from '@wills/apis';

import { RelationshipDefinitionContext } from '@wills/pages/WillsFamilyFlow/contexts/RelationshipDefinitionContext';
import { SnackBarContext } from '@/providers';
import { getErrorMessage } from '@/Utils';

import { IRelativeInfo } from '../../WillsFamilyFlow.types';
import { NavigationButtons } from '../NavigationButtons';
import {
  StyledReviewContainer,
  StyledReviewListContainer,
  StyledReviewNavigationButtonsContainer,
  StyledReviewRow,
  StyledReviewRowTitle,
  StyledTagContainer,
} from './Review.styles';
import { ReviewRow } from '../ReviewRow';

const Review: React.FC<{
  onBack: () => void;
  onComplete: () => void;
}> = ({ onBack, onComplete }) => {
  const { setSnackMessage } = useContext(SnackBarContext);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [allRelatives, setAllRelatives] = useState<IRelativeInfo[]>([]);

  useRelationships('all', setAllRelatives);
  const {
    relationshipDefinitionsGroups: { all: relationshipDefinitions },
  } = React.useContext(RelationshipDefinitionContext);

  async function onLooksGoodButtonClick() {
    if (isSubmitting) return;

    setIsSubmitting(true);

    try {
      await upsertRelationships(allRelatives);
      await updateCompleteRelationshipFlag();
      amplitude.track('Wills Family Flow Complete');
      onComplete();
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <StyledReviewContainer>
      <StyledWillsIntroText center>Your Family</StyledWillsIntroText>
      <StyledWillsTitle center>Great! Review your answers</StyledWillsTitle>
      <StyledReviewListContainer>
        <StyledReviewRow>
          <StyledReviewRowTitle>Your Family</StyledReviewRowTitle>
          <StyledTagContainer>Completed</StyledTagContainer>
        </StyledReviewRow>
        {allRelatives.map((relationship) => {
          const relativeDefinition = relationshipDefinitions.find((r) => r.id === relationship.relationDefinitionId);

          return relativeDefinition ? (
            <ReviewRow
              key={relationship.id}
              relativeDefinition={relativeDefinition}
              relationship={relationship}
              setAllRelatives={setAllRelatives}
            />
          ) : null;
        })}
      </StyledReviewListContainer>
      <StyledReviewNavigationButtonsContainer>
        <NavigationButtons
          continueDisbaled={isSubmitting}
          confirmBtnText="Looks Good"
          onContinue={onLooksGoodButtonClick}
          onPrevious={onBack}
        />
      </StyledReviewNavigationButtonsContainer>
    </StyledReviewContainer>
  );
};

export default Review;
