import styled from 'styled-components';

export const Title = styled.p`
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1rem;
`;

export const Subtitle = styled.p`
  font-size: 0.8rem;
  margin-top: 0.2rem;
`;
