import React, { useContext, useEffect, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { Navigate } from 'react-router-dom';
import { axiosClient, toApiResponse } from '@/lib/axios';
import { SnackBarContext } from '@/providers';
import { EPaymentProducts } from '../../types';

const ReturnAfterCheckout = () => {
  const { setSnackMessage } = useContext(SnackBarContext);
  const [status, setStatus] = useState(null);
  const [onSuccessUrl, setOnSuccessUrl] = useState<string>('');
  const [onFailureUrl, setOnFailureUrl] = useState<string>('');

  async function getCheckoutSessionStatus() {
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const sessionId = urlParams.get('session_id');
    const product = urlParams.get('product');

    determineRedirectUrlsForProduct(product);

    const { data, error } = await toApiResponse<any>(
      axiosClient.get(`/checkout-session-status?session_id=${sessionId}`),
    );

    if (error) {
      setSnackMessage(error.message);
    }

    if (data) {
      amplitude.track(`Payment Status Changed to ${data.status}`);
      setStatus(data.status);
    }
  }

  function determineRedirectUrlsForProduct(product: string | null) {
    if (product === EPaymentProducts.WILLS) {
      setOnSuccessUrl('/wills/payment-confirmed');
      setOnFailureUrl('/wills/payment');
    }
  }

  useEffect(() => {
    getCheckoutSessionStatus();
  }, []);

  if (status === 'open') {
    return <Navigate to={onFailureUrl} replace />;
  }

  if (status === 'complete') {
    return <Navigate to={onSuccessUrl} replace />;
  }

  return null;
};

export default ReturnAfterCheckout;
