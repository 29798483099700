import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledForgotPasswordContainer = styled.div`
  padding: 3rem 6rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${Palette.INDEPENDENCE_BLUE};
`;

export const StyledForgotPasswordForm = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 600px) {
    width: 140%;
  }
`;

export const StyledTransparentDivider = styled.div`
  height: 3rem;
`;
