import React from 'react';
import { QuestionInfo } from '@/lib/wills/components/QuestionInfo';

const GenderQuestionInfo: React.FC = () => {
  return (
    <QuestionInfo
      question="Why do we ask?"
      answer={
        <div>
          Based on your response, it allows us to ask the right questions so that your estate is properly distributed
          according to Shariah principles.
        </div>
      }
    />
  );
};

export default GenderQuestionInfo;
