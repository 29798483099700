import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { StyledWillsIntroText } from '@/lib/wills/components';

export const StyledWillsPlanWrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${Palette.AQUA_HAZE};
  display: grid;
  place-items: center;
  padding-bottom: 1.5rem;
`;

export const StyledWillsPlanContainer = styled.div`
  padding: 5rem 0 2rem 0;
  width: 85%;
  max-width: 1366px;
  margin: 0 auto;
`;

export const StyledSmallHeader = styled(StyledWillsIntroText)`
  text-align: center;
  font-size: 1.25rem;
`;

export const StyledHeader = styled.h2`
  font-size: clamp(1.8rem, 2.5vw, 3rem);
  font-family: 'SofiaPro-Regular';
  line-height: 2.5rem;
  font-weight: 500;
  text-align: center;
  margin-top: 1rem;

  @media screen and (min-width: 700px) {
    max-width: 20em;
    line-height: 3rem;
    margin-left: auto;
    margin-right: auto;
  }
`;
