import styled from 'styled-components';
import { pageTitle, pageBody } from '@/components';
import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';

export const RowWrap = styled.div`
  display: flex;
  flex-flow: row wrap;
  gap: 24px;
`;

export const PageTitle = styled.h1`
  ${pageTitle}
  color: ${Palette.NEW_BLUE};
`;

export const PageDescription = styled.p`
  ${pageBody}
  color: ${Palette.PLANET_BLUE};
`;

export const SubscriptionInfoMobileWrapper = styled.div`
    display: block;

    @media (min-width: ${Breakpoints.TABLET_MIN_WIDTH}) {
      display: none;
  }
`;

export const SubscriptionInfoTabletWrapper = styled.div`
    display: none;

    @media (min-width: ${Breakpoints.TABLET_MIN_WIDTH}) {
      display: block;
    }
`;

export const PageInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 1.5rem;
  min-width: 340px;
`;
