import React from 'react';
import { useForm } from 'react-hook-form';
import { normalizeDate } from '@user/pages/Profile/helpers/normalizeDateOnChange/normalizeDate';
import {
  StyledBequestAdderForm,
  StyledBequestAdderFormName,
  InputGroup,
  StyledBequestAdderFormLabel,
  StyledBequestFormInputContainer,
  StyledBequestAdderFormInput,
  StyledBequestAllocationlabel,
  StyledBequestAllocationFormInput,
  StyledBequestRelationshipDropdown,
  StyledBequestAdderFormHelperText,
  BequestButtonGroup,
} from './BequestForm.styles';
import { normalizeDateOnChange } from '@/lib/user/pages/Profile/helpers/normalizeDateOnChange';
import { IBequest, IRelationshipDefinition } from '@/lib/wills/apis';

import { Button } from '@/lib/wills/components';
import { formatPhoneNumber } from '@/Utils/phone/formatPhoneNumber';

interface BequestFormValues {
  firstName: string | undefined;
  lastName: string | undefined;
  dob: string | undefined;

  organizationName: string | undefined;
  organizationId: string | undefined;
  email: string;
  phone: string;
  allocation: string;
  willsRelationshipId: number;
}

export interface UpdateBequestValues extends BequestFormValues {
  id: number;
}

export interface NewBequestValues extends BequestFormValues {
  id: undefined;
}

export type BequestFormProps = {
  relationshipDefinitions: IRelationshipDefinition[];
  onCancel: () => void;
} & (
  | {
      bequest?: undefined;
      onSubmit: (data: NewBequestValues) => Promise<void>;
    }
  | { bequest: IBequest; onSubmit: (data: UpdateBequestValues) => Promise<void> }
);

// Grab the first element of the array, otherwise return undefined when empty
// Use this to improve type safety
function head<T>(array: T[]): T | undefined {
  return array.length === 0 ? undefined : array[0];
}

export const BequestForm: React.FC<BequestFormProps> = ({ bequest, relationshipDefinitions, onCancel, onSubmit }) => {
  const defaultRelationshipDefinition = head(relationshipDefinitions);
  if (!defaultRelationshipDefinition) {
    throw new Error('defaultRelationshipDefinition cannot be empty');
  }

  const { register, handleSubmit, watch, setValue, formState: { errors } } = useForm<BequestFormValues>({
    defaultValues: {
      firstName: bequest?.firstName,
      lastName: bequest?.lastName,
      organizationName: bequest?.organizationName,
      organizationId: bequest?.organizationId,
      dob: bequest?.dob?.split(' ')?.[0],
      email: bequest?.contact?.email ?? '',
      phone: bequest?.contact?.cellPhone ?? '',
      allocation: bequest?.allocation ?? '',
      willsRelationshipId: bequest?.willsRelationshipId ?? defaultRelationshipDefinition.id,
    },
    shouldUnregister: true,
  });
  const currentRelationshipId = watch('willsRelationshipId');
  const isCurrentRelationshipOrganization = relationshipDefinitions.find(
    (relationshipDef) => relationshipDef.id === currentRelationshipId,
  )?.isOrganization;

  const onSubmitHandler = handleSubmit(async (data) => {
    try {
      if (bequest) {
        await onSubmit({
          id: bequest.id,
          ...data,
        });
      } else {
        await onSubmit({
          id: undefined,
          ...data,
        });
      }
    } catch (e) {
      throw new Error(String(e));
    }
  });

  return (
    <StyledBequestAdderForm data-testid="bequest-adder-form" onSubmit={onSubmitHandler}>
      <StyledBequestAdderFormName>Beneficiary Details</StyledBequestAdderFormName>
      <StyledBequestFormInputContainer>
        <InputGroup full>
          <StyledBequestAdderFormLabel htmlFor="willsRelationshipId">Relationship</StyledBequestAdderFormLabel>
          <StyledBequestRelationshipDropdown
            id="willsRelationshipId"
            {...register('willsRelationshipId', { required: true, valueAsNumber: true })}
          >
            {relationshipDefinitions.map((relationshipDefinition, index) => (
              <option key={index} value={relationshipDefinition.id}>
                {relationshipDefinition.description}
              </option>
            ))}
          </StyledBequestRelationshipDropdown>
        </InputGroup>

        {!isCurrentRelationshipOrganization && (
          <>
            <InputGroup>
              <StyledBequestAdderFormLabel htmlFor="firstName">First Name</StyledBequestAdderFormLabel>
              <StyledBequestAdderFormInput
                id="firstName"
                placeholder="First name"
                {...register('firstName', { required: 'First name is required' })}
              />
              {errors.firstName ? (
              <StyledBequestAdderFormHelperText htmlFor="firstName">
                {errors.firstName.message}
              </StyledBequestAdderFormHelperText>
            ) : null}
            </InputGroup>

            <InputGroup>
              <StyledBequestAdderFormLabel htmlFor="lastName">Last Name</StyledBequestAdderFormLabel>

              <StyledBequestAdderFormInput
                id="lastName"
                placeholder="Last name"
                {...register('lastName', { required: 'Last name is required' })}
              />
              {errors.lastName ? (
              <StyledBequestAdderFormHelperText htmlFor="lastName">
                {errors.lastName.message}
              </StyledBequestAdderFormHelperText>
            ) : null}
            </InputGroup>

            <InputGroup>
              <StyledBequestAdderFormLabel htmlFor="dob">Date of Birth</StyledBequestAdderFormLabel>
              <StyledBequestAdderFormInput
                id="dob"
                placeholder="yyyy-mm-dd"
                {...register('dob', {
                  required: "Date of birth is required",
                  onChange: normalizeDateOnChange,
                  validate: (data) => {
                    const date: string = normalizeDate(data!);
                    if (String(new Date(date ?? 'bad date')) === 'Invalid Date') {
                      return 'invalid date';
                    }
                    setValue("dob", date);
                    return true;
                  },
                })}
              />
              {errors.dob ? (
              <StyledBequestAdderFormHelperText htmlFor="dob">
                {errors.dob.message}
              </StyledBequestAdderFormHelperText>
            ) : null}
            </InputGroup>
          </>
        )}
        {isCurrentRelationshipOrganization && (
          <>
            <InputGroup full>
              <StyledBequestAdderFormLabel htmlFor="organizationName">Organization Name</StyledBequestAdderFormLabel>
              <StyledBequestAdderFormInput
                id="organizationName"
                placeholder="Organization name"
                {...register('organizationName', { required: 'Organization name is required' })}
              />
              {errors.organizationName ? (
              <StyledBequestAdderFormHelperText htmlFor="organizationName">
                {errors.organizationName.message}
              </StyledBequestAdderFormHelperText>
            ) : null}
            </InputGroup>
            <InputGroup>
              <StyledBequestAdderFormLabel htmlFor="organizationId">Organization Number</StyledBequestAdderFormLabel>
              <StyledBequestAdderFormInput
                id="organizationId"
                placeholder="Organization Number"
                {...register('organizationId', { required: 'Organization id is required' })}
              />
              {errors.organizationId ? (
              <StyledBequestAdderFormHelperText htmlFor="organizationId">
                {errors.organizationId.message}
              </StyledBequestAdderFormHelperText>
            ) : null}
            </InputGroup>
          </>
        )}
        <InputGroup>
          <StyledBequestAdderFormLabel htmlFor="email">Email</StyledBequestAdderFormLabel>
          <StyledBequestAdderFormInput
            id="email"
            placeholder="Email"
            type="email"
            {...register('email', { required: 'Email is required' })}
          />
          {errors.email ? (
              <StyledBequestAdderFormHelperText htmlFor="email">
                {errors.email.message}
              </StyledBequestAdderFormHelperText>
            ) : null}
        </InputGroup>
        <InputGroup full>
          <StyledBequestAdderFormLabel htmlFor="phone">Phone (Optional)</StyledBequestAdderFormLabel>
          <StyledBequestAdderFormInput
            id="phone"
            placeholder="Phone"
            {...register('phone', { onChange: formatPhoneNumber })}
          />
        </InputGroup>
        <InputGroup full>
          <StyledBequestAllocationlabel htmlFor="allocation">Allocation</StyledBequestAllocationlabel>
          <StyledBequestAllocationFormInput
            type="number"
            id="allocation"
            {...register('allocation', { required: 'Allocation amount is required', valueAsNumber: true, min: 0 })}
            placeholder="%"
          />
          {errors.allocation ? (
              <StyledBequestAdderFormHelperText htmlFor="allocation" align="center">
                {errors.allocation.message}
              </StyledBequestAdderFormHelperText>
            ) : null}
        </InputGroup>
      </StyledBequestFormInputContainer>
      <BequestButtonGroup>
        <Button type="button" onClick={onCancel}>
          Cancel
        </Button>
        <Button type="submit" primary>
          Save
        </Button>
      </BequestButtonGroup>
    </StyledBequestAdderForm>
  );
};
