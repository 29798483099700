import React, { useContext } from 'react';

import { BigButton, Header } from '@/Shared/Components';
import { Palette } from '@/Shared/Constants';
import { LocaleContext } from '@/providers';

import {
  StepList,
  IntroductionContainer,
  IntroductionContent,
  IntroductionContainerWrapper,
  StyledDisclaimer,
} from './Introduction.styles';
import { StepInfo } from '../StepInfo';
import introductionLocales from './introductionLocales.json';

interface IProps {
  onClickNext: () => void;
}

const Introduction: React.FC<IProps> = ({ onClickNext }) => {
  const { locale } = useContext(LocaleContext);

  const introductionLocale = introductionLocales[locale];

  return (
    <IntroductionContainerWrapper>
      <IntroductionContainer>
        <IntroductionContent>
          <Header bold>{introductionLocale.header}</Header>
          <StepList>
            <StepInfo
              stepNumber={1}
              title={introductionLocale['heading.step-one']}
              description={introductionLocale['description.step-one']}
              active
            />
            <StepInfo
              stepNumber={2}
              title={introductionLocale['heading.step-two']}
              description={introductionLocale['description.step-two']}
            />
            <StepInfo
              stepNumber={3}
              title={introductionLocale['heading.step-three']}
              description={introductionLocale['description.step-three']}
            />
            <StepInfo
              stepNumber={4}
              title={introductionLocale['heading.step-four']}
              description={introductionLocale['description.step-four']}
            />
          </StepList>
          <BigButton
            color={Palette.INDEPENDENCE_BLUE}
            btnText={introductionLocale['button.text']}
            onClick={onClickNext}
          />
          <StyledDisclaimer>
            By continuing the onboarding process, you recognize that the information you provide is automatically shared
            with OneVest, who will determine what products are suitable for you. Manzil Invest offers wealth management
            services which are provided by OneVest. OneVest is a registered Portfolio Manager in each province and
            territory of Canada. Manzil is not registered as an Investment Fund Manager or Portfolio Manager but
            consults to OneVest to make sure their funds and products are Shariah compliant.
          </StyledDisclaimer>
        </IntroductionContent>
      </IntroductionContainer>
    </IntroductionContainerWrapper>
  );
};

export default Introduction;
