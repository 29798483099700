import React from 'react';
import { StyledSmallHeader, StyledHeader } from '../../../../components/styles';

interface IFormHeaderProps {
  subHeader: string;
  header: string;
}

const FormHeader = ({ subHeader, header }: IFormHeaderProps) => {
  return (
    <>
      <StyledSmallHeader center>{subHeader}</StyledSmallHeader>
      <StyledHeader center fsLg>
        {header}
      </StyledHeader>
    </>
  );
};

export default FormHeader;
