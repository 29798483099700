import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const StyledSingleReviewWrapper = styled.div<{ active?: boolean }>`
  width: 20%;
  min-width: 20rem;
`;

export const StyledReviewHeaderContainer = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  font-family: 'SofiaPro-Regular';
`;

export const StyledHeaderText = styled.span`
  font-size: 1rem;
  color: ${Palette.SMOKY_GREY};
`;

export const StyledReviewDescriptionContainer = styled.div`
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const StyledTitle = styled.h3`
  font-size: 1.2rem;
  font-family: 'SofiaPro-Regular';
  color: ${Palette.NEW_BLUE};
  font-weight: 500;
`;

export const StyledInfo = styled.p`
  font-size: 1rem;
  font-family: 'SofiaPro-Regular';
  color: ${Palette.SMOKY_GREY};
`;

export const StyledAuthor = styled.span`
  font-size: 1rem;
  font-family: 'SofiaPro-Regular';
  color: ${Palette.SMOKY_GREY};
`;
