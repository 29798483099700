import React from 'react';

import { formatCurrency } from './CurrencyInput.helper';
import { Input } from './CurrencyInput.styles';

export type CurrencyInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'style'>;

const CurrencyInput = React.forwardRef<HTMLInputElement, CurrencyInputProps>(
  ({ defaultValue, onChange, ...props }, ref) => {
    return (
      <Input
        data-testid="test"
        ref={ref}
        {...props}
        defaultValue={defaultValue ? formatCurrency(Number(defaultValue)) : 0}
        type="text"
        onChange={(event) => {
          const valueWithoutCommasAndLetters = event.currentTarget.value.replace(/[^\d.]/g, '');
          event.currentTarget.value = formatCurrency(Number(valueWithoutCommasAndLetters));
          onChange?.(event);
        }}
      />
    );
  },
);
export default CurrencyInput;
