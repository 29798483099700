export enum ESelectPlanSteps {
  PLANS_DESCRIPTION,
  SUBSCRIPTION,
  SUMMARY,
}

export interface IWillsSelectPlanContext {
  plan: string;
  goToPlansPage: () => void;
  handlePlanSelect: (type: string) => void;
  subscribed: boolean;
  setSubscribed: React.Dispatch<React.SetStateAction<boolean>>;
  goToSummaryPage: () => void;
  goToSubscriptionPage: () => void;
}
