import { IWillsProfile } from '../types';

export function isAllFlowsCompleted(willsProfile: IWillsProfile) {
  return (
    willsProfile.isComplete &&
    willsProfile.isPlanComplete &&
    willsProfile.isFamilyComplete &&
    willsProfile.isEstateComplete
  );
}
