import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { PopulatedDocumentProvider } from '@wills/pages/Documents/PopulatedDocumentContext';
import { GoBackButton } from '@wills/components';
import { DocumentsProvider } from '@wills/pages/Documents/Documents.context';
import {
  WillsSteps,
  WillsSelectPlan,
  WillsWelcomeBack,
  WillsFamilyFlow,
  CompleteWills,
  Documents,
  WillsLanding,
  HereToHelp,
  WillsPayment,
  PaymentSummary,
  AboutYou,
  WillsSavePaymentMethod,
  AppointeesPage,
  LiabilityListPage,
  AssetsList,
  AdditionalWishes,
  EstateIntake,
} from '../../pages';
import { createAboutYouRoute } from '../createAboutYouRoute';
import { createGettingStartedRoute } from '../createGettingStartedRoute';
import { WillsProfileProvider } from '../../components/WillsProfileProvider';

export const createWillsRoute = (): React.ReactElement | null => (
  <Route id="wills" key="wills" path="wills">
    <Route index element={<Navigate to="landing" replace />} />
    <Route path="*" element={<Navigate to="../landing" replace />} />
    <Route path="landing" element={<WillsLanding />} />
    {createGettingStartedRoute('../steps')}
    {createAboutYouRoute()}
    <Route path="steps" element={<WillsSteps />} />
    <Route path="plan" element={<WillsSelectPlan />} />
    <Route path="family" element={<WillsFamilyFlow />} />
    <Route path="complete" element={<CompleteWills />} />
    <Route path="estate" element={<EstateIntake />} />
    <Route path="welcome-back" element={<WillsWelcomeBack />} />
    <Route
      path="documents"
      element={
        <DocumentsProvider>
          <PopulatedDocumentProvider>
            <Outlet />
          </PopulatedDocumentProvider>
        </DocumentsProvider>
      }
    >
      <Route index element={<Documents />} />
      <Route path="liability-list" element={<LiabilityListPage renderNav={() => <GoBackButton />} />} />
      <Route path="asset-list" element={<AssetsList renderNav={() => <GoBackButton />} />} />
      <Route path="letter-of-wishes" element={<AdditionalWishes renderNav={() => <GoBackButton />} />} />
      <Route path="appointees" element={<AppointeesPage />} />
    </Route>
    <Route path="payment" element={<WillsPayment />} />
    <Route path="payment-confirmed" element={<PaymentSummary />} />
    <Route path="help" element={<HereToHelp />} />
    <Route path="save-payment-method" element={<WillsSavePaymentMethod />} />
    <Route path="review">
      <Route index element={<WillsSteps isReview />} />
      <Route
        path="about-you"
        element={
          <WillsProfileProvider>
            <AboutYou isReview />
          </WillsProfileProvider>
        }
      />
      <Route path="plan" element={<WillsSelectPlan isReview />} />
      <Route path="family" element={<WillsFamilyFlow isReview />} />
      <Route path="estate" element={<EstateIntake isReview />} />
    </Route>
  </Route>
);
