import styled from 'styled-components';
import { sectionGap } from '@/components';

export const WishesContainer = styled.section`
  display: flex;
  flex-direction: column;
  align-self: baseline;
  width: 370px;
  ${sectionGap}
`;
