import { AxiosError } from 'axios';

export interface ApiResponseError {
  code: string;
  message: string;
}

export interface ServerErrorCode {
  response: { data: { Code: string } };
}

export interface ServerErrorMessage {
  response: { data: { Message: string } };
}

// Naive check, might break if its not a nested object
const objectHasCode = (obj: object): obj is ServerErrorCode =>
  typeof (obj as ServerErrorCode)?.response?.data?.Code === 'string';

// Naive check, might break if its not a nested object
const objectHasMessage = (obj: object): obj is ServerErrorMessage =>
  typeof (obj as ServerErrorMessage)?.response?.data?.Message === 'string';

/**
 * Converts the error response from the BE to a ApiResponseError
 *
 * @param error error response from request
 * @returns
 */
export const toApiResponseError = (error: unknown): ApiResponseError => {
  if (
    typeof error === 'object' &&
    error !== null &&
    'response' in error &&
    objectHasCode(error) &&
    objectHasMessage(error)
  ) {
    return {
      code: error.response.data.Code,
      message: error.response.data.Message,
    };
  }

  if (error instanceof AxiosError) {
    return {
      code: error.code || 'UNKNOWN_AXIOS_ERROR',
      message: error.message,
    };
  }

  return {
    code: 'UNKNOWN',
    message: 'Unknown error occurred',
  };
};
