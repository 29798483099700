import styled from 'styled-components';
import { BiEditAlt } from 'react-icons/bi';
import { BsTrash, BsEnvelope, BsGift } from 'react-icons/bs';

import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledRelativeDisplayContainer = styled.div`
  background-color: ${Palette.NEW_WHITE};
  border-radius: 0.5rem;
  padding: 1.5rem;
  width: 100%;
  margin-inline: auto;

  &:nth-last-child(2) {
    margin-bottom: 1.5rem;
  }

  @media (min-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    width: 85%;
  }
`;

export const StyledRelativeDisplayRelation = styled.p`
  font-family: 'SofiaPro-Bold';
  font-size: clamp(0.9rem, 1vw, 1rem);
  color: ${Palette.GUN_POWDER};
  text-transform: capitalize;
`;

export const StyledRelativeDisplayName = styled.h2`
  font-family: 'SofiaPro-Bold';
  color: ${Palette.GUN_POWDER};
  text-transform: capitalize;
  margin-top: 1rem;
`;

export const StyledRelativeDisplayEmailContainer = styled.div`
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  font-family: 'SofiaPro-Regular';
  color: ${Palette.PLANET_BLUE};
  text-transform: lowercase;
`;

export const StyledRelativeDisplayDobContainer = styled(StyledRelativeDisplayEmailContainer)``;

export const StyledRelativeDisplayEmailDobContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
  margin-top: 1rem;
`;

export const StyledRelativeDisplayButtonGroup = styled.div`
  margin-top: 1.5rem;
  display: flex;
  gap: 1.5rem;
`;

export const StyledRelativeDisplayButton = styled.button`
  color: black;
  border: none;
  background-color: transparent;
  display: flex;
  align-items: center;
  gap: 0.3rem;
  cursor: pointer;
`;

export const StyledRelativeDisplayEditIcon = styled(BiEditAlt)`
  color: ${Palette.CADET_BLUE_DARKER};
  font-size: 1rem;
`;

export const StyledRelativeDisplayRemoveIcon = styled(BsTrash)`
  color: ${Palette.CADET_BLUE_DARKER};
  font-size: 1rem;
`;

export const StyledRelativeDisplayEmailIcon = styled(BsEnvelope)`
  color: ${Palette.CADET_BLUE_DARKER};
  font-size: 1.1rem;
`;

export const StyledRelativeDisplayDobIcon = styled(BsGift)`
  color: ${Palette.CADET_BLUE_DARKER};
  font-size: 1.1rem;
`;
