import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const LoadingContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${Palette.AQUA_HAZE};
  justify-content: center;
  align-items: center;
`;
