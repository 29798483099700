import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const StyledWillsPaymentContainer = styled.div`
  padding-top: 5rem;
  background-color: ${Palette.AQUA_HAZE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;
