import React from 'react';

import {
  StyledExecutorAndEstateChooser,
  StyledExecutorChooserRadioButtonLabel,
  StyledExecutorChooserText,
} from './ElementChooser.styles';
import { IElementChooser } from './ElementChooser.types';

const ElementChooser = ({ id, checked, onClick, value }: IElementChooser) => {
  return (
    <StyledExecutorAndEstateChooser>
      <StyledExecutorChooserRadioButtonLabel htmlFor={id}>
        <input id={id} type="radio" checked={checked} onChange={onClick} />
        <StyledExecutorChooserText>{value}</StyledExecutorChooserText>
      </StyledExecutorChooserRadioButtonLabel>
    </StyledExecutorAndEstateChooser>
  );
};

export default ElementChooser;
