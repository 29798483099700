import React from 'react';
import { UserContext } from '../user/UserContext';

export const useReligion = () => {
  const { user } = React.useContext(UserContext);

  return {
    isReligious: Boolean(user.reasonsForJoining?.religion ?? true),
  };
};
