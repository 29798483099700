import React, { useContext, useEffect, useRef, useState } from 'react';
import './AddressQuestion.css';
import { useForm } from 'react-hook-form';
import {
  StyledForm,
  StyledFormFieldContainer,
  StyledFormLabel,
  StyledInputField,
  QuestionInfo,
  WillsProfileContext,
  InfoContentWrapper,
  EAboutYouSteps,
} from '@wills/components';
import { useGooglePlace } from '@/Shared/Hooks';
import { AboutYouContainer, Content, FormFooter, FormHeader, IWillsProps } from '../../shared';
import { HiddenSubmitButton } from '@/Shared/Shared.styles';

const AddressQuestionInfo: React.FC = () => (
  <QuestionInfo
    question="Why do we ask?"
    answer={
      <div>
        Each province has different will and power of attorney legislation, so we ask your current province to ensure
        your documents reflect where you live. We ask your city because it’s referenced in your will.
        <br />
        <br />
        You will need to update your province or city if it changes, in the future.
      </div>
    }
  />
);

const AddressQuestion: React.FC<IWillsProps> = ({ goBack, isEdit }) => {
  const { handleSubmit } = useForm();
  const [isDisabled, setIsDisabled] = useState(false);
  const { setAddressData, goToStep } = useContext(WillsProfileContext);
  const options = {
    componentRestrictions: { country: 'ca' },
  };
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { place } = useGooglePlace(inputRef, options);

  const focusInputOnLoad = () => {
    inputRef.current?.focus();
  };

  const recordAddress = (name: string, value: string) => {
    setAddressData((prevAddress) => ({
      ...prevAddress,
      [name]: value,
    }));
  };

  const onAddressSubmit = (event: any) => {
    event.preventDefault();
    if (!place) setIsDisabled(true);
  };

  const resetInputField = () => {
    inputRef.current!.value = '';
  };

  const handleDocumentClick = (event: MouseEvent) => {
    if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
      resetInputField();
    }
  };

  const handleDocumentKeyDown = (event: KeyboardEvent) => {
    if (event.key === 'Escape') {
      resetInputField();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleDocumentClick);
    document.addEventListener('keydown', handleDocumentKeyDown);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
      document.removeEventListener('keydown', handleDocumentKeyDown);
    };
  }, []);

  useEffect(() => {
    if (place) {
      place.forEach((data: any) => {
        if (data.types.includes('route')) recordAddress('streetName', data.long_name);
        if (data.types.includes('street_number')) recordAddress('streetNumber', data.long_name);
        if (data.types.includes('locality')) recordAddress('city', data.long_name);
        if (data.types.includes('administrative_area_level_1')) recordAddress('provinceState', data.short_name);
        if (data.types.includes('country')) recordAddress('country', data.short_name);
        if (data.types.includes('postal_code')) recordAddress('postalZip', data.long_name);
      });
      goToStep(EAboutYouSteps.LOCATION_QUESTION);
    }
  }, [place]);

  useEffect(() => {
    focusInputOnLoad();
  }, []);

  return (
    <AboutYouContainer>
      <InfoContentWrapper InfoContent={AddressQuestionInfo}>
        <Content>
          <FormHeader subHeader="ABOUT YOU" header="What is your address?" />
          <StyledForm onSubmit={onAddressSubmit}>
            <StyledFormFieldContainer>
              <StyledFormLabel>Address</StyledFormLabel>
              <StyledInputField placeholder="Please insert your address" ref={inputRef} />
            </StyledFormFieldContainer>
            <HiddenSubmitButton type="submit" />
          </StyledForm>
          <FormFooter
            previousStep={EAboutYouSteps.MADHAB_QUESTION}
            goBack={goBack}
            handleSubmit={handleSubmit}
            onFormSubmit={onAddressSubmit}
            isDisabled={isDisabled}
            isEdit={isEdit}
          />
        </Content>
      </InfoContentWrapper>
    </AboutYouContainer>
  );
};

export default AddressQuestion;
