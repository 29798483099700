import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledProgressBarContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  margin: 2rem 0 2rem 0;
`;

export const StyledProgressBarTrack = styled.div`
  background-color: rgb(97, 146, 122, 0.4);
  height: 0.5rem;
  border-radius: 2rem;
  width: 10rem;
`;

export const StyledProgressBarLine = styled.div<{ progress: number }>`
  background-color: ${Palette.SLATE_GREEN};
  height: 0.5rem;
  border-radius: 2rem;
  width: ${({ progress }) => `${progress}%`};
  max-width: 10rem;
  z-index: 5;
`;

export const StyledProgresBarReading = styled.span`
  font-family: 'SofiaPro-Regular';
  color: ${Palette.GUN_POWDER};
`;
