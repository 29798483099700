import React, { useContext, useState } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { observer } from 'mobx-react-lite';

import { BigButton, SubHeader } from '@/Shared/Components';
import { Palette } from '@/Shared/Constants';
import { AddressStore, ContactStore, ProfileStore } from '@/Stores';
import { AuthContext, LocaleContext } from '@/providers';

import { StyledArrowLeft, StyledBackButton, StyledContainer, StyledContent } from './ConfirmContinue.styles';
import confirmContinueLocales from './confirmContinueLocales.json';
import { createInvest } from '../../apis';
import { bindLoadingToPromise } from '@/Utils';
import { useReligion } from '@/lib/contextAware';
import { useErrorHandler } from '@/hooks';

export interface ConfirmContinueProps {
  renderOnBack: () => JSX.Element;
  renderOnComplete: () => JSX.Element;
}

const ConfirmContinue: React.FC<ConfirmContinueProps> = ({ renderOnBack, renderOnComplete }) => {
  const { locale } = useContext(LocaleContext);
  const { user, setUser } = useContext(AuthContext);
  const { profile } = useContext(ProfileStore);
  const { contact } = useContext(ContactStore);
  const { address } = useContext(AddressStore);
  const { isReligious } = useReligion();
  const [shouldGoBack, setShouldGoBack] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [complete, setComplete] = useState(false);
  const { handleAsyncError } = useErrorHandler();
  const confirmContinueLocale = confirmContinueLocales[locale];

  if (!profile || !contact || !address || !user) {
    throw new Error('missing info');
  }

  const onClick = handleAsyncError(async () => {
    const { data, error } = await createInvest({
      profile,
      contact,
      address,
    });

    if (error) {
      throw new Error(error.message);
    }

    setUser({
      ...user,
      isOnboarding: false,
      profile: [
        {
          ...profile,
          ...data.profile,
          middleName: data.profile.middleName ?? undefined,
        },
      ],
      contact: [
        {
          ...contact,
          ...data.contact,
        },
      ],
      address: [
        {
          ...address,
          ...data.address,
        },
      ],
    });

    amplitude.track('Invest KYC Complete');
    setComplete(true);
  });

  if (complete) {
    return renderOnComplete();
  }
  if (shouldGoBack) {
    return renderOnBack();
  }

  return (
    <StyledContainer>
      <StyledBackButton type="button" onClick={() => setShouldGoBack(true)}>
        <StyledArrowLeft />
      </StyledBackButton>
      <StyledContent>
        <SubHeader alignCenter>
          {[isReligious ? 'Alhamdulillāh.' : null, confirmContinueLocale.message].filter(Boolean).join('')}
        </SubHeader>
        <BigButton
          color={Palette.INDEPENDENCE_BLUE}
          disabled={disabled}
          btnText={confirmContinueLocale['button.text']}
          onClick={() => bindLoadingToPromise(onClick(), setDisabled)}
        />
      </StyledContent>
    </StyledContainer>
  );
};

export default observer(ConfirmContinue);
