import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledWillsIntroText = styled.h4<{ center?: boolean }>`
  color: ${Palette.SLATE_GREEN};
  text-transform: uppercase;
  font-family: 'SofiaPro-Regular';
  margin-bottom: 1rem;
  ${({ center }) => center && 'text-align: center;'}
`;

export const StyledWillsTitle = styled.h1<{ center?: boolean }>`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
  ${({ center }) => center && 'text-align: center;'}

  @media (max-width: 720px) {
    margin-bottom: 2rem;
  }
`;

export const StyledWillsText = styled.p`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
`;
