import { ApiResponse, axiosClient, toApiResponse } from '@/lib/axios';
import { snakeToCamelParser } from '@/Utils';
import { UserProfile } from '../types';

export type CreateUserProfileInput = Pick<UserProfile, 'firstName' | 'middleName' | 'lastName' | 'dob'>;

export const createUserProfile = async (input: CreateUserProfileInput): Promise<ApiResponse<UserProfile>> => {
  const response = await toApiResponse<UserProfile>(axiosClient.post('/user/profile', input));

  if (response.error) return response;

  return {
    // TODO: should be parsed in the BE, will do this if we clean up User model
    data: snakeToCamelParser(response.data),
    error: undefined,
  };
};
