import React from 'react';

import { Spinner } from '@/Shared/Components';

import { LoadingContainer } from './Loading.styles';
import { EDataTestId } from '@/test-utils/EDataTestId';

export const Loading: React.FC = () => {
  return (
    <LoadingContainer key="loading" data-testid={EDataTestId.LOADING_PAGE}>
      <Spinner />
    </LoadingContainer>
  );
};
