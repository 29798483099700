import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { WillsProfileContext, EAboutYouSteps } from '@wills/components';
import { useStepsReviewNavigation } from '@wills/hooks/useStepsReviewNavigation';
import { useScrollToTop } from '@/Shared/Hooks';
import {
  LegalNameQuestion,
  DobQuestion,
  ChildrenQuestion,
  MaritalStatusQuestion,
  GenderQuestion,
  MadhabQuestion,
  AddressQuestion,
  LocationQuestion,
  FormSummary,
} from './components';
import { Page } from '@/components';

export const AboutYou: React.FC<{ isReview?: boolean }> = observer(({ isReview }) => {
  const [isEdit, setIsEdit] = useState(false);
  const { step, goToStep } = useContext(WillsProfileContext);
  const { goToStepsOrReview } = useStepsReviewNavigation(isReview);
  useScrollToTop([step]);

  const navigate = useNavigate();

  const goBack = (renderStep?: EAboutYouSteps) => {
    if (!step) {
      navigate(-1);
    } else {
      goToStep(renderStep!);
    }
  };

  const goToSummaryPage = () => {
    setIsEdit(false);
    goToStep(EAboutYouSteps.ABOUT_YOU_SUMMARY);
  };

  return (
    <Page>
      {step === EAboutYouSteps.LEGAL_NAME_QUESTION && (
        <LegalNameQuestion goBack={goBack} isEdit={isEdit} goToSummaryPage={goToSummaryPage} />
      )}

      {step === EAboutYouSteps.DOB_QUESTION && (
        <DobQuestion goBack={goBack} isEdit={isEdit} goToSummaryPage={goToSummaryPage} />
      )}

      {step === EAboutYouSteps.MARITAL_STATUS_QUESTION && (
        <MaritalStatusQuestion goBack={goBack} isEdit={isEdit} goToSummaryPage={goToSummaryPage} />
      )}

      {step === EAboutYouSteps.CHILDREN_QUESTION && (
        <ChildrenQuestion goBack={goBack} isEdit={isEdit} goToSummaryPage={goToSummaryPage} />
      )}

      {step === EAboutYouSteps.GENDER_QUESTION && (
        <GenderQuestion goBack={goBack} isEdit={isEdit} goToSummaryPage={goToSummaryPage} />
      )}

      {step === EAboutYouSteps.MADHAB_QUESTION && (
        <MadhabQuestion goBack={goBack} isEdit={isEdit} goToSummaryPage={goToSummaryPage} />
      )}

      {step === EAboutYouSteps.ADDRESS_QUESTION && (
        <AddressQuestion goBack={goBack} isEdit={isEdit} goToSummaryPage={goToSummaryPage} />
      )}

      {step === EAboutYouSteps.LOCATION_QUESTION && (
        <LocationQuestion goBack={goBack} isEdit={isEdit} goToSummaryPage={goToSummaryPage} />
      )}

      {step === EAboutYouSteps.ABOUT_YOU_SUMMARY && (
        <FormSummary goBack={goBack} onComplete={goToStepsOrReview} setIsEdit={setIsEdit} />
      )}
    </Page>
  );
});
