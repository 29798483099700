import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const StyledContactUsText = styled.p`
  text-align: center;
  font-family: 'SofiaPro-Regular';
  color: #6a6d7c;
`;

export const StyledContactUsInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;

export const StyledFormHeader = styled.h2`
  color: ${Palette.NEW_BLUE};
  font-family: 'SofiaPro-Regular';
  text-align: center;
  font-size: 24px;
`;

export const StyledImage = styled.img`
  width: 6.125rem;
  height: 6.125rem;
  display: block;
  margin: 1rem auto;
`;
