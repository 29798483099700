import { IProfile } from '@/Types';
import { RelativeDefinitionRelationship, IWillsProfile, ALL_RELATIONSHIPS } from '../types';
import { removeRelationships } from './removeRelationships';

const getSpouseRelationshipToRemove = (marritalStatus: string, sex: string): RelativeDefinitionRelationship[] => {
  if (marritalStatus === 'MARRIED') {
    switch (sex) {
      case 'M':
        return ['husband'];
      case 'F':
        return ['wife'];
      default:
        return [];
    }
  }
  return ['wife', 'husband'];
};

const getChildrenRelationshipsToRemove = (hasKids: Boolean): RelativeDefinitionRelationship[] => {
  if (hasKids) {
    return [];
  }
  return ['son', 'daughter', "gdaughter",  "gson"];
};

export const filterRelationshipsByProfile = (
  profile: Pick<IProfile, 'marritalStatus'>,
  willsProfile?: Pick<IWillsProfile, 'sex' | 'kids'>,
) => {
  return removeRelationships(ALL_RELATIONSHIPS, [
    ...(willsProfile ? getSpouseRelationshipToRemove(profile.marritalStatus, willsProfile.sex) : []),
    ...(willsProfile ? getChildrenRelationshipsToRemove(Boolean(willsProfile.kids)) : []),
  ]);
};
