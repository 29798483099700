import { axiosClient, toApiResponse } from '@/lib/axios';

export interface OtpLoginInput {
  email: string;
  otp: string;
}

export type OtpLoginDto = {
  AccessToken: string;
  zendeskToken: string;
};

export const otpLogin = (input: OtpLoginInput) => {
  return toApiResponse<OtpLoginDto>(axiosClient.post('/otp-login', input, { withCredentials: true }));
};
