import { axiosClient, toApiResponse } from '@/lib/axios';

export type ConfirmUserDto =
  | {
      Code: never;
      HTTPStatusCode: number;
      Message: string;
    }
  | {
      Code: string;
      HTTPStatusCode: never;
      Message: string;
    };
export const confirmUser = async (userSub: string) => {
  return toApiResponse<ConfirmUserDto>(axiosClient.get(`/confirm-user/${userSub}`));
};
