import React, { useContext, useEffect } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { Header } from '@/Shared/Components';
import { IProfile, IUserContact } from '@/Types';
import { IOnboardingInputs, IOnboardingKeyNames } from '@/lib/invest/pages/Onboarding/Types';
import { HiddenSubmitButton, LongInput } from '@/Shared/Shared.styles';
import { LocaleContext } from '@/providers';

import { LongSelect, OneLineFormContainer, QuestionContainer, RequiredInfoIndicator } from './Question.styles';
import { Prompt } from '../Prompt';
import { setModelValues } from './QuestionHelpers';
import { DobForm } from '../DobForm';
import { PhoneForm } from '../PhoneForm';
import questionLocales from './questionLocales.json';

interface IProps {
  question: string;
  placeholder?: string;
  name?: IOnboardingKeyNames;
  goToNextQuestion: () => void;
  recordAnswer?: (name: string, value: string) => void;
  formType?: 'oneLine' | 'dob' | 'marritalStatus' | 'phone' | 'preferredLanguage';
  model: IProfile | IUserContact;
  required?: boolean;
}

const Question: React.FC<IProps> = ({
  question,
  placeholder,
  name,
  goToNextQuestion,
  recordAnswer,
  formType = 'oneLine',
  model,
  required,
}) => {
  const { locale } = useContext(LocaleContext);

  const questionLocale = questionLocales[locale];

  const {
    register,
    handleSubmit,
    reset,
    setValue,
    formState: { errors },
  } = useForm<IOnboardingInputs>();

  const onSubmit: SubmitHandler<IOnboardingInputs> = (data) => {
    recordAnswer!(name!, data[name!]);
    goToNextQuestion();
  };

  const propertyRequired = name === 'dob' ? false : required;

  const { onChange, onBlur, name: inputName, ref } = register(name!, { required: propertyRequired });

  const registerReference = (element: HTMLElement | null) => {
    element?.focus();
    ref(element);
  };

  useEffect(() => {
    reset(setModelValues(model));
  }, [model]);

  const oneLineForm = (
    <OneLineFormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LongInput
          ref={(element) => registerReference(element)}
          name={inputName}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
        />
        <HiddenSubmitButton type="submit" />
      </form>
      <Prompt disabled={!!errors[name!]} onClick={handleSubmit(onSubmit)} />
    </OneLineFormContainer>
  );

  const dobForm = (
    <DobForm
      dobValue={(model as IProfile).dob}
      setValue={setValue}
      recordAnswer={recordAnswer!}
      goToNextQuestion={goToNextQuestion}
    />
  );

  const preferredLanguageForm = (
    <OneLineFormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LongSelect ref={(element) => registerReference(element)} name={inputName} onChange={onChange} onBlur={onBlur}>
          <option value="ENGLISH">English</option>
          <option value="FRENCH">French</option>
        </LongSelect>
        <HiddenSubmitButton type="submit" />
      </form>
      <Prompt disabled={!!errors[name!]} onClick={handleSubmit(onSubmit)} />
    </OneLineFormContainer>
  );

  const marritalStatusForm = (
    <OneLineFormContainer>
      <form onSubmit={handleSubmit(onSubmit)}>
        <LongSelect ref={(element) => registerReference(element)} name={inputName} onChange={onChange} onBlur={onBlur}>
          <option value="SINGLE">{questionLocale['marital-status.single']}</option>
          <option value="MARRIED">{questionLocale['marital-status.married']}</option>
          <option value="DIVORCED">{questionLocale['marital-status.divorced']}</option>
          <option value="SEPARATED">{questionLocale['marital-status.separated']}</option>
          <option value="WIDOWED">{questionLocale['marital-status.widowed']}</option>
          <option value="COMMON_LAW">{questionLocale['marital-status.common-law']}</option>
        </LongSelect>
        <HiddenSubmitButton type="submit" />
      </form>
      <Prompt disabled={!!errors[name!]} onClick={handleSubmit(onSubmit)} />
    </OneLineFormContainer>
  );

  const phoneForm = (
    <PhoneForm
      attributeName={name as 'cellPhone' | 'homePhone' | 'primaryPhone'}
      recordAnswer={recordAnswer!}
      goToNextQuestion={goToNextQuestion}
      contact={model as IUserContact}
    />
  );

  const getForm = () => {
    switch (formType) {
      case 'dob':
        return dobForm;
      case 'marritalStatus':
        return marritalStatusForm;
      case 'preferredLanguage':
        return preferredLanguageForm;
      case 'phone':
        return phoneForm;
      default:
        return oneLineForm;
    }
  };

  const formElement = getForm();
  return (
    <QuestionContainer>
      <Header alignCenter>
        {question}
        {required && <RequiredInfoIndicator>*</RequiredInfoIndicator>}
      </Header>
      {formElement}
    </QuestionContainer>
  );
};

export default Question;
