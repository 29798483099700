import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledWillsText = styled.p`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
`;

export const StyledIntroductionPageContainer = styled.div`
  margin: 0 auto;
  width: 85%;

  @media screen and (min-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    width: min(60%, 1366px);
    height: 100%;
  }
`;

export const StyledWillsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 2rem;

  @media (min-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    grid-template-columns: minmax(0, 1.7fr) minmax(0, 1fr);
  }
`;

export const StyledInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 0rem;
  align-items: center;

  @media screen and (min-width: ${Breakpoints.TABLET_MIN_WIDTH}) {
    margin-top: 2rem;
  }
`;

export const StyledIntroText = styled(StyledWillsText)`
  margin-top: 1rem;
`;

export const StyledFooterBoldText = styled.span`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Bold';
`;

export const StyledWillsImageContainer = styled.div`
  @media (max-width: ${Breakpoints.MOBILE_MAX_WIDTH}) {
    padding-inline: 2rem;
  }
`;

export const StyledWillsImage = styled.img`
  width: 100%;
`;

export const StyledNavigationButtonsWrapper = styled.div`
  margin-top: 2rem;
  width: 100%;

  @media (min-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    width: 40%;
  }
`;
