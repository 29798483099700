import React from 'react';
import { Link } from 'react-router-dom';
import { AuthLinkText } from './AuthLink.styles';

interface IProps {
  to: string;
  children: string;
  bold?: boolean;
}

const AuthLink: React.FC<IProps> = ({ to, children, bold }) => {
  return (
    <Link to={to}>
      <AuthLinkText bold={bold}>{children}</AuthLinkText>
    </Link>
  );
};

export default AuthLink;
