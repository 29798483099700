import React from 'react';
import { Route, Navigate, Outlet } from 'react-router-dom';
import { Introduction, OnboardingNote } from '../../pages';
import { WillsProfileProvider } from '../../components/WillsProfileProvider';

export const createGettingStartedRoute = (nextRoute: string) => (
  <Route
    path="getting-started"
    element={
      <WillsProfileProvider>
        <Outlet />
      </WillsProfileProvider>
    }
  >
    <Route index element={<Navigate to="introduction" replace />} />
    <Route path="introduction" element={<Introduction nextRoute="../notice" />} />,
    <Route path="notice" element={<OnboardingNote nextRoute={`../${nextRoute}`} />} />,
    <Route path="*" element={<Navigate to="../introduction" replace />} />
  </Route>
);
