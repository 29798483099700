import { AxiosResponse } from 'axios';
import { ApiResponseError, toApiResponseError } from './toApiResponseError';

interface ApiResponseWithData<T> {
  data: T;
  error: undefined;
}
interface ApiResponseWithoutData {
  data: undefined;
  error: ApiResponseError;
}

export type ApiResponse<T> = ApiResponseWithData<T> | ApiResponseWithoutData;

/**
 * Wraps an axios request to standardize the interface for the response and error
 *
 * @param request axios request
 * @returns
 */
export const toApiResponse = async <ReturnDataType, Config = any>(
  request: Promise<AxiosResponse<ReturnDataType, Config>>,
): Promise<ApiResponse<ReturnDataType>> => {
  try {
    const result = await request;
    return {
      data: result.data,
      error: undefined,
    };
  } catch (e: unknown) {
    // When we abort the request, we need to handle it cleanly or else a Javascript error will be thrown
    // and the app can crash

    if (e instanceof Error && e.name === 'CanceledError') {
      return {
        data: undefined,
        error: {
          code: 'CanceledError',
          // TODO: make a more accurate message and update code that checks for this instead of code.
          message: 'CanceledError',
        },
      };
    }

    return {
      data: undefined,
      error: toApiResponseError(e),
    };
  }
};
