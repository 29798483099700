import React from 'react';
import * as amplitude from '@amplitude/analytics-browser';

export interface UseAnalyticsOptions {
  messageOnload?: string;
}

export const useAnalytics = ({ messageOnload }: UseAnalyticsOptions = {}) => {
  const sendEventMessage = (message: string) => {
    if (process.env.NODE_ENV === 'development') {
      // eslint-disable-next-line no-console
      console.info(message);
    } else {
      amplitude.track(message);
    }
  };
  React.useEffect(() => {
    if (messageOnload) {
      sendEventMessage(messageOnload);
    }
  }, []);

  return {
    sendEventMessage,
  };
};
