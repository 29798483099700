import React from 'react';
import { IAppointeeRole } from '@/lib/wills';
import { findAppointeeInEmailPayload, removeAppointeeFromEmailPayload } from './AppointeesRoleList.helpers';

function useAllAppointeeChecker(
  emailsToNotify: IAppointeeRole[],
  setEmailsToNotify: React.Dispatch<React.SetStateAction<IAppointeeRole[]>>,
) {
  function isChecked(appointees: IAppointeeRole[]) {
    const tolalAppointees = appointees.length;

    if (tolalAppointees < 1) return false;

    let selectedEmails: IAppointeeRole[] = [];

    appointees.forEach((appointee) => {
      const emailsPresent = findAppointeeInEmailPayload(emailsToNotify, appointee);
      selectedEmails = [...selectedEmails, ...emailsPresent];
    });
    return selectedEmails.length === tolalAppointees;
  }

  function onChangeHandler(event: React.ChangeEvent<HTMLInputElement>, appointees: IAppointeeRole[]) {
    const { checked } = event.currentTarget;

    let collection: IAppointeeRole[] = [...emailsToNotify];

    if (checked) {
      appointees.forEach((appointee) => {
        const exists = findAppointeeInEmailPayload(emailsToNotify, appointee).length > 0;

        if (!exists) {
          collection.push({
            name: appointee.name,
            email: appointee.email,
            role: appointee.role,
          });
        }
      });
    } else {
      appointees.forEach((appointee) => {
        collection = removeAppointeeFromEmailPayload(collection, appointee);
      });
    }

    setEmailsToNotify(collection);
  }

  return { onChangeHandler, isChecked };
}

export default useAllAppointeeChecker;
