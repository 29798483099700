import styled from 'styled-components';
import { alertBody } from '../typography.styles';

export const AlertContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 0.5rem;
  width: fit-content;
  border-radius: 0.5rem;
  border-style: solid;
  border-width: 1px;
  border-color: transparent;
  padding: 0.5rem 1rem;
  ${alertBody}
  align-items: center;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 24px;
  height: 24px;
`;
