import React from 'react';
import { QuestionInfo } from '@/lib/wills/components/QuestionInfo';

const MaritalStatusQuestionInfo = () => {
  return (
    <QuestionInfo
      question="Why do we ask?"
      answer={
        <div>
          Your marital status plays a vital role in the planning of your estate in accordance with the Shariah. It also
          allows us to better understand what your needs might be.
        </div>
      }
    />
  );
};

export default MaritalStatusQuestionInfo;
