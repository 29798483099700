export const METHOD_OF_CALCULATIONS = [
  {
    name: 'JUMHOUR',
    info: 'Jumhour (majority)',
  },
  {
    name: 'HANAFI',
    info: 'Hanafi',
  },
  {
    name: 'MALIKI',
    info: 'Maliki',
  },
  {
    name: 'SHAFI',
    info: 'Shafi',
  },
  {
    name: 'HANBALI',
    info: 'Hanbali',
  },
];
