import styled, { css } from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { FONT_FAMILY } from '@/components';

export const Title = styled.h1`
  font-family: ${FONT_FAMILY};
  font-size: 2.2rem;
`;

export const InputLabel = styled.label<{ required?: boolean }>`
  font-family: ${FONT_FAMILY};
  font-size: 1rem;
  line-height: 1.1rem;
  color: ${Palette.NEW_BLUE};
  ${({ required }) =>
    required
      ? css`
          :after {
            content: '*';
            color: red;
          }
        `
      : ''}
`;

export const ErrorLabel = styled.label`
  font-family: ${FONT_FAMILY};
  font-size: 0.85rem;
  color: red;
`;
