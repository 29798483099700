import React from 'react';
import { OpenUrl } from './OpenUrl';

interface UrlType {
  type: 'download' | 'view';
  url: string;
}

interface OpenPdfContextValue {
  openUrl: (show: UrlType) => void;
}

export const OpenPdfContext = React.createContext({} as OpenPdfContextValue);

export const OpenPdfProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const [urlType, setUrlType] = React.useState<UrlType | undefined>(undefined);

  return (
    <OpenPdfContext.Provider
      value={{
        openUrl: setUrlType,
      }}
    >
      {urlType && (
        <OpenUrl
          url={urlType.url}
          type={urlType.type}
          onClose={() => {
            setUrlType(undefined);
          }}
        />
      )}
      {children}
    </OpenPdfContext.Provider>
  );
};
