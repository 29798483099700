import React from 'react';
import { useNavigate } from 'react-router-dom';
import { StyledWillsIntroText, StyledWillsTitle, StyledDescription } from '@wills/components';
import {
  StyledButton,
  StyledContactUsContentContainer,
  StyledContactUsGrid,
  StyledContactUsInfoContainer,
  StyledContactUsPageContainer,
  StyledContactUsText,
  StyledQueryInfoContainer,
  StyledQueryAndLearnMoreSectionContainer,
  StyledLearnMoreContainer,
  StyledButtonContainer,
} from './HereToHelp.styles';

import { FormModal, NavigationButtons } from './components';

export const HereToHelp: React.FC = () => {
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const onContinueBtnClick = () => {
    setIsModalOpen(true);
  };
  const onPreviousBtnClick = () => {
    navigate('/wills/getting-started/notice');
  };

  return (
    <StyledContactUsPageContainer>
      {isModalOpen && <FormModal setIsModalOpen={setIsModalOpen} />}
      <StyledContactUsContentContainer>
        <StyledContactUsGrid>
          <StyledContactUsInfoContainer>
            <StyledWillsIntroText center>We&apos;re here to help</StyledWillsIntroText>
            <StyledWillsTitle center>We&apos;re here to help</StyledWillsTitle>
            <StyledQueryAndLearnMoreSectionContainer>
              <StyledQueryInfoContainer>
                <StyledContactUsText>Contact Us</StyledContactUsText>
                <StyledDescription marginTop>
                  Alhamdulillāh, Manzil Wills is a good fit for majority of Canadian Muslims. However, there are a
                  number of situations that can add complexity to your will and require legal advice from a lawyer.
                </StyledDescription>
                <StyledDescription marginTop>
                  Our team of professionals are here to answer your questions on why you may not currently qualify for a
                  Manzil Will.
                </StyledDescription>
                <StyledButton
                  borderColor="#EAEAEC"
                  bgColor="#F9FAFB"
                  textColor="#555969"
                  onClick={() => setIsModalOpen(true)}
                >
                  Reach out to us
                </StyledButton>
              </StyledQueryInfoContainer>
              <StyledLearnMoreContainer>
                <StyledContactUsText>Learn more about Estate Planning in Islam</StyledContactUsText>
                <StyledDescription marginTop>
                  Our Knowledge Centre contains overviews of important topics in regards to Estate Planning from a legal
                  and Shariah Perspective and our Help Center can answer questions in regards to the features of Manzil
                  Wills.
                </StyledDescription>
                <StyledButtonContainer>
                  <a
                    href="https://help.manzil.ca/hc/en-us/categories/8802446791707-Wills"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <StyledButton borderColor="#EAEAEC" bgColor="#FAEBD2" textColor="#555969">
                      Help center
                    </StyledButton>
                  </a>
                </StyledButtonContainer>
              </StyledLearnMoreContainer>
            </StyledQueryAndLearnMoreSectionContainer>
          </StyledContactUsInfoContainer>
          <NavigationButtons onContinue={onContinueBtnClick} onPrevious={onPreviousBtnClick} />
        </StyledContactUsGrid>
      </StyledContactUsContentContainer>
    </StyledContactUsPageContainer>
  );
};
