import React, { useContext, useEffect, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { getCode } from 'country-list';

import { LocaleContext } from '@/providers';
import { useGooglePlace } from '@/Shared/Hooks';
import { updateCountryInfo } from '@/lib/invest/pages/Onboarding/Components/CitizenshipInput/CitizenshipConstants';
import { ProfileStore } from '@/Stores';

import {
  CalendarIcon,
  CitizenIcon,
  ErrorMessage,
  InfoAttributes,
  InfoContent,
  InfoHeader,
  InputField,
  LanguageIcon,
  RingIcon,
  SelectField,
  SingleInfoWrapper,
  UserIcon,
} from '../../Profile.style';
import profileFormFieldsLocales from './ProfileFormFields.locales.json';

import { firstDropdownElement, isEnterKeyPressed } from './ProfileFormFields.helpers';
import { IProfileFormFieldsProps } from './ProfileFormFields.types';
import { normalizeDateOnChange } from '../../helpers/normalizeDateOnChange';

const ProfileFormFields = ({ register, errors, profileInfo, setProfileInfo, setValue }: IProfileFormFieldsProps) => {
  const { locale } = useContext(LocaleContext);
  const { profile } = useContext(ProfileStore);

  const inputLocales = profileFormFieldsLocales[locale];

  const citizenshipInputRef = useRef<HTMLInputElement | null>(null);

  const options = {
    types: ['country'],
  };

  const { place } = useGooglePlace(citizenshipInputRef, options);

  const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
    if (e.target.name === 'dob') {
      if (e.target.value.length > 10) {
        return;
      }
      normalizeDateOnChange(e);
    }
    setProfileInfo({ ...profileInfo, [e.target.name]: e.target.value });
  };

  const onCitizenshipChange = () => {
    document.querySelectorAll('.pac-item').forEach((el) => el.remove());
    let countryShortCode = '';
    if (firstDropdownElement()) {
      const firstItem = firstDropdownElement();
      updateCountryInfo();
      const info = getCode(firstItem!);
      if (info) {
        countryShortCode = info;
      }
    } else {
      countryShortCode = place?.[0].short_name;
      if (!place) return;
    }
    setValue('citizenship', countryShortCode);
    setProfileInfo({
      ...profileInfo,
      citizenship: countryShortCode,
    });
  };

  useEffect(() => {
    onCitizenshipChange();
  }, [place]);

  useEffect(() => {
    setProfileInfo({ ...profile });
    citizenshipInputRef.current!.value = profile!.citizenship;
  }, [profile]);

  return (
    <>
      <SingleInfoWrapper>
        <UserIcon />
        <InfoAttributes>
          <InfoHeader>{inputLocales['label.first-name']}</InfoHeader>
          <InfoContent>
            <InputField
              {...register('firstName', {
                required: inputLocales['message.error.first-name.required'],
                onChange,
              })}
              value={profileInfo.firstName}
            />
            {errors.firstName && <ErrorMessage>{errors.firstName.message?.toString()}</ErrorMessage>}
          </InfoContent>
        </InfoAttributes>
      </SingleInfoWrapper>

      <SingleInfoWrapper>
        <UserIcon />
        <InfoAttributes>
          <InfoHeader>{inputLocales['label.middle-name']}</InfoHeader>
          <InfoContent>
            <InputField type="text" {...register('middleName', { onChange })} value={profileInfo.middleName} />
          </InfoContent>
        </InfoAttributes>
      </SingleInfoWrapper>

      <SingleInfoWrapper>
        <UserIcon />
        <InfoAttributes>
          <InfoHeader>{inputLocales['label.last-name']}</InfoHeader>
          <InfoContent>
            <InputField
              type="text"
              {...register('lastName', {
                required: inputLocales['message.error.first-name.required'],
                onChange,
              })}
              value={profileInfo.lastName}
            />
            {errors.lastName && <ErrorMessage>{errors.lastName.message?.toString()}</ErrorMessage>}
          </InfoContent>
        </InfoAttributes>
      </SingleInfoWrapper>

      <SingleInfoWrapper>
        <CalendarIcon />
        <InfoAttributes>
          <InfoHeader>{inputLocales['label.dob']}</InfoHeader>
          <InfoContent>
            <InputField
              type="text"
              {...register('dob', {
                required: inputLocales['message.error.date.required'],
                onChange,
              })}
              value={profileInfo.dob}
            />
            {errors.dob && <ErrorMessage>{errors.dob.message?.toString()}</ErrorMessage>}
          </InfoContent>
        </InfoAttributes>
      </SingleInfoWrapper>

      <SingleInfoWrapper>
        <RingIcon />
        <InfoAttributes>
          <InfoHeader>{inputLocales['label.marital-status']}</InfoHeader>
          <InfoContent>
            <SelectField {...register('marritalStatus', { onChange })} value={profileInfo.marritalStatus}>
              <option value="SINGLE">Single</option>
              <option value="MARRIED">Married</option>
              <option value="DIVORCED">Divorced</option>
              <option value="SEPARATED">Separated</option>
              <option value="WIDOWED">Widowed</option>
              <option value="COMMON_LAW">Common Law</option>
            </SelectField>
          </InfoContent>
        </InfoAttributes>
      </SingleInfoWrapper>

      <SingleInfoWrapper>
        <CitizenIcon />
        <InfoAttributes>
          <InfoHeader>{inputLocales['label.citizenship']}</InfoHeader>
          <InfoContent>
            <InputField
              type="text"
              ref={citizenshipInputRef}
              onKeyUp={(event) => {
                if (isEnterKeyPressed(event) && firstDropdownElement()) {
                  citizenshipInputRef.current!.value = firstDropdownElement()!;
                }
              }}
            />
            {errors.citizenship && <ErrorMessage>{errors.citizenship.message?.toString()}</ErrorMessage>}
          </InfoContent>
        </InfoAttributes>
      </SingleInfoWrapper>

      <SingleInfoWrapper>
        <LanguageIcon />
        <InfoAttributes>
          <InfoHeader>{inputLocales['label.language']}</InfoHeader>
          <InfoContent>
            <SelectField {...register('preferredLanguage', { onChange })} value={profileInfo.preferredLanguage}>
              <option value="ENGLISH">English</option>
              <option value="FRENCH">French</option>
            </SelectField>
          </InfoContent>
        </InfoAttributes>
      </SingleInfoWrapper>
    </>
  );
};

export default observer(ProfileFormFields);
