import React, { createContext, useEffect, useState } from 'react';

import { SnackBar } from '@/Shared/Components/SnackBar';
import { StyledSnackBarContainer, StyledSnackBarLayoutContainer } from './SnackBarProvider.styles';

export interface ISnackbarContext {
  setSnackMessage: (snackMessage: string) => void;
}

export const SnackBarContext = createContext({} as ISnackbarContext);

interface IProps {
  children: React.ReactNode;
}

export const SnackBarProvider: React.FC<IProps> = ({ children }) => {
  const [message, setMessage] = useState('');
  const [visibility, setVisibility] = useState(false);

  const setSnackMessage = (snackMessage: string) => {
    setVisibility(true);
    setMessage(snackMessage);
  };

  useEffect(() => {
    let visibilityTimeout: NodeJS.Timeout;
    let messageTimeout: NodeJS.Timeout;

    if (message) {
      visibilityTimeout = setTimeout(() => setVisibility(false), 3000);
      messageTimeout = setTimeout(() => setMessage(''), 4000);
    }

    return () => {
      clearTimeout(visibilityTimeout);
      clearTimeout(messageTimeout);
    };
  }, [message, visibility]);

  const value = { setSnackMessage };

  return (
    <SnackBarContext.Provider value={value}>
      <StyledSnackBarLayoutContainer>
        {children}
        <StyledSnackBarContainer hidden={!visibility}>
          <SnackBar message={message!} setVisibility={setVisibility} />
        </StyledSnackBarContainer>
      </StyledSnackBarLayoutContainer>
    </SnackBarContext.Provider>
  );
};
