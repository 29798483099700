import React from 'react';
import { ApiResponse } from './toApiResponse';
import { ApiResponseError, toApiResponseError } from './toApiResponseError';

type ApiRequest<J> = () => Promise<ApiResponse<J>>;

interface UseApiOptions<T> {
  onError?: (error: ApiResponseError) => ApiResponseError | void;
  onSuccess?: (data: T) => void;
}

type UseApiCallbacks<J> = {
  setData: (input: J) => void;
  refetch: () => Promise<ApiResponse<J> | void>;
};

type UseApiDataState<J> =
  | {
      loading: true;
      data: undefined;
      error: undefined;
    }
  | {
      loading: false;
      error: ApiResponseError;
      data: undefined;
    }
  | {
      loading: false;
      error: undefined;
      data: J;
    };

type UseApiData<J> = UseApiDataState<J> & UseApiCallbacks<J>;

export const useApi = <J>(
  request: ApiRequest<J>,
  deps: Array<any> = [],
  options: UseApiOptions<J> = {},
): UseApiData<J> => {
  const [loading, setLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<J | undefined>(undefined);
  const [error, setError] = React.useState<ApiResponseError | undefined>(undefined);

  const fire = (): Promise<ApiResponse<J> | void> => {
    setLoading(true);
    setData(undefined);
    setError(undefined);
    return request()
      .then((response) => {
        if (response.error) {
          setError(options?.onError ? options.onError(response.error) ?? undefined : response.error);
        } else {
          setData(response.data);
          options?.onSuccess?.(response.data);
        }

        return {
          data: response.data,
          error: response.error,
        };
      })
      .catch((e) => {
        setError(toApiResponseError(e));
        return e;
      })
      .finally(() => {
        setLoading(false);
      });
  };

  React.useEffect(() => {
    fire();
  }, deps);

  return {
    loading,
    data,
    setData,
    error,
    refetch: fire,
  } as UseApiData<J>;
};
