import { overwrite } from 'country-list';

export const updateCountryInfo = () => {
  overwrite([
    {
      code: 'US',
      name: 'USA',
    },
    {
      code: 'RU',
      name: 'Russia',
    },
    {
      code: 'US',
      name: 'United States',
    },
    {
      code: 'UK',
      name: 'United Kingdom',
    },
    {
      code: 'US',
      name: 'America',
    },
  ]);
};
