import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const LongInput = styled.input<{ error?: boolean; phoneInput?: boolean }>`
  padding: 0.5rem 1rem;
  font-size: clamp(1rem, 2vw, 1.5rem);
  font-family: 'SofiaPro-Bold';
  min-width: 30rem;
  border-radius: 0.5rem;
  border: 1px solid ${({ error }) => (error ? 'red' : Palette.INDEPENDENCE_BLUE)};
  color: ${Palette.INDEPENDENCE_BLUE};

  &::placeholder {
    color: ${Palette.GREY};
    opacity: 1;
  }

  &:-ms-input-placeholder {
    color: ${Palette.GREY};
  }

  &::-ms-input-placeholder {
    color: ${Palette.GREY};
  }

  @media (max-width: 600px) {
    min-width: 18rem;
  }
`;

export const ShortInput = styled(LongInput)<{ sm?: boolean }>`
  min-width: 10rem;
  ${({ sm }) =>
    sm &&
    `
    width: 6rem;
    min-width: 0;
  `}
`;

export const InputGroup = styled.div`
  display: flex;
  gap: 1rem;
`;

export const HiddenSubmitButton = styled.input`
  position: absolute;
  left: -9999px;
`;
