import React from 'react';
import { Input } from '../actionable.styles';

export type DateInputProps = Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'style'>;

const formatDateString = (input: string): string => {
  const cleanedInput = input.replace(/\D/g, '');

  const yyyy = cleanedInput.slice(0, 4);
  const mm = cleanedInput.slice(4, 6);
  const dd = cleanedInput.slice(6, 8);

  let formattedString = yyyy;
  if (mm) {
    formattedString += `-${mm}`;
  }
  if (dd) {
    formattedString += `-${dd}`;
  }
  return formattedString;
};

export const DateInput: React.FC<DateInputProps> = React.forwardRef<HTMLInputElement, DateInputProps>(
  ({ onChange, ...props }, ref) => {
    return (
      <Input
        ref={ref}
        {...props}
        type="text"
        placeholder="YYYY-MM-DD"
        onChange={(e) => {
          if (e.currentTarget) {
            e.currentTarget.value = formatDateString(e.currentTarget.value);
          }
          onChange?.(e);
        }}
      />
    );
  },
);
