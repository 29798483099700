import { axiosClient, toApiResponse } from '@/lib/axios';
import { IPaymentInfo } from '@/lib/payment/types';
import { snakeToCamelParser } from '@/Utils';

export async function getPaymentInfofromDb() {
  const { data, error } = await toApiResponse<IPaymentInfo>(axiosClient.get('/payment-info'));

  if (error) {
    return {
      error,
      data: undefined,
    };
  }

  const transformedData = snakeToCamelParser(data) as IPaymentInfo;
  return {
    data: transformedData,
    error: undefined,
  };
}
