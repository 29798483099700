import { useState } from 'react';
import { ControllerRenderProps } from 'react-hook-form';
import { ILiabilityDetailsFormValues } from './LiabilityDetailsForm.types';

export function useLiabilityOwnedPercentageController() {
  const [isTextFieldVisible, setIsTextFieldVisible] = useState(false);

  function setOwnedPercentageAndHideTextField(
    event: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<ILiabilityDetailsFormValues, 'ownedPercentage'>,
  ) {
    field.onChange(event);
    setIsTextFieldVisible(false);
  }

  function setOwnedPercentageAndShowTextField(
    event: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<ILiabilityDetailsFormValues, 'ownedPercentage'>,
  ) {
    field.onChange(event);
    setIsTextFieldVisible(true);
  }

  function handlePercentageInputChange(
    event: React.ChangeEvent<HTMLInputElement>,
    field: ControllerRenderProps<ILiabilityDetailsFormValues, 'ownedPercentage'>,
  ) {
    event.currentTarget.value = event.target.value.replace(/[^\d]/g, '');
    field.onChange(event);
  }

  return {
    isTextFieldVisible,
    setOwnedPercentageAndShowTextField,
    setOwnedPercentageAndHideTextField,
    handlePercentageInputChange,
  };
}
