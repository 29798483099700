import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const SingleInfoWrapper = styled.div<{ spanFullWidth?: boolean }>`
  max-width: 100%;
  padding-left: 0.5rem;
  display: flex;
  margin: 1rem 0;
  gap: 0 1rem;

  @media only screen and (min-width: 600px) {
    gap: 3rem 1rem;
    width: 100%;
    padding-left: 1rem;
  }
`;

export const StyledMfaButton = styled.button<{ short?: boolean }>`
  margin: 1.5rem 0;
  padding: 0.5rem 1rem;
  color: ${Palette.WHITE};
  background-color: ${Palette.GUN_POWDER};
  cursor: pointer;
  border: none;
  border-radius: 0.5rem;
  ${({ short }) => short && 'width: fit-content;'}
`;

export const StyledMfaParagraph = styled.p`
  margin: 1.5rem 0;
  font-size: clamp(0.9rem, 1vw, 1rem);
  color: ${Palette.GUN_POWDER};
`;

export const StyledMfaRadioButtonGroup = styled.div`
  display: flex;
  gap: 1.3rem;
`;

export const StyledMfaInfoContent = styled.div`
  color: ${Palette.CADET_BLUE_DARKER};
  font-size: 1rem;
  font-family: 'SofiaPro-Regular';
  font-weight: 400;

  label {
    display: flex;
    gap: 0.2rem;
    cursor: pointer;
  }
`;
