import { getAge } from '@/lib/wills/pages/WillsFamilyFlow/Components/RelativeAdderForm/RelativeAdderFormHelper';

export const isDobValid = (relativeDefinition: string | undefined, entryDob: string, userDob: string | undefined) => {
  const newEntryAge = getAge(entryDob);
  const userAge = getAge(userDob ?? '');

  switch (relativeDefinition) {
    case 'father':
    case 'mother':
      if (newEntryAge <= userAge) {
        return {
          isValid: false,
          message: 'Parents must be younger than children',
        };
      }
      break;

    case 'son':
    case 'daughter':
      if (newEntryAge >= userAge) {
        return {
          isValid: false,
          message: 'Children must be younger than parent',
        };
      }
      break;

    case 'gfather':
    case 'gmotherM':
    case 'gmotherF':
      if (newEntryAge <= userAge) {
        return {
          isValid: false,
          message: 'Grand parents must be older than grand children',
        };
      }
      break;

    default:
      return {
        isValid: true,
        message: '',
      };
  }
  return {
    isValid: true,
    message: '',
  };
};
