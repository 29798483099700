export const possessive = (noun: string) => {
  if (noun.length === 0) {
    return '';
  }

  if (noun.endsWith('s')) {
    return `${noun}'`;
  }

  return `${noun}'s`;
};
