import React from 'react';
import { StyledToggleButtonThumb, StyledToggleButtonTrack } from './ToggleButton.styles';

export interface IToggleButtonProps {
  on: boolean;
  onToggle: () => void;
}

const ToggleButton = ({ on, onToggle }: IToggleButtonProps) => {
  return (
    <StyledToggleButtonTrack on={on ? 'true' : 'false'} onClick={onToggle}>
      <StyledToggleButtonThumb on={on ? 'true' : 'false'} />
    </StyledToggleButtonTrack>
  );
};

export default ToggleButton;
