import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const FooterWrapper = styled.div`
  background-color: ${Palette.EGGSHELL_WHITE};
  margin-top: auto;
  display: flex;
  justify-content: center;
`;

export const FooterContent = styled.div`
  max-width: min(75rem, 85%);
  width: 100%;
  display: flex;
  align-items: center;
  border-top: 1px solid ${Palette.GREY};
  padding: 0 3rem;
  color: ${Palette.INDEPENDENCE_BLUE};
  font-weight: bold;
  font-family: 'SofiaPro-Bold';
`;
