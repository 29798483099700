import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledContactUsFormInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
`;

export const StyledContactUsFormLabel = styled.p`
  font-family: 'SofiaPro-Regular';
  color: ${Palette.NEW_BLUE};
`;

export const StyledContactUsFormInput = styled.input`
  border-radius: 0.3rem;
  font-size: clamp(0.9rem, 1vw, 1rem);
  border: 1px solid ${Palette.GREY};

  padding: 0.5rem 0.75rem;
  color: ${Palette.NEW_BLUE};
`;

export const StyledContactUsFormTextareaField = styled.textarea`
  padding: 0.5rem 0.75rem;
  color: ${Palette.NEW_BLUE};
  font-size: clamp(0.9rem, 1vw, 1rem);
  border: 1px solid #e4e4e4;
  border-radius: 0.3rem;
  font-family: 'SofiaPro-Regular';

  &::placeholder {
    color: ${Palette.NEW_BLUE};
  }
`;

export const StyledSubmitBtn = styled.button`
  background-color: ${Palette.GOLD_CRAYOLA};
  padding: 0.625rem 1.125rem;
  border: none;
  border-radius: 8px;
  color: ${Palette.NEW_BLUE};
  margin-top: 0.5rem;
  font-family: 'SofiaPro-Regular';
  letter-spacing: 0.1rem;
  font-size: 18px;
  font-weight: bold;
`;

export const StyledFormHeader = styled.h2`
  color: ${Palette.NEW_BLUE};
  font-family: 'SofiaPro-Regular';
  text-align: center;
  font-size: 24px;
`;

export const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;
