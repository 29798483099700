import React from 'react';
import { QuestionInfo } from '@/lib/wills/components/QuestionInfo';

const DobQuestionInfo: React.FC = () => {
  return (
    <QuestionInfo
      question="Why do we ask?"
      answer={<div>We need to ensure you are over the minimum age requirement to make a will in your province.</div>}
    />
  );
};

export default DobQuestionInfo;
