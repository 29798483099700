import React from 'react';
import { upsertWillsBequest } from '@wills/apis/bequests/upsertWillsBequest';
import { UpdateBequestValues } from '../BequestForm/BequestForm';
import { EstateIntakeContext } from '../../../contexts/EstateIntakeContext/EstateIntakeContext';
import { SnackBarContext } from '@/providers';
import { isBequestAllocationValid } from '../BequestForm/BequestForm.helpers';
import { IBequest } from '@/lib/wills/apis';
import { EstateIntakeContextActionType } from '../../../contexts';

export interface UpdateBequestFormProps {
  onComplete: () => void;
  renderForm: (onSubmit: (data: UpdateBequestValues) => Promise<void>) => JSX.Element;
}

export const UpdateBequestForm: React.FC<UpdateBequestFormProps> = ({ onComplete, renderForm }) => {
  const { state, dispatch } = React.useContext(EstateIntakeContext);
  const { setSnackMessage } = React.useContext(SnackBarContext);

  const onSubmit = async (data: UpdateBequestValues): Promise<void> => {
    const { bequests, totalBequestAllocation } = state.allBequests;
    const updatingBequest = bequests.find((bequest) => bequest.id === data.id);
    const updatedTotalAllocation = bequests.length >= 1 ?
      totalBequestAllocation - Number(updatingBequest!.allocation): 0;
    if (!isBequestAllocationValid(updatedTotalAllocation, Number(data.allocation))) {
      setSnackMessage('Total beneficiary allocation cannot be more than 33');
      return;
    }

    const totalAllocation = updatedTotalAllocation + Number(data.allocation);

    const { data: updatedBequest, error } = await upsertWillsBequest({
      ...data,
    });

    if (error) {
      setSnackMessage(error.message);
      return;
    }

    const updatedBequests = bequests.map<IBequest>((existingBequest) =>
      existingBequest.id === updatedBequest.id ? updatedBequest : existingBequest,
    );

    dispatch({
      type: EstateIntakeContextActionType.SET_ALL_BEQUESTS,
      payload: {
        totalBequestAllocation: totalAllocation,
        bequests: updatedBequests,
      },
    });

    onComplete();
  };

  return renderForm(onSubmit);
};
