import React from 'react';
import { ApiResponse } from '@/lib/axios';

const getErrorMessageFromUnknown = (err: unknown): string => {
  if (err instanceof Error) {
    return err.message;
  }
  // TODO: need to update typescript so `in` operator works
  if (typeof err === 'object' && err !== null && 'message' in err && typeof (err as any).message === 'string') {
    return (err as any).message;
  }
  return 'UnknownError';
};

export const useErrorHandler = () => {
  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  // Raise the error to ErrorBoundary
  if (errorMessage) {
    throw new Error(errorMessage);
  }

  const handleAsyncError = <P, K, T extends Array<K>>(fn: (...args: T) => Promise<P>) => {
    return async (...args: T): Promise<P> => {
      try {
        return await fn(...args);
      } catch (err: unknown) {
        setErrorMessage(getErrorMessageFromUnknown(err));
        throw new Error('handleAsyncError triggered');
      }
    };
  };

  const handleError = <P, K, T extends Array<K>>(fn: (...args: T) => P) => {
    return (...args: T) => {
      try {
        return fn(...args);
      } catch (err: unknown) {
        setErrorMessage(getErrorMessageFromUnknown(err));
        throw new Error('handleError triggered');
      }
    };
  };

  const throwApiResponseError = <T>(response: ApiResponse<T>): T => {
    if (response.error) {
      throw new Error(response.error.message);
    }
    return response.data;
  };

  return {
    handleAsyncError,
    handleError,
    throwApiResponseError,
  };
};
