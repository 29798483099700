import React from 'react';
import { HeaderText } from './Header.styles';

interface IProps {
  alignCenter?: boolean;
  children?: React.ReactNode;
  italic?: boolean;
  bold?: boolean;
}

const Header: React.FC<IProps> = ({ children, alignCenter, italic, bold }) => {
  return (
    <HeaderText italic={italic} bold={bold} alignCenter={alignCenter}>
      {children}
    </HeaderText>
  );
};

export default Header;
