import styled from 'styled-components';
import { sectionGap } from '@/components';

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-self: baseline;
  min-width: 370px;
  ${sectionGap}
`;
