import styled, { css, keyframes } from 'styled-components';
import { subSectionBody, subSectionTitle } from '@/components';
import { Palette } from '@/Shared/Constants';

// TODO: look into how to get this working
/* const overflowTextEllipsis = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`; */

interface Expandable {
  isExpanded?: boolean;
}

export const Container = styled.div<Expandable>`
  display: flex;
  flex-flow: column nowrap;
  border: solid 1px transparent;
  border-radius: 10px;
  background-color: ${({ isExpanded }) => (isExpanded ? '#F7FAF8' : '#FFFFFF')};
  transition: background-color 0.3s ease;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
  padding: 20px;
  gap: 20px;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  gap: 20px;
`;

export const BannerImage = styled.img`
  width: 100%;
`;

export const Title = styled.h4`
  ${subSectionTitle}
  color: ${Palette.NEW_BLUE};
`;

export const Description = styled.p<Expandable>`
  ${subSectionBody}
  color: ${Palette.PLANET_BLUE};
`;

export const IconContainer = styled.div`
  height: 33.6px;
  align-content: center;
`;

const rotateClockwise = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(90deg);
  }
`;

export const CollapseStateIcon = styled.img<Expandable & { isHidden: boolean }>`
  visibility: ${({ isHidden }) => (isHidden ? 'hidden' : 'visible')};
  transform: ${({ isExpanded }) => (isExpanded ? 'rotate(90deg)' : 'rotate(0deg)')};
  ${({ isExpanded }) =>
    isExpanded &&
    css`
      animation: ${rotateClockwise} 0.3s ease;
    `}
  transition: transform 0.3s ease;
`;
