import { ApiResponse, axiosClient, toApiResponse } from '@/lib/axios';
import { snakeToCamelParser } from '@/Utils';
import { IBequest } from './types';

export const getWillsBequests = async (): Promise<ApiResponse<IBequest[]>> => {
  const response = await toApiResponse<any[]>(axiosClient.get('/wills-bequest'));

  if (response.error) {
    return response;
  }

  return {
    ...response,
    data: response.data.map((bequest) => snakeToCamelParser(bequest)),
  };
};
