import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';
import { IRelationshipDefinition } from '@wills/apis';
import { RelationshipDefinitionProvider } from '@wills/pages/WillsFamilyFlow/contexts/RelationshipDefinitionContext';
import { useScrollToTop } from '@/Shared/Hooks';
import { Introduction, PrimaryRelativeInfo, Review, SecondaryRelativeInfo } from './Components';
import { WillsFamilyFlowContext } from './WillsFamilyFlow.context';
import { EFamilyFlowStep, IRelativeInfo } from './WillsFamilyFlow.types';
import { useStepsReviewNavigation } from '../../hooks/useStepsReviewNavigation';
import { Page } from '@/components';

const WillsFamilyFlow: React.FC<{ isReview?: boolean }> = ({ isReview }) => {
  const { goToStepsOrReview } = useStepsReviewNavigation(isReview);
  const { state } = useLocation();
  const [step, setStep] = useState(state && state.lastStep ? EFamilyFlowStep.REVIEW : EFamilyFlowStep.INTRODUCTION);
  const [selectedRelativeDefinition, setSelectedRelativeDefinition] = useState<IRelationshipDefinition | null>(null);
  const [primaryRelationships, setPrimaryRelationships] = useState<IRelativeInfo[]>([]);
  const [secondaryRelationships, setSecondaryRelationships] = useState<IRelativeInfo[]>([]);
  useScrollToTop([step]);

  const value = {
    setStep,
    selectedRelativeDefinition,
    setSelectedRelativeDefinition,
    primaryRelationships,
    setPrimaryRelationships,
    secondaryRelationships,
    setSecondaryRelationships,
  };

  return (
    <Page>
      <WillsFamilyFlowContext.Provider value={value}>
        <RelationshipDefinitionProvider>
          {step === EFamilyFlowStep.INTRODUCTION && (
            <Introduction onBack={goToStepsOrReview} onNext={() => setStep(EFamilyFlowStep.PRIMARY_RELATIVES)} />
          )}
          {step === EFamilyFlowStep.PRIMARY_RELATIVES && <PrimaryRelativeInfo />}
          {step === EFamilyFlowStep.SECONDARY_RELATIVES && <SecondaryRelativeInfo />}
          {step === EFamilyFlowStep.REVIEW && (
            <Review
              onBack={() => {
                setStep(EFamilyFlowStep.SECONDARY_RELATIVES);
              }}
              onComplete={goToStepsOrReview}
            />
          )}
        </RelationshipDefinitionProvider>
      </WillsFamilyFlowContext.Provider>
    </Page>
  );
};

export default WillsFamilyFlow;
