import React from 'react';
import { IAppointeeRole } from '@/lib/wills';
import { findAppointeeInEmailPayload, removeAppointeeFromEmailPayload } from './AppointeesRoleList.helpers';

function useAppointeeChecker(
  emailsToNotify: IAppointeeRole[],
  setEmailsToNotify: React.Dispatch<React.SetStateAction<IAppointeeRole[]>>,
) {
  function isChecked(appointee: IAppointeeRole) {
    const emailsPayload = findAppointeeInEmailPayload(emailsToNotify, appointee);
    return emailsPayload.length > 0;
  }

  function onChangeHandler(event: React.ChangeEvent<HTMLInputElement>, appointee: IAppointeeRole) {
    const { checked } = event.currentTarget;

    if (checked) {
      setEmailsToNotify((emails) => [
        ...emails,
        { name: appointee.name, email: appointee.email, role: appointee.role },
      ]);
    } else {
      setEmailsToNotify((emails) => removeAppointeeFromEmailPayload(emails, appointee));
    }
  }

  return {
    isChecked,
    onChangeHandler,
  };
}

export default useAppointeeChecker;
