import React from 'react';
import { Navigate, Outlet, Route } from 'react-router-dom';
import { OneVestPortal } from './pages/OneVestPortal';
import { InvestAuthGuard } from './components/InvestAuthGuard';
import { InvestError } from './components/InvestError';
import { ConfirmContinue } from './pages/ConfirmContinue';
import { Onboarding } from './pages/Onboarding';
import { ErrorBoundaryWithUrlReset } from '@/components/ErrorBoundaryWithUrlReset/ErrorBoundaryWithUrlReset';

export const createInvestRoute = (): JSX.Element => {
  return (
    <Route
      id="invest"
      key="invest"
      path="invest"
      element={
        <ErrorBoundaryWithUrlReset renderFallback={() => <InvestError />}>
          <Outlet />
        </ErrorBoundaryWithUrlReset>
      }
    >
      <Route id="invest-index" index element={<Navigate to="./onboarding" replace />} />
      <Route
        id="invest-create"
        path="onboarding"
        element={
          <InvestAuthGuard
            key="onboarding-guard"
            renderOnMissingData={() => <Outlet />}
            render={() => <Navigate to="../portal" replace />}
          />
        }
      >
        <Route id="invest-create-index" index element={<Navigate to="./intake" replace />} />
        <Route
          id="invest-create-kyc"
          path="intake"
          element={<Onboarding renderOnComplete={() => <Navigate to="../confirm" />} />}
        />
        {/** TODO: Optimistic that user doesn't enter via address bar, component throws error  */}
        <Route
          id="invest-create-confirm"
          path="confirm"
          element={
            <ConfirmContinue
              renderOnBack={() => <Navigate to="../intake" />}
              renderOnComplete={() => <Navigate to="../../portal" />}
            />
          }
        />
      </Route>
      <Route
        id="onevest-portal"
        path="portal"
        element={
          <InvestAuthGuard
            key="portal-guard"
            renderOnMissingData={() => <Navigate to="../onboarding" replace />}
            render={() => <OneVestPortal />}
          />
        }
      />
    </Route>
  );
};
