/* eslint-disable max-len */
export const RESPONSIBILITIES = [
  {
    id: 1,
    text: 'Obtaining your signed Will',
  },
  {
    id: 2,
    text: 'Obtaining certified copies of any appropriate death certificates',
  },
  {
    id: 3,
    text: 'Identifying and locating your named beneficiaries',
  },
  {
    id: 4,
    text: 'Preparing an inventory and valuation of your estate',
  },
  {
    id: 5,
    text: 'Handling matters with the probate court under the laws of your state',
  },
  {
    id: 6,
    text: 'Distribute your property to your beneficiaries and handle any disputes concerning distributions of property.',
  },
];
