import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const QuestionContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const OneLineFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const LongSelect = styled.select`
  padding: 0.5rem 1rem;
  font-size: clamp(1rem, 2vw, 1.5rem);
  font-family: 'SofiaPro-Bold';
  width: 30rem;
  border-radius: 0.5rem;
  border: 1px solid ${Palette.INDEPENDENCE_BLUE};
  color: ${Palette.INDEPENDENCE_BLUE};

  @media (max-width: 600px) {
    width: 18rem;
  }
`;

export const RequiredInfoIndicator = styled.span`
  color: #ca6e56;
  margin-left: 0.2rem;
`;
