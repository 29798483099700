import { axiosClient, toApiResponse } from '@/lib/axios';

export interface SetChildGuardiansInput {
  mainGuardianId: number;
  backupGuardianId: number | null;
}

export const setChildGuardians = (guardianData: SetChildGuardiansInput) => {
  return toApiResponse<never>(axiosClient.put('/wills-set-child-guardians', guardianData));
};
