import { ApiResponse, axiosClient, toApiResponse } from '@/lib/axios';
import { IAssetList } from './types';

export const getWillsAssets = async (): Promise<ApiResponse<IAssetList>> => {
  const response = await toApiResponse<IAssetList>(axiosClient.get('/wills/assets'));

  if (response.error) {
    return response;
  }

  return {
    ...response,
  };
};
