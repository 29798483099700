import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
  StyledButton,
  StyledDescription,
  StyledSmallHeader,
  StyledHeader,
  StyledLayout,
  QuestionInfo,
} from '@wills/components';
import {
  StyledOnboardingNoteWrapper,
  StyledOnboardingNoteLeftWrapper,
  StyledOnboardingNoteRightWrapper,
  StyledCheckboxContainer,
  StyledSingleCheckboxContainer,
  StyledCheckbox,
  StyledLabel,
} from './OnboardingNote.styles';
import { ONBOARDING_NOTES } from './OnboardingNote.constants';

export interface IOnboardingNote {
  nextRoute: string;
}

export const OnboardingNote = ({ nextRoute }: IOnboardingNote) => {
  const navigate = useNavigate();
  const [selectedOption, setSelectedOption] = useState<string>('');

  const handleChange = (e: any) => {
    setSelectedOption(e.target.value);
  };

  const navigateToNextFlow = () => {
    if (selectedOption === ONBOARDING_NOTES[ONBOARDING_NOTES.length - 1].value) {
      navigate(nextRoute);
    } else {
      navigate('/wills/help');
    }
  };

  return (
    <StyledLayout>
      <StyledOnboardingNoteWrapper>
        <StyledOnboardingNoteLeftWrapper>
          <StyledSmallHeader>IMPORTANT NOTE</StyledSmallHeader>
          <StyledHeader fsLg>Let’s make sure you’re a fit for Manzil Wills</StyledHeader>
          <StyledDescription marginTop>
            Manzil Wills is a good fit for the majority of Muslim Canadians in the Provinces that we serve, there are a
            number of situations that can add complexity to your will and require legal advice from a Lawyer. Please
            check any of the below boxes if they apply to you.
          </StyledDescription>
          <StyledCheckboxContainer>
            {ONBOARDING_NOTES.map((onboardingNote) => {
              return (
                <StyledSingleCheckboxContainer key={onboardingNote.id}>
                  <StyledLabel>
                    <StyledCheckbox
                      name="onboarding-note"
                      type="checkbox"
                      value={onboardingNote.value}
                      checked={selectedOption === onboardingNote.value}
                      onChange={(e) => handleChange(e)}
                    />
                    {onboardingNote.label}
                  </StyledLabel>
                </StyledSingleCheckboxContainer>
              );
            })}
          </StyledCheckboxContainer>
          <StyledButton disabled={selectedOption === ''} padding onClick={navigateToNextFlow}>
            Continue
          </StyledButton>
        </StyledOnboardingNoteLeftWrapper>
        <StyledOnboardingNoteRightWrapper>
          <QuestionInfo
            question="Why do we ask?"
            answer={
              <div>
                If you have legal questions or need advice specific to your situation, seek assistance from a local
                estate lawyer.
                <br />
                <br />
                Manzil Wills is not a Law Firm and does not provide legal or tax advice - we provide a service which we
                have built with an amazing team of Lawyers.
              </div>
            }
          />
        </StyledOnboardingNoteRightWrapper>
      </StyledOnboardingNoteWrapper>
    </StyledLayout>
  );
};
