import {
  completeWishList as completeWishlistApi,
  updateWish as updateWishApi,
  createWish as createWishApi,
  deleteWish as deleteWishApi,
  CreateWishInput,
  UpdateWishInput,
  IWish,
  IWishList,
} from '@wills/apis';
import React from 'react';
import { useErrorHandler } from '@/hooks';
import { DocumentsContext } from '../pages/Documents/Documents.context';

export const useWishList = () => {
  const { wishList, setWishList } = React.useContext(DocumentsContext);
  const { handleAsyncError, throwApiResponseError } = useErrorHandler();

  const createWish = handleAsyncError(async (input: CreateWishInput): Promise<IWish> => {
    const response = throwApiResponseError(await createWishApi(input));
    if (response) {
      setWishList({
        ...wishList,
        wishes: [...wishList.wishes, response],
      });
    }
    return response;
  });

  const updateWish = handleAsyncError(async (id: number, input: UpdateWishInput): Promise<IWish> => {
    const response = throwApiResponseError(await updateWishApi(id, input));
    if (response) {
      setWishList({
        ...wishList,
        wishes: wishList.wishes.map((wish) => (wish.id === response.id ? response : wish)),
      });
    }
    return response;
  });

  const deleteWish = handleAsyncError(async (wishId: number): Promise<{}> => {
    const response = throwApiResponseError(await deleteWishApi(wishId));
    if (response) {
      setWishList({
        ...wishList,
        wishes: wishList.wishes.filter((wish) => wish.id !== wishId),
      });
    }
    return response;
  });

  const completeWishList = handleAsyncError(async (): Promise<IWishList> => {
    const response = throwApiResponseError(await completeWishlistApi());
    if (response) {
      setWishList(response);
    }
    return response;
  });

  return {
    wishList,
    createWish,
    updateWish,
    deleteWish,
    completeWishList,
  };
};
