import { useState } from 'react';

export function useLiabilityForm() {
  const [formOpened, setFormOpened] = useState(false);

  function openForm() {
    setFormOpened(true);
  }

  function closeForm() {
    setFormOpened(false);
  }

  return { formOpened, openForm, closeForm };
}
