import React from 'react';
import { InputLabel } from '../typography.styles';
import { CheckboxFieldContainer } from './CheckboxField.styles';
import { Checkbox } from '../actionable.styles';

export type CheckboxFieldProps = {
  label: string;
} & Omit<React.InputHTMLAttributes<HTMLInputElement>, 'type' | 'style'>;
export const CheckboxField: React.FC<CheckboxFieldProps> = React.forwardRef<HTMLInputElement, CheckboxFieldProps>(
  ({ label, ...props }, ref) => {
    return (
      <CheckboxFieldContainer>
        <Checkbox type="checkbox" id={props.id} {...props} ref={ref} />
        <InputLabel htmlFor={props.id}>{label}</InputLabel>
      </CheckboxFieldContainer>
    );
  },
);
