import styled from 'styled-components';
import { AiOutlineEdit } from 'react-icons/ai';
import { Palette } from '@/Shared/Constants';

export const StyledWillsPlanSummaryWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-color: ${Palette.AQUA_HAZE};
  display: grid;
  place-items: center;
`;

export const StyledWillsPlanSummaryContainer = styled.div`
  padding: 5rem 0 2rem 0;
  width: 85%;
  max-width: 1366px;
  margin: 0 auto;
`;

export const StyledSummaryContainer = styled.div`
  background-color: #f7faf8;
  padding: 1.25rem;
  border-radius: 1rem;
  width: 60%;
  margin: 2rem auto;

  @media screen and (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledSummaryHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const StyledTitle = styled.h3`
  font-family: 'SofiaPro-Regular';
  font-weight: 600;
  color: ${Palette.NEW_BLUE};
  font-size: clamp(1.4rem, 1.8vw, 2.25rem);
`;

export const StyledTagContainer = styled.div`
  font-family: 'SofiaPro-Regular';
  background-color: #faebd2;
  padding: 0.7rem 1.1rem;
  color: ${Palette.NEW_BLUE};
  font-size: 1.125rem;
  display: grid;
  place-items: center;
  border-radius: 0.5rem;
`;

export const StyledSummaryContentWrapper = styled.div`
  background-color: ${Palette.SLATE_GREEN_LIGHT};
  padding: 1.25rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top: 1rem;
  border-radius: 1rem;
`;

export const StyledSinglePoint = styled.div`
  display: flex;
  gap: 1rem;
  align-items: center;
`;

export const StyledButtonContainer = styled.div<{ justifyBetween?: boolean }>`
  ${({ justifyBetween }) =>
    justifyBetween &&
    `
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 60%;
        margin: 1rem auto;
        
        @media screen and (max-width: 768px) {
            flex-direction: column;
            gap: 1rem;
            width: 100%;
        }

    `}
`;

export const StyledButton = styled.button<{ gold?: boolean; gap?: boolean }>`
  background-color: ${({ gold }) => (gold ? `${Palette.GOLD_CRAYOLA}` : `${Palette.ATHENS_GREY}`)};
  display: grid;
  place-items: center;
  cursor: pointer;
  border: none;
  border-radius: 0.5rem;
  font-family: 'SofiaPro-Regular';
  font-weight: 500;
  padding: 0.8rem 1.5rem;
  margin-top: 1rem;
  ${({ gap }) =>
    gap &&
    `
        display: flex;
        gap: 0.5rem;
    `}

  @media screen and (max-width: 768px) {
    width: 100%;
    ${({ gap }) =>
      gap &&
      `
        display: flex;
        gap: 0.5rem;
    `}
  }
`;

export const StyledEditIcon = styled(AiOutlineEdit)`
  color: ${Palette.SLATE_GREEN};
  font-size: 1.2rem;
`;

export const StyledSummaryHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media screen and (max-width: 600px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const StyledPlanSummaryToggleButtonContainer = styled.div`
  background-color: ${Palette.WHITE};
  border-radius: 0.5rem;
  padding: 0.5rem 2rem;
  display: flex;
  justify-content: center;
`;
