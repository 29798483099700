import React, { KeyboardEvent, SyntheticEvent, useContext, useEffect, useRef, useState } from 'react';
import { UseFormSetValue } from 'react-hook-form';

import { HiddenSubmitButton, ShortInput } from '@/Shared/Shared.styles';
import { isValueText, replaceTextWithEmptyString } from '@/Shared/SharedUtils';
import { IOnboardingDob, IOnboardingInputs } from '@/lib/invest/pages/Onboarding/Types';
import { FormMessage } from '@/lib/auth/components/FormMessage';
import { LocaleContext } from '@/providers';

import { Prompt } from '../Prompt';
import { DobFormContainer, DobFormStyled } from './DobForm.styles';
import {
  dateParser,
  formatLenghtOneDateInput,
  monthInputCompleted,
  validateDateForm,
  yearInputCompleted,
  isNotAllowedToOpenAnAccount,
} from '../../../../../../Utils/dateOfBirthHelpers';
import { DEFAULT_DOB_VALUE } from './DobFormConstants';
import dobFormLocales from './dobFormLocales.json';

interface IProps {
  dobValue: string;
  setValue: UseFormSetValue<IOnboardingInputs>;
  recordAnswer: (name: string, value: string) => void;
  goToNextQuestion: () => void;
}

const DobForm: React.FC<IProps> = ({ dobValue, setValue, recordAnswer, goToNextQuestion }) => {
  const { locale } = useContext(LocaleContext);

  const dobFormLocale = dobFormLocales[locale];

  const [dob, setDob] = useState<IOnboardingDob>(DEFAULT_DOB_VALUE);
  const [canOpenAnAccount, setCanOpenAnAccount] = useState(true);

  const [dobError, setDobError] = useState('');

  const yearInputRef = useRef<HTMLInputElement | null>(null);
  const monthInputRef = useRef<HTMLInputElement | null>(null);
  const dayInputRef = useRef<HTMLInputElement | null>(null);

  const onChangeHandler = (e: SyntheticEvent<HTMLInputElement>) => {
    setDobError('');
    setCanOpenAnAccount(true);
    const { name, value } = e.currentTarget;

    if (yearInputCompleted(name, value)) monthInputRef.current?.focus();

    if (monthInputCompleted(name, value)) dayInputRef.current?.focus();

    if (isValueText(value)) {
      replaceTextWithEmptyString(value);
      return;
    }
    setDob({
      ...dob,
      [name]: value,
    });
  };

  const onDobSubmit = async (e: SyntheticEvent<HTMLFormElement>) => {
    e.preventDefault();

    const valid = validateDateForm(dob);
    if (!valid) {
      setDobError('Invalid date');
      return;
    }

    if (isNotAllowedToOpenAnAccount(dob)) {
      setCanOpenAnAccount(false);
      setDobError('You must be at least 18 to open an account.');
      return;
    }

    const month = formatLenghtOneDateInput(dob.month);
    const day = formatLenghtOneDateInput(dob.day);

    setDob({
      ...dob,
      day,
      month,
    });

    const formattedDate = `${dob.year}-${month}-${day}`;

    setValue('dob', formattedDate);
    recordAnswer('dob', formattedDate);

    goToNextQuestion();
  };

  const onTabPressInDayInput = (event: KeyboardEvent) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      yearInputRef.current?.focus();
    }
  };

  useEffect(() => {
    yearInputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (dobValue !== '') {
      const { year, month, day } = dateParser(dobValue);
      setDob({
        year,
        month,
        day,
      });
    }
    setValue('dob', dobValue);
  }, [dobValue]);

  return (
    <DobFormContainer>
      {!canOpenAnAccount && <FormMessage>{dobError}</FormMessage>}
      <DobFormStyled onSubmit={onDobSubmit}>
        <ShortInput
          ref={yearInputRef}
          sm
          placeholder={dobFormLocale['placeholder.dob.year']}
          name="year"
          onChange={onChangeHandler}
          value={dob.year}
          maxLength={4}
        />
        <ShortInput
          ref={monthInputRef}
          sm
          placeholder={dobFormLocale['placeholder.dob.month']}
          name="month"
          onChange={onChangeHandler}
          value={dob.month}
          maxLength={2}
        />
        <ShortInput
          ref={dayInputRef}
          sm
          placeholder={dobFormLocale['placeholder.dob.day']}
          name="day"
          onChange={onChangeHandler}
          value={dob.day}
          maxLength={2}
          onKeyDown={onTabPressInDayInput}
        />
        <HiddenSubmitButton type="submit" />
      </DobFormStyled>
      <Prompt onClick={onDobSubmit} disabled={!!dobError} />
    </DobFormContainer>
  );
};

export default DobForm;
