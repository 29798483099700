import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const StyledSingleOptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
  cursor: pointer;
`;

export const StyledOptionButton = styled.div<{ active?: boolean }>`
  padding: 0.5rem 0.2rem;
  width: 7rem;
  margin: 0 auto;
  border-radius: 0.5rem;
  background-color: ${({ active }) => (active ? `${Palette.NEW_BLUE}` : `${Palette.ATHENS_GREY}`)};
  color: ${({ active }) => (active ? `${Palette.ATHENS_GREY}` : `${Palette.NEW_BLUE}`)};
  font-family: 'SofiaPro-Regular';
  font-weight: 400;
  font-size: 18px;
  text-align: center;
`;
