import styled from 'styled-components';

import { Breakpoints } from '@/Utils/breakpoints';

export const StyledSecondaryRelativePageContainer = styled.div`
  max-width: 90%;
  height: 100%;

  @media screen and (min-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    max-width: 75%;
  }
`;

export const StyledSecondaryRelativeInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 1.5rem;
  min-height: 30rem;
`;

export const StyledNavigationButtonsWrapper = styled.div`
  margin-top: auto;
`;
