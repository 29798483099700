import React, { useContext, useEffect } from 'react';
import * as amplitude from '@amplitude/analytics-browser';

import { StyledWillsIntroText, StyledWillsTitle } from '@wills/components';
import { setFamilyFlowStartedTime } from '@wills/apis/willsProfile/setFamilyFlowStartedTime';
import {
  StyledInfoContainer,
  StyledIntroText,
  StyledIntroductionPageContainer,
  StyledNavigationButtonsWrapper,
  StyledWillsGrid,
  StyledWillsImage,
  StyledWillsImageContainer,
  StyledFooterBoldText,
  StyledWillsText,
} from './Introduction.styles';
import { NavigationButtons } from '../NavigationButtons';
import { familyTree } from '../../Assets';
import { SnackBarContext } from '@/providers';

interface IntroductionProps {
  onBack: () => void;
  onNext: () => void;
  fetchStartedTime?: typeof setFamilyFlowStartedTime;
}
const Introduction: React.FC<IntroductionProps> = ({ onBack, onNext, fetchStartedTime = setFamilyFlowStartedTime }) => {
  const { setSnackMessage } = useContext(SnackBarContext);

  async function getOrSetFamilyFlowStartTime() {
    const { error } = await fetchStartedTime();
    if (error) {
      setSnackMessage(error.message);
    }
  }

  useEffect(() => {
    getOrSetFamilyFlowStartTime();
  }, []);

  useEffect(() => {
    amplitude.track('Wills Family Flow Started');
  }, []);

  return (
    <StyledIntroductionPageContainer>
      <StyledWillsIntroText>Up next</StyledWillsIntroText>
      <StyledWillsTitle>Your Family</StyledWillsTitle>
      <StyledWillsGrid>
        <StyledInfoContainer>
          <StyledWillsText>
            The process to distribute an estate according to the Qu’ran starts with identifying your Islamic heirs. Our
            scholar-certified calculator will help you identify your Islamic heirs and their shares.
          </StyledWillsText>
          <StyledIntroText>
            <StyledFooterBoldText>REMINDER:</StyledFooterBoldText> DO NOT include any non-Muslim relatives ( parents,
            siblings, cousins, etc.). They are NOT considered Islamic heirs, however, they can receive the bequeathal.
          </StyledIntroText>
        </StyledInfoContainer>
        <StyledWillsImageContainer>
          <StyledWillsImage src={familyTree} alt="family-tree" />
        </StyledWillsImageContainer>
      </StyledWillsGrid>
      <StyledNavigationButtonsWrapper>
        <NavigationButtons onContinue={onNext} onPrevious={onBack} />
      </StyledNavigationButtonsWrapper>
    </StyledIntroductionPageContainer>
  );
};

export default Introduction;
