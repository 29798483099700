import React from 'react';
import {
  getUserProfile,
  getWillsProfile,
  unsubscribe as unsubscribeApi,
  getWillsAssets,
  getLiabilityList,
  getWishList,
  updateDocumentChecklist as updateDocumentChecklistApi,
  IUpdateDocumentChecklistInput,
  IDocumentChecklist,
  ILiabilityList,
  IAssetList,
  IWishList,
  getDocumentChecklist,
} from '@wills/apis';
import { IWillsProfile } from '@wills/types';
import { useApi } from '@/lib/axios';
import { Loading } from '@/Shared/Components/Loading';
import { IProfile } from '@/Types';
import {
  isSubscriptionActive,
  isTrialPaid,
  isTrialUnpaid,
  updateCacheAfterUnsubscribe,
} from './components/subscription/SubscriptionInfo/SubscriptionInfo.helpers';
import { SnackBarContext } from '@/providers';

interface IDocumentsContextValue {
  userProfile: IProfile;
  willsProfile: IWillsProfile;
  liabilityList: ILiabilityList;
  assetList: IAssetList;
  wishList: IWishList;
  documentChecklist: IDocumentChecklist;
  isSubscriptionFeatureSetEnabled: boolean;
  updateDocumentChecklist: (input: IUpdateDocumentChecklistInput) => Promise<void>;
  unsubscribe: () => Promise<void>;
  setAssetList: (assetList: IAssetList) => void;
  setLiabilityList: (liabilityList: ILiabilityList) => void;
  setWishList: (wishList: IWishList) => void;
}

export const DocumentsContext = React.createContext({} as IDocumentsContextValue);

export const DocumentsProvider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { setSnackMessage } = React.useContext(SnackBarContext);
  const { data: userProfile, loading: userProfileLoading, error: userProfileError } = useApi(getUserProfile);

  const {
    data: willsProfile,
    setData: setWillsProfile,
    loading: willsProfileLoading,
    error: willsProfileError,
  } = useApi(getWillsProfile);

  const {
    data: documentChecklist,
    setData: setDocumentChecklist,
    loading: documentChecklistLoading,
    error: documentChecklistError,
  } = useApi(getDocumentChecklist);

  const {
    data: liabilityList,
    loading: liabilityListLoading,
    error: liabilityListError,
    setData: setLiabilityList,
  } = useApi(getLiabilityList);

  const {
    data: assetList,
    loading: assetListLoading,
    error: assetListError,
    setData: setAssetList,
  } = useApi(getWillsAssets);

  const { data: wishList, loading: wishListLoading, error: wishListError, setData: setWishList } = useApi(getWishList);

  const [errorMessage, setErrorMessage] = React.useState<string | undefined>();

  if (errorMessage) {
    throw new Error(errorMessage);
  }

  const updateDocumentChecklist = async (input: IUpdateDocumentChecklistInput) => {
    const { error, data } = await updateDocumentChecklistApi(input);
    if (error) {
      setErrorMessage(error.message);
      throw new Error('async error');
    } else {
      setDocumentChecklist(data);
    }
  };

  const unsubscribe = async () => {
    const { error } = await unsubscribeApi();
    if (error) {
      setSnackMessage(error.message);
    } else {
      // TODO: BE should probably return updated values
      const updatedProfile = updateCacheAfterUnsubscribe(willsProfile!);
      setWillsProfile(updatedProfile);
      setSnackMessage('Unsubscribed');
    }
  };

  if (
    willsProfileLoading ||
    userProfileLoading ||
    liabilityListLoading ||
    assetListLoading ||
    wishListLoading ||
    documentChecklistLoading
  ) {
    return <Loading />;
  }

  if (
    userProfileError ||
    willsProfileError ||
    documentChecklistError ||
    liabilityListError ||
    assetListError ||
    wishListError
  ) {
    throw new Error(
      userProfileError?.message ??
        willsProfileError?.message ??
        documentChecklistError?.message ??
        liabilityListError?.message ??
        assetListError?.message ??
        wishListError?.message,
    );
  }

  const isSubscriptionFeatureSetEnabled =
    isSubscriptionActive(willsProfile) || isTrialPaid(willsProfile) || isTrialUnpaid(willsProfile);

  return (
    <DocumentsContext.Provider
      value={{
        userProfile,
        willsProfile,
        liabilityList,
        assetList,
        wishList,
        documentChecklist,
        isSubscriptionFeatureSetEnabled,
        updateDocumentChecklist,
        unsubscribe,
        setAssetList,
        setLiabilityList,
        setWishList,
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};
