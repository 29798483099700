import React, { ReactElement, useContext, useState } from 'react';
import { ILiability } from '@wills/apis';
import { interpolate, possessive } from '@wills/helpers';
import { useNavigate } from 'react-router-dom';
import { ContentContainer } from './LiabilityListPage.styles';
import { Page, SupportingDocumentHero } from '../../components';
import { useLiabilityForm } from './useAddLiabilityButton';
import { LiabilityDetailsForm } from './components/LiabilityDetailsForm';
import { LiabilityList } from './components/LiabilityList';
import { ProfileStore } from '@/Stores';

import locales from './LiabilityListPage.locales.json';
import { PrimaryButton, SecondaryButton } from './components';
import { bindFuncToAsync } from '@/Utils';
import { useLiabilityList } from '../../hooks/useLiabilityList';
import { SnackBarContext } from '@/providers';
import { PopulatedDocumentContext } from '../Documents/PopulatedDocumentContext';

const locale = locales.EN;

type Props = {
  renderNav: () => ReactElement;
};

export const LiabilityListPage: React.FC<Props> = ({ renderNav }: Props) => {
  const navigate = useNavigate();
  const { setSnackMessage } = useContext(SnackBarContext);
  const { regenerateDocuments } = useContext(PopulatedDocumentContext);
  const { profile } = useContext(ProfileStore);
  const { liabilityList, createLiability, updateLiability, deleteLiability, completeLiabilityList } =
    useLiabilityList();
  const { formOpened, openForm, closeForm } = useLiabilityForm();
  const [liabilityToEdit, setLiabilityToEdit] = useState<ILiability | null>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const bindToLoading = bindFuncToAsync(setLoading);

  return (
    <Page loading={loading}>
      {renderNav()}
      <SupportingDocumentHero
        tag={locale.tag}
        title={interpolate(locale.title, {
          name: possessive(profile?.firstName ?? ''),
        })}
        description={locale.description}
        warning={locale.warning}
      />
      <ContentContainer>
        <SecondaryButton onClick={openForm} disabled={formOpened}>
          {locale.addButtonLabel}
        </SecondaryButton>
        {formOpened && (
          <LiabilityDetailsForm
            key="create"
            onCancel={closeForm}
            onSubmit={async (data) => {
              await createLiability(data);
              closeForm();
            }}
          />
        )}
        {liabilityToEdit && (
          <LiabilityDetailsForm
            key="update"
            liability={liabilityToEdit}
            onCancel={() => setLiabilityToEdit(null)}
            onSubmit={async (data) => {
              await updateLiability(liabilityToEdit.id, data);
              setLiabilityToEdit(null);
            }}
          />
        )}
        {liabilityList.liabilities.length > 0 && (
          <LiabilityList
            liabilities={liabilityList.liabilities}
            liabilityToEdit={liabilityToEdit}
            onEdit={setLiabilityToEdit}
            onDelete={async (liability) => {
              await deleteLiability(liabilityList.id, liability.id);
            }}
          />
        )}
        <PrimaryButton
          disabled={liabilityList.liabilities.length < 1}
          onClick={bindToLoading(async () => {
            await completeLiabilityList();
            const regenerateResponse = await regenerateDocuments(['ASSETS_AND_LIABILITIES']);
            if (regenerateResponse.error) {
              setSnackMessage(regenerateResponse.error.message);
            } else {
              navigate('/wills/documents');
            }
          })}
        >
          {locale.updateButtonLabel}
        </PrimaryButton>
      </ContentContainer>
    </Page>
  );
};
