import styled from 'styled-components';
import { BsPlus } from 'react-icons/bs';

import { Palette } from '@/Shared/Constants';

export const StyledRelativeSelectorContainer = styled.div`
  background-color: ${Palette.NEW_WHITE};
  border-radius: 0.5rem;
  padding: 1.2rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  margin-top: 2rem;
`;

export const StyledRelativeSelectorName = styled.p`
  font-family: 'SofiaPro-Bold';
  font-size: clamp(0.9rem, 1vw, 1rem);
  color: ${Palette.GUN_POWDER};
`;

export const StyledDropdownButtonContainer = styled.div`
  position: relative;
`;

export const StyledDropdownButton = styled.button<{ disabled?: boolean }>`
  border: 1px solid ${Palette.LIGHT_GREY};
  border-radius: 0.3rem;
  padding: 0.5rem 1rem;
  cursor: pointer;
  color: ${Palette.GUN_POWDER};
  background-color: ${Palette.NEW_WHITE};
  font-family: 'SofiaPro-Regular';
  display: flex;
  align-items: center;

  ${({ disabled }) =>
    disabled &&
    `
    background-color: ${Palette.LIGHT_GREY};
    opacity: 0.5;
  `}
`;

export const StyledPlusIcon = styled(BsPlus)`
  font-size: 1.2rem;
`;
