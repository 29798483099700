import React from 'react';
import { useForm } from 'react-hook-form';

import { CreateUserReasonsForJoiningInput } from '@user/apis';
import { Title, GroupField, CheckboxField, PrimaryButton, SecondaryButton } from '../../../components';
import { UserKycForm, UserKycButtonGroup } from '../styles';

export const englishTextValues = {
  title: 'One last thing, what brings you to Manzil?',
  options: {
    religion: 'Religious reasons',
    ethics: 'Access to ethical financial products and services',
    like: 'I really like what you folks are doing',
    exploring: "I'm just taking a look around for now",
  },
  submitButton: 'Submit',
  backButton: 'Back',
};

type UserKycReasonFormTextValues = typeof englishTextValues;

interface UserKycReasonFormValues {
  reasons: string[];
}

interface UserKycReasonFormProps {
  onBack: () => void;
  onSubmit: (values: CreateUserReasonsForJoiningInput) => Promise<void>;
  textValues?: UserKycReasonFormTextValues;
}

export const UserKycReasonForm: React.FC<UserKycReasonFormProps> = ({
  onBack,
  onSubmit,
  textValues = englishTextValues,
}) => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm<UserKycReasonFormValues>({
    defaultValues: {
      reasons: [],
    },
  });

  const onSubmitHandler = handleSubmit(async ({ reasons }) => {
    await onSubmit({
      religion: reasons.includes('religion'),
      ethics: reasons.includes('ethics'),
      like: reasons.includes('like'),
      exploring: reasons.includes('exploring'),
    });
  });

  return (
    <UserKycForm onSubmit={onSubmitHandler}>
      <Title>{textValues.title}</Title>

      <GroupField id="reasons" error={errors.reasons?.message}>
        {Object.entries(textValues.options).map(([key, value], i) => {
          return (
            <CheckboxField
              key={i}
              id={key}
              label={value}
              value={key}
              {...register(`reasons`, {
                validate: (v) => {
                  return v.length > 0 || 'Please select one option';
                },
              })}
            />
          );
        })}
      </GroupField>
      <UserKycButtonGroup>
        <PrimaryButton type="submit" disabled={isSubmitting}>
          {textValues.submitButton}
        </PrimaryButton>
        <SecondaryButton type="button" disabled={isSubmitting} onClick={onBack}>
          {textValues.backButton}
        </SecondaryButton>
      </UserKycButtonGroup>
    </UserKycForm>
  );
};
