import styled from 'styled-components';

import { LongInput } from '@/Shared/Shared.styles';

export const PhoneFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
`;

export const PhoneFormStyled = styled.form`
  display: flex;
  gap: 1em;
`;

export const PhoneInputStyled = styled(LongInput)`
  min-width: 0;
  width: 30rem;
  font-size: clamp(1rem, 2vw, 1.5rem);

  @media (max-width: 600px) {
    width: 14rem;
  }
`;
