import { agreement, personalHygine, testament } from './assets';
import { IDocumentData } from './LegalDocument.types';

export const LEGAL_DOCUMENTS_DATA: IDocumentData[] = [
  {
    title: 'Last Will and Testament',
    description: 'Control what happens after you are gone',
    image: testament,
    documentType: 'LAST_WILL_AND_TESTAMENT',
  },
  {
    title: 'Power of Attorney for Property',
    description: 'Control what happens after you are gone',
    image: agreement,
    documentType: 'POA_FOR_PROPERTY',
  },
  {
    title: 'Power of Attorney for Personal Care',
    description: 'Control what happens after you are gone',
    image: personalHygine,
    documentType: 'POA_FOR_PERSONAL_CARE',
  },
];
