import React from 'react';
import { PopulatedDocument, PopulatedDocumentType } from '@wills/apis';
import {
  ActionCardsContainer,
  PrimaryLink,
  PrimaryLinkDisabled,
  SecondaryButton,
  Section,
  SectionTitle,
} from '../styles';
import locales from './SupportingDocuments.locales.json';
import { ActionCard } from '../ActionCard';
import { assetsImage, letterImage, liabilitiesImage } from './assets';

const locale = locales.EN;

export interface SupportingDocumentsProps {
  documents: Record<PopulatedDocumentType, PopulatedDocument | undefined>;
  viewDocument: (type: PopulatedDocumentType) => Promise<void>;
  disabled?: boolean;
}

export const SupportingDocuments: React.FC<SupportingDocumentsProps> = ({ documents, disabled, viewDocument }) => {
  const missingAssetAndLiabilities = !documents.ASSETS_AND_LIABILITIES;
  const missingLetterOfWishes = !documents.LETTER_OF_WISHES;
  return (
    <Section id="supporting-documents">
      <SectionTitle>{locale.title}</SectionTitle>
      <ActionCardsContainer>
        <ActionCard
          title={locale.assetsList.title}
          description={locale.assetsList.description}
          imageProps={{
            src: assetsImage,
            alt: locale.assetsList.imageAlt,
          }}
        >
          {disabled ? (
            <PrimaryLinkDisabled>{locale.editButtonLabel}</PrimaryLinkDisabled>
          ) : (
            <PrimaryLink to="asset-list">{locale.editButtonLabel}</PrimaryLink>
          )}
          <SecondaryButton
            onClick={() => viewDocument('ASSETS_AND_LIABILITIES')}
            disabled={missingAssetAndLiabilities || disabled}
          >
            {locale.viewButtonLabel}
          </SecondaryButton>
        </ActionCard>
        <ActionCard
          title={locale.liabilitiesList.title}
          description={locale.liabilitiesList.description}
          imageProps={{
            src: liabilitiesImage,
            alt: locale.liabilitiesList.imageAlt,
          }}
        >
          {disabled ? (
            <PrimaryLinkDisabled>{locale.editButtonLabel}</PrimaryLinkDisabled>
          ) : (
            <PrimaryLink to="liability-list">{locale.editButtonLabel}</PrimaryLink>
          )}
          <SecondaryButton
            onClick={() => viewDocument('ASSETS_AND_LIABILITIES')}
            disabled={missingAssetAndLiabilities || disabled}
          >
            {locale.viewButtonLabel}
          </SecondaryButton>
        </ActionCard>
        <ActionCard
          title={locale.letterOfWishes.title}
          description={locale.letterOfWishes.description}
          imageProps={{
            src: letterImage,
            alt: locale.letterOfWishes.imageAlt,
          }}
        >
          {disabled ? (
            <PrimaryLinkDisabled>{locale.editButtonLabel}</PrimaryLinkDisabled>
          ) : (
            <PrimaryLink to="letter-of-wishes">{locale.editButtonLabel}</PrimaryLink>
          )}
          <SecondaryButton
            onClick={() => viewDocument('LETTER_OF_WISHES')}
            disabled={missingLetterOfWishes || disabled}
          >
            {locale.viewButtonLabel}
          </SecondaryButton>
        </ActionCard>
      </ActionCardsContainer>
    </Section>
  );
};
