import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledBequestAdderContainer = styled.div`
  margin-top: 0.8rem;
  margin-bottom: 2rem;
`;

export const StyledBequestAdderButton = styled.button`
  width: 100%;
  border: 1px solid ${Palette.LIGHT_GREY};
  border-radius: 0.3rem;
  min-width: 8rem;
  font-family: 'SofiaPro-Regular';
  padding: 0.5rem 1rem;
  font-size: clamp(0.9rem, 1vw, 1rem);
  color: ${Palette.GUN_POWDER};
  background-color: ${Palette.NEW_WHITE};
  cursor: pointer;
`;
