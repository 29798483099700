import { axiosClient, toApiResponse } from '@/lib/axios';

export interface SetPoaPropertyInput {
  mainPoaPropertyId: number;
  backupPoaPropertyId: number | null;
}

export const setPoaProperty = (input: SetPoaPropertyInput) => {
  return toApiResponse<never>(axiosClient.put('/wills-set-poa-property', input));
};
