import styled from 'styled-components';
import { ZIndex } from '@/Utils/zIndexes';

export const StyledSnackBarLayoutContainer = styled.div`
  height: 100vh;
  position: relative;
`;

export const StyledSnackBarContainer = styled.div<{ hidden: boolean }>`
  position: fixed;
  bottom: ${({ hidden }) => (hidden ? '0' : '4rem')};
  width: 100%;
  display: flex;
  justify-content: center;
  opacity: ${({ hidden }) => (hidden ? '0' : '1')};
  pointer-events: none;
  transition:
    opacity 0.2s ease,
    bottom 0.2s ease;
  z-index: ${ZIndex.SNACKBAR};

  @media screen and (max-width: 700px) {
    position: fixed;
    top: ${({ hidden }) => (hidden ? '0' : '0rem')};
    bottom: auto;
  }
`;
