import React from 'react';
import { StyledPaymentParagraph } from '@/lib/wills/components/styles';
import {
  StyledSubscriptionItemsCheckIcon,
  StyledSubscriptionItemsContainer,
  StyledSubscriptionItemsCrossIcon,
  StyledSubscriptionItemsGroup,
} from './SubscriptionItems.styles';
import { StyledSubscriptionParagraph } from '../Subscription/Subscription.styles';
import { SUBSCRIPTION_ITEMS } from './SubscriptionItems.constants';
import { ISubscriptionItemsProps } from './SubscriptionItems.types';

const SubscriptionItems = ({ subscribed }: ISubscriptionItemsProps) => {
  return (
    <StyledSubscriptionItemsContainer>
      <StyledPaymentParagraph bold>INCLUDES:</StyledPaymentParagraph>

      {SUBSCRIPTION_ITEMS.map((item, index) => (
        <StyledSubscriptionItemsGroup key={index}>
          {subscribed ? <StyledSubscriptionItemsCheckIcon /> : <StyledSubscriptionItemsCrossIcon />}
          <StyledSubscriptionParagraph>{item}</StyledSubscriptionParagraph>
        </StyledSubscriptionItemsGroup>
      ))}
    </StyledSubscriptionItemsContainer>
  );
};

export default SubscriptionItems;
