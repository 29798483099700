import styled from 'styled-components';
import { ZIndex } from '@/Utils/zIndexes';
import { Palette } from '@/Shared/Constants';

export const Layout = styled.div<{ type: 'fixed' | 'absolute' }>`
  position: ${({ type }) => type};
  z-index: ${ZIndex.OVERLAY};
  width: ${({ type }) => (type === 'absolute' ? '100%' : '100vw')};
  height: ${({ type }) => (type === 'absolute' ? '100%' : '100vh')};
  ${({ type }) => {
    if (type === 'fixed') {
      return `
        top: 0;
        left: 0;
      `;
    }
    return '';
  }}

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Background = styled.div<{ backgroundColor: React.CSSProperties['backgroundColor'] }>`
  position: absolute;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  z-index: 0;
  background-color: ${({ backgroundColor }) => backgroundColor ?? Palette.AQUA_HAZE};
`;
