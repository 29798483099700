import React, { useContext, useState } from 'react';

import { Modal } from '@/Shared/Components';
import { getErrorMessage } from '@/Utils';
import { SnackBarContext } from '@/providers';

import { IConfirmationModalProps } from './ConfirmationModal.types';
import {
  StyledConfirmationModalContainer,
  StyledConfirmationModalInfo,
  StyledConfirmationModalName,
  StyledConfirmationModalNameContainer,
  StyledConfirmationModalNavigationButtonsContainer,
  StyledConfirmationModalRemoveIcon,
  StyledConfirmationModalTitle,
} from './ConfirmationModal.styles';
import { NavigationButtons } from '../NavigationButtons';
import { RelativeDropdown } from '../RelativeDropdown';
import { IRelativeInfo } from '../../WillsFamilyFlow.types';
import { deleteRelationship } from '../../../../apis/relationship';
import { NoRelativesModalInfo } from '../NoRelativesModalInfo';

const ConfirmationModal = ({
  onClose,
  onConfirm,
  continueDisabled,
  relationships,
  setRelationships,
  relativeTypes,
}: IConfirmationModalProps) => {
  const { setSnackMessage } = useContext(SnackBarContext);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const noRelativesAdded = relationships.length < 1;
  const relativeCategory = relativeTypes[0]?.category;

  async function removeAddedRelative(relationship: IRelativeInfo) {
    try {
      await deleteRelationship(relationship.id as number);

      setRelationships((relations) => {
        const relationshipsClone = relations.slice();
        const elementIndex = relations.indexOf(relationship);

        relationshipsClone.splice(elementIndex, 1);
        return relationshipsClone;
      });
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    }
  }

  return (
    <Modal onCloseCallback={onClose}>
      <StyledConfirmationModalContainer>
        <StyledConfirmationModalTitle>Confirm Details</StyledConfirmationModalTitle>
        <StyledConfirmationModalInfo>
          Please make sure to scroll through the list to check that you did not forget anyone, before continuing
        </StyledConfirmationModalInfo>
        {noRelativesAdded && <NoRelativesModalInfo category={relativeCategory} />}
        {relationships.map((relationship, index) => (
          <StyledConfirmationModalNameContainer key={index}>
            <StyledConfirmationModalName>
              {relationship.firstName} {relationship.lastName}
            </StyledConfirmationModalName>
            <StyledConfirmationModalRemoveIcon onClick={() => removeAddedRelative(relationship)} />
          </StyledConfirmationModalNameContainer>
        ))}
        <StyledConfirmationModalNavigationButtonsContainer>
          <NavigationButtons
            continueDisbaled={continueDisabled}
            cancelBtnText="Add a person +"
            onContinue={onConfirm}
            onPrevious={() => setDropdownVisible(true)}
          />
          {dropdownVisible && (
            <RelativeDropdown
              onClick={onClose}
              bottom="3rem"
              setDropdownVisible={setDropdownVisible}
              relativeTypes={relativeTypes}
            />
          )}
        </StyledConfirmationModalNavigationButtonsContainer>
      </StyledConfirmationModalContainer>
    </Modal>
  );
};

export default ConfirmationModal;
