import React, { useContext } from 'react';

import { BackArrowIcon, BackButtonText, IconButton } from '@/lib/user/pages/Profile/Profile.style';
import { LocaleContext } from '@/providers';

import backButtonLocales from './backButtonLocales.json';

const BackButton: React.FunctionComponent<{ goBack: () => void }> = ({ goBack }) => {
  const { locale } = useContext(LocaleContext);

  const backButtonLocale = backButtonLocales[locale];

  return (
    <IconButton backBtn onClick={goBack}>
      <BackArrowIcon />
      <BackButtonText>{backButtonLocale['button.text']}</BackButtonText>
    </IconButton>
  );
};

export default BackButton;
