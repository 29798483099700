import React, { FC, useState } from 'react';
import { ChangeHandler, RefCallBack } from 'react-hook-form';
import {
  StyledClosedEyeIcon,
  StyledIconContainer,
  StyledInputField,
  StyledInputWrapper,
  StyledOpenEyeIcon,
} from './Input.styles';

interface IProps {
  name: string;
  placeholder?: string;
  type?: string;
  inputRef: RefCallBack;
  onChange: ChangeHandler;
  onBlur: ChangeHandler;
}

const RInput: FC<IProps> = ({ name, placeholder, type = 'text', inputRef, onChange, onBlur }) => {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const getInputType = () => {
    if (type === 'password') {
      if (showPassword) return 'text';
      return 'password';
    }
    return type;
  };

  return (
    <StyledInputWrapper>
      <StyledInputField
        type={getInputType()}
        placeholder={placeholder}
        ref={inputRef}
        name={name}
        onChange={onChange}
        onBlur={onBlur}
      />
      {type === 'password' && (
        <StyledIconContainer onClick={togglePasswordVisibility}>
          {showPassword ? <StyledOpenEyeIcon /> : <StyledClosedEyeIcon />}
        </StyledIconContainer>
      )}
    </StyledInputWrapper>
  );
};

export default RInput;
