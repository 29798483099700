import { IRelationshipDefinition } from '@wills/apis';
import React, { useContext, useEffect, useRef, useState } from 'react';

import { WillsFamilyFlowContext } from '../../WillsFamilyFlow.context';
import {
  StyledRelativeSelectorDropdown,
  StyledRelativeSelectorDropdownItem,
  StyledDownArrow,
} from './RelativeDropdown.styles';
import { IRelativeDropdownProps } from './RelativeDropdown.types';

const RelativeDropdown = ({
  top,
  right,
  bottom,
  left,
  onClick,
  setDropdownVisible,
  relativeTypes,
}: IRelativeDropdownProps) => {
  const { setSelectedRelativeDefinition } = useContext(WillsFamilyFlowContext);
  const [visibility, setVisibility] = useState<boolean>(true);
  const dropDownRef = useRef<HTMLDivElement | null>(null);

  function scrollDown() {
    if (dropDownRef?.current) {
      const { scrollHeight, clientHeight } = dropDownRef.current;
      const maxScrollTop = scrollHeight - clientHeight;

      dropDownRef.current.scrollTo({
        top: maxScrollTop,
        behavior: 'smooth',
      });
    }
    setVisibility(false);
  }

  function onDropdownItemClick(relationDefinition: IRelationshipDefinition) {
    setSelectedRelativeDefinition(relationDefinition);
    setDropdownVisible(false);

    if (onClick) onClick();
  }

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setDropdownVisible(false);
      }
    }
    function hideDownArrowWhileScrolling() {
      if (dropDownRef.current?.scrollTop === 0) {
        setVisibility(true);
      } else {
        setVisibility(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    dropDownRef.current?.addEventListener('scroll', hideDownArrowWhileScrolling);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      dropDownRef.current?.removeEventListener('scroll', hideDownArrowWhileScrolling);
    };
  }, []);

  return (
    <StyledRelativeSelectorDropdown ref={dropDownRef} top={top} bottom={bottom} right={right} left={left}>
      {relativeTypes.map((relative) => (
        <StyledRelativeSelectorDropdownItem key={relative.id} onClick={() => onDropdownItemClick(relative)}>
          {relative.description}
        </StyledRelativeSelectorDropdownItem>
      ))}
      {visibility ? <StyledDownArrow onClick={() => scrollDown()} /> : null}
    </StyledRelativeSelectorDropdown>
  );
};

export default RelativeDropdown;
