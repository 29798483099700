import React from 'react';
import { MSpinner } from './Spinner.styles';

const Spinner = () => {
  return (
    <MSpinner>
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </MSpinner>
  );
};

export default Spinner;
