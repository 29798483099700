import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const Button = styled.button<{ color?: string }>`
  padding: 0.9rem 4rem;
  font-size: 1rem;
  font-family: 'SofiaPro-Bold';
  color: ${Palette.EGGSHELL_WHITE};
  border: none;
  border-radius: 2rem;
  background-color: ${({ color }) => color || Palette.SLATE_GREEN};
  cursor: pointer;

  &:disabled {
    opacity: 0.5;
  }
`;
