import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const InvestContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  background-color: ${Palette.AQUA_HAZE};
  align-items: center;
  min-height: 100vh;
`;

export const OnevestContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 600px) {
    padding-top: 2rem;
  }
`;

export const Iframe = styled.iframe`
  width: 100%;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  border: none;
`;

export const ErrorMessage = styled.p`
  font-family: 'SofiaPro-Regular';
`;
