import React from 'react';
import { EstateIntakeProvider } from './contexts/EstateIntakeContext/EstateIntakeContext';
import { EstateIntakeIterator } from './EstateIntake.iterator';

export const EstateIntake: React.FC<{
  isReview?: boolean;
}> = ({ isReview }) => {
  return (
    <EstateIntakeProvider>
      <EstateIntakeIterator isReview={isReview} />
    </EstateIntakeProvider>
  );
};
