import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

const BasePage = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Palette.AQUA_HAZE};
  flex: 1 0;
  align-items: center;
  justify-content: center;
`;

export const Page = styled(BasePage)`
  padding: 2rem 0.5rem;
`;
