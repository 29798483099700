import styled from 'styled-components';
import { HiOutlineArrowNarrowLeft, HiX } from 'react-icons/hi';
import { MdDateRange } from 'react-icons/md';
import { FiEdit3, FiFlag } from 'react-icons/fi';
import { RiMapPinLine } from 'react-icons/ri';
import { BiUser, BiPhone } from 'react-icons/bi';
import { GiLinkedRings } from 'react-icons/gi';
import { TbLanguage } from 'react-icons/tb';

import { Palette } from '@/Shared/Constants';

export const ProfileContainer = styled.div`
  display: flex;
  justify-content: center;
  background-color: ${Palette.AQUA_HAZE};
  padding-bottom: 2rem;
`;

export const ProfileForm = styled.div`
  width: 40rem;
  background-color: ${Palette.AQUA_HAZE};
  border-radius: 3rem;
  padding: 1rem;
  box-sizing: border-box;
  font-family: SofiaPro-Regular;
  height: fit-content;

  @media only screen and (max-width: 600px) {
    width: 60rem;
    padding-top: 4rem;
  }
`;

export const FormContent = styled.div`
  padding: 1rem;
  background-color: ${Palette.NEW_WHITE};
  position: relative;
  box-shadow: 0px 1rem 2.5rem rgba(0, 0, 0, 0.05);
  border-radius: 1rem;

  @media only screen and (max-width: 600px) {
    margin-top: 1.5rem;
    width: 100%;
  }
`;

export const FormContentHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const Heading = styled.div`
  margin-top: 1rem;
  font: normal normal normal 'SofiaPro-Bold';
  font-weight: 600;
  color: ${Palette.NEW_BLUE};
  font-size: clamp(2rem, 2vw, 3rem);
`;

export const Subheading = styled.p`
  margin-top: 1rem;
  margin-bottom: 2rem;
  box-sizing: border-box;
  font-size: clamp(1rem, 1vw, 1.5rem);
  color: ${Palette.NEW_BLUE};

  @media only screen and (max-width: 600px) {
    font-size: 12px;
  }
`;

export const ContentHeading = styled.h3`
  font: normal normal normal 'SofiaPro-Bold';
  font-size: clamp(1.25rem, 1.3vw, 1.75rem);
  color: ${Palette.NEW_BLUE};
`;

export const InfoWrapper = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;

  @media only screen and (min-width: 600px) {
    flex-direction: row;
    flex-wrap: wrap;
    padding-left: 0.5rem;
    justify-content: space-between;
  }
`;

export const SingleInfoWrapper = styled.div<{ spanFullWidth?: boolean }>`
  max-width: 100%;
  padding-left: 0.5rem;
  flex-basis: ${({ spanFullWidth }) => spanFullWidth && '100%'};
  display: flex;
  margin: 1rem 0;
  gap: 0 1rem;

  @media only screen and (min-width: 600px) {
    gap: 3rem 1rem;
    width: 50%;
    padding-left: ${({ spanFullWidth }) => (spanFullWidth ? '0.75rem' : '1rem')};
  }
`;

export const InfoAttributes = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 0.3rem;
`;

export const InfoHeader = styled.h4`
  color: ${Palette.NEW_BLUE};
  font-family: 'SofiaPro-Bold';
  font-weight: 400;
  font-size: 1rem;
`;

export const InfoContent = styled.p`
  color: ${Palette.CADET_BLUE_DARKER};
  font-size: 1.1rem;
  font-family: 'SofiaPro-Regular';
  font-weight: 400;
`;

export const IconButton = styled.button<{ backBtn?: boolean; saveBtn?: boolean }>`
  border-radius: 50%;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;

  ${({ backBtn }) =>
    backBtn &&
    `
        display: flex;
        align-items: center;
        gap: 1rem;
    `}

  ${({ saveBtn }) =>
    saveBtn &&
    `
        margin-right: 0.3rem;
    `}
`;

export const BackButtonText = styled.span`
  font-size: 1rem;
  font-family: SofiaPro-Regular;
  color: ${Palette.INDEPENDENCE_BLUE};

  @media only screen and (max-width: 600px) {
    font-size: 0.8rem;
  }
`;

export const BackArrowIcon = styled(HiOutlineArrowNarrowLeft)`
  font-size: 1.2rem;
  color: ${Palette.INDEPENDENCE_BLUE};
`;

export const EditIcon = styled(FiEdit3)<{ show?: boolean }>`
  color: ${Palette.INDEPENDENCE_BLUE};
  cursor: pointer;
  font-size: 1.6rem;
`;

export const UserIcon = styled(BiUser)`
  color: ${Palette.NEW_BLUE};
  border-radius: 50%;
  border: 1px solid rgba(61, 64, 91, 0.1);
  width: 4.2rem;
  height: 3.5rem;
  padding: 10px;
  background: #fff;
  text-align: center;
`;

export const CalendarIcon = styled(MdDateRange)`
  color: ${Palette.NEW_BLUE};
  font-size: 1.5rem;
  border-radius: 50%;
  border: 1px solid rgba(61, 64, 91, 0.1);
  width: 4.2rem;
  height: 3.5rem;
  padding: 10px;
  background: #ffffff;
  text-align: center;
`;

export const RingIcon = styled(GiLinkedRings)`
  color: ${Palette.NEW_BLUE};
  font-size: 1.5rem;
  border-radius: 50%;
  border: 1px solid rgba(61, 64, 91, 0.1);
  width: 4.2rem;
  height: 3.5rem;
  padding: 10px;
  background: #ffffff;
  text-align: center;
`;

export const CitizenIcon = styled(FiFlag)`
  color: ${Palette.NEW_BLUE};
  font-size: 1.5rem;
  border-radius: 50%;
  border: 1px solid rgba(61, 64, 91, 0.1);
  width: 4.2rem;
  height: 3.5rem;
  padding: 10px;
  background: #ffffff;
  text-align: center;
`;

export const PhoneIcon = styled(BiPhone)`
  color: ${Palette.NEW_BLUE};
  font-size: 1.5rem;
  border-radius: 50%;
  border: 1px solid rgba(61, 64, 91, 0.1);
  width: 4.2rem;
  height: 3.5rem;
  padding: 10px;
  background: #ffffff;
  text-align: center;
`;

export const LanguageIcon = styled(TbLanguage)`
  color: ${Palette.NEW_BLUE};
  font-size: 1.5rem;
  border-radius: 50%;
  border: 1px solid rgba(61, 64, 91, 0.1);
  width: 4.2rem;
  height: 3.5rem;
  padding: 10px;
  background: #ffffff;
  text-align: center;
`;

export const AddressIcon = styled(RiMapPinLine)`
  color: ${Palette.NEW_BLUE};
  font-size: 1.5rem;
  border-radius: 50%;
  border: 1px solid rgba(61, 64, 91, 0.1);
  width: 4.2rem;
  height: 3.5rem;
  padding: 10px;
  background: #ffffff;
  text-align: center;
`;

export const BackToProfileContainer = styled.div<{ show?: boolean }>`
  display: ${({ show }) => (show ? 'block' : 'none')};
  color: ${Palette.GUN_POWDER};
  cursor: pointer;
  font-size: 1rem;
`;

export const InputField = styled.input`
  width: 100%;
  height: 1.5rem;
  padding-right: 0.5rem;
  box-sizing: border-box;
  border: none;
  margin-top: 0.2rem;
  margin-right: 1rem;
  font-size: 0.9rem;
  font-family: SofiaPro-Regular;
  color: ${Palette.NEW_BLUE};
  background-color: ${Palette.AQUA_HAZE};

  &:focus {
    outline: none;
    border-radius: 5px;
    border: 1px solid ${Palette.DIRTY_ORANGE};
  }
`;

export const SelectField = styled.select`
  border: none;
  height: 1.5rem;
  margin-top: 0.2rem;
  font-family: SofiaPro-Regular;
  color: ${Palette.NEW_BLUE};
  width: 100%;
  background-color: ${Palette.AQUA_HAZE};
`;

export const Form = styled.form`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  @media only screen and (max-width: 600px) {
    flex-direction: column;
    gap: 1rem 0;
  }
`;

export const ErrorMessage = styled.span`
  color: red;
  font-size: 0.8rem;
`;

export const SnackBarMessage = styled.div`
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 0.5rem;
  position: fixed;
  z-index: 1;
  left: 50%;
  bottom: 30px;
  font-family: 'SofiaPro-Regular';
  -webkit-animation:
    fadein 0.5s,
    fadeout 0.5s 2.5s;
  animation:
    fadein 0.5s,
    fadeout 0.5s 2.5s;

  @keyframes fadeout {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }

  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }
`;

export const StyledPhoneInfo = styled.div`
  display: flex;
  width: 100%;
`;

export const StyledCountryCodeField = styled(InputField)`
  width: 25%;
`;

export const StyledContactNumberField = styled(InputField)`
  width: 75%;
  margin-left: 0;
  margin-right: 0;
`;

export const EditIconContainer = styled.div<{ show?: boolean }>`
  display: ${({ show }) => (show ? 'none' : 'block')};
`;

export const GoBackButton = styled(HiX)`
  color: ${Palette.TERRA_COTTA_ORANGE};
  cursor: pointer;
  position: absolute;
  right: 60px;
  top: 14px;
  width: 2.1rem;
  height: 2.1rem;
  padding: 0.2rem;

  @media screen and (max-width: 600px) {
    right: 50px;
  }
`;

export const StyledSubmitButton = styled.input`
  border: none;
  padding: 1rem 1.25rem;
  color: ${Palette.GUN_POWDER};
`;

export const StyledAuthCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
`;

export const StyledModalInput = styled.input`
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: 1px solid ${Palette.GREY};
`;
