import React from 'react';
import { IconContainer } from '../Alert.styles';
import { AlertProps } from '../Alert.types';
import { WarningContainer, WarningIcon } from './WarningAlert.styles';

export const WarningAlert: React.FC<AlertProps> = ({ children }) => {
  return (
    <WarningContainer>
      <IconContainer>
        <WarningIcon />
      </IconContainer>
      {children}
    </WarningContainer>
  );
};
