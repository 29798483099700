import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const StyledNoRelativesModalInfoContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  justify-content: center;
  align-items: center;
`;

export const StyledNoRelatviesModalInfoHeader = styled.h2`
  font-family: 'SofiaPro-Regular';
  font-size: 1.5rem;
  color: ${Palette.NEW_BLUE};
  text-align: center;
  margin-bottom: 1rem;
`;
