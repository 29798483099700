import React from 'react';
import { QuestionInfo } from '@/lib/wills/components/QuestionInfo';

const ChildrenQuestionInfo = () => {
  return (
    <QuestionInfo
      question="An Important Note"
      answer={
        <div>
          You should include children that you or your spouse have together or from other relationships. Manzil Wills
          does not support the addition of children who haven’t been born yet, but you can update your will at the time
          any future children are born, inshAllah.
        </div>
      }
    />
  );
};

export default ChildrenQuestionInfo;
