import React from 'react';

export type IntakeStep<T, J extends string = string> = {
  name: J;
  shouldSkip?: (context: T) => boolean;
};

/**
 * This hook makes an assumption that the context is constant and will not change.
 * Edit behaviour may be non-deterministic if context does change.
 *
 * @param steps
 * @param context
 * @returns
 */
export const useIntakeSteps = <T, J extends string>(steps: IntakeStep<T, J>[], context: T) => {
  const [stepIndex, setStepIndex] = React.useState<number>(0);

  const nextStep = React.useCallback(() => {
    const next = (index: number): IntakeStep<T, J> => {
      const shouldSkip = steps[index].shouldSkip ?? (() => false);

      if (index > steps.length - 1) {
        return steps[index];
      }
      if (!shouldSkip(context)) {
        setStepIndex(index);
        return steps[index];
      }

      return next(index + 1);
    };
    return next(stepIndex + 1);
  }, [steps, stepIndex, context]);

  const prevStep = React.useCallback(() => {
    const prev = (index: number): IntakeStep<T, J> => {
      const shouldSkip = steps[index].shouldSkip ?? (() => false);

      if (index < 0) {
        return steps[index];
      }
      if (!shouldSkip(context)) {
        setStepIndex(index);
        return steps[index];
      }

      return prev(index - 1);
    };
    return prev(stepIndex - 1);
  }, [steps, stepIndex, context]);

  const goToStep = React.useCallback(
    (target: J): IntakeStep<T, J> => {
      const index = steps.findIndex(({ name }) => target === name);
      if (index === undefined) {
        throw new Error('Step not found');
      }

      const shouldSkip = steps[index].shouldSkip ?? (() => false);
      if (shouldSkip(context)) {
        throw new Error('Bad: Attempted going to a skipped step');
      }

      setStepIndex(index);
      return steps[index];
    },
    [steps, context],
  );

  return {
    step: steps[stepIndex],
    nextStep,
    prevStep,
    goToStep,
  };
};
