import React from 'react';
import { Background, Layout } from './Overlay.styles';

interface OverlayProps {
  children: React.ReactNode;
  type: 'fixed' | 'absolute';
  backgroundColor?: React.CSSProperties['backgroundColor'];
  onBackgroundClick?: () => void;
}

export const Overlay: React.FC<OverlayProps> = ({ children, type, backgroundColor, onBackgroundClick }) => {
  return (
    <Layout type={type}>
      <Background backgroundColor={backgroundColor} onClick={onBackgroundClick} />
      {children}
    </Layout>
  );
};
