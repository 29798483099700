import { loadStripe, Stripe, StripeElements } from '@stripe/stripe-js';
import { axiosClient } from '../../axios';

export async function getStripeKey() {
  const response = await axiosClient.get('/stripe-public-key');
  return response.data;
}

export async function getStripe() {
  const stripeKey = await getStripeKey();

  return loadStripe(stripeKey.stripe_public_key);
}

export async function stripeConfirmIntentSetup(stripe: Stripe, elements: StripeElements) {
  const response = await stripe.confirmSetup({
    elements,
    confirmParams: {
      return_url: `${window.location.origin}/wills/documents`,
    },
    redirect: 'if_required',
  });

  return response;
}

export async function stripeConfirmPayment(stripe: Stripe, elements: StripeElements) {
  const response = await stripe.confirmPayment({
    elements,
    confirmParams: {
      return_url: `${window.location.origin}/wills/documents`,
    },
    redirect: 'if_required',
  });

  return response;
}
