import styled from 'styled-components';
import { BsCheck, BsX } from 'react-icons/bs';
import { Palette } from '@/Shared/Constants';

export const StyledTitle = styled.h2`
  font-family: 'SofiaPro-Regular';
  font-size: 1rem;
  font-weight: 600;
  color: ${Palette.NEW_BLUE};

  @media screen and (max-width: 600px) {
    padding-left: 0.3rem;
  }
`;

export const StyledBenefitsSection = styled.div<{ nomargin?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 1.3rem;
  margin-top: ${({ nomargin }) => (nomargin ? '0' : '2rem')};
`;

export const StyledSingleBenefit = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
`;

export const StyledIconContainer = styled.div<{ type?: string; show?: string | boolean }>`
  display: grid;
  place-items: center;
  border-radius: 100px;
  padding: 1rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: ${({ type }) => (type === 'Premium' ? `${Palette.SLATE_GREEN}` : `${Palette.MELON}`)};
`;

export const StyledTick = styled(BsCheck)`
  color: white;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const StyledCross = styled(BsX)`
  color: white;
  color: white;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

export const StyledInfo = styled.p`
  font-family: 'SofiaPro-Regular';
  font-weight: 400;
  font-size: 0.9rem;
  color: ${Palette.PLANET_BLUE};
`;
