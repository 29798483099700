import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  StyledForm,
  StyledInputField,
  StyledFormLabel,
  StyledInputGroup,
  StyledInfoIconWrapper,
  StyledInfoIconContainer,
  StyledInfoIcon,
  HiddenSubmitButton,
  WillsProfileContext,
  InfoContentWrapper,
  EAboutYouSteps,
} from '@wills/components';

import { AboutYouContainer, Content, RadioButtonContainer, FormHeader, FormFooter, IWillsProps } from '../../shared';

import ChildrenQuestionInfo from './ChildrenQuestionInfo';

import { Modal } from '@/Shared/Components';

const ChildrenQuestion = ({ goBack, isEdit, goToSummaryPage }: IWillsProps) => {
  const { handleSubmit, register } = useForm();
  const {
    willsProfileData,
    recordWillsProfile,
    willsOnboardingComplete,
    updateWillsProfile,
    createWillsProfile,
    goToStep,
  } = useContext(WillsProfileContext);
  const isYesFieldChecked = willsProfileData?.kids === 1;
  const isNoFieldChecked = willsProfileData?.kids === 0;
  const isNoneSelected = !(isYesFieldChecked || isNoFieldChecked);
  const [showInfoBlurb, setShowInfoBlurb] = useState(false);

  const onChildrenQuerySubmit = (_: any, event?: React.BaseSyntheticEvent) => {
    const formEvent = event as React.KeyboardEvent<HTMLFormElement>;
    if (isNoneSelected) return;

    if (willsOnboardingComplete) {
      updateWillsProfile({ kids: willsProfileData?.kids });
    } else {
      createWillsProfile({ kids: willsProfileData?.kids });
    }
    if (formEvent && formEvent.target?.id === 'summary-btn') {
      goToSummaryPage!();
    } else {
      goToStep(EAboutYouSteps.GENDER_QUESTION);
    }
  };

  return (
    <AboutYouContainer>
      <InfoContentWrapper InfoContent={ChildrenQuestionInfo}>
        <Content>
          <FormHeader subHeader="ABOUT YOU" header="Do you have any children?" />
          <StyledForm onSubmit={handleSubmit(onChildrenQuerySubmit)}>
            <StyledInputGroup flexCol>
              <RadioButtonContainer onClick={() => recordWillsProfile('kids', 1)} selected={isYesFieldChecked}>
                <StyledInputField {...register('kids')} radio type="radio" value={1} checked={isYesFieldChecked} />
                <StyledFormLabel>Yes</StyledFormLabel>
              </RadioButtonContainer>
              <RadioButtonContainer onClick={() => recordWillsProfile('kids', 0)} selected={isNoFieldChecked}>
                <StyledInputField {...register('kids')} radio type="radio" value={0} checked={isNoFieldChecked} />
                <StyledFormLabel>No</StyledFormLabel>
              </RadioButtonContainer>
            </StyledInputGroup>
            <HiddenSubmitButton type="submit" />
          </StyledForm>

          <StyledInfoIconWrapper>
            <StyledInfoIconContainer onClick={() => setShowInfoBlurb(true)}>
              <StyledInfoIcon />
            </StyledInfoIconContainer>
          </StyledInfoIconWrapper>

          {showInfoBlurb && (
            <Modal transparent onCloseCallback={() => setShowInfoBlurb(false)}>
              <ChildrenQuestionInfo />
            </Modal>
          )}

          <FormFooter
            previousStep={EAboutYouSteps.MARITAL_STATUS_QUESTION}
            goBack={goBack}
            handleSubmit={handleSubmit}
            onFormSubmit={handleSubmit(onChildrenQuerySubmit)}
            isEdit={isEdit}
          />
        </Content>
      </InfoContentWrapper>
    </AboutYouContainer>
  );
};

export default ChildrenQuestion;
