import React from 'react';
import { useIntakeStepsWithReview } from '@wills/hooks';
import { useScrollToTop } from '@/Shared/Hooks';
import { useStepsReviewNavigation } from '../../hooks/useStepsReviewNavigation';
import {
  BequestIntro,
  ChildGuardians,
  ExecutorAndEstateTrustee,
  Introduction,
  PowerOfAttorneyPersonalCare,
  PowerOfAttorneyProperty,
  Review,
  SpecifyBequest,
} from './pages';
import { StepContext, StepName, steps } from './EstateIntake.steps';
import { EstateIntakeContext, EstateIntakeContextActionType } from './contexts';

export const EstateIntakeIterator: React.FC<{ isReview?: boolean }> = ({ isReview }) => {
  const { state, dispatch } = React.useContext(EstateIntakeContext);
  const { step, nextStep, prevStep, goToStep, setIsReview, history } = useIntakeStepsWithReview<StepContext, StepName>(
    steps,
    state,
  );
  const { goToStepsOrReview } = useStepsReviewNavigation(isReview);

  // TODO: move this to page level, shouldnt be in the iterator
  useScrollToTop([step.name]);

  const setEditMode = (on: boolean) => {
    setIsReview(on);
    // Legacy: used for navigation summary button, need to refactor if we want to remove this state
    dispatch({
      type: EstateIntakeContextActionType.SET_ENABLE_EDITING,
      payload: on,
    });
  };

  const goToReview = () => {
    setEditMode(false);
    goToStep('review');
  };

  const editStep = (name: StepName): undefined | (() => void) => {
    const edit = () => {
      setEditMode(true);
      goToStep(name);
    };
    const historyNames = history.map(({ name: stepName }) => stepName);
    return historyNames.includes(name) ? edit : undefined;
  };

  switch (step.name) {
    case 'introduction':
      return <Introduction onBack={goToStepsOrReview} onNext={nextStep} />;
    case 'executorAndEstateTrustee':
      return <ExecutorAndEstateTrustee onBack={prevStep} onNext={nextStep} onSummary={goToReview} />;
    case 'powerOfAttorneyProperty':
      return <PowerOfAttorneyProperty onBack={prevStep} onNext={nextStep} onSummary={goToReview} />;
    case 'powerOfAttorneyPersonalCare':
      return <PowerOfAttorneyPersonalCare onBack={prevStep} onNext={nextStep} onSummary={goToReview} />;
    case 'childGuardians':
      return <ChildGuardians onBack={prevStep} onNext={nextStep} onSummary={goToReview} />;
    case 'bequestIntro':
      return <BequestIntro onBack={prevStep} onNext={nextStep} onSummary={goToReview} />;
    case 'specifyBequest':
      return <SpecifyBequest onBack={prevStep} onNext={nextStep} onSummary={goToReview} />;
    case 'review':
      return (
        <Review
          goToExecutorAndEstateTrustee={editStep('executorAndEstateTrustee')}
          goToPowerOfAttorneyProperty={editStep('powerOfAttorneyProperty')}
          goToPowerOfAttorneyPersonalCare={editStep('powerOfAttorneyPersonalCare')}
          goToChildGuardians={editStep('childGuardians')}
          goToSpecifyBequest={editStep('specifyBequest')}
          onLoad={() => setEditMode(false)}
          onBack={prevStep}
          onComplete={goToStepsOrReview}
        />
      );
    default:
      return null;
  }
};
