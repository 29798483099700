import React, { useContext } from 'react';
import * as amplitude from '@amplitude/analytics-browser';
import { StyledBenefitsSection } from '@/lib/wills/pages/SelectPlan/Components/PlansBenefits/PlansBenefits.styles';
import { StyledInfo } from '@/lib/wills/pages/SelectPlan/Components/WillsPlans/WillsPlans.styles';
import { getErrorMessage } from '@/Utils';
import { SnackBarContext } from '@/providers';
import { ToggleButton } from '@/Shared/Components/ToggleButton';
import {
  StyledWillsPlanSummaryContainer,
  StyledSummaryContainer,
  StyledSummaryContentWrapper,
  StyledSinglePoint,
  StyledSummaryHeaderWrapper,
  StyledTagContainer,
  StyledTitle,
  StyledButtonContainer,
  StyledButton,
  StyledEditIcon,
  StyledSummaryHeader,
  StyledWillsPlanSummaryWrapper,
  StyledPlanSummaryToggleButtonContainer,
} from './WillsPlanSummary.styles';
import { StyledSmallHeader, StyledHeader } from '../PlansDescription/PlansDescription.styles';
import {
  ESSENTIALS_COVERAGE_BENEFITS,
  ESSENTIALS_PER_WILL_COST,
  PREMIUM_COVERAGE_BENEFITS,
  PREMIUM_PER_WILL_COST,
  WILLS_MEMBERSHIP_COST,
} from './WillsPlanSummarConstants';
import { WillsSelectPlanContext } from '../../WillsSelectPlan.context';
import { completeSelectPlan } from './WillsPlanSummaryApi';
import { SUBSCRIPTION_ITEMS } from '../SubscriptionItems/SubscriptionItems.constants';
import { StyledPlanSummaryCheckIcon, StyledPlanSummaryCrossIcon } from '../ComparePoints/ComparePoints.styles';

const WillsPlanSummary: React.FC<{
  onComplete: () => void;
}> = ({ onComplete }) => {
  const { goToPlansPage, goToSubscriptionPage, plan, subscribed, setSubscribed } = useContext(WillsSelectPlanContext);
  const { setSnackMessage } = useContext(SnackBarContext);
  const COVERAGE_BENEFITS = plan === 'Premium' ? PREMIUM_COVERAGE_BENEFITS : ESSENTIALS_COVERAGE_BENEFITS;
  const planCost = plan === 'Premium' ? PREMIUM_PER_WILL_COST : ESSENTIALS_PER_WILL_COST;
  const membershipCost = WILLS_MEMBERSHIP_COST;

  const confirmPlanSelect = async () => {
    try {
      await completeSelectPlan({
        plan: plan.toUpperCase(),
        isPlanComplete: true,
        isSubscribed: subscribed,
      });
      amplitude.track('Wills Plan Flow Complete');
      onComplete();
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    }
  };

  return (
    <StyledWillsPlanSummaryWrapper data-testid="plan-summary">
      <StyledWillsPlanSummaryContainer>
        <StyledSmallHeader>SELECT A PLAN</StyledSmallHeader>
        <StyledHeader>Great! Review your answers</StyledHeader>

        <StyledSummaryContainer>
          <StyledSummaryHeaderWrapper>
            <StyledTitle>Plan Summary</StyledTitle>
            <StyledTagContainer>Completed</StyledTagContainer>
          </StyledSummaryHeaderWrapper>

          <StyledSummaryContentWrapper>
            <StyledSummaryHeader>
              <StyledTitle>
                {plan}
                &nbsp;Coverage - ${planCost}
              </StyledTitle>
              <StyledButtonContainer>
                <StyledButton gap onClick={goToPlansPage}>
                  Change Plan
                  <StyledEditIcon />
                </StyledButton>
              </StyledButtonContainer>
            </StyledSummaryHeader>

            <StyledBenefitsSection nomargin>
              {COVERAGE_BENEFITS.map((benefit, index) => {
                return (
                  <StyledSinglePoint key={index}>
                    <StyledPlanSummaryCheckIcon />
                    <StyledInfo>{benefit.text}</StyledInfo>
                  </StyledSinglePoint>
                );
              })}
            </StyledBenefitsSection>
          </StyledSummaryContentWrapper>

          <StyledSummaryContentWrapper>
            <StyledSummaryHeader>
              <StyledTitle>Manzil Wills Membership - ${membershipCost}/yr</StyledTitle>
              <StyledPlanSummaryToggleButtonContainer>
                <ToggleButton on={subscribed} onToggle={() => setSubscribed(!subscribed)} />
              </StyledPlanSummaryToggleButtonContainer>
            </StyledSummaryHeader>

            <StyledBenefitsSection nomargin>
              {SUBSCRIPTION_ITEMS.map((item, index) => {
                return (
                  <StyledSinglePoint key={index}>
                    {subscribed ? <StyledPlanSummaryCheckIcon /> : <StyledPlanSummaryCrossIcon />}
                    <StyledInfo>{item}</StyledInfo>
                  </StyledSinglePoint>
                );
              })}
            </StyledBenefitsSection>
          </StyledSummaryContentWrapper>
        </StyledSummaryContainer>

        <StyledButtonContainer justifyBetween>
          <StyledButton onClick={goToSubscriptionPage}>Previous</StyledButton>
          <StyledButton gold onClick={confirmPlanSelect}>
            Looks Good
          </StyledButton>
        </StyledButtonContainer>
      </StyledWillsPlanSummaryContainer>
    </StyledWillsPlanSummaryWrapper>
  );
};

export default WillsPlanSummary;
