import React from 'react';
import { useForm } from 'react-hook-form';
import { ISingleAsset } from '@wills/apis';

import {
  StyledAssetListFormGroup,
  StyledAssetListFormInput,
  StyledAssetListFormLabel,
  StyledAssetListFormName,
  StyledAssetListFormSelect,
  StyledBtn,
  StyledTextArea,
  StyledForm,
  StyledFormBtnContainer,
  StyledFormWrapper,
  StyledErrorMessage,
  StyledExecutorChooserText,
  StyledAssetListRadioButtonContainer,
} from './AssetForm.styles';

import { ASSET_OWNERSHIP, ASSET_TYPE } from './AssetForm.constants';
import { WarningAlert, CurrencyInput } from '@/components';
import { formatCurrency, parseCurrency } from '@/components/CurrencyInput/CurrencyInput.helper';

export type IAssetMutableFields = Omit<ISingleAsset, 'id' | 'assetListId' | 'createdAt' | 'updatedAt'>;

type IAssetFormValues = IAssetMutableFields & {
  ownedPercentagePreset: (typeof ASSET_OWNERSHIP)[number]['label'] | '';
  valueInDollarsFormatted: string;
};

export interface IAssetFormProps {
  defaultValues?: Partial<IAssetMutableFields>;
  onSubmit: (data: IAssetMutableFields) => Promise<void>;
  onCancel: () => void;
}

export const AssetForm: React.FC<IAssetFormProps> = ({ defaultValues, onSubmit, onCancel }) => {
  const assetDetailsRef = React.useRef<HTMLDivElement>(null);

  const getPercentagePreset = (value: number) => {
    if (value === 50) {
      return '50%';
    }
    if (value === 100) {
      return '100%';
    }
    if (value > 0 && value < 100) {
      return 'Other';
    }
    return '';
  };

  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<IAssetFormValues>({
    defaultValues: {
      type: defaultValues?.type || '',
      title: defaultValues?.title || '',
      description: defaultValues?.description || '',
      ownedPercentage: defaultValues?.ownedPercentage ?? 0,
      ownedPercentagePreset: getPercentagePreset(defaultValues?.ownedPercentage ?? 0),
      valueInDollarsFormatted: formatCurrency(defaultValues?.valueInDollars ?? 0),
    },
  });

  const onSubmitHandler = handleSubmit(async (values) => {
    const response: IAssetMutableFields = {
      type: values.type,
      title: values.title,
      description: values.description,
      ownedPercentage: values.ownedPercentage,
      valueInDollars: parseCurrency(values.valueInDollarsFormatted),
    };

    await onSubmit(response);
  });

  return (
    <StyledFormWrapper>
      <StyledAssetListFormName ref={assetDetailsRef}>Asset Details</StyledAssetListFormName>
      <StyledForm data-testid="asset-form" onSubmit={onSubmitHandler}>
        <StyledAssetListFormGroup>
          <StyledAssetListFormLabel htmlFor="type">
            What type of financial asset are you adding?
          </StyledAssetListFormLabel>
          <StyledAssetListFormSelect id="type" {...register('type', { required: 'Required' })}>
            <option value="" disabled defaultChecked>
              Select your option
            </option>
            {ASSET_TYPE.map((assetType) => {
              return (
                <option key={assetType.id} value={assetType.value}>
                  {assetType.value}
                </option>
              );
            })}
          </StyledAssetListFormSelect>
          {errors.type && <StyledErrorMessage>{errors.type.message}</StyledErrorMessage>}
        </StyledAssetListFormGroup>
        <StyledAssetListFormGroup>
          <StyledAssetListFormInput
            id="title"
            placeholder="Title of the asset"
            {...register('title', { required: 'Required' })}
          />
          {errors.title && <StyledErrorMessage>{errors.title.message}</StyledErrorMessage>}
        </StyledAssetListFormGroup>
        <StyledAssetListFormGroup>
          <StyledAssetListFormLabel htmlFor="description">
            Please share the description and details of the asset which might help your Trustee.
          </StyledAssetListFormLabel>
          <StyledTextArea
            id="description"
            placeholder="Description of the asset"
            {...register('description', {
              required: 'Required',
              maxLength: { value: 200, message: 'Asset description cannot be more than 200 characters' },
            })}
          />
          {errors.description && <StyledErrorMessage>{errors.description.message}</StyledErrorMessage>}
        </StyledAssetListFormGroup>
        <WarningAlert>Please don&apos;t share passwords and usernames with anyone</WarningAlert>
        <StyledAssetListFormGroup>
          <StyledAssetListFormLabel>How much of this asset is owned by you?</StyledAssetListFormLabel>
          {ASSET_OWNERSHIP.map(({ label }, i) => (
            <StyledAssetListRadioButtonContainer key={i}>
              <input
                type="radio"
                value={label}
                {...register('ownedPercentagePreset', {
                  validate: {
                    required: (currentValue) => {
                      return !!currentValue || 'Required';
                    },
                  },
                  onChange: (e) => {
                    setValue(
                      'ownedPercentage',
                      ASSET_OWNERSHIP.find((ownership) => ownership.label === e.currentTarget.value)?.value ?? 0,
                    );
                  },
                })}
              />
              <StyledExecutorChooserText>{label}</StyledExecutorChooserText>
            </StyledAssetListRadioButtonContainer>
          ))}
          {errors.ownedPercentagePreset && (
            <StyledErrorMessage>{errors.ownedPercentagePreset.message}</StyledErrorMessage>
          )}
          {watch('ownedPercentagePreset') === 'Other' ? (
            <>
              <StyledAssetListFormInput
                placeholder="Percentage ownership of asset"
                {...register('ownedPercentage', {
                  valueAsNumber: true,
                  validate: {
                    required: (value) => !!value || 'Required',
                    tooSmall: (value) => !(value <= 0) || 'Value cannot be less than 0',
                    tooLarge: (value) => !(value > 100) || 'Value cannot be greater than 100',
                  },
                })}
              />
              {errors.ownedPercentage && <StyledErrorMessage>{errors.ownedPercentage.message}</StyledErrorMessage>}
            </>
          ) : null}
        </StyledAssetListFormGroup>
        <StyledAssetListFormGroup>
          <StyledAssetListFormLabel htmlFor="valueInDollarsFormatted">
            What is the approximate value of this asset?
          </StyledAssetListFormLabel>
          <CurrencyInput
            id="valueInDollarsFormatted"
            placeholder="Value In Dollars"
            {...register('valueInDollarsFormatted', {
              validate: {
                required: (value) => {
                  const parsedValue = parseCurrency(value);
                  return parsedValue === 0 ? 'Required' : true;
                },
              },
            })}
          />
          {errors.valueInDollars && <StyledErrorMessage>{errors.valueInDollars.message}</StyledErrorMessage>}
        </StyledAssetListFormGroup>
        <StyledFormBtnContainer>
          <StyledBtn disabled={isSubmitting} type="submit" save>
            Save
          </StyledBtn>
          <StyledBtn type="button" disabled={isSubmitting} save={false} onClick={() => onCancel()}>
            Cancel
          </StyledBtn>
        </StyledFormBtnContainer>
      </StyledForm>
    </StyledFormWrapper>
  );
};
