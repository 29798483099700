import styled from 'styled-components';
import { FaArrowRight } from 'react-icons/fa';

import { Palette } from '@/Shared/Constants';

export const PromptContainer = styled.div`
  margin-top: 2rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  cursor: pointer;
`;

export const PromptText = styled.p`
  font-family: 'SofiaPro-Bold';
  color: ${Palette.DOVE_GREY};
  font-size: 1.2rem;
`;

export const IconContainer = styled.div<{ disabled?: boolean }>`
  background-color: ${({ disabled }) => (disabled ? Palette.DOVE_GREY : Palette.INDEPENDENCE_BLUE)};
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const ArrowIcon = styled(FaArrowRight)`
  color: ${Palette.WHITE};
  font-size: 1.2rem;
`;
