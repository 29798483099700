import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledNotVerifiedPageWrapper = styled.div`
  background-color: ${Palette.AQUA_HAZE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`;

export const StyledLink = styled.p`
  font-family: 'SofiaPro-Regular';
  color: ${Palette.GUN_POWDER};
  text-decoration: underline;
  text-underline-offset: 0.2rem;
  cursor: pointer;
`;
