import * as amplitude from '@amplitude/analytics-browser';
import { AMPLITUDE_API_KEY } from './config';

export const loadHubspotScript = () => {
  const hubsSpotScript = document.createElement('script');
  hubsSpotScript.type = 'text/javascript';
  hubsSpotScript.id = 'hs-script-loader';
  hubsSpotScript.async = true;
  hubsSpotScript.defer = true;
  hubsSpotScript.src = '//js.hs-scripts.com/6138734.js';
  document.body.appendChild(hubsSpotScript);
};

export const loadGoogleTagManagerScript = () => {
  const tagManagerScriptString = `<script>(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','GTM-TB83L2Q');</script>`;
  document.head.insertAdjacentHTML('afterbegin', tagManagerScriptString);
};

export const loadGoogleTagManagerNoScript = () => {
  const noScript = document.createElement('noscript');
  const iframe = document.createElement('iframe');
  iframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-TB83L2Q';
  iframe.height = '0';
  iframe.width = '0';
  iframe.style.display = 'none';
  iframe.style.visibility = 'hidden';
  noScript.appendChild(iframe);
  document.body.insertAdjacentElement('afterbegin', noScript);
};

export const loadZendeskScript = () => {
  const script = document.createElement('script');
  script.type = 'text/javascript';
  script.id = 'zendesk-script';
  script.text = `
    if(localStorage.getItem('zendeskToken')) {
      zE('messenger', 'loginUser', (callback) => {
        const token = localStorage.getItem('zendeskToken');
        callback(token); 
      })
    } else {
      zE('messenger', 'logoutUser');
    }
  `;
  document.body.appendChild(script);
};

export const removeZendeskScript = () => document.getElementById('zendesk-script')?.remove();

export const initializeAmplitude = () => {
  if (!AMPLITUDE_API_KEY) {
    return;
  }

  amplitude.init(AMPLITUDE_API_KEY, undefined, {
    defaultTracking: true,
  });
};
