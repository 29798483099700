import React from 'react';
import {
  CreateUserProfileInput,
  CreateUserReasonsForJoiningInput,
  createUserProfile as defaultCreateUserProfile,
  createUserReasonsForJoining as defaultCreateReasonsForJoining,
  useCreateUserProfile,
  useCreateUserReasonsForJoining,
} from '@user/apis';
import { observer } from 'mobx-react-lite';
import { ApiResponse } from '@/lib/axios';

import { UserKycBasicForm } from './UserKycBasicForm/UserKycBasicForm';
import { bindLoadingToPromise } from '@/Utils';
import { LoadingOverlay } from '@/Shared/Components';
import { UserKycReasonForm } from './UserKycReasonForm';
import { Page } from '../../components';
import { UserProfile, UserReasonsForJoining } from '../../types';

export interface UserKycProps {
  renderOnComplete: () => JSX.Element;
  createUserProfile?: (data: CreateUserProfileInput) => Promise<ApiResponse<UserProfile>>;
  createUserReasonsForJoining?: (data: CreateUserReasonsForJoiningInput) => Promise<ApiResponse<UserReasonsForJoining>>;
}

export const UserKyc: React.FC<UserKycProps> = observer(
  ({
    renderOnComplete,
    createUserProfile = defaultCreateUserProfile,
    createUserReasonsForJoining = defaultCreateReasonsForJoining,
  }) => {
    const { createUserProfile: createUserProfileWithContext } = useCreateUserProfile({ request: createUserProfile });
    const { createUserReasonsForJoining: createUserReasonsForJoiningWithContext } = useCreateUserReasonsForJoining({
      request: createUserReasonsForJoining,
    });
    const [loading, setLoading] = React.useState<boolean>(false);
    const [index, setIndex] = React.useState<number>(0);

    const handleBasicSubmit = async (data: CreateUserProfileInput) => {
      const { error } = await createUserProfileWithContext(data);
      if (error) {
        throw new Error(error.message);
      }
      setIndex(index + 1);
    };

    const handleReasonSubmit = async (data: CreateUserReasonsForJoiningInput) => {
      const { error } = await createUserReasonsForJoiningWithContext(data);
      if (error) {
        throw new Error(error.message);
      }
      setIndex(index + 1);
    };

    return (
      <Page>
        {loading && <LoadingOverlay type="fixed" />}
        {index === 0 && (
          <UserKycBasicForm onSubmit={(data) => bindLoadingToPromise(handleBasicSubmit(data), setLoading)} />
        )}
        {index === 1 && (
          <UserKycReasonForm
            onBack={() => setIndex(index - 1)}
            onSubmit={(data) => bindLoadingToPromise(handleReasonSubmit(data), setLoading)}
          />
        )}
        {index === 2 && renderOnComplete()}
      </Page>
    );
  },
);
