import styled, { css } from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const pageGap = css`
  gap: 50px;
`;

export const sectionGap = css`
  gap: 30px;
`;

export const PageContainer = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  flex: 1;
  padding: 60px 30px;
  width: 100vw;
  background-color: ${Palette.AQUA_HAZE};
`;

export const PageContent = styled.div`
  display: flex;
  flex-flow: column nowrap;
  min-width: 340px;
  max-width: 1080px;
  ${pageGap}
`;
