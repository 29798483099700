import React from 'react';
import { useForm } from 'react-hook-form';
import { BigButton, Header, SubHeader } from '@/Shared/Components';

import { AuthLink } from '../../../components/AuthLink';
import { Input } from '../../../components/Input';
import { Paragraph } from '../../../components/Paragraph';
import { StyledForgotPasswordLink, StyledLoginContainer, StyledLoginForm } from '../Login.styles';
import { FormMessage } from '../../../components/FormMessage';

export type AuthFormValues = {
  email: string;
  password: string;
};

export interface AuthFormProps {
  onSubmit: (data: AuthFormValues) => Promise<void>;
  localeText: Record<string, string>;
}

export const AuthForm: React.FC<AuthFormProps> = ({ onSubmit, localeText }) => {
  const [message, setMessage] = React.useState<string | null>(null);

  const {
    register,
    handleSubmit,
    formState: { isSubmitting, isValid },
  } = useForm<AuthFormValues>({
    mode: 'onChange',
  });

  const { ref: emailRef, onChange: onEmailChange, onBlur: onEmailBlur } = register('email', { required: true });

  const {
    ref: passwordRef,
    onChange: onPasswordChange,
    onBlur: onPasswordBlur,
  } = register('password', { required: true });

  const onSubmitHandler = handleSubmit(async (data) => {
    try {
      await onSubmit(data);
    } catch (e) {
      setMessage(String(e));
    }
  });

  return (
    <StyledLoginContainer>
      <Header alignCenter>{localeText.header}</Header>
      <SubHeader alignCenter>{localeText.subheader}</SubHeader>
      {!isSubmitting && message && <FormMessage>{message}</FormMessage>}
      <StyledLoginForm onSubmit={onSubmitHandler}>
        <Input
          type="email"
          name="email"
          placeholder={localeText['placeholder.email']}
          inputRef={emailRef}
          onChange={onEmailChange}
          onBlur={onEmailBlur}
        />
        <Input
          type="password"
          name="password"
          placeholder={localeText['placeholder.password']}
          inputRef={passwordRef}
          onChange={onPasswordChange}
          onBlur={onPasswordBlur}
        />
        <StyledForgotPasswordLink>
          <AuthLink to="/forgot-password">{localeText['link.forgotPassword']}</AuthLink>
        </StyledForgotPasswordLink>
        <BigButton disabled={isSubmitting || !isValid} btnText={localeText['button.login']} />
      </StyledLoginForm>
      <Paragraph>
        {localeText['question.no-account']}
        &nbsp;
        <AuthLink bold to="/signup">
          {localeText['link.signup']}
        </AuthLink>
      </Paragraph>
    </StyledLoginContainer>
  );
};
