import styled from 'styled-components';

import { LongInput, ShortInput } from '@/Shared/Shared.styles';

import { LongSelect } from '../Question/Question.styles';

export const StyledAddressFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const StyledAddressForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 35rem;
  margin-top: 1rem;
`;

export const StyledAddressInput = styled(LongInput)`
  @media (max-width: 1024px) {
    min-width: 0;
    width: 24rem;
  }

  @media (max-width: 600px) {
    width: 20rem;
  }
`;

export const StyledAddressShortInput = styled(ShortInput)`
  @media (max-width: 1024px) {
    min-width: 0;
    width: 11.5rem;
  }

  @media (max-width: 600px) {
    width: 9.5rem;
  }
`;

export const StyledAddressLongSelect = styled(LongSelect)`
  width: 100%;
`;

export const StyledAddressShortSelect = styled(LongSelect)`
  min-width: 10rem;
  max-width: 17rem;

  @media (max-width: 1024px) {
    min-width: 0;
    width: 11.5rem;
  }

  @media (max-width: 600px) {
    width: 9.5rem;
  }
`;
