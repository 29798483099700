import styled from 'styled-components';
import { Breakpoints } from '@/Utils/breakpoints';

export const Container = styled.div`
  display: flex;
  flex-flow: row nowrap;
  gap: 1rem;
  justify-content: space-between;
`;

export const MainContentContainer = styled.div`
  flex: 1;

  @media screen and (min-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    flex: 7;
  }
`;

export const InfoContentContainer = styled.div`
  display: none;

  @media screen and (min-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    display: block;
    flex: 3;
  }
`;
