import React from 'react';
import { Container, InfoContentContainer, MainContentContainer } from './InfoContentWrapper.styles';

interface InfoContentWrapperProps {
  children: React.ReactNode;
  InfoContent: React.FC;
}

export const InfoContentWrapper: React.FC<InfoContentWrapperProps> = ({ children, InfoContent }) => {
  return (
    <Container>
      <MainContentContainer>{children}</MainContentContainer>
      <InfoContentContainer>
        <InfoContent />
      </InfoContentContainer>
    </Container>
  );
};
