import React, { useContext, useState } from 'react';
import { SnackBarContext } from '@/providers';
import { getErrorMessage } from '@/Utils';
import { deleteRelationship, upsertRelationships } from '../../../../apis/relationship';

import {
  StyledRelativeDisplayButton,
  StyledRelativeDisplayButtonGroup,
  StyledRelativeDisplayContainer,
  StyledRelativeDisplayEditIcon,
  StyledRelativeDisplayName,
  StyledRelativeDisplayRelation,
  StyledRelativeDisplayRemoveIcon,
  StyledRelativeDisplayEmailContainer,
  StyledRelativeDisplayEmailIcon,
  StyledRelativeDisplayDobIcon,
  StyledRelativeDisplayDobContainer,
  StyledRelativeDisplayEmailDobContainer,
} from './RelativeDisplay.styles';
import { IRelativeDisplayProps } from './RelativeDisplay.types';
import { RelativeAdderForm } from '../RelativeAdderForm';
import { IRelativeInfo } from '../../WillsFamilyFlow.types';
import { RelativeFormAdderValues } from '../RelativeAdderForm/RelativeAdderForm.types';

const RelativeDisplay = ({ relationship, setRelationships, relativeDefinition }: IRelativeDisplayProps) => {
  const { setSnackMessage } = useContext(SnackBarContext);
  const [editMode, setEditMode] = useState(false);

  const onEditRelationship = async (data: RelativeFormAdderValues) => {
    let relationshipsToUpdate: IRelativeInfo[] = [];
    setRelationships((relationships) => {
      const elementIndex = relationships.indexOf(relationship);
      const tempRelations = relationships.slice();
      const updatedData: IRelativeInfo = {
        ...relationship,
        ...data,
      };
      tempRelations.splice(elementIndex, 1, updatedData);
      relationshipsToUpdate = tempRelations;
      return tempRelations;
    });

    try {
      await upsertRelationships(relationshipsToUpdate);
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    }

    setEditMode(false);
  };

  const onCancelEditRelationship = () => {
    setEditMode(false);
  };

  async function removeAddedRelative() {
    try {
      if (typeof relationship.id === 'number') {
        await deleteRelationship(relationship.id);
      }

      setRelationships((relationships) => {
        const relationshipsClone = relationships.slice();
        const elementIndex = relationships.indexOf(relationship);

        relationshipsClone.splice(elementIndex, 1);
        return relationshipsClone;
      });
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    }
  }

  return !editMode ? (
    <StyledRelativeDisplayContainer>
      <StyledRelativeDisplayRelation>{relativeDefinition?.description}</StyledRelativeDisplayRelation>
      <StyledRelativeDisplayName>{`${relationship.firstName} ${relationship.lastName}`}</StyledRelativeDisplayName>
      <StyledRelativeDisplayEmailDobContainer>
        <StyledRelativeDisplayDobContainer>
          <StyledRelativeDisplayDobIcon />
          {relationship.dob}
        </StyledRelativeDisplayDobContainer>
        <StyledRelativeDisplayEmailContainer>
          <StyledRelativeDisplayEmailIcon />
          {relationship.email}
        </StyledRelativeDisplayEmailContainer>
      </StyledRelativeDisplayEmailDobContainer>
      <StyledRelativeDisplayButtonGroup>
        <StyledRelativeDisplayButton onClick={() => setEditMode(true)}>
          <StyledRelativeDisplayEditIcon />
          Edit
        </StyledRelativeDisplayButton>
        <StyledRelativeDisplayButton onClick={() => removeAddedRelative()}>
          <StyledRelativeDisplayRemoveIcon />
          Remove
        </StyledRelativeDisplayButton>
      </StyledRelativeDisplayButtonGroup>
    </StyledRelativeDisplayContainer>
  ) : (
    <RelativeAdderForm
      relativeDefinitionId={relationship.relationDefinitionId}
      relationship={relationship}
      onCancel={onCancelEditRelationship}
      onSubmit={onEditRelationship}
    />
  );
};

export default RelativeDisplay;
