import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { pageTitle, sectionBody, sectionGap } from '@/components';

export const Hero = styled.div`
  display: flex;
  flex-flow: column wrap;

  ${sectionGap}
`;

export const CenteredRow = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
  ${sectionGap}
`;
export const HeroContent = styled.section`
  display: flex;
  flex-flow: column nowrap;
  flex: 1;
  min-width: 340px;
  ${sectionGap}
`;

export const HeroImage = styled.img`
  max-width: 295px;
`;

export const Tagline = styled.p`
  font-family: 'SofiaPro-Bold';
  font-weight: 700;
  size: 1.25rem;
  line-height: 1.4;
  text-transform: uppercase;
  color: ${Palette.SLATE_GREEN};
`;

export const Title = styled.h1`
  ${pageTitle}
  color: ${Palette.NEW_BLUE};
`;

export const Body = styled.p`
  ${sectionBody}
  color: ${Palette.PLANET_BLUE};
`;
