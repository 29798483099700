export const HEADER_BUTTONS = [
    {
        sectionTitle: 'Legal documents',
        sectionId: 'legal-documents'
    },
    {
        sectionTitle: 'Supporting documents',
        sectionId: 'supporting-documents'
    },
    {
        sectionTitle: 'Appointees',
        sectionId: 'appointees'
    }
];