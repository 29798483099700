/* eslint-disable no-console */

import { makeAutoObservable } from 'mobx';
import { createContext } from 'react';

class TokenStore {
  token: string | null = null;

  zendeskToken: string | null = null;

  constructor() {
    makeAutoObservable(this);
    this.getToken();
  }

  getToken = () => {
    this.token = localStorage.getItem('accessToken');
    return this.token;
  };

  setToken = (token: string) => {
    this.token = token;
    localStorage.setItem('accessToken', token);
  };

  setZendeskToken = (token: string) => {
    this.zendeskToken = token;
    localStorage.setItem('zendeskToken', token);
  };

  removeToken = () => {
    this.token = null;
    localStorage.removeItem('accessToken');
  };

  removeZendeskToken = () => {
    this.zendeskToken = null;
    localStorage.removeItem('zendeskToken');
  };
}

export default createContext(new TokenStore());
