import React from 'react';
import {
  StyledModalOverlay,
  StyledModalContainer,
  StyledModalCancelBtn,
  StyledModalContent,
  StyledModalFormContent,
} from './FormModal.styles';
import Form from '../Form/Form';
import { IFormModal } from './FormModal.types';

const FormModal = ({ setIsModalOpen }: IFormModal) => {
  return (
    <StyledModalOverlay>
      <StyledModalContainer>
        <StyledModalContent>
          <StyledModalCancelBtn onClick={() => setIsModalOpen(false)} />
          <StyledModalFormContent>
            <Form setIsModalOpen={setIsModalOpen} />
          </StyledModalFormContent>
        </StyledModalContent>
      </StyledModalContainer>
    </StyledModalOverlay>
  );
};

export default FormModal;
