import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { ErrorBoundary, ErrorBoundaryProps } from '../ErrorBoundary/ErrorBoundary';

export const ErrorBoundaryWithUrlReset: React.FC<ErrorBoundaryProps> = ({ children, renderFallback }) => {
  const location = useLocation();
  const errorBoundaryRef = React.useRef<ErrorBoundary>(null);

  useEffect(() => {
    if (errorBoundaryRef.current) {
      errorBoundaryRef.current.resetError();
    }
  }, [location.pathname]);

  return (
    <ErrorBoundary ref={errorBoundaryRef} renderFallback={renderFallback}>
      {children}
    </ErrorBoundary>
  );
};
