import { css } from 'styled-components';

/**
 * We want to keep font css here so we can determine that our font styling has a standard hierarchy.
 * Currently using `css` rathen than html elements because we can't be certain html elements will always be styled
 * the same way. We can move to html elements once we have alignment but for now we will go down the composition path.
 */

/* TODO: 500 font-size normal style seems to be off center, might need new file */

export const FONT_FAMILY = 'SofiaPro';

const defaultFontStyle = css`
  font-family: ${FONT_FAMILY};
  line-height: 1.4;
`;

export const pageTitle = css`
  ${defaultFontStyle}
  font-size: 3rem;
  font-weight: 500;
`;

export const pageBody = css`
  ${defaultFontStyle}
  font-size: 1.5rem;
  font-weight: 400;
`;

export const sectionTitle = css`
  ${defaultFontStyle}
  font-size: 1.875rem;
  font-weight: 500;
`;

export const sectionBody = css`
  ${defaultFontStyle}
  font-size: 1.125rem;
  font-weight: 400;
`;

export const subSectionTitle = css`
  ${defaultFontStyle}
  font-size: 1.25rem;
  font-weight: 500;
`;

export const subSectionBody = css`
  ${defaultFontStyle}
  font-size: 1rem;
  font-weight: 400;
`;

export const alertBody = subSectionBody;

export const inputLabel = subSectionBody;

export const buttonLabel = subSectionBody;
