import React from 'react';
import { Link } from 'react-router-dom';

import { Palette } from '@/Shared/Constants';

import {
  StyledStepButton,
  StyledStepContainer,
  StyledStepInfoButtonContainer,
  StyledStepInfoContainer,
  StyledStepParagraph,
  StyledStepTitle,
  StyledStepTitleBar,
} from './Step.styles';
import { IStepProps } from './Step.types';

const Step = ({ data, active, complete }: IStepProps) => {
  return (
    <StyledStepContainer active={active}>
      <StyledStepTitleBar>
        <StyledStepTitle active={active}>{data.name}</StyledStepTitle>
        {complete ? (
          <Link to={data.link}>
            <StyledStepButton type="button" color={Palette.GUN_POWDER}>
              Completed
            </StyledStepButton>
          </Link>
        ) : (
          <StyledStepButton type="button" color={active ? Palette.NEW_WHITE : ''}>
            {data.minutes}
          </StyledStepButton>
        )}
      </StyledStepTitleBar>
      {active && (
        <StyledStepInfoContainer>
          <StyledStepParagraph>{data.description}</StyledStepParagraph>
          <StyledStepInfoButtonContainer>
            <Link to={data.link}>
              <StyledStepButton type="button" color={Palette.GOLD_CRAYOLA}>
                Continue
              </StyledStepButton>
            </Link>
          </StyledStepInfoButtonContainer>
        </StyledStepInfoContainer>
      )}
    </StyledStepContainer>
  );
};

export default Step;
