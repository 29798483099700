import { handleDeleteEventOnChange } from './handleDeleteEventOnChange';
import { normalizeDate } from './normalizeDate';

export const normalizeDateOnChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
  const currentValue = e.currentTarget.value;
  const newValue = normalizeDate(e.currentTarget.value);
  if (currentValue !== newValue) {
    e.currentTarget.value = newValue;
    handleDeleteEventOnChange(e);
  }
};