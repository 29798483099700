import {
  updateLiabilityListCompletedTime,
  updateLiability as updateLiabilityApi,
  createLiability as createLiabilityApi,
  deleteLiability as deleteLiabilityApi,
  IUpdateLiabilityInput,
  ICreateLiabilityInput,
  ILiability,
  ILiabilityList,
} from '@wills/apis';
import React from 'react';
import { useErrorHandler } from '@/hooks';
import { DocumentsContext } from '../pages/Documents/Documents.context';

export const useLiabilityList = () => {
  const { liabilityList, setLiabilityList } = React.useContext(DocumentsContext);
  const { handleAsyncError, throwApiResponseError } = useErrorHandler();

  const createLiability = handleAsyncError(async (input: ICreateLiabilityInput) => {
    const response = throwApiResponseError(await createLiabilityApi(input));
    if (response) {
      setLiabilityList({
        ...liabilityList,
        liabilities: [...liabilityList.liabilities, response],
      });
    }
    return response;
  });

  const updateLiability = handleAsyncError(async (id: number, input: IUpdateLiabilityInput): Promise<ILiability> => {
    const response = throwApiResponseError(await updateLiabilityApi(id, input));
    if (response) {
      setLiabilityList({
        ...liabilityList,
        liabilities: liabilityList.liabilities.map((liability) =>
          liability.id === response.id ? response : liability,
        ),
      });
    }
    return response;
  });

  const deleteLiability = handleAsyncError(async (liabilityListId: number, liabilityId: number): Promise<never> => {
    const response = throwApiResponseError(await deleteLiabilityApi(liabilityListId, liabilityId));
    if (response) {
      setLiabilityList({
        ...liabilityList,
        liabilities: liabilityList.liabilities.filter((liability) => liability.id !== liabilityId),
      });
    }
    return response;
  });

  const completeLiabilityList = handleAsyncError(async (): Promise<ILiabilityList> => {
    const response = throwApiResponseError(await updateLiabilityListCompletedTime());
    if (response) {
      setLiabilityList(response);
    }
    return response;
  });

  return {
    liabilityList,
    createLiability,
    updateLiability,
    deleteLiability,
    completeLiabilityList,
  };
};
