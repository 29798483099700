import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StyledWillsStepsPageContainer = styled.div`
  background-color: ${Palette.AQUA_HAZE};
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledWillsStepsContentContainer = styled.div`
  width: min(50%, 1366px);
  margin-inline: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.5rem;

  @media (max-width: 720px) {
    width: 85%;
  }
`;

export const StyledWillsHelpText = styled.h4`
  color: ${Palette.SLATE_GREEN};
  text-transform: uppercase;
  font-family: 'SofiaPro-Regular';
  text-align: center;
  margin-bottom: 1rem;
`;

export const StyledWillsTitle = styled.h1`
  color: ${Palette.GUN_POWDER};
  font-family: 'SofiaPro-Regular';
  text-align: center;
  margin-bottom: 2rem;
`;
