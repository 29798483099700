import React, { ErrorInfo } from 'react';

export interface ErrorBoundaryProps {
  children: React.ReactNode;
  renderFallback: (props: ErrorBoundaryState) => JSX.Element;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  langCode: string;
  message: string;
}

export class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, langCode: 'EN', message: '' };
  }

  static getDerivedStateFromError(err: Error): ErrorBoundaryState {
    return { hasError: true, langCode: 'EN', message: err.message };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // eslint-disable-next-line no-console
    console.error(error, errorInfo.componentStack);
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  resetError = () => {
    this.setState({ hasError: false });
  };

  render() {
    const { hasError } = this.state;
    const { children, renderFallback } = this.props;
    if (hasError) {
      // You can render any custom fallback UI
      return renderFallback(this.state);
    }

    return children;
  }
}
