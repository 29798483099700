import React from 'react';
import { Card, TitleContainer, Title, ClickableIcon, EditIcon, DeleteIcon, ContentContainer } from './DataCard.styles';
import locales from './DataCard.locales.json';

const locale = locales.EN;

export interface IDataCard {
  title: string;
  onEdit?: () => void | Promise<void>;
  onDelete?: () => void | Promise<void>;
  disabled?: boolean | undefined;
  hideActions?: boolean | undefined;
  children: React.ReactNode;
}

export const DataCard: React.FC<IDataCard> = ({ title, onEdit, onDelete, disabled, hideActions, children }) => {
  return (
    <Card>
      <TitleContainer>
        <Title>{title}</Title>
        <ClickableIcon
          aria-label={locale.editAriaLabel}
          aria-disabled={disabled}
          aria-hidden={hideActions}
          disabled={disabled}
          hidden={hideActions}
          role="button"
          onClick={() => onEdit?.()}
        >
          <EditIcon />
        </ClickableIcon>
        <ClickableIcon
          aria-label={locale.deleteAriaLabel}
          // alt={locale.deleteAlt}
          aria-disabled={disabled}
          aria-hidden={hideActions}
          disabled={disabled}
          hidden={onDelete === undefined || hideActions}
          role="button"
          onClick={() => onDelete?.()}
        >
          <DeleteIcon />
        </ClickableIcon>
      </TitleContainer>
      <ContentContainer>{children}</ContentContainer>
    </Card>
  );
};
