import styled, { css } from 'styled-components';
import { IoMdArrowRoundDown } from "react-icons/io";


export const buttonStyle = css`
    color: #61927A;
    font-size: 24px;
`;

export const StyledHeaderWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    gap: 1.5rem;
`;

export const StyledSingleHeaderContainer = styled.div`
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
`;

export const StyledDownArrow = styled(IoMdArrowRoundDown)`
    ${buttonStyle}
`;

export const StyledSectionNameLink = styled.a`
    ${buttonStyle}
    font-family: 'SofiaPro-Regular';
    text-decoration: none;
    cursor: pointer;
`;
