import { axiosClient } from '@/lib/axios';
import { IUserContact, IUpdateContact } from '@/Types';

export const getUserContact = async () => {
  const response = await axiosClient.get('/contact');

  return response.data;
};

export const putUserContact = async (contact: IUpdateContact) => {
  const response = await axiosClient.put('/contact', contact);

  return response.data;
};

export const postUserContact = async (contact: IUserContact) => {
  const response = await axiosClient.post('/contact', contact);

  return response.data;
};
