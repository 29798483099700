import React, { useContext } from 'react';

import { LocaleContext } from '@/providers';

import {
  BackToProfileContainer,
  ContentHeading,
  FormContent,
  GoBackButton,
  ProfileContainer,
  ProfileForm,
} from '../../Profile.style';
import { MFAFormField } from '../MFAFormField';
import { ISecurityFormProps } from './SecurityForm.types';
import securityFormLocales from './securityFormLocales.json';
import { EDataTestId } from '@/test-utils/EDataTestId';

const SecurityForm = ({ isEditMode, setIsEditMode }: ISecurityFormProps) => {
  const { locale } = useContext(LocaleContext);

  const securityFormLocale = securityFormLocales[locale];

  return (
    <ProfileContainer data-testid={EDataTestId.USER_SECURITY_FORM}>
      <ProfileForm>
        <FormContent>
          <ContentHeading>{securityFormLocale['card.heading']}</ContentHeading>
          <ContentHeading>
            <BackToProfileContainer show={isEditMode}>
              <GoBackButton onClick={() => setIsEditMode(false)} title="exit" aria-label="exit" />
            </BackToProfileContainer>
          </ContentHeading>

          <MFAFormField setIsEditMode={setIsEditMode} />
        </FormContent>
      </ProfileForm>
    </ProfileContainer>
  );
};

export default SecurityForm;
