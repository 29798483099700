import React, { createContext, useEffect, useState } from 'react';

export interface ILocaleContext {
  locale: 'EN' | 'FR';
  setLocale: React.Dispatch<React.SetStateAction<'EN' | 'FR'>>;
}

export const LocaleContext = createContext({} as ILocaleContext);

interface IProps {
  children: React.ReactNode;
}

export const LocaleProvider: React.FC<IProps> = ({ children }) => {
  const savedLocale = localStorage.getItem('locale') as 'EN' | 'FR';

  const [locale, setLocale] = useState<'EN' | 'FR'>(savedLocale || 'EN');

  const value = { locale, setLocale };

  useEffect(() => {
    localStorage.setItem('locale', locale);
  }, [locale]);

  return <LocaleContext.Provider value={value}>{children}</LocaleContext.Provider>;
};
