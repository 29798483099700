import React, { useEffect, useReducer } from 'react';

import { IDocumentChecklist, IUpdateDocumentChecklistInput } from '@wills/apis';
import { DocumentsContext } from '../../Documents.context';

export type DocumentsChecklistSteps = 'PAYMENT' | 'LEGAL_DOCUMENTS' | 'SUPPORTING_DOCUMENTS' | 'SIGN' | 'STORE';

type ActionableStep = Exclude<DocumentsChecklistSteps, 'PAYMENT' | 'COMPLETE'>;

const documentsChecklistSteps: DocumentsChecklistSteps[] = [
  'PAYMENT', // including for completion, but we always skip this
  'LEGAL_DOCUMENTS',
  'SUPPORTING_DOCUMENTS',
  'SIGN',
  'STORE',
];

type TDocumentChecklistState = {
  completedSteps: DocumentsChecklistSteps[];
  currentStep: DocumentsChecklistSteps | null;
};

type TReducerAction =
  | {
      type: TDocumentChecklistAction.SET_STEP;
      payload: DocumentsChecklistSteps | null;
    }
  | {
      type: TDocumentChecklistAction.SET_COMPLETED_STEPS;
      payload: DocumentsChecklistSteps[];
    };

enum TDocumentChecklistAction {
  SET_STEP = 'SET_STEP',
  SET_COMPLETED_STEPS = 'SET_COMPLETED_STEPS',
}

const StepToKeyMap: Record<ActionableStep, [keyof IUpdateDocumentChecklistInput, keyof IDocumentChecklist]> = {
  LEGAL_DOCUMENTS: ['isReviewDocumentsCompleted', 'reviewDocumentsCompletedAt'],
  SUPPORTING_DOCUMENTS: ['isSupportingDocumentsCompleted', 'supportingDocumentsCompletedAt'],
  SIGN: ['isSignDocumentsCompleted', 'signDocumentsCompletedAt'],
  STORE: ['isStoreDocumentsCompleted', 'storeDocumentsCompletedAt'],
};

const INITIAL_STATE: TDocumentChecklistState = {
  completedSteps: ['PAYMENT'],
  currentStep: 'PAYMENT',
};

const documentChecklistReducer = (
  state: TDocumentChecklistState,
  { type, payload }: TReducerAction,
): TDocumentChecklistState => {
  switch (type) {
    case TDocumentChecklistAction.SET_STEP:
      return {
        ...state,
        currentStep: payload,
      };
    case TDocumentChecklistAction.SET_COMPLETED_STEPS:
      return {
        ...state,
        completedSteps: payload,
      };
    default:
      return state;
  }
};

export const useDocumentsChecklist = () => {
  const { documentChecklist, updateDocumentChecklist } = React.useContext(DocumentsContext);
  const [{ currentStep, completedSteps }, dispatch] = useReducer(documentChecklistReducer, INITIAL_STATE);

  const populateAndGetCompletedSteps = (): DocumentsChecklistSteps[] => {
    const completed: DocumentsChecklistSteps[] = [...completedSteps];

    const stepKeys = Object.keys(StepToKeyMap) as ActionableStep[];

    for (let i = 0; i < stepKeys.length; i += 1) {
      const stepKey = stepKeys[i];
      const completedAt = StepToKeyMap[stepKey][1];

      if (documentChecklist[completedAt]) {
        completed.push(stepKey);
      }
    }

    dispatch({ type: TDocumentChecklistAction.SET_COMPLETED_STEPS, payload: completed });

    return completed;
  };

  const expandIncompleteStep = () => {
    const completed = populateAndGetCompletedSteps();
    const lastCompletedStep = documentsChecklistSteps[completed.length - 1];
    const stepIndex = documentsChecklistSteps.findIndex((s) => s === lastCompletedStep);
    const nextStepIndex = stepIndex + 1;

    if (nextStepIndex <= documentsChecklistSteps.length) {
      dispatch({ type: TDocumentChecklistAction.SET_STEP, payload: documentsChecklistSteps[nextStepIndex] });
    } else {
      dispatch({ type: TDocumentChecklistAction.SET_STEP, payload: null });
    }
  };

  const setCurrentStep = (step: DocumentsChecklistSteps) => {
    dispatch({ type: TDocumentChecklistAction.SET_STEP, payload: step });
  };

  const completeStep = async (step: ActionableStep) => {
    const key = StepToKeyMap[step][0];
    await updateDocumentChecklist({
      [key]: true,
    });
    const stepIndex = documentsChecklistSteps.findIndex((s) => s === step);
    const nextStepIndex = stepIndex + 1;

    if (nextStepIndex <= documentsChecklistSteps.length - 1) {
      const nextStep = documentsChecklistSteps[nextStepIndex];
      dispatch({ type: TDocumentChecklistAction.SET_STEP, payload: nextStep });
    }
  };

  useEffect(() => {
    expandIncompleteStep();
  }, [documentChecklist]);

  return { currentStep, completedSteps, completeStep, setCurrentStep };
};
