import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const MyWishesContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 370px;
  gap: 30px;
`;

export const Title = styled.h3`
  font-family: 'SofiaPro-Regular';
  font-size: 36px;
  font-weight: 500;
  line-height: 46.8px;
  color: ${Palette.NEW_BLUE};
`;
