import styled from 'styled-components';
import { PiSealCheckFill } from 'react-icons/pi';
import { Palette } from '@/Shared/Constants';

export const StyledPaymentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-grow: 1;
  width: 100%;
  background-color: ${Palette.AQUA_HAZE};
`;

export const StyledPaymentContent = styled.div`
  width: 40%;
  max-width: 50rem;
  margin-top: 4rem;

  @media (max-width: 720px) {
    width: 90%;
  }
`;

export const StyledPaymentCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1.5rem;
`;

export const StyledPaymentSummaryHeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const StyledPaymentSummaryHeader = styled.h1`
  color: ${Palette.NEW_BLUE};
  font-family: 'SofiaPro-Regular';
`;

export const StyledPaymentSummarySubtitle = styled.h3`
  color: ${Palette.NEW_BLUE};
  font-family: 'SofiaPro-Regular';
  font-size: 1.4rem;
`;

export const StyledPaymentSummaryParagraph = styled.p`
  color: ${Palette.CADET_BLUE_DARKER};
  font-family: 'SofiaPro-Regular';
  font-size: clamp(0.9rem, 1vw, 1rem);
  margin: 1rem 0;
`;

export const StyledPaymentIconContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const StyledPaymentSuccessIcon = styled(PiSealCheckFill)`
  width: 6rem;
  height: 6rem;
  color: ${Palette.SLATE_GREEN};
`;

export const StyledPaymentConfirmedSection = styled.div`
  margin-top: 1rem;
  margin-bottom: 5rem;

  @media (max-width: 720px) {
    margin-top: 0;
    margin-bottom: 3rem;
  }
`;
