import { dateValidityCheck } from '../../helpers/dateValidityCheck';
import profileInfoFormLocales from './profileInformationFormLocales.json';

export function validateDob(dob: string, locale: 'EN' | 'FR' = 'EN') {
  const validationLocales = profileInfoFormLocales[locale];
  if (dateValidityCheck(dob) !== 'Valid Date') {
    if (dateValidityCheck(dob) === 'Invalid Year') {
      throw new Error(validationLocales['message.error.date.year']);
    }
    if (dateValidityCheck(dob) === 'Invalid Month') {
      throw new Error(validationLocales['message.error.date.month']);
    }
    if (dateValidityCheck(dob) === 'Invalid Day') {
      throw new Error(validationLocales['message.error.date.day']);
    }
    throw new Error(validationLocales['message.error.date.date-format']);
  }
}
