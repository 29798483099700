export const ONBOARDING_NOTES = [
  {
    id: 1,
    value: 'You’re looking for advice on your estate',
    label: 'You’re looking for advice on your estate',
  },
  {
    id: 2,
    value: 'You’re separated but not divorced',
    label: 'You’re separated but not divorced',
  },
  {
    id: 3,
    value: 'You have a blended family',
    label: 'You have a blended family',
  },
  {
    id: 4,
    value: 'You want to create a Henson trust',
    label: 'You want to create a Henson trust',
  },
  {
    id: 5,
    value: 'You want to create dual wills',
    label: 'You want to create dual wills',
  },
  {
    id: 6,
    value: 'You want to create a will for someone else',
    label: 'You want to create a will for someone else',
  },
  {
    id: 7,
    value: 'You want to customize allocation of your estate',
    label: 'You want to customize allocation of your estate',
  },
  {
    id: 8,
    value: 'You reside outside of Ontario',
    label: 'You reside outside of Ontario',
  },
  {
    id: 9,
    value: 'None of the above',
    label: 'None of the above',
  },
];
