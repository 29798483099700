import styled from 'styled-components';
import { BsPlus, BsFillArrowDownCircleFill } from 'react-icons/bs';

import { Palette } from '@/Shared/Constants';
import { Breakpoints } from '@/Utils/breakpoints';

export const StyledRelativeSelectorDropdown = styled.div<{
  top?: string;
  right?: string;
  bottom?: string;
  left?: string;
}>`
  position: absolute;
  min-width: 15rem;
  background-color: ${Palette.NEW_WHITE};
  border-radius: 0.5rem;
  border: 1px solid ${Palette.LIGHT_GREY};
  box-shadow: 0.5rem 0.3rem 1rem ${Palette.LIGHT_GREY};
  max-height: 25rem;
  overflow-y: scroll;
  z-index: 1;
  ${({ top }) => top && `top: ${top};`}
  ${({ right }) => right && `right: ${right};`}
  ${({ bottom }) => bottom && `bottom: ${bottom};`}
  ${({ left }) => left && `left: ${left};`}
`;

export const StyledRelativeSelectorDropdownItem = styled.div`
  padding: 0.5rem 1rem;
  font-size: clamp(0.9rem, 1vw, 1rem);
  font-family: 'SofiaPro-Regular';
  color: ${Palette.GUN_POWDER};
  border-bottom: 1px solid ${Palette.LIGHT_GREY};
  cursor: pointer;
`;

export const StyledPlusIcon = styled(BsPlus)`
  font-size: 1.2rem;
`;

export const StyledDownArrow = styled(BsFillArrowDownCircleFill)`
  position: sticky;
  bottom: 4rem;
  left: 12rem;
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
  pointer-events: all;

  @media screen and (min-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    bottom: 2rem;
  }
`;
