import styled from 'styled-components';
import { RiEyeCloseLine } from 'react-icons/ri';
import { MdOutlineRemoveRedEye } from 'react-icons/md';

import { Palette } from '@/Shared/Constants';

export const StyledInputWrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: 1.5rem;
`;

export const StyledInputField = styled.input`
  width: 100%;
  padding: 1.7rem 1.5rem;
  border-radius: 1rem;
  border: 1px solid ${Palette.AQUA_HAZE};
  background-color: ${Palette.AQUA_HAZE};
  font-family: 'SofiaPro-Bold';
  font-size: 1rem;
  color: ${Palette.NEW_BLUE};
  transition: border 0.5s;
  box-shadow: 0px 16px 40px rgba(0, 0, 0, 0.05);

  &:focus {
    outline: none;
    border: 1px solid ${Palette.NEW_BLUE};
    transition: border 0.5s;
  }
`;

export const StyledIconContainer = styled.div`
  position: absolute;
  right: 1.5rem;
  background-color: transparent;
  cursor: pointer;
`;

export const StyledClosedEyeIcon = styled(RiEyeCloseLine)`
  font-size: 1.5rem;
`;

export const StyledOpenEyeIcon = styled(MdOutlineRemoveRedEye)`
  font-size: 1.5rem;
`;
