export const CANADIAN_AREA_CODES = [
  '403',
  '587',
  '780',
  '825',
  '250',
  '604',
  '236',
  '778',
  '204',
  '431',
  '506',
  '709',
  '867',
  '782',
  '902',
  '867',
  '365',
  '548',
  '705',
  '226',
  '289',
  '613',
  '807',
  '437',
  '905',
  '249',
  '343',
  '416',
  '519',
  '647',
  '902',
  '782',
  '418',
  '450',
  '873',
  '367',
  '579',
  '514',
  '581',
  '819',
  '438',
  '306',
  '639',
  '867',
  '672',
];
