import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';
import { StyledIconContainer } from '../PlansBenefits/PlansBenefits.styles';

export const StyledComparePlansWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  gap: 2px;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    width: 60%;
    margin-left: auto;
    margin-right: auto;
  }
`;

export const StyledCompareTopicsContainer = styled.div<{ type?: string }>`
  padding: 1.5rem 0;
  display: grid;
  margin: 0.5rem 0;
  place-items: center;

  ${({ type }) =>
    type === 'title' &&
    `
    width: 50%;
    background-color: #FCF5E9;
    color: ${Palette.NEW_BLUE};
    font-family: 'SofiaPro-Regular';
    font-weight: 600;
    font-size: 1.2rem;
    padding-left: 0.2rem;
    
    @media screen and (min-width: 768px) {
        place-items:
        padding: 2rem;
    }
    `}

  ${({ type }) =>
    type === 'essentials' &&
    `
        width: 20%;
        background-color: ${Palette.DRIED_LAVENDER};
        color: ${Palette.NEW_BLUE};
        font-family: 'SofiaPro-Regular';
        font-weight: 500;
        font-size: 0.9rem;
        text-align: center;`}

${({ type }) =>
    type === 'premium' &&
    `
        width: 20%;
        background-color: ${Palette.SLATE_GREEN};
        color: ${Palette.NEW_WHITE};
        font-family: 'SofiaPro-Regular';
        font-weight: 500;
        font-size: 0.9rem;
        text-align: center;`}
`;

export const StyledComparePlansContentContainer = styled.div<{ type?: string }>`
  padding: 1.5rem 0.5rem;
  gap: 0.5rem;
  margin: 0.5rem 0;
  place-items: center;

  ${({ type }) =>
    type === 'title' &&
    `
    width: 50%;
    background-color: #FEFAF4;
    color: ${Palette.NEW_BLUE};
    font-family: 'SofiaPro-Regular';
    font-weight: 600;
    font-size: 1.2rem;
    padding-left: 0.3rem;
    color: ${Palette.PLANET_BLUE};
    
    @media screen and (min-width: 768px) {
        padding: 2rem;
    }

    `}

  ${({ type }) =>
    type === 'essentials' &&
    `
        width: 20%;
        background-color: #EAEAEC;
        color: ${Palette.NEW_BLUE};
        font-family: 'SofiaPro-Regular';
        font-weight: 500;
        display: grid;
        place-items: center;
        font-size: 0.9rem;
        text-align: center;
        color: #555969;`}

${({ type }) =>
    type === 'premium' &&
    `
        width: 20%;
        background-color: ${Palette.SLATE_GREEN};
        color: ${Palette.NEW_WHITE};
        font-family: 'SofiaPro-Regular';
        font-weight: 500;
        display: grid;
        place-items: center;
        font-size: 0.9rem;
        text-align: center;
        color: #555969;
        background-color: #DFE9E4;`}
`;

export const StyledIconWrapper = styled(StyledIconContainer)`
  background-color: ${({ show }) => (show === true ? `${Palette.SLATE_GREEN}` : `${Palette.MELON}`)};
  visibility: ${({ show }) => show === 'None' && 'hidden'};
`;
