import { useContext, useEffect, useState } from 'react';
import { SnackBarContext } from '@/providers';
import { IPaymentInfo } from '../../types';
import { getPaymentInfo } from './usePaymentInfo.api';

const usePaymentInfo = () => {
  const { setSnackMessage } = useContext(SnackBarContext);
  const [paymentInfo, setPaymentInfo] = useState<IPaymentInfo | null>(null);

  async function loadPaymentInfo() {
    const { data, error } = await getPaymentInfo();

    if (error) {
      if (error.code !== 'PMNotFound') {
        setSnackMessage(error.message);
      }
    }

    if (data) {
      setPaymentInfo(data);
    }
  }

  useEffect(() => {
    loadPaymentInfo();
  }, []);

  return { paymentInfo };
};

export default usePaymentInfo;
