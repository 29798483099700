export const ESSENTIALS_PER_WILL_COST = 99;
export const PREMIUM_PER_WILL_COST = 149;
export const WILLS_MEMBERSHIP_COST = 20;

export const PREMIUM_COVERAGE_BENEFITS = [
  {
    id: 1,
    text: 'Legal, Islamic Will',
  },
  {
    id: 2,
    text: 'Create legal Power of Attorney documents',
  },
  {
    id: 3,
    text: 'Plan for your assets',
  },
];

export const ESSENTIALS_COVERAGE_BENEFITS = [
  {
    id: 1,
    text: 'Legal, Islamic Will',
  },
  {
    id: 2,
    text: 'Plan for your assets',
  },
];
