import React, { useContext } from 'react';

import { LocaleContext } from '@/providers';

import { ExternalLink } from './SignupFooter.styles';
import { Paragraph } from '../../../components';

import authContainerLocales from './SignupFooter.locales.json';

export const SignupFooter: React.FC = () => {
  const { locale } = useContext(LocaleContext);
  const authLocale = authContainerLocales[locale];
  return (
    <Paragraph>
      {authLocale['text.terms-text-part-one']}
      &nbsp;
      <ExternalLink target="__blank" href="https://manzil.ca/terms-conditions">
        {authLocale['link.terms-of-use']}
      </ExternalLink>
      &nbsp;
      {authLocale['text.terms-text-part-two']}
      &nbsp;
      <ExternalLink target="__blank" href="https://manzil.ca/privacy-policy">
        {authLocale['link.privacy-policy']}
      </ExternalLink>
      {locale === 'FR' ? ` ${authContainerLocales.FR['text.terms-text-part-three']}` : null}
    </Paragraph>
  );
};
