import React, { ChangeEvent, useContext, useEffect, useRef, KeyboardEvent, useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  StyledForm,
  StyledFormFieldContainer,
  StyledFormLabel,
  StyledInputField,
  StyledInputGroup,
  EAboutYouSteps,
  HiddenSubmitButton,
  WillsProfileContext,
} from '@wills/components';
import { StyledSelect } from './LocationQuestion.styles';
import { AboutYouContainer, FormHeader, FormFooter, IWillsProps } from '../../shared';
import { IUserAddress } from '@/Types';
import { PROVINCE_DATA } from '@/Shared/Constants/provinceData';
import { AddressStore } from '@/Stores';
import { Overlay, Modal } from '@/Shared/Components';

const setAddressValues = (address: IUserAddress) => {
  return {
    streetName: address.streetName,
    streetNumber: address.streetNumber,
    unitSuiteApt: address.unitSuiteApt,
    city: address.city,
    provinceState: address.provinceState,
    postalZip: address.postalZip,
    country: address.country,
  };
};

const LocationQuestion = ({ goBack, isEdit }: IWillsProps) => {
  const {
    setAddressData,
    addressData,
    createWillsUserAddress,
    isOnboarding,
    updateUserAddress,
    createUserAddress,
    goToStep,
  } = useContext(WillsProfileContext);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const streetNameInputRef = useRef<HTMLInputElement | null>(null);
  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<IUserAddress>();

  const { setAddress } = useContext(AddressStore);

  const { name } = register('streetName');
  const onLocationSubmit = async () => {
    const data = {
      streetName: addressData.streetName,
      streetNumber: addressData.streetNumber,
      unitSuiteApt: addressData.unitSuiteApt,
      city: addressData.city,
      provinceState: addressData.provinceState,
      postalZip: addressData.postalZip,
      country: addressData.country,
    };

    if (data.provinceState !== 'ON' || data.country !== 'CA') {
      setIsModalOpen(true);
      return;
    }
    setAddress(data);
    createWillsUserAddress(data);

    if (!isOnboarding) {
      updateUserAddress(data);
    } else {
      createUserAddress(data);
    }

    goToStep(EAboutYouSteps.ABOUT_YOU_SUMMARY);
  };

  const onStreetNameChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
    setValue('streetName', event.target.value);

    streetNameInputRef.current!.value = event.target.value;

    setAddressData((currentAddress) => ({
      ...currentAddress,
      streetName: event.target.value,
    }));
  };

  const onTabPressInLastInput = (event: KeyboardEvent<HTMLSelectElement>) => {
    if (event.key === 'Tab') {
      event.preventDefault();
      streetNameInputRef.current?.focus();
    }
  };

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    setAddressData((currentAddress) => ({
      ...currentAddress,
      [e.target.name]: e.target.value,
    }));
  };

  useEffect(() => {
    reset(setAddressValues(addressData));
    streetNameInputRef?.current?.focus();
    streetNameInputRef.current!.value = addressData.streetName;
  }, []);

  useEffect(() => {
    if (streetNameInputRef.current?.value === '') streetNameInputRef.current?.focus();
  }, [errors.streetName]);

  const readableProvince = PROVINCE_DATA.find(({ value }) => addressData.provinceState === value)?.name.EN || '';
  return (
    <>
      {isModalOpen ? (
        <Overlay type="fixed" onBackgroundClick={() => setIsModalOpen(false)}>
          <Modal onCloseCallback={() => setIsModalOpen(false)}>
            <StyledFormLabel>
              For now, our wills are only available for Ontario residents; when we launch in {readableProvince}, we will
              notify you Inshā Allāh. Jazakum Allahu Khayran.
            </StyledFormLabel>
          </Modal>
        </Overlay>
      ) : null}
      <AboutYouContainer>
        <FormHeader subHeader="ABOUT YOU" header="What is your address?" />
        <StyledForm onSubmit={handleSubmit(onLocationSubmit)}>
          <StyledFormFieldContainer widthFull>
            <StyledFormLabel>Street Name</StyledFormLabel>
            <StyledInputField
              ref={streetNameInputRef}
              name={name}
              onChange={onStreetNameChangeHandler}
              error={!!errors.streetName}
              widthFull
              maxLength={120}
            />
          </StyledFormFieldContainer>
          <StyledInputGroup flexCol>
            <StyledFormFieldContainer>
              <StyledFormLabel>Street No.</StyledFormLabel>
              <StyledInputField
                {...register('streetNumber', { required: true, onChange })}
                error={!!errors.streetNumber}
                maxLength={10}
              />
            </StyledFormFieldContainer>
            <StyledFormFieldContainer>
              <StyledFormLabel>Unit/Suite/Apt No.</StyledFormLabel>
              <StyledInputField {...register('unitSuiteApt', { onChange })} maxLength={10} />
            </StyledFormFieldContainer>
          </StyledInputGroup>
          <StyledFormFieldContainer widthFull>
            <StyledFormLabel>City</StyledFormLabel>
            <StyledInputField
              {...register('city', { required: true, onChange })}
              error={!!errors.city}
              widthFull
              maxLength={50}
            />
          </StyledFormFieldContainer>
          <StyledInputGroup flexCol>
            <StyledFormFieldContainer>
              <StyledFormLabel>Province</StyledFormLabel>
              <StyledSelect {...register('provinceState', { required: true, onChange })}>
                {PROVINCE_DATA.map((province) => (
                  <option key={province.id} value={province.value}>
                    {province.name.EN}
                  </option>
                ))}
              </StyledSelect>
            </StyledFormFieldContainer>
            <StyledFormFieldContainer>
              <StyledFormLabel>Postal/Zip Code.</StyledFormLabel>
              <StyledInputField
                {...register('postalZip', { required: true, onChange })}
                error={!!errors.postalZip}
                maxLength={10}
              />
            </StyledFormFieldContainer>
          </StyledInputGroup>
          <StyledFormFieldContainer widthFull>
            <StyledFormLabel>Country</StyledFormLabel>
            <StyledSelect
              {...register('country', { required: true })}
              onKeyDown={(event) => onTabPressInLastInput(event)}
              widthFull
            >
              <option value="CA">Canada</option>
            </StyledSelect>
          </StyledFormFieldContainer>
          <HiddenSubmitButton type="submit" />
        </StyledForm>
        <FormFooter
          previousStep={EAboutYouSteps.MADHAB_QUESTION}
          goBack={goBack}
          handleSubmit={handleSubmit}
          onFormSubmit={handleSubmit(onLocationSubmit)}
          isEdit={isEdit}
        />
      </AboutYouContainer>
    </>
  );
};

export default LocationQuestion;
