import styled from 'styled-components';
import { BiEditAlt } from 'react-icons/bi';

import { Palette } from '@/Shared/Constants';

export const StyledReviewRow = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.2rem 0;
  border-bottom: 1px solid ${Palette.LIGHT_GREY};

  &:first-child,
  &:last-child {
    border: none;
  }
`;

export const StyledReviewText = styled.p`
  font-family: 'SofiaPro-Regular';
  color: ${Palette.GUN_POWDER};
  font-size: clamp(1rem, 2vw, 1.2rem);
`;

export const StyledReviewGreySpan = styled.span`
  color: ${Palette.CADET_BLUE_DARKER};
  text-transform: capitalize;
`;

export const StyledReviewRowEditButton = styled.button`
  color: black;
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 1rem;
  font-family: 'SofiaPro-Regular';
`;

export const StyledReviewRowEditIcon = styled(BiEditAlt)`
  color: ${Palette.CADET_BLUE_DARKER};
  font-size: 1.2rem;
`;
