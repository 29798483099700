import { axiosClient, toApiResponse } from '@/lib/axios';
import { Relationship, RelationshipCategory } from './types';
import { snakeToCamelParser } from '@/Utils';

export const getRelationships = async (category: RelationshipCategory = 'primary', min_age: number = 0) => {
  const response = await toApiResponse<Relationship[]>(
    axiosClient.get(`/wills-relationships?category=${category}&min_age=${min_age}`),
  );
  response.data = response.data?.map((relationship) => snakeToCamelParser(relationship));
  return response;
};
