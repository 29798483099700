import { ESubscriptionStatus } from '@/lib/payment/types';
import { IWillsProfile } from '@/lib/wills/types';

export function isSubscriptionActive(willsProfile: IWillsProfile) {
  return willsProfile.subscriptionStatus === ESubscriptionStatus.SUBSCRIBED;
}

export function isFreeMode(willsProfile: IWillsProfile) {
  return willsProfile.subscriptionStatus === ESubscriptionStatus.FREE;
}

export function isTrialPaid(willsProfile: IWillsProfile) {
  return willsProfile.subscriptionStatus === ESubscriptionStatus.TRIAL_PAID;
}

export function isTrialUnpaid(willsProfile: IWillsProfile) {
  return willsProfile.subscriptionStatus === ESubscriptionStatus.TRIAL_UNPAID;
}

export function isTrialExpired(willsProfile: IWillsProfile) {
  return willsProfile.subscriptionStatus === ESubscriptionStatus.TRIAL_EXPIRED;
}

export function isPendingExpiration(willsProfile: IWillsProfile) {
  return willsProfile.subscriptionStatus === ESubscriptionStatus.PENDING_EXPIRATION;
}

export function isSubscriptionExpired(willsProfile: IWillsProfile) {
  return willsProfile.subscriptionStatus === ESubscriptionStatus.EXPIRED;
}

export function checkIfAllowedToSubscribe(willsProfile: IWillsProfile) {
  return (
    isTrialUnpaid(willsProfile) ||
    isFreeMode(willsProfile) ||
    isTrialExpired(willsProfile) ||
    isPendingExpiration(willsProfile)
  );
}

export function checkIfAllowedToUnsubscribe(willsProfile: IWillsProfile) {
  return isSubscriptionActive(willsProfile) || isTrialPaid(willsProfile) || isSubscriptionExpired(willsProfile);
}

export function updateCacheAfterUnsubscribe(willsProfile: IWillsProfile): IWillsProfile {
  let subscriptionStatus;
  if (isTrialPaid(willsProfile)) {
    subscriptionStatus = ESubscriptionStatus.TRIAL_UNPAID;
  } else if (isSubscriptionActive(willsProfile)) {
    subscriptionStatus = ESubscriptionStatus.PENDING_EXPIRATION;
  }

  return {
    ...willsProfile,
    isSubscribed: false,
    subscriptionStatus,
  };
}
