import { IBequest, IRelationshipDefinition, PartialUserProfile } from '@wills/apis';
import { IWillsProfile } from '@/lib/wills';
import { IRelativeResponse } from '../../../WillsFamilyFlow/WillsFamilyFlow.types';
import { IUserAddress } from '@/Types';

interface IAllBequestsInfo {
  totalBequestAllocation: number;
  bequests: IBequest[];
}

interface ISingleBequestInfo {
  totalBequestAllocation: number;
  bequest: IBequest;
}

export enum EstateIntakeContextActionType {
  SET_RELATIVES,
  SET_RELATIVE_DEFINITIONS,
  ADD_TO_RELATIVES,
  SET_POA_AVAILABILITY,
  SET_MAIN_EXECUTOR,
  SET_BACKUP_EXECUTOR,
  SET_SPOUSE_EXECUTOR,
  SET_MAIN_POA_PROPERTY,
  SET_BACKUP_POA_PROPERTY,
  SET_MAIN_POA_PERSONAL_CARE,
  SET_BACKUP_POA_PERSONAL_CARE,
  SET_SPOUSE_POA_PROPERTY,
  SET_SPOUSE_POA_PERSONAL_CARE,
  SET_MAIN_GUARDIAN,
  SET_BACKUP_GUARDIAN,
  SET_ENABLE_EDITING,
  ADD_TO_BEQUESTS,
  SET_ALL_BEQUESTS,
  SET_WILLS_PROFILE,
  SET_PROFILE,
  SET_ADDRESS,
}

export type EstateIntakeContextAction =
  | {
      type: EstateIntakeContextActionType.SET_RELATIVES;
      payload: IRelativeResponse[];
    }
  | {
      type: EstateIntakeContextActionType.SET_RELATIVE_DEFINITIONS;
      payload: IRelationshipDefinition[];
    }
  | {
      type: EstateIntakeContextActionType.ADD_TO_RELATIVES;
      payload: IRelativeResponse;
    }
  | {
      type: EstateIntakeContextActionType.SET_POA_AVAILABILITY;
      payload: boolean;
    }
  | {
      type: EstateIntakeContextActionType.SET_MAIN_EXECUTOR;
      payload: IRelativeResponse | null;
    }
  | {
      type: EstateIntakeContextActionType.SET_BACKUP_EXECUTOR;
      payload: IRelativeResponse | null;
    }
  | {
      type: EstateIntakeContextActionType.SET_SPOUSE_EXECUTOR;
      payload: boolean;
    }
  | {
      type: EstateIntakeContextActionType.SET_MAIN_POA_PROPERTY;
      payload: IRelativeResponse | null;
    }
  | {
      type: EstateIntakeContextActionType.SET_BACKUP_POA_PROPERTY;
      payload: IRelativeResponse | null;
    }
  | {
      type: EstateIntakeContextActionType.SET_SPOUSE_POA_PROPERTY;
      payload: boolean;
    }
  | {
      type: EstateIntakeContextActionType.SET_MAIN_POA_PERSONAL_CARE;
      payload: IRelativeResponse | null;
    }
  | {
      type: EstateIntakeContextActionType.SET_BACKUP_POA_PERSONAL_CARE;
      payload: IRelativeResponse | null;
    }
  | {
      type: EstateIntakeContextActionType.SET_SPOUSE_POA_PERSONAL_CARE;
      payload: boolean;
    }
  | {
      type: EstateIntakeContextActionType.SET_MAIN_GUARDIAN;
      payload: IRelativeResponse | null;
    }
  | {
      type: EstateIntakeContextActionType.SET_BACKUP_GUARDIAN;
      payload: IRelativeResponse | null;
    }
  | {
      type: EstateIntakeContextActionType.SET_ALL_BEQUESTS;
      payload: IAllBequestsInfo;
    }
  | {
      type: EstateIntakeContextActionType.ADD_TO_BEQUESTS;
      payload: ISingleBequestInfo;
    }
  | {
      type: EstateIntakeContextActionType.SET_ENABLE_EDITING;
      payload: boolean;
    }
  | {
      type: EstateIntakeContextActionType.SET_WILLS_PROFILE;
      payload: IWillsProfile;
    }
  | {
      type: EstateIntakeContextActionType.SET_PROFILE;
      payload: PartialUserProfile;
    }
  | {
      type: EstateIntakeContextActionType.SET_ADDRESS;
      payload: IUserAddress;
    };
