import React from 'react';

export const withScrollIntoViewOnLoad = <P extends object>(WrappedComponent: React.ComponentType<P>) => {
  const ScrollIntoViewOnLoadComponent: React.FC<P> = (props) => {
    const childRef = React.useRef<HTMLElement>(null);

    React.useEffect(() => {
      if (childRef.current) {
        childRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }, []);

    return <WrappedComponent {...props} ref={childRef} />;
  };

  return ScrollIntoViewOnLoadComponent;
};
