import React from 'react';
import { BackArrowIcon, StyledGoBackButton } from './GoBackButton.styles';

export const GoBackButton: React.FC = () => {
  return (
    <StyledGoBackButton to="..">
      <BackArrowIcon />
      Back
    </StyledGoBackButton>
  );
};
