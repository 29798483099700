import styled from 'styled-components';
import { BiEditAlt } from 'react-icons/bi';
import { Palette } from '@/Shared/Constants';

export const StyledFormSummaryContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${Palette.PRIMARY_WHITE};
  padding: 1rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  flex-wrap: wrap;

  width: 100%;
  max-width: 50rem;
`;

export const StyledFormSummaryHeader = styled.div`
  font-family: 'SofiaPro-Regular';
  font-size: 1.5rem;
  font-weight: 500;
`;

export const StyledStatusText = styled.p`
  font-family: 'SofiaPro-Regular';
`;

export const StyledFormSummaryRowWrapper = styled.div<{ marginTop?: boolean; borderBottom?: boolean }>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: ${({ marginTop }) => marginTop && '1rem'};
  border-bottom: ${({ borderBottom }) => borderBottom && '1px solid #EAEAEC'};
  padding-bottom: ${({ borderBottom }) => borderBottom && '1rem'};
`;

export const StyledQuestion = styled.span`
  font-family: 'SofiaPro-Regular';
  color: #555969;
  font-size: 18px;
`;

export const StyledAnswer = styled.span<{ capitalize?: boolean }>`
  font-size: 18px;
  font-family: 'SofiaPro-Regular';
  font-weight: 400;
  color: ${Palette.NEW_BLUE};
  ${({ capitalize }) => capitalize && `text-transform: capitalize;`};
  word-break: break-word;
`;

export const StyledEditIcon = styled(BiEditAlt)`
  width: 1.8rem;
  height: 1.8rem;
  color: ${Palette.NEW_BLUE};
  cursor: pointer;
`;

export const StyledCompletedTag = styled.div`
  font-family: 'SofiaPro-Regular';
  background-color: #faebd2;
  padding: 0.7rem 1.1rem;
  color: ${Palette.NEW_BLUE};
  font-size: 1.125rem;
  display: grid;
  place-items: center;
  border-radius: 0.5rem;
`;
