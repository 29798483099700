import { isAllFlowsCompleted } from '@wills/helpers/isAllFlowsCompleted';
import { IWillsProfile } from '../../types';
import { IStepData } from './WillsSteps.types';

export const getWillsSteps = (
  willsProfile: IWillsProfile | null,
  addLinkPrefix: (link: string) => string,
): IStepData[] => {
  const activeStepName = getActiveStepName(willsProfile);
  return [
    {
      name: 'About You',
      description: `Here we get to know you a little better by asking your name, personal details,
        and your family at home.`,
      minutes: '3 minutes',
      active: activeStepName === 'aboutYou',
      complete: willsProfile?.isComplete || false,
      link: addLinkPrefix('about-you'),
    },
    {
      name: 'Select a Plan',
      description: `Pick a plan for you or save with our Premium Coverage for Families plan. Change
        your plan at any time.`,
      minutes: '3 minutes',
      active: activeStepName === 'yourPlan',
      complete: willsProfile?.isPlanComplete || false,
      link: addLinkPrefix(`plan`),
    },
    {
      name: 'Your family',
      description: `We ask about your family, so we can determine the percentage amounts which your
        relatives will inherit.`,
      minutes: '3 minutes',
      active: activeStepName === 'yourFamily',
      complete: willsProfile?.isFamilyComplete || false,
      link: addLinkPrefix(`family`),
    },
    {
      name: 'Your estate',
      description: `Appoint your Executor, Power of Attorney, Guardian for your Children, and
        share any Bequests you may want to give to people who are not recognized as potential
        inheritors.`,
      minutes: '3 minutes',
      active: activeStepName === 'yourEstate',
      complete: willsProfile?.isEstateComplete || false,
      link: addLinkPrefix(`estate`),
    },
  ];
};

function getActiveStepName(willsProfile: IWillsProfile | null) {
  if (willsProfile) {
    if (!willsProfile.isComplete) {
      return 'aboutYou';
    }
    if (!willsProfile.isPlanComplete) {
      return 'yourPlan';
    }
    if (!willsProfile.isFamilyComplete) {
      return 'yourFamily';
    }
    if (!willsProfile.isEstateComplete) {
      return 'yourEstate';
    }

    if (isAllFlowsCompleted(willsProfile)) {
      return 'none';
    }
  }
  return 'aboutYou';
}

export function getPreviousStep(willsStepsData: IStepData[], indexOfActiveStep: number) {
  if (indexOfActiveStep > 0) {
    return willsStepsData[indexOfActiveStep - 1];
  }
  return null;
}

export function calculateProgressPercentage(totalStpesCount: number, indexOfActiveStep: number) {
  return indexOfActiveStep === -1 ? 99 : (indexOfActiveStep / totalStpesCount) * 100;
}

export const dynamicWillsTitle = (willsProfile: IWillsProfile) => {
  if (
    !willsProfile ||
    (!willsProfile.isComplete &&
      !willsProfile.isPlanComplete &&
      !willsProfile.isFamilyComplete &&
      !willsProfile.isEstateComplete)
  ) {
    return 'We’ll cover 4 sections, first is all about you';
  }

  if (
    willsProfile.isComplete &&
    !willsProfile.isPlanComplete &&
    !willsProfile.isFamilyComplete &&
    !willsProfile.isEstateComplete
  ) {
    return 'Great! Next let’s select a plan, along with any add-ons';
  }

  if (
    willsProfile.isComplete &&
    willsProfile.isPlanComplete &&
    !willsProfile.isFamilyComplete &&
    !willsProfile.isEstateComplete
  ) {
    return 'We’re making progress! Now let’s learn about your family';
  }

  if (
    willsProfile.isComplete &&
    willsProfile.isPlanComplete &&
    willsProfile.isFamilyComplete &&
    !willsProfile.isEstateComplete
  ) {
    return 'Great job, we’re almost done! Let’s talk about your estate';
  }
  return 'Welcome Back';
};
