import React, { useContext, useState } from 'react';

import { AuthContext, SnackBarContext } from '@/providers';

import {
  StyledMfaContainer,
  StyledModalButton,
  StyledModalButtonGroup,
  StyledModalParagraph,
} from './EmailMfaModal.styles';
import { EModalStep, IEmailMfaModalProps } from './EmailMfaModal.types';
import { getEmailWithVerificationCode } from './EmailMfaModal.api';
import { StyledAuthCodeContainer, StyledModalInput } from '../../Profile.style';
import { getErrorMessage } from '@/Utils';

const EmailMfaModal = ({
  onConfirmMfaBtnClick,
  onCancel,
  disabled,
  onAuthCodeInputChange,
  authCode,
}: IEmailMfaModalProps) => {
  const { user } = useContext(AuthContext);
  const { setSnackMessage } = useContext(SnackBarContext);
  const [step, setStep] = useState(EModalStep.MESSAGE);
  const [emailSending, setEmailSending] = useState(false);

  async function onNextButtonClick() {
    setEmailSending(true);

    try {
      await getEmailWithVerificationCode();

      setEmailSending(false);
      setStep(EModalStep.AUTH_CODE);
    } catch (error: any) {
      const message = getErrorMessage(error);
      setSnackMessage(message);
    }
  }

  return (
    <StyledMfaContainer>
      {step === EModalStep.MESSAGE && (
        <>
          <StyledModalParagraph>
            Are you sure you want to receive verification codes at&nbsp;
            {user?.email}?
          </StyledModalParagraph>
          <StyledModalButtonGroup>
            <StyledModalButton secondary type="button" onClick={onCancel}>
              Cancel
            </StyledModalButton>
            <StyledModalButton type="button" disabled={disabled || emailSending} onClick={onNextButtonClick}>
              Yes
            </StyledModalButton>
          </StyledModalButtonGroup>
        </>
      )}

      {step === EModalStep.AUTH_CODE && (
        <>
          <StyledAuthCodeContainer>
            <StyledModalParagraph>Enter the code sent to your email</StyledModalParagraph>
            <StyledModalInput onChange={onAuthCodeInputChange} value={authCode || ''} />
          </StyledAuthCodeContainer>
          <StyledModalButtonGroup>
            <StyledModalButton secondary type="button" onClick={onCancel}>
              Cancel
            </StyledModalButton>
            <StyledModalButton type="button" disabled={disabled || !authCode} onClick={onConfirmMfaBtnClick}>
              Confirm
            </StyledModalButton>
          </StyledModalButtonGroup>
        </>
      )}
    </StyledMfaContainer>
  );
};

export default EmailMfaModal;
