import React from 'react';
import { createUserReasonsForJoining as callApiCreateUserReasonsForJoining } from '@user/apis';
import { UserContext } from '@/lib/user/UserContext';

export const useCreateUserReasonsForJoining = ({ request = callApiCreateUserReasonsForJoining } = {}) => {
  const { dispatch } = React.useContext(UserContext);

  const createUserReasonsForJoining: typeof callApiCreateUserReasonsForJoining = async (input) => {
    const response = await request(input);

    if (response.data) {
      dispatch({
        type: 'SET_USER_REASONS_FOR_JOINING',
        value: response.data,
      });
    }

    return response;
  };

  return {
    createUserReasonsForJoining,
  };
};
