import styled, { css } from 'styled-components';
import { Breakpoints } from '@/Utils/breakpoints';
import { Palette } from '@/Shared/Constants';

export const aboutYouWidth = css`
  max-width: 90%;
  @media screen and (min-width: ${Breakpoints.DESKTOP_MIN_WIDTH}) {
    max-width: 75%;
  }
`;

export const content = css`
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
`;

export const AboutYouContainerWithContent = styled.div`
  ${aboutYouWidth}
  ${content}
`;

export const AboutYouContainer = styled.div`
  ${aboutYouWidth}
`;

export const Content = styled.div`
  ${content}
`;

export const RadioButtonContainer = styled.label<{ selected?: boolean }>`
  position: relative;
  display: flex;
  gap: 0.6rem;
  background-color: ${({ selected }) => (selected ? Palette.NEW_BLUE : Palette.ATHENS_GREY)};
  color: ${({ selected }) => (selected ? Palette.ATHENS_GREY : Palette.NEW_BLUE)};
  padding: 0.5rem 1.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  align-items: center;
  min-width: 9rem;
  justify-content: center;

  input[type='radio'] {
    position: absolute;
    opacity: 0;
  }
`;

export const ErrorMessage = styled.p`
  color: red;
  text-align: center;
  font-family: 'SofiaPro-Regular';
  font-size: 1.1rem;
  margin: 1rem 0;
`;
