import styled from 'styled-components';
import { Dropdown } from '@/lib/wills/components';

export const StyledSelect = styled(Dropdown)<{ widthFull?: boolean }>`
  color: black;
  font-size: 1rem;
  padding: 0.65rem 0.875rem;
  border-radius: 0.5rem;
  width: ${({ widthFull }) => (widthFull ? '100%' : '20rem')};
  border: none;
`;
