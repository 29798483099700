import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';

import { RelationshipDefinitionContext } from '@wills/pages/WillsFamilyFlow/contexts/RelationshipDefinitionContext';
import { NavigationButtons } from '../NavigationButtons';
import {
  StyledRelativeAdderForm,
  StyledRelativeAdderFormButtonGroup,
  StyledRelativeAdderFormInput,
  StyledRelativeAdderFormInputGroup,
  StyledRelativeAdderFormLabel,
  StyledRelativeAdderFormName,
  StyledRelativeAdderInputHelperText,
  StyledRelativeFormContainer,
  StyledRelativeFormInputContainer,
} from './RelativeAdderForm.styles';
import { normalizeDateOnChange } from '@/lib/user/pages/Profile/helpers/normalizeDateOnChange';
import { IRelativeAdderFormProps, RelativeFormAdderValues } from './RelativeAdderForm.types';
import { normalizeDate } from '@/lib/user/pages/Profile/helpers/normalizeDateOnChange/normalizeDate';
import { isValidEmail } from '@/Utils/isValidEmail';
import { WillsFamilyFlowContext } from '../../WillsFamilyFlow.context';
import { isDobValid } from './RelativeAdderFormHelper';
import { ProfileStore } from '@/Stores';
import { formatPhoneNumber } from '@/Utils/phone/formatPhoneNumber';
import { maskPhoneNumber } from '@/Utils/phone/maskPhoneNumber';

const RelativeAdderForm = ({
  relativeDefinitionId,
  relationship,
  title = 'Details',
  onCancel,
  onSubmit,
}: IRelativeAdderFormProps) => {
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitting },
  } = useForm<RelativeFormAdderValues>({
    defaultValues: {
      firstName: relationship?.firstName,
      lastName: relationship?.lastName,
      dob: relationship?.dob,
      email: relationship?.email,
      phone: relationship?.phone ?? '',
    },
    mode: 'onBlur',
  });
  const { primaryRelationships } = useContext(WillsFamilyFlowContext);
  const {
    relationshipDefinitionsGroups: { all: relationshipDefinitions },
  } = React.useContext(RelationshipDefinitionContext);
  const { profile } = useContext(ProfileStore);

  const onSubmitWrapper = handleSubmit((data) => {
    const cleanData: RelativeFormAdderValues = {
      ...data,
      firstName: data.firstName.trim(),
      lastName: data.lastName.trim(),
      email: data.email.trim(),
    };
    return onSubmit(cleanData);
  });

  return (
    <StyledRelativeFormContainer>
      <StyledRelativeAdderFormName>{title}</StyledRelativeAdderFormName>
      <StyledRelativeAdderForm onSubmit={onSubmitWrapper}>
        <StyledRelativeFormInputContainer>
          <StyledRelativeAdderFormInputGroup>
            <StyledRelativeAdderFormLabel htmlFor="firstName">First Name</StyledRelativeAdderFormLabel>
            <StyledRelativeAdderFormInput
              id="firstName"
              type="text"
              placeholder="First Name"
              {...register('firstName', { required: 'First name is required' })}
            />
            {errors.firstName ? (
              <StyledRelativeAdderInputHelperText>{errors.firstName.message}</StyledRelativeAdderInputHelperText>
            ) : null}
          </StyledRelativeAdderFormInputGroup>
          <StyledRelativeAdderFormInputGroup>
            <StyledRelativeAdderFormLabel htmlFor="lastName">Last Name</StyledRelativeAdderFormLabel>
            <StyledRelativeAdderFormInput
              id="lastName"
              type="text"
              placeholder="Last Name"
              {...register('lastName', { required: 'Last name is required' })}
            />
            {errors.lastName ? (
              <StyledRelativeAdderInputHelperText>{errors.lastName.message}</StyledRelativeAdderInputHelperText>
            ) : null}
          </StyledRelativeAdderFormInputGroup>
        </StyledRelativeFormInputContainer>
        <StyledRelativeFormInputContainer>
          <StyledRelativeAdderFormInputGroup>
            <StyledRelativeAdderFormLabel htmlFor="dob">Date of birth</StyledRelativeAdderFormLabel>
            <StyledRelativeAdderFormInput
              id="dob"
              type="text"
              placeholder="yyyy-mm-dd"
              {...register('dob', {
                required: 'Date of Birth is required',
                onChange: (e) => {
                  normalizeDateOnChange(e);
                },
                validate: (data) => {
                  const date: string = normalizeDate(data);
                  const { isValid, message } = isDobValid(
                    data,
                    relationshipDefinitions,
                    profile?.dob!,
                    relativeDefinitionId,
                    primaryRelationships,
                  );
                  if (String(new Date(date ?? 'bad date')) === 'Invalid Date') {
                    return 'invalid date';
                  }
                  if (!isValid) {
                    return message;
                  }
                  setValue('dob', date);
                  return true;
                },
              })}
            />
            {errors.dob ? (
              <StyledRelativeAdderInputHelperText htmlFor="dob">
                {errors.dob.message}
              </StyledRelativeAdderInputHelperText>
            ) : null}
          </StyledRelativeAdderFormInputGroup>
          <StyledRelativeAdderFormInputGroup>
            <StyledRelativeAdderFormLabel htmlFor="email">Email</StyledRelativeAdderFormLabel>
            <StyledRelativeAdderFormInput
              id="email"
              placeholder="Email"
              type="email"
              {...register('email', {
                required: 'Email is required',
                validate: (data) => {
                  return isValidEmail(data) || 'Invalid email address';
                },
              })}
            />
            {errors.email ? (
              <StyledRelativeAdderInputHelperText htmlFor="email" type="error">
                {errors.email.message}
              </StyledRelativeAdderInputHelperText>
            ) : (
              <StyledRelativeAdderInputHelperText htmlFor="email" type="info">
                If underage, use parents email
              </StyledRelativeAdderInputHelperText>
            )}
          </StyledRelativeAdderFormInputGroup>
        </StyledRelativeFormInputContainer>
        <StyledRelativeAdderFormInputGroup>
          <StyledRelativeAdderFormLabel htmlFor="phone">Phone (optional)</StyledRelativeAdderFormLabel>
          <StyledRelativeAdderFormInput
            id="phone"
            type="tel"
            placeholder="Phone"
            {...register('phone', {
              onChange: formatPhoneNumber,
              validate: (data) => {
                const phone = maskPhoneNumber(data ?? '');

                if (phone === '') {
                  return true;
                }
                return /^\(\d{3}\) \d{3}-\d{4}$/.test(phone) || 'Invalid phone number';
              },
            })}
          />
          {errors.phone ? (
            <StyledRelativeAdderInputHelperText htmlFor="phone">
              {errors.phone.message}
            </StyledRelativeAdderInputHelperText>
          ) : null}
        </StyledRelativeAdderFormInputGroup>

        <StyledRelativeAdderFormButtonGroup>
          <NavigationButtons
            confirmBtnText={relationship ? 'Save' : 'Add'}
            cancelBtnText="Cancel"
            confirmBtnType="submit"
            continueDisbaled={isSubmitting}
            onContinue={handleSubmit(onSubmit)}
            onPrevious={onCancel}
          />
        </StyledRelativeAdderFormButtonGroup>
      </StyledRelativeAdderForm>
    </StyledRelativeFormContainer>
  );
};

export default RelativeAdderForm;
