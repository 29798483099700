export const ASSET_TYPE = [
  {
    id: 1,
    value: 'Account',
  },
  {
    id: 2,
    value: 'Financial Account',
  },
  {
    id: 3,
    value: 'Physical Asset',
  },
  {
    id: 4,
    value: 'Policies',
  },
  {
    id: 5,
    value: 'Other',
  },
];

export const ASSET_OWNERSHIP = [
  {
    value: 100,
    label: '100%',
  },
  {
    value: 50,
    label: '50%',
  },
  {
    value: 0,
    label: 'Other',
  },
] as const;
