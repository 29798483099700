import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const StyledToggleButtonTrack = styled.div<{ on: string }>`
  border-radius: 50rem;
  height: 2.5rem;
  width: 4.5rem;
  padding: 0.3rem;
  background-color: ${Palette.SLATE_GREEN};
  display: flex;
  align-items: center;
  transition: all 0.2s;

  ${({ on }) =>
    on === 'true'
      ? `
    background-color: ${Palette.SLATE_GREEN};
  `
      : `
    background-color: ${Palette.CADET_BLUE};
  `}
`;

export const StyledToggleButtonThumb = styled.div<{ on: string }>`
  border-radius: 50%;
  height: 2rem;
  width: 2rem;
  background-color: ${Palette.WHITE};
  transition: all 0.2s;

  ${({ on }) =>
    on === 'true'
      ? `
    transform: translateX(1.9rem);
  `
      : `
    transform: translateX(0);
  `}
`;
