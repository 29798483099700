const isObject = (value: any) => {
  return value instanceof Object && !Array.isArray(value);
};

const capitalizeFirstLetter = (value: string): string => {
  return value[0].toUpperCase() + value.substring(1).toLowerCase();
};

const snakeToCamel = (value: string): string => {
  const splittedKeys = value.split('_');
  if (splittedKeys.length > 1) {
    let formattedKey = '';
    const transformedKeys = splittedKeys.map((key, index) => {
      if (index !== 0) {
        const capitalizedKey = capitalizeFirstLetter(key);
        formattedKey += capitalizedKey;
      } else {
        formattedKey += key;
      }
      return formattedKey;
    });
    return transformedKeys[splittedKeys.length - 1];
  }
  return value;
};

const convertAllKeysToCamelCase = (keys: string[]): string[] => {
  return keys.map((key) => {
    const formatterKey = snakeToCamel(key);
    return formatterKey;
  });
};

export const snakeToCamelParser = (object: any) => {
  const transformedObject: any = {};
  const originalKeys = Object.keys(object);
  const keys = convertAllKeysToCamelCase(originalKeys);

  keys.forEach((_, index) => {
    const originalValue = object[originalKeys[index]];
    if (Array.isArray(originalValue)) {
      const transformedChildObject = originalValue.map((childObject: any) => snakeToCamelParser(childObject));
      transformedObject[keys[index]] = transformedChildObject;
    } else if (isObject(originalValue)) {
      const transformedChildObject = snakeToCamelParser(originalValue);
      transformedObject[keys[index]] = transformedChildObject;
    } else {
      transformedObject[keys[index]] = originalValue;
    }
  });

  return transformedObject;
};
