/* eslint-disable max-len */
export const REVIEWS = [
  {
    id: 1,
    title: 'IT’S SO SIMPLE',
    description:
      'Alhamduli’Allah a digital platform to do my simple will. Jazākum Allahu Khayran for creating this for our community. It makes an inconvenient experience very convenient.',
    author: 'Mohammad',
  },
  {
    id: 2,
    title: 'PERFECT FOR MY MOTHER',
    description:
      "This is great, this was perfect for my mother. It didn't take long to get this done and now we can update it when needed with the subscription. Making an Islamic Will outside of this was too complicated and was costing way too much.",
    author: 'Ahmad',
  },
  {
    id: 3,
    title: 'FINALLY!',
    description:
      'I’m glad there’s something out there that can calculate the distribution of my Will according to the Quran. It puts my heart at ease. I hope you continue to improve the product and provide more services like these.',
    author: 'Fatima',
  },
  {
    id: 4,
    title: 'GREAT UI/UX',
    description:
      'MashAllah the UI/UX was great and I was able to complete my Will so quickly. Adding the immediate family was so easy. There were so many things I had never thought about before, so I’m glad I was able to figure that out too with the help of this product.',
    author: 'Hannan',
  },
];
