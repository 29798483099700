import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const IntroductionContainerWrapper = styled.div`
  display: flex;
  width: 70%;
  height: 100%;
  flex-grow: 1;

  @media (max-width: 1024px) {
    width: 90%;
  }

  @media (max-width: 720px) {
    flex-direction: column;
    gap: 5rem;
  }
`;

export const IntroductionContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: justify;
`;

export const StyledDisclaimer = styled.div`
  font-family: 'SofiaPro-Regular';
  margin-top: 3rem;
  color: ${Palette.DOVE_GREY};
  font-size: 0.8rem;
  line-height: 140%;
`;

export const IntroductionContent = styled.div`
  width: 80%;
  height: 80%;

  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const StepList = styled.div`
  margin: 3rem 0 4rem 2rem;
  display: flex;
  flex-direction: column;
  gap: 2.3rem;
`;
