import styled from 'styled-components';
import { Palette } from '@/Shared/Constants';

export const StyledIntroductionContainer = styled.div`
  width: 100%;
  height: 100%;
  display: grid;
  place-items: center;
`;

export const StyledIntroductionTopContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${Palette.AQUA_HAZE};
  padding-bottom: 2rem;
`;

export const StyledIntroductionTopContentWrapper = styled.div`
  max-width: 75%;
  height: 100%;
  flex-grow: 1;
  display: grid;
  place-items: center;

  @media screen and (max-width: 1024px) {
    max-width: 90%;
  }
`;

export const StyledIntroductionBottomContainer = styled(StyledIntroductionTopContainer)`
  padding: 5rem 0;
  background-color: ${Palette.NEW_WHITE};
`;

export const StyledHeaderDescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;
  gap: 2rem;
`;

export const StyledIntroductionBottomContentWrapper = styled(StyledIntroductionTopContentWrapper)``;

export const StyledAllReviews = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const StyledIntroductionTopWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const StyledIntroductionMiddleWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2rem;
`;

export const StyledIntroductionBottomWrapper = styled.div``;

export const StyledReviewContainer = styled.div`
  background-color: white;
`;

export const StyledImg = styled.img``;

export const StyledHeader = styled.h2<{ maxWidthSm?: boolean; center?: boolean }>`
  font-family: 'SofiaPro-Regular';
  font-weight: 600;
  color: ${Palette.NEW_BLUE};
  line-height: 2.8rem;
  font-size: clamp(1.875rem, 2vw, 2.25rem);
  text-align: ${({ center }) => center && 'center'};

  @media screen and (min-width: 600px) {
    max-width: ${({ maxWidthSm }) => maxWidthSm && '25em'};
  }
`;
