import React, { useContext } from 'react';

import { AuthContext, LocaleContext } from '@/providers';

import { DisplayInfo } from '../DisplayInfo';
import {
  InfoWrapper,
  FormContent,
  FormContentHeader,
  ContentHeading,
  EditIconContainer,
  EditIcon,
  ProfileForm,
  ProfileContainer,
  UserIcon,
} from '../../Profile.style';
import securityDisplayLocales from './securityDisplayLocales.json';
import { ISecurityDisplayProps } from './SecurityDisplay.types';
import { EDataTestId } from '@/test-utils/EDataTestId';

const SecurityDisplay = ({ isEditMode, setIsEditMode }: ISecurityDisplayProps) => {
  const { locale } = useContext(LocaleContext);
  const { user } = useContext(AuthContext);

  const securityDisplayLocale = securityDisplayLocales[locale];

  return (
    <ProfileContainer data-testid={EDataTestId.USER_SECURITY_DISPLAY}>
      <ProfileForm>
        <FormContent>
          <FormContentHeader>
            <ContentHeading>{securityDisplayLocale['card.heading']}</ContentHeading>
            <ContentHeading>
              <EditIconContainer show={isEditMode}>
                <EditIcon onClick={() => setIsEditMode(true)} aria-label="edit" />
              </EditIconContainer>
            </ContentHeading>
          </FormContentHeader>

          <InfoWrapper>
            <DisplayInfo icon={<UserIcon />} header="MFA Method" value={user && user.mfaType ? user.mfaType : 'None'} />
          </InfoWrapper>
        </FormContent>
      </ProfileForm>
    </ProfileContainer>
  );
};

export default SecurityDisplay;
