import React from 'react';
import { createUserProfile as callApiCreateUserProfile } from '@user/apis';
import { ProfileStore } from '@/Stores';

export const useCreateUserProfile = ({ request = callApiCreateUserProfile } = {}) => {
  const { profile, setProfile } = React.useContext(ProfileStore);

  const createUserProfile: typeof callApiCreateUserProfile = async (input) => {
    if (profile) {
      throw new Error('UserProfile exists: Cannot create profile');
    }
    const response = await request(input);

    if (response.data) {
      setProfile({
        ...response.data,
      });
    }
    return response;
  };

  return {
    userProfile: profile,
    createUserProfile,
  };
};
