import styled from 'styled-components';

import { Palette } from '@/Shared/Constants';

export const StepInfoContainer = styled.div``;

export const StepItem = styled.div`
  display: flex;
  color: ${Palette.INDEPENDENCE_BLUE};
  align-items: center;
  margin-bottom: 0.5rem;
`;

export const StepNumber = styled.div<{ active?: boolean }>`
  border-radius: 50%;
  background-color: ${({ active }) => (active ? Palette.GREEN_SHEEN : Palette.GOLD_CRAYOLA)};
  color: ${({ active }) => (active ? Palette.WHITE : Palette.INDEPENDENCE_BLUE)};
  font-family: 'SofiaPro-Bold';
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 1rem;
  transform: translateY(-0.1rem);
`;

export const StepTile = styled.div`
  font-family: 'SofiaPro-Bold';
`;

export const StepDescription = styled.div`
  font-family: 'SofiaPro-Regular';
  color: ${Palette.DOVE_GREY};
  margin-left: 2.5rem;
`;
