import React, { useContext, useEffect, useState, useRef } from 'react';
import { SetEstateExecutorsInput, setEstateExecutors } from '@wills/apis';
import { getErrorMessage } from '@/Utils';
import { SnackBarContext } from '@/providers';
import { Modal } from '@/Shared/Components';
import { EstateIntakeContext } from '../../contexts/EstateIntakeContext/EstateIntakeContext';
import { StyledWillsIntroText, StyledWillsTitle } from '../../components/styles';
import {
  StyledNavigationButtonsWrapper,
  StyledRelativeTipsWrapper,
  StyledExecutorAndEstateTrusteeContentContainer,
  StyledExecutorAndEstateTrusteeGrid,
  StyledExecutorAndEstateTrusteeInfoContainer,
  StyledExecutorAndEstateTrusteePageContainer,
  StyledExecutorAndEstateTextMessage,
  StyledExecutorAndEstateChooserContainer,
  StyledExecutorAndEstateChooserContent,
  StyledBackupExecutorAndTrusteesContainer,
  StyledBackupExecutorAndTrusteesContent,
  StyledBackupTrusteesContainer,
  StyledErrorMessage,
} from './ExecutorAndEstateTrustee.styles';

import {
  StyledExecutorAndEstateChooser,
  StyledExecutorChooserRadioButtonLabel,
  StyledExecutorChooserText,
} from '../../components/ElementChooser/ElementChooser.styles';
import ExecutorAndEstateQuestionInfo from './ExecutorAndEstateQuestionInfo';
import { StyledInfoIcon, StyledInfoIconContainer, StyledInfoIconWrapper } from '@/lib/wills/components/styles';
import { ElementChooser, RelativeAdder, NavigationButtons, handleNavigationButtons } from '../../components';
import { EstateIntakeContextActionType } from '../../contexts';
import { getNonSpouseRelatives, getSpouseRelative } from '../../helpers';

export const ExecutorAndEstateTrustee: React.FC<{
  onBack: () => void;
  onNext: () => void;
  onSummary: () => void;
}> = ({ onBack, onNext, onSummary }) => {
  const { state, dispatch } = useContext(EstateIntakeContext);
  const { setSnackMessage } = useContext(SnackBarContext);
  const [isSubmitting, setSubmitting] = useState(false);
  const errorMsgRef = useRef<HTMLParagraphElement>(null);
  const [showErrorMessage, setShowErrorMessage] = useState({
    showSpouseOptionError: false,
    showWithoutSpouseOptionError: false,
  });
  const [showInfoBlurb, setShowInfoBlurb] = useState(false);

  const spouseRelative = getSpouseRelative(state.adultRelatives);
  const relativesWithoutSpouse = getNonSpouseRelatives(state.adultRelatives);

  const relativeDefinitions = state.relativeDefinitions.filter(
    (relDef) => ['OTHER', 'GENERAL'].includes(relDef.category) && relDef.relationship !== 'charity',
  );
  const relativesWithoutMainExecutor = relativesWithoutSpouse.filter(
    (relative) => relative.id !== state.executorAndEstateTrustees.main?.id,
  );

  async function onContinueBtnClick(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (state.executorAndEstateTrustees.isSpouseSelected === null) {
      setShowErrorMessage({ ...showErrorMessage, showSpouseOptionError: true });
      return;
    }

    if (!state.executorAndEstateTrustees.main) {
      setShowErrorMessage({ ...showErrorMessage, showWithoutSpouseOptionError: true });
      return;
    }

    if (isSubmitting) return;

    setSubmitting(true);

    const executorData: SetEstateExecutorsInput = {
      mainExecutorId: state.executorAndEstateTrustees.main.id as number,
      backupExecutorId: state.executorAndEstateTrustees.backup
        ? (state.executorAndEstateTrustees.backup.id as number)
        : null,
    };

    try {
      const { error } = await setEstateExecutors(executorData);
      if (error) {
        throw new Error(error.message);
      }
      handleNavigationButtons(event, onNext, onSummary);
    } catch (error: any) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    } finally {
      setSubmitting(false);
    }
  }

  const hideErrorMessage = (option: string) => {
    setShowErrorMessage({ ...showErrorMessage, [option]: false });
  };

  useEffect(() => {
    if (errorMsgRef.current) {
      errorMsgRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  });

  return (
    <StyledExecutorAndEstateTrusteePageContainer>
      <StyledExecutorAndEstateTrusteeContentContainer>
        <StyledExecutorAndEstateTrusteeGrid>
          <StyledExecutorAndEstateTrusteeInfoContainer>
            <StyledWillsIntroText center>Your estate</StyledWillsIntroText>
            <StyledWillsTitle center>Executor and Estate Trustee</StyledWillsTitle>
            {spouseRelative && (
              <>
                <StyledExecutorAndEstateTextMessage center>
                  Do you choose your spouse
                  {spouseRelative && ` ${spouseRelative.firstName},`}
                  &nbsp;to act in this role?
                </StyledExecutorAndEstateTextMessage>
                {showErrorMessage.showSpouseOptionError && (
                  <StyledErrorMessage ref={errorMsgRef} marginBottomZero>
                    Please select one of the options
                  </StyledErrorMessage>
                )}
                <StyledExecutorAndEstateChooserContainer>
                  <StyledExecutorAndEstateChooserContent>
                    <ElementChooser
                      id="spouse-as-trustee-yes"
                      checked={state.executorAndEstateTrustees.isSpouseSelected === true}
                      onClick={() => {
                        hideErrorMessage('showSpouseOptionError');
                        if (spouseRelative) {
                          dispatch({
                            type: EstateIntakeContextActionType.SET_SPOUSE_EXECUTOR,
                            payload: true,
                          });
                          dispatch({
                            type: EstateIntakeContextActionType.SET_MAIN_EXECUTOR,
                            payload: spouseRelative,
                          });
                        }
                      }}
                      value="Yes"
                    />
                    <ElementChooser
                      id="spouse-as-trustee-no"
                      checked={state.executorAndEstateTrustees.isSpouseSelected === false}
                      onClick={() => {
                        hideErrorMessage('showSpouseOptionError');
                        dispatch({
                          type: EstateIntakeContextActionType.SET_SPOUSE_EXECUTOR,
                          payload: false,
                        });
                        dispatch({
                          type: EstateIntakeContextActionType.SET_MAIN_EXECUTOR,
                          payload: null,
                        });
                      }}
                      value="No"
                    />
                  </StyledExecutorAndEstateChooserContent>
                </StyledExecutorAndEstateChooserContainer>
              </>
            )}
            {state.executorAndEstateTrustees.isSpouseSelected === false && (
              <StyledBackupTrusteesContainer>
                <StyledExecutorAndEstateTextMessage center>
                  Who do you choose to act in this role?
                </StyledExecutorAndEstateTextMessage>
                <StyledBackupExecutorAndTrusteesContainer>
                  {showErrorMessage.showWithoutSpouseOptionError && (
                    <StyledErrorMessage ref={errorMsgRef}>Please select one of the options</StyledErrorMessage>
                  )}
                  <StyledBackupExecutorAndTrusteesContent>
                    {relativesWithoutSpouse.map((relative) => (
                      <ElementChooser
                        key={relative.id}
                        id={`main-executor-${relative.id}`}
                        checked={relative === state.executorAndEstateTrustees.main}
                        onClick={() => {
                          hideErrorMessage('showWithoutSpouseOptionError');
                          dispatch({
                            type: EstateIntakeContextActionType.SET_MAIN_EXECUTOR,
                            payload: relative,
                          });
                        }}
                        value={`${relative.firstName} ${relative.lastName}`}
                      />
                    ))}
                    <RelativeAdder
                      action={EstateIntakeContextActionType.SET_MAIN_EXECUTOR}
                      btnText="Add a Executor and Estate Trustee +"
                      relativeDefinitions={relativeDefinitions}
                    />
                  </StyledBackupExecutorAndTrusteesContent>
                </StyledBackupExecutorAndTrusteesContainer>
              </StyledBackupTrusteesContainer>
            )}

            {state.executorAndEstateTrustees.main && (
              <StyledBackupTrusteesContainer>
                <StyledExecutorAndEstateTextMessage center>
                  Backups are recommended, would you like to add one?
                </StyledExecutorAndEstateTextMessage>
                <StyledBackupExecutorAndTrusteesContainer>
                  <StyledBackupExecutorAndTrusteesContent>
                    <StyledExecutorAndEstateChooser>
                      <StyledExecutorChooserRadioButtonLabel htmlFor="None">
                        <input
                          id="None"
                          type="radio"
                          checked={!state.executorAndEstateTrustees.backup}
                          onChange={() =>
                            dispatch({
                              type: EstateIntakeContextActionType.SET_BACKUP_EXECUTOR,
                              payload: null,
                            })
                          }
                          value=""
                        />
                        <StyledExecutorChooserText>None</StyledExecutorChooserText>
                      </StyledExecutorChooserRadioButtonLabel>
                    </StyledExecutorAndEstateChooser>
                    {relativesWithoutMainExecutor.map((relative) => {
                      return (
                        <ElementChooser
                          key={relative.id}
                          id={`backup-executor-${relative.id}`}
                          checked={relative === state.executorAndEstateTrustees.backup}
                          onClick={() =>
                            dispatch({
                              type: EstateIntakeContextActionType.SET_BACKUP_EXECUTOR,
                              payload: relative,
                            })
                          }
                          value={`${relative.firstName} ${relative.lastName}`}
                        />
                      );
                    })}
                    <RelativeAdder
                      action={EstateIntakeContextActionType.SET_BACKUP_EXECUTOR}
                      btnText="Add a Executor and Estate Trustee +"
                      relativeDefinitions={relativeDefinitions}
                    />
                  </StyledBackupExecutorAndTrusteesContent>
                </StyledBackupExecutorAndTrusteesContainer>
              </StyledBackupTrusteesContainer>
            )}

            <StyledInfoIconWrapper>
              <StyledInfoIconContainer onClick={() => setShowInfoBlurb(true)}>
                <StyledInfoIcon />
              </StyledInfoIconContainer>
            </StyledInfoIconWrapper>

            {showInfoBlurb && (
              <Modal transparent onCloseCallback={() => setShowInfoBlurb(false)}>
                <ExecutorAndEstateQuestionInfo />
              </Modal>
            )}

            <StyledNavigationButtonsWrapper>
              <NavigationButtons onContinue={onContinueBtnClick} onPrevious={onBack} />
            </StyledNavigationButtonsWrapper>
          </StyledExecutorAndEstateTrusteeInfoContainer>

          <StyledRelativeTipsWrapper>
            <ExecutorAndEstateQuestionInfo />
          </StyledRelativeTipsWrapper>
        </StyledExecutorAndEstateTrusteeGrid>
      </StyledExecutorAndEstateTrusteeContentContainer>
    </StyledExecutorAndEstateTrusteePageContainer>
  );
};
