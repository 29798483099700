import { axiosClient, toApiResponse } from '@/lib/axios';
import { IWish } from './types';

export interface UpdateWishInput {
  title?: string;
  details?: string;
}
export const updateWish = (id: number, input: UpdateWishInput) => {
  return toApiResponse<IWish>(axiosClient.put(`/wills/wish-list/wish/${id}`, input));
};
