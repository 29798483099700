import React, { useState, useContext } from 'react';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm } from 'react-hook-form';
import {
  StyledContactUsFormInput,
  StyledContactUsFormInputContainer,
  StyledContactUsFormLabel,
  StyledForm,
  StyledContactUsFormTextareaField,
  StyledSubmitBtn,
  StyledFormHeader,
} from './Form.styles';
import { IContactUsFormData, IFormProps } from './Form.types';
import { FormSuccess } from '../FormSuccess';
import { createZendeskTicketFormBody } from './Form.helpers';
import { createZendeskTicket } from './Form.api';
import { getErrorMessage } from '@/Utils';
import { SnackBarContext } from '@/providers';
import { formatPhoneNumber } from '@/Utils/phone/formatPhoneNumber';

const Form = ({ setIsModalOpen }: IFormProps) => {
  const schema = yup.object({
    firstName: yup.string().required(),
    lastName: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string(),
    subject: yup.string().required(),
    message: yup.string().required(),
  });
  const { handleSubmit, register, reset } = useForm<IContactUsFormData>({ resolver: yupResolver(schema) });
  const { setSnackMessage } = useContext(SnackBarContext);
  const [isFormSubmitSuccessful, setIsFormSubmitSuccessful] = useState(false);

  const onPhoneNoChangeHandler = (event: React.KeyboardEvent<HTMLInputElement>) => {
    formatPhoneNumber(event);
  };

  const onContactUsFormSubmit: SubmitHandler<IContactUsFormData> = async (data) => {
    const body = createZendeskTicketFormBody(data);

    try {
      await createZendeskTicket(body);
      setIsFormSubmitSuccessful(true);
      reset();
    } catch (error) {
      const errorMessage = getErrorMessage(error);
      setSnackMessage(errorMessage);
    }
  };

  const renderFormComponent = isFormSubmitSuccessful ? (
    <FormSuccess setIsModalOpen={setIsModalOpen} />
  ) : (
    <>
      <StyledFormHeader>Contact Form</StyledFormHeader>
      <StyledForm onSubmit={handleSubmit(onContactUsFormSubmit)}>
        <StyledContactUsFormInputContainer>
          <StyledContactUsFormLabel>First Name</StyledContactUsFormLabel>
          <StyledContactUsFormInput placeholder="First name" {...register('firstName', { required: true })} />
        </StyledContactUsFormInputContainer>
        <StyledContactUsFormInputContainer>
          <StyledContactUsFormLabel>Last Name</StyledContactUsFormLabel>
          <StyledContactUsFormInput placeholder="Last name" {...register('lastName', { required: true })} />
        </StyledContactUsFormInputContainer>
        <StyledContactUsFormInputContainer>
          <StyledContactUsFormLabel>Email *</StyledContactUsFormLabel>
          <StyledContactUsFormInput placeholder="Email" {...register('email', { required: true })} />
        </StyledContactUsFormInputContainer>
        <StyledContactUsFormInputContainer>
          <StyledContactUsFormLabel>Phone (Optional)</StyledContactUsFormLabel>
          <StyledContactUsFormInput placeholder="Phone" {...register('phone', { onChange: onPhoneNoChangeHandler })} />
        </StyledContactUsFormInputContainer>
        <StyledContactUsFormInputContainer>
          <StyledContactUsFormInput placeholder="Subject*" {...register('subject', { required: true })} />
        </StyledContactUsFormInputContainer>
        <StyledContactUsFormInputContainer>
          <StyledContactUsFormTextareaField
            placeholder="Message*"
            rows={5}
            {...register('message', { required: true })}
          />
        </StyledContactUsFormInputContainer>
        <StyledSubmitBtn>Submit</StyledSubmitBtn>
      </StyledForm>
    </>
  );

  return (
    <>
      {renderFormComponent}
      {}
    </>
  );
};

export default Form;
