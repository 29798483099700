import { axiosClient, toApiResponse } from '@/lib/axios';
import { PopulatedDocumentType } from './types';

export const getDocumentDownloadUrl = async (type: PopulatedDocumentType) => {
  return toApiResponse<string>(
    axiosClient.post(
      '/wills/document/download',
      {},
      {
        params: {
          'document-type': type,
        },
      },
    ),
  );
};
