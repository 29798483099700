export function bindLoadingToPromise<T>(
  promise: Promise<T>,
  loadingStateSetter: (loading: boolean) => void,
): Promise<T> {
  loadingStateSetter(true);
  return promise.finally(() => loadingStateSetter(false));
}

export const bindFuncToAsync =
  (loadingStateSetter: (loading: boolean) => void) =>
  <T, Args extends any[]>(promise: (...args: Args) => Promise<T>): ((...args: Args) => Promise<T>) => {
    return (...args) => {
      loadingStateSetter(true);
      return promise(...args).finally(() => loadingStateSetter(false));
    };
  };
