import React from 'react';

import { CloseIcon, StyledModalBackground, StyledModalContainer } from './Modal.styles';

interface IModalProps {
  children: React.ReactNode;
  onCloseCallback: () => void;
  transparent?: boolean;
  width?: string;
}

const Modal = ({ children, onCloseCallback, width, transparent = false }: IModalProps) => {
  return (
    <StyledModalBackground onClick={onCloseCallback}>
      <StyledModalContainer transparent={transparent} width={width} onClick={(e) => e.stopPropagation()}>
        <CloseIcon onClick={onCloseCallback} />
        {children}
      </StyledModalContainer>
    </StyledModalBackground>
  );
};

export default Modal;
